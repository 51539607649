import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import axios from "axios";
import moment from "moment";

import API from "../../../config/config.json";

import IconAvatar from "../../../assets/svg/avatar.svg";
import IconBookmarksActive from "../../../assets/icons/IconBookmarksBlue.png";
import IconBookmarks from "../../../assets/icons/IconBookmarks.png";
import IconPlayWhite from "../../../assets/svg/playWhite.svg";
import IconPlayBlack from "../../../assets/svg/playBlack.svg";
import PostBookmarkActiveSVG from "../../../assets/svg/postBookmarkActive";
import PostBookmarkSVG from "../../../assets/svg/postBookmark";

function PublicationGroup(props) {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get the params
  const { match: { params } } = props;

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");

  // Store and set the state value
  const [publicationData, setPublicationData] = useState([]);

  // Main container for history
  let history = useHistory();

  // Get all posts validation method
  useEffect(async () => {

    // URL for report user
    const url = API.URL + 'conversations/' + params.id + '/posts';

    // fetch data and validate
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setPublicationData(response.data.items)
        }
      })
      .catch(e => {
        console.log(e)
      })

  },[]);

  const truncateMessage = (str, num) => {

    if(str == null || !str) {
      return "Read more ...";
    }

    if(str.length <= num) {
      return str;
    }

    return str.slice(0, num) + ' ... Read more ...';
  }

  if(!publicationData || publicationData.length == 0){
    return (
      <ReactBootstrap.Row id="group-publication">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container">
            <div className="tbs--headline">
              Group posts
            </div>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container">
            <div className="tbs--post--element">
              No group posts to display at this time ...
            </div>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  } else {
    return (
      <ReactBootstrap.Row id="group-publication">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container">
            <div className="tbs--headline">
              Group posts
            </div>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        {
          publicationData.map(post => {
            return (
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <ReactBootstrap.Container fluid={true} className="tbs--container chat--publications">
                  <Link to={`/dashboard/feeds/profile/${post.userId}/feedlist/post/${post.id}`}>
                    {
                      post.parentPost != null
                      ?
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--reposted">
                            Reposted by {post.user.name}
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      :
                        ""
                    }
                    <ReactBootstrap.Row>
                      <ReactBootstrap.Col xs={11} sm={11} md={11} lg={11} xl={11} className="post-user-data">
                        <div className="tbs--contact--image">
                          {
                            post.parentPost != null
                            ?
                              <img
                                src={post.parentPost.user.image != null ? post.parentPost.user.image.url : post.parentPost.user.profileImage != null ? post.parentPost.user.profileImage.url : IconAvatar}
                                className="tbs--container--favourites--image"
                                alt="Profile"
                              />
                            :
                              <img
                                src={post.user.image != null ? post.user.image.url : post.user.profileImage != null ? post.user.profileImage.url : IconAvatar}
                                className="tbs--container--favourites--image"
                                alt="Profile"
                              />
                          }
                        </div>
                        <div className="tbs--contact--data--feedlist">
                          <div>
                            {
                              post.user != null && post.parentPost == null
                              ?
                                post.user.name
                              :
                                post.user != null && post.parentPost != null
                                ?
                                  post.parentPost.user.name
                                :
                                  ""
                            }
                          </div>
                          <div>
                            {
                              post.user != null && post.parentPost == null
                              ?
                                post.user.position
                              :
                                post.user != null && post.parentPost != null
                                ?
                                  post.parentPost.user.position
                                :
                                  ""
                            }
                            <span>at</span>
                            {
                              post.user != null && post.parentPost == null
                              ?
                                post.user.company
                              :
                                post.user != null && post.parentPost != null
                                ?
                                  post.parentPost.user.company
                                :
                                  ""
                            }
                          </div>
                          <div>
                            {moment(post.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                          </div>
                        </div>
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col xs={1} sm={1} md={1} lg={1} xl={1}>
                        <div className="tbs--contact--data--feedlist">
                          <div className="tbs--contact--data--post--favourite">
                            {
                              post.isFavourite == true
                                ?
                                /* Remove */
                                /*<img className="tbs--favourite--image" src={IconBookmarksActive} alt="Bookmarks"/>*/
                                <span>
                                  <PostBookmarkActiveSVG/>
                                </span>
                                :
                                /* Add */
                                /*<img className="tbs--favourite--image" src={IconBookmarks} alt="Bookmarks"/>*/
                                <span>
                                  <PostBookmarkSVG/>
                                </span>
                            }
                          </div>
                        </div>
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <div className="tbs--contact--data--feedlist">
                          <div className="tbs--contact--data--feedlist--title">
                            {
                              post.title != null ? post.title : post.parentPost != null ? post.parentPost.title : ""
                            }
                          </div>
                        </div>
                        {
                          /*(((item.parentPost == null) || (item.parentPost != null && item.parentPost.file == null)) && item.file == null)
                            ?*/
                          <div className="read-more">
                            {
                              post.parentPost == null
                                ?
                                truncateMessage(post.description, 200)
                                :
                                truncateMessage(post.parentPost.description, 200)
                            }
                          </div>
                          /*:
                            ""*/
                          /*isBiggerTablet
                            ?
                            <div className="no-description-and-image">Nothing</div>
                            :
                            ""*/
                        }
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className="tbs--contact--data--feedlist">
                          <div className={
                            post.parentPost !== null
                            ?
                              post.parentPost.file === null && post.parentPost.audioId != null
                              ?
                                `tbs--contact--data--feedlist--image tbs--feed--image--${post.id} tbs--contact--data--feedlist--without--image`
                              :
                                `tbs--contact--data--feedlist--image tbs--feed--image--${post.id}`
                            :
                              post.file === null && post.audioId != null
                              ?
                                `tbs--contact--data--feedlist--image tbs--feed--image--${post.id} tbs--contact--data--feedlist--without--image`
                              :
                                `tbs--contact--data--feedlist--image tbs--feed--image--${post.id}`
                          }>
                            {
                              <img
                                className="tbs--favourite--image"
                                src={
                                  post.file != null
                                    ?
                                    post.file.url
                                    :
                                    post.parentPost != null
                                      ?
                                      post.parentPost.file != null
                                        ?
                                        post.parentPost.file.url
                                        :
                                        ""
                                      :
                                      ""
                                }
                                alt="Bookmarks"
                              />
                            }
                            {
                              post.parentPost != null
                              ?
                                <div className="tbs--audio--feed" id={`audio-${post.parentPost.audioId}-player`} > {/* onClick={() => audioPlayer(item.id, item.parentPost.audioId)} */}
                                  <img src={
                                    post.parentPost.file != null && post.parentPost.audioId != null
                                      ?
                                        IconPlayWhite
                                        :
                                          post.parentPost.file == null && post.parentPost.audioId != null
                                        ?
                                          IconPlayBlack
                                      :
                                      ""
                                  } alt="Play"/>
                                </div>
                              :
                                post.audioId != null
                                ?
                                  <div className="tbs--audio--feed" id={`audio-${post.audioId}-player`} > {/* onClick={() => audioPlayer(item.id, item.audioId)} */}
                                    <img src={
                                      post.file != null && post.audioId != null
                                      ?
                                        IconPlayWhite
                                        :
                                          post.file == null && post.audioId != null
                                        ?
                                          IconPlayBlack
                                      :
                                        ""
                                    } alt="Play"/>
                                  </div>
                              :
                                ""
                            }
                            {
                              post.parentPost != null
                              ?
                                <div className="tbs--contact--audio--overview">
                                  <audio controls controlsList="nodownload" id={`audio-${post.parentPost.audioId}`}>
                                    <source src={post.parentPost.audioFile != null ? post.parentPost.audioFile.url : ""} type="audio/mp3"/>
                                    Your browser does not support the audio element.
                                  </audio>
                                </div>
                              :
                                post.audioFile != null
                                ?
                                  <div className="tbs--contact--audio--overview">
                                    <audio controls controlsList="nodownload" id={`audio-${post.audioId}`}>
                                      <source src={post.audioFile != null ? post.audioFile.url : ""} type="audio/mp3"/>
                                      Your browser does not support the audio element.
                                    </audio>
                                  </div>
                                :
                              ""
                            }
                          </div>
                        </div>
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </Link>
                </ReactBootstrap.Container>
              </ReactBootstrap.Col>
            )
          })
        }
      </ReactBootstrap.Row>
    );
  }
}

export default PublicationGroup;
