import React from 'react';
// import { withRouter } from 'react-router';
import {BrowserRouter as Router, Switch, Route, Redirect, useHistory} from 'react-router-dom';

import Login from './views/login';
import Register from './views/register';
import ForgotPassword from './views/forgotPassword';
import ResetPassword from './views/resetPassword';
import Dashboard from './views/dashboard';
import Error from './views/error';
import OnboardingStepOne from './views/onboardingStepOne';
import OnboardingStepTwo from './views/onboardingStepTwo';
/*import CommunityPublic from "./views/dashboard/groups/communityPublic";
import PublicGroups from "./views/dashboard/publicGroups";*/
import PublicGroupAll from "./views/PublicGroupAll";
import PublicCommunity from "./views/PublicCommunity";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/popper.min.js';

import './App.css';

function App() {
  // Get the user token and save this in a variable
  // Get the user email for handling the reset password form
  let item = localStorage.getItem("tbs-token");
  let itemReset = localStorage.getItem("tbs-reset-password");

  let pathData = window.location.pathname;

  let id;

  if(pathData.includes("/public-groups") && pathData.includes("/community") && !pathData.includes("dashboard")){
    id = pathData.split('/')[2];

    localStorage.setItem("public-group-id", id);
  }

  return (
    // Setting of the router and their redirections, if a token has available
    <Router>
      <Switch>
        <Route path="/dashboard">
          {item ? <Dashboard /> : <Redirect to="/" />}
        </Route>
        <Route path={`/public-groups/${id}/community`} component={PublicCommunity} />
        <Route path={`/public-groups/${id}/community`}>
          {item ? <Redirect to={`/dashboard/public-groups/${id}/community`} /> : <Redirect to={`/public-groups/${id}/community`} />}
        </Route>
        <Route path="/public-groups">
          {item ? <Redirect to="/dashboard/public-groups/" /> : <PublicGroupAll />}
        </Route>
        <Route path="/onboarding-step-two">
          {!item ? <OnboardingStepTwo /> : <Redirect to="/dashboard/newsfeed" />}
        </Route>
        <Route path="/onboarding-step-one">
          {!item ? <OnboardingStepOne /> : <Redirect to="/dashboard/newsfeed" />}
        </Route>
        <Route path="/reset-password">
          {!item && itemReset ? <ResetPassword /> : !item && !itemReset ? <Redirect to="/forget-password" /> : <Redirect to="/dashboard/newsfeed" />}
        </Route>
        <Route path="/forget-password">
          {!item ? <ForgotPassword /> : <Redirect to="/dashboard/newsfeed" />}
        </Route>
        <Route path="/register">
          {!item ? <Register /> : <Redirect to="/dashboard/newsfeed" />}
        </Route>
        <Route path="/login">
          {!item ? <Login /> : <Redirect to="/dashboard/newsfeed" />}
        </Route>
        <Route path="/logout">
          {!item ? <Redirect to="/" /> : <Redirect to="/dashboard/newsfeed" /> }
        </Route>
        <Route exact path="/">
          {!item ? <Login /> : <Redirect to="/dashboard/newsfeed" />}
        </Route>
        <Route path="/*" component={Error}/>
      </Switch>
    </Router>
  );
}

export default App;
