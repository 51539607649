import React from 'react';

const TipSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="240" height="120" viewBox="0 0 240 120">
      <defs>
        <filter id="Icon_open-reload" x="29" y="29" width="67.938" height="68" filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur"/>
          <feFlood flood-opacity="0.161"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g id="Gruppe_1451" data-name="Gruppe 1451" transform="translate(-1113 -1139)">
        <g id="Gruppe_1450" data-name="Gruppe 1450">
          <path id="Pfad_146" data-name="Pfad 146" d="M26,0H214a26,26,0,0,1,26,26V94a26,26,0,0,1-26,26H26A26,26,0,0,1,0,94V26A26,26,0,0,1,26,0Z" transform="translate(1113 1139)" fill="#141c27"/>
        </g>
        <text id="Ok" transform="translate(1285.76 1218)" fill="#fefefe" font-size="50" font-family="Ubuntu-Light, Ubuntu" font-weight="300"><tspan x="-31.725" y="0">Ok</tspan></text>
        <g id="Gruppe_964" data-name="Gruppe 964" transform="translate(1113 1139)">
          <path id="Pfad_144" data-name="Pfad 144" d="M26,0H93a26,26,0,0,1,26,26V94a26,26,0,0,1-26,26H26A26,26,0,0,1,0,94V26A26,26,0,0,1,26,0Z" fill="#141c27"/>
          <g id="Gruppe_960" data-name="Gruppe 960" transform="translate(9 10)">
            <rect id="Rechteck_729" data-name="Rechteck 729" width="101" height="102" rx="26" transform="translate(0 -1)" fill="#fefefe"/>
          </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, 1113, 1139)" filter="url(#Icon_open-reload)">
          <path id="Icon_open-reload-2" data-name="Icon open-reload" d="M25,0A25,25,0,1,0,42.75,42.75l-4.5-4.5a18.763,18.763,0,1,1-13.312-32A18.182,18.182,0,0,1,38,11.937L31.187,18.75h18.75V0L42.5,7.437A24.86,24.86,0,0,0,24.937,0Z" transform="translate(35 35)" fill="#141c27"/>
        </g>
      </g>
    </svg>
  )
}

export default TipSVG;
