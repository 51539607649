import React from 'react';

const InfoSVG = () => {
  return(
    <svg id="Komponente_4_1" data-name="Komponente 4 – 1" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
      <g id="Ellipse_110" data-name="Ellipse 110" fill="#141c27" stroke="#141c27" stroke-width="5">
        <circle cx="40" cy="40" r="40" stroke="none"/>
        <circle cx="40" cy="40" r="37.5" fill="none"/>
      </g>
      <text id="i" transform="translate(45 57)" fill="#fefefe" font-size="47" font-family="Ubuntu-Light, Ubuntu" font-weight="300"><tspan x="-11.139" y="0">i</tspan></text>
    </svg>
  )
}

export default InfoSVG;
