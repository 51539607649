import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import axios from 'axios';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
/* import { yupResolver } from "@hookform/resolvers/yup"; */

import MobileAppBanner from "../components/mobileAppBanner";

import API from "./../config/config.json";

import IconUser from "./../assets/icons/IconUser.png";
import IconLock from "./../assets/icons/IconPassword.png";

/* SVG */
import Logo from "./../assets/svg/logo.svg";
import LogoDark from "./../assets/svg/logoDark.svg";

function Login() {

  // Store and set the state value
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRedirect, setIsRedirect] = useState(false);

  // Main container for history
  let history = useHistory();

  // Make a redirect if value is true
  useEffect(() => {
    (() => {
      if(isRedirect) {
        history.push("/dashboard/newsfeed");
      }
    })()
  }, [isRedirect]);

  const handleChangeEmail = e => setEmail(e.target.value);
  const handleChangePassword = e => setPassword(e.target.value);

  // Login validation method
  const validationLogin = (e) => {
    e.preventDefault();

    // URL for the user authentication in the login area
    const url = API.URL + 'authenticate';

    // Post value for validation
    const data = {
      email: email,
      password: password
    };

    // fetch data and validate
    axios
      .post(url, data)
      .then((response) => {
        if(response.status === 200){
          localStorage.setItem("tbs-data", JSON.stringify(response.data));

          localStorage.setItem("tbs-token", response.data.token);

          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("login-email")[0].classList.remove("error-field");
          document.getElementsByClassName("error-text")[1].style.display = "none";
          document.getElementsByClassName("login-password")[0].classList.remove("error-field");

          setIsRedirect(true);

          if(localStorage.getItem("tbs-token") && !localStorage.getItem("public-group-number")){
            window.location.replace("/dashboard/newsfeed");
          } else {
            window.location.replace("/dashboard/public-groups/" + localStorage.getItem("public-group-number") + "/community");
          }
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!email || !regexEmail.test(String(email).toLowerCase())){
          document.getElementsByClassName("error-text")[0].style.display = "block";
          document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a valid e-mail.";
          document.getElementsByClassName("login-email")[0].classList.add("error-field");
        } else {
          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("login-email")[0].classList.remove("error-field");
        }

        if(!password){
          document.getElementsByClassName("error-text")[1].style.display = "block";
          document.getElementsByClassName("error-text")[1].innerHTML = "Please enter a valid password.";
          document.getElementsByClassName("login-password")[0].classList.add("error-field");
        } else {
          document.getElementsByClassName("error-text")[1].style.display = "none";
          document.getElementsByClassName("login-password")[0].classList.remove("error-field");
        }

        document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Invalid credentials.\nThis account does not exist.";
        document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
        document.getElementsByClassName("login-email")[0].classList.add("error-field");
        document.getElementsByClassName("login-password")[0].classList.add("error-field");

        console.log(e)
      })

  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('E-Mail is required'),
    password: Yup.string()
      .required('Password is required.')
  });

  return (
    <ReactBootstrap.Container fluid={true}>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col md={5} lg={7} xl={7} id="login" className="d-none d-sm-none d-md-block">
          <div className="tbs--main--block">
            <div className="tbs--main--title">
              {/*<img src={Logo}/>*/}
              meoh TBS
            </div>
            <div className="tbs--main--subtitle text-center">
              Leverage your trust network
            </div>
          </div>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={7} lg={5} xl={5} id="loginForm">
          <div className="tbs--main--block--two d-block d-sm-block d-md-none d-lg-none d-xl-none">
            <div className="tbs--main--title">
              {/*<img src={LogoDark}/>*/}
              meoh TBS
            </div>
            {/*<div className="tbs--main--subtitle text-center">
              Trust Building System
            </div>*/}
          </div>
          <div className="tbs--form--block">
            <div className="tbs--form--title">
              WELCOME <span>(Beta)</span>
            </div>
            <div className="tbs--form--main--error text-center"></div>
            <div className="tbs--form--complete">
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                validationSchema={SignupSchema}
                onSubmit={values => {
                  console.log(values.text);
                }}
              >
                {({ errors, touched }) => (
                  <Form onSubmit={validationLogin}>
                    <div>
                      <label htmlFor="email">
                        E-MAIL
                      </label>
                    </div>
                    <div id="email-login">
                      <img src={IconUser} alt="E-mail"/>
                      <Field type="email" className={`login-email`} name="email" id="email" placeholder="Enter E-Mail" value={email} onChange={handleChangeEmail}/>
                    </div>
                    <div className="error-text">
                      {/*
                        errors.email && touched.email
                          ?
                          errors.email
                          :
                          null
                      */}
                      <ErrorMessage name="email">
                        {errors.email}
                      </ErrorMessage>
                    </div>
                    <div>
                      <label htmlFor="password">
                        PASSWORD
                      </label>
                    </div>
                    <div id="password-login">
                      <img src={IconLock} alt="Password"/>
                      <Field type="password" className={`login-password`} name="password" id="password" placeholder="Enter Password" value={password} onChange={handleChangePassword}/>
                    </div>
                    <div className="error-text">
                      {/*
                        errors.password && touched.password
                          ?
                          errors.password
                          :
                          null
                      */}
                      <ErrorMessage name="password">
                        {errors.password}
                      </ErrorMessage>
                    </div>
                    <div className="text-right tbs--forget--password">
                      <Link to="/forget-password" className="link--native--style">Forgot your password?</Link>
                    </div>
                    <div className="text-center button--sign">
                      <ReactBootstrap.Button type="submit">Log in</ReactBootstrap.Button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div>
                <p>Don't have an account? <Link to="/register" className="link--custom--style">Register here!</Link></p>
              </div>
              <div className="link--custom--public">
                <p>
                  <Link to="/public-groups" className="link--custom--style">
                    <div className="text-center button--sign">
                      <ReactBootstrap.Button type="submit">Explore public groups</ReactBootstrap.Button>
                    </div>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <MobileAppBanner/>
    </ReactBootstrap.Container>
  );
}

export default Login;
