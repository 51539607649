import React, {useEffect, useState, useRef} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import axios from "axios";
import {Link, useRouteMatch} from "react-router-dom";
import API from "./../../../config/config.json";
import moment from "moment";
import {useMediaQuery} from "react-responsive";
import Linkify from 'react-linkify';

import ActionMenu from "../actionMenu";
import useOutsideClick from "../../../helper/useOutsideClick";

import IconAvatar from "./../../../assets/svg/avatar.svg";
import IconComment from "./../../../assets/icons/IconComment.png";
import IconForward from "./../../../assets/icons/IconForward.png";
import IconRepost from "./../../../assets/icons/IconRepost.png";
import IconBookmarks from "./../../../assets/icons/IconBookmarks.png";
import IconPulse from "../../../assets/icons/IconPulse.png";
import IconBookmarksActive from "../../../assets/icons/IconBookmarksBlue.png";
import IconPlayBlack from "../../../assets/svg/playBlack.svg";
import IconPlayWhite from "../../../assets/svg/playWhite.svg";
import IconPauseBlack from "../../../assets/svg/pauseBlack.svg";
import IconPauseWhite from "../../../assets/svg/pauseWhite.svg";
import AcceptSVG from "../../../assets/svg/accept";
import PostCommentSVG from "../../../assets/svg/postComment";
import PostRepostSVG from "../../../assets/svg/postRepost";
import PostNoRepostSVG from "../../../assets/svg/postNoRepost";
import PostForwardSVG from "../../../assets/svg/postForward";
import PostBookmarkSVG from "../../../assets/svg/postBookmark";
import PostActivitySVG from "../../../assets/svg/postActivity";
import PostBookmarkActiveSVG from "../../../assets/svg/postBookmarkActive";

/* Images */
import NoImage from "../../../assets/images/white-background.jpeg";
import AvatarSVG from "../../../assets/svg/avatar.svg";
import IconIntroduce from "../../../assets/svg/introduce.svg";
import IconImage from "../../../assets/svg/defaultImage.svg";
import CommentButton from "../../../assets/svg/commentButton.svg";

function FeedlistAll() {

  const ref = useRef();

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get url from other user profile feeds
  const urlPost = API.URL + "posts?page=1";

  // Get url from your profile
  const urlProfile = API.URL + 'users';

  // Get url from all chats and groups
  // const urlChatGroupList = API.URL + 'conversations?isNew=true';
  const urlChatList = API.URL + 'conversations?isNew=true&type=SINGLE';
  const urlGroupList = API.URL + 'conversations?isNew=true&type=GROUP';

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Store and set the state value
  const [feedData, setFeedData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [showForward, setShowForward] = useState(false);
  const [chatGroupAlert, setChatGroupAlert] = useState(false);
  const [showNewsfeedAlert, setShowNewsfeedAlert] = useState(false);
  const [showNewsfeedText, setShowNewsfeedText] = useState('');
  const [messageForward, setMessageForward] = useState('');
  const [postItemId, setPostItemId] = useState('');
  const [title, setTitle] = useState("");

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isBiggerTablet = useMediaQuery({ query: '(min-width: 991px)' })

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      urlPost, {
        headers: {
          authorization: token
        }
      }
    );

    setFeedData(result.data.items);

    const resultProfile = await axios.get(
      urlProfile, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setProfileData(resultProfile.data);

    const resultChat = await axios.get(
      urlChatList, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setChatList(resultChat.data.items);

    const resultGroup = await axios.get(
      urlGroupList, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setGroupList(resultGroup.data.items);
  }, []);

  const audioPlayer = (elementId, audioId) => {
    let audioPlayer = document.getElementById("audio-" + audioId + "-player");
    let audioElement = document.getElementById("audio-" + audioId);
    let postContainer = document.getElementById("post-link-" + elementId);

    postContainer.addEventListener("click", (evt) => {
      evt.preventDefault();
      let targetElement = evt.target;

      do {
        if (targetElement == audioPlayer) {
          if(audioElement.paused) {
            audioElement.play();
          }
          else {
            audioElement.pause();
            audioElement.currentTime = 0;
          }
        }

        if (targetElement != audioPlayer && targetElement == postContainer) {
          window.location.href = `${url}/post/${elementId}`;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);

      window.location.href = `${url}/post/${elementId}`;

    });
  }

  // Get 'Tip - Feedlist' - Storage
  let createPostTip = localStorage.getItem("tbs-tip-feedlist-" + userID);

  // Execute the API and get all informations
  useEffect( () => {
    if(createPostTip){
      document.getElementById("tip-feedlist").classList.add("tips-disabled");
    } else {
      document.getElementById("tip-feedlist").classList.remove("tips-disabled");
    }
  }, []);

  const tipClose = () => {
    localStorage.setItem("tbs-tip-feedlist-" + userID, "Feeds tips accepted.");
    document.getElementById("tip-feedlist").classList.add("tips-disabled");
  }

  useEffect(() => {
    const contentInfo = document.getElementById("no-data-feeds");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading content";
      setTimeout( () => {
        contentInfo.innerHTML = "No posts to display at the time";
      }, 5000)
    }
  })

  const truncateMessage = (str, num) => {

    if(str == null || !str) {
      return "Read more ...";
    }

    if(str.length <= num) {
      return str;
    }

    return str.slice(0, num) + ' ... Read more ...';
  }

  const changeFavouriteStatus = (e, item) => {
    e.preventDefault();
    validationFavourite(item);
  }

  const newRepostFeed = (e, item) => {
    e.preventDefault();
    validationRepostedPost(item);
  }

  const openForwardModal = (e, id) => {
    e.preventDefault();
    setShowForward(true);
    setPostItemId(id)
  }

  // Favourite validation method
  const validationFavourite = (item) => {
    // URL for the post favourites
    const url = API.URL + 'posts/' + item.id + '/favourites';

    // fetch data and validate
    axios
      .put(url, "", {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShowNewsfeedAlert(true)
          if(item.isFavourite == true){
            setShowNewsfeedText("Remove from favourites")
          } else {
            setShowNewsfeedText("Added to favourites")
          }

          setTimeout(() => {
            setShowNewsfeedAlert(false)
          }, 1000);

          setTimeout(async () => {
            //window.location.reload();
            setShowNewsfeedText("")

            const result = await axios.get(
              urlPost, {
                headers: {
                  authorization: token
                }
              }
            );

            setFeedData(result.data.items);
          }, 1200);
        } else {
          console.log("Error: Cannot handle this method.");
        }
      })
      .catch(e => {
        console.log(e);
      })

  };

  // Reposted a post validation method
  const validationRepostedPost = (itemContainer) => {

    // URL for the post to reposted
    const urlForward = API.URL + 'posts/' + itemContainer.id + '/forward';

    let title = itemContainer.parentPost != null ? itemContainer.parentPost.title : itemContainer.title;
    let description = itemContainer.parentPost != null ? itemContainer.parentPost.description : itemContainer.description;
    let fileId = itemContainer.parentPost != null ? itemContainer.parentPost.fileId : itemContainer.fileId;
    let audioId = itemContainer.parentPost != null ? itemContainer.parentPost.audioId : itemContainer.audioId;

    const data = {
      parentPost: {
        title: title,
        description: description,
        fileId: fileId,
        audioId: audioId,
        forwardToConvoType: "SINGLE"
      }
    }

    // fetch data and validate
    axios
      .post(urlForward, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShowNewsfeedAlert(true)
          setShowNewsfeedText("Post has been reposted.")

          setTimeout(() => {
            setShowNewsfeedAlert(false)
          }, 1000);

          setTimeout(() => {
            setShowNewsfeedText("")
          }, 1500);

        } else {
          console.log("Error: Cannot handle this method.");
        }
      })
      .catch(e => {
        console.log(e);
      })

  };

  const handleChangeMessageForward = e => setMessageForward(e.target.value);

  // Forward a post validation method - Not complete and valid
  const validationForwardPost = (itemContainerId) => {
    // URL for the post to forwarded
    const urlForwardPost = API.URL + 'posts/' + itemContainerId + '/message';

    // Get all values of the checkboxes which is checked.
    const dataChat = [...document.querySelectorAll('.chat-list:checked')].map(e => Number(e.value));
    const dataGroup = [...document.querySelectorAll('.group-list:checked')].map(e => Number(e.value));

    // Data validation
    const data = {
      userIds: dataChat,
      groupIds: dataGroup,
      message: messageForward
    }

    // fetch data and validate
    if((dataChat.length != 0 && dataGroup.length == 0) || dataChat.length == 0 && dataGroup.length != 0){
      document.querySelector("#chat-group-select-contact > .row:first-child").classList.remove("is-show");

      axios
        .post(urlForwardPost, data, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        })
        .then((response) => {
          if (response.status === 200) {
            setChatGroupAlert(true)
            setShowNewsfeedAlert(true)
            setShowNewsfeedText("Post has been forwarded.")

            setTimeout(() => {
              setShowForward(false);
              setChatGroupAlert(false);
              setShowNewsfeedAlert(false)
              setMessageForward('');
              setShowNewsfeedText("")
            }, 2000);

            /*setTimeout(() => {
              setIsRedirect(true);
            }, 3000);*/
          }
        })
    } else {
      document.querySelector("#chat-group-select-contact > .row:first-child").classList.add("is-show");
    }
  }

  const createNewPost = () => {
    window.location.href = "/dashboard/profile/post/create?title=" + title;

    /*localStorage.setItem("Post_Title", title);*/
  }

  const handleChangeTitle = e => setTitle(e.target.value);

  if (!feedData || feedData.length === 0) {
    return (
      <ReactBootstrap.Row id="feed-list" className="new-feedlist">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-feedlist">
          <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
            <ReactBootstrap.Row>
              {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--tips--close">
                <img src={IconClose} alt="Tips - Close" onClick={tipClose}/>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-right">
                <img src={IconTip} alt="Tips" />
              </ReactBootstrap.Col>*/}
              <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9}>
                <div className="tbs--tips--subheadline">
                  TIP
                </div>
                <div>
                  Your newsfeed shows posts from your network and from featured contacts.
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
                <div onClick={tipClose}>
                  <AcceptSVG/>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist tbs--new--title">
            <ReactBootstrap.Row>
              <div className="tbs--headline">
                My Newsfeed
              </div>
              <div className="tbs--divider--title">
                <hr></hr>
              </div>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tbs--create--new--post">
          <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist tbs--create--post--container">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--container--feedlist--item">
                <div className="tbs--title--new--post">
                  Create post
                </div>
                <div>
                  {
                    profileData != null && profileData.profileImage == null
                    ?
                      <img src={IconAvatar} alt="Profile" />
                    :
                      <img src={profileData.profileImage.url} alt="Profile" />
                  }
                  {/*
                    <Link to={"/dashboard/profile/post/create"}>
                      <input type="text" value="" placeholder="Type a title for your post" />
                    </Link>
                  */}
                  <form onSubmit={createNewPost} action={`/dashboard/profile/post/create?title=` + title}>
                    <input type="text" maxLength={70} value={title} name="title" id="title" placeholder="Add title" onChange={handleChangeTitle}/>
                    <span className="button--sign">
                      {
                        /*
                          <ReactBootstrap.Button type="submit">
                            <img src={CommentButton} />
                          </ReactBootstrap.Button>
                        */
                      }
                      <ReactBootstrap.Button type="submit" className="create--new--post--button">
                        Next
                      </ReactBootstrap.Button>
                    </span>
                  </form>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center" id="no-data-feeds">
                No posts to display at the time
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  } else {
    return (
      <ReactBootstrap.Row id="feed-list" className="new-feedlist">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-feedlist">
          <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
            <ReactBootstrap.Row>
              {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--tips--close">
                <img src={IconClose} alt="Tips - Close" onClick={tipClose}/>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-right">
                <img src={IconTip} alt="Tips" />
              </ReactBootstrap.Col>*/}
              <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9}>
                <div className="tbs--tips--subheadline">
                  TIP
                </div>
                <div>
                  Your newsfeed shows posts from your network and from featured contacts.
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
                <div onClick={tipClose}>
                  <AcceptSVG/>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist tbs--new--title">
            <ReactBootstrap.Row>
              <div className="tbs--headline">
                My Newsfeed
              </div>
              <div className="tbs--divider--title">
                <hr></hr>
              </div>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tbs--create--new--post">
          <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist tbs--create--post--container">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--container--feedlist--item">
                <div className="tbs--title--new--post">
                  Create post
                </div>
                <div>
                  {
                    profileData != null && profileData.profileImage == null
                    ?
                      <img src={IconAvatar} alt="Profile" />
                    :
                      <img src={profileData.profileImage.url} alt="Profile" />
                  }
                  {/*
                    <Link to={"/dashboard/profile/post/create"}>
                      <input type="text" value="" placeholder="Type a title for your post" />
                    </Link>
                  */}
                  <form onSubmit={createNewPost} action={`/dashboard/profile/post/create?title=` + title}>
                    <input type="text" maxLength={70} value={title} name="title" id="title" placeholder="Add title" onChange={handleChangeTitle}/>
                    <span className="button--sign">
                      {
                        /*
                          <ReactBootstrap.Button type="submit">
                            <img src={CommentButton} />
                          </ReactBootstrap.Button>
                        */
                      }
                      <ReactBootstrap.Button type="submit" className="create--new--post--button">
                        Next
                      </ReactBootstrap.Button>
                    </span>
                  </form>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist">
            {
              feedData.map((item, index) => {
                return (
                  <Link to={`${url}/post/${item.id}`}>
                    <ReactBootstrap.Row id={`post-link-${item.id}`} key={item.id}>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {
                          showNewsfeedAlert
                            ?
                            <ReactBootstrap.Alert variant="success" transition={true} id="newsfeed-all-alert" className="text-center">
                              <ReactBootstrap.Alert.Heading>
                                {showNewsfeedText}
                              </ReactBootstrap.Alert.Heading>
                            </ReactBootstrap.Alert>
                            :
                            ""
                        }
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--container--feedlist--item" id={`tbs--post--${item.id}`}>
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col  xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--reposted">
                            { item.parentId != null ? "Reposted by " + item.user.name : "" }
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <ReactBootstrap.Row className={ item.parentId == null ? "tbs-friend-posts tbs--feedlist--item--no--repost" : "tbs-friend-posts"}>
                          <ReactBootstrap.Col xs={11} sm={11} md={11} lg={11} xl={11} className="tbs--contact--details">
                            <Link to={`/dashboard/profile/${item.parentPost != null ? item.parentPost.userId : item.userId}`}>
                              <div className="tbs--contact--image">
                                {
                                  item.user.profileImage != null && item.parentId == null
                                    ?
                                    <img src={item.user.profileImage.url} className="tbs--container--feedlist--image" alt="Profile"/>
                                    :
                                    item.parentPost != null && item.parentPost.user != null && item.parentPost.user.profileImage != null
                                      ?
                                      <img src={item.parentPost.user.profileImage.url} className="tbs--container--feedlist--image" alt="Profile"/>
                                      :
                                      item.parentPost != null && item.parentPost.user != null && item.parentPost.user.profileImage == null
                                        ?
                                        <img src={item.parentPost.user.image} className="tbs--container--feedlist--image" alt="Profile"/>
                                        :
                                          item.user.image != null
                                            ?
                                            <img src={item.user.image} className="tbs--container--feedlist--image" alt="Profile"/>
                                            :
                                            <img src={IconAvatar} className="tbs--container--feedlist--image" alt="Profile"/>
                                  /*item.user.image != null
                                    ?
                                    <img src={item.user.image} className="tbs--container--feedlist--image" alt="Profile"/>
                                    :
                                    <img src={
                                      item.parentPost != null
                                        ?
                                        item.parentPost.user != null
                                          ?
                                          item.parentPost.user.profileImage != null
                                            ?
                                            item.parentPost.user.profileImage != null
                                              ?
                                              item.parentPost.user.profileImage.url
                                              :
                                              IconAvatar
                                            :
                                            item.parentPost.user.image
                                          :
                                          item.user.image != null
                                            ?
                                            item.user.image
                                            :
                                            item.user.profileImage != null
                                              ?
                                              item.user.profileImage.url
                                              :
                                              IconAvatar
                                        :
                                        item.user.image != null
                                          ?
                                          item.user.image
                                          :
                                          item.user.profileImage != null
                                            ?
                                            item.user.profileImage.url
                                            :
                                            IconAvatar
                                    } className="tbs--container--feedlist--image" alt="Profile"/>*/
                                }
                              </div>
                              <div className="tbs--contact--data--feedlist">
                                <div>
                                  {
                                    item.parentPost != null
                                      ?
                                      item.parentPost.user != null
                                        ?
                                        item.parentPost.user.name
                                        :
                                        item.user.name
                                      :
                                      item.user.name
                                  }
                                </div>
                                <div>
                                  {
                                    item.parentPost != null
                                      ?
                                      item.parentPost.user != null
                                        ?
                                        item.parentPost.user.position
                                        :
                                        item.user.position
                                      :
                                      item.user.position
                                  } at {
                                  item.parentPost != null
                                    ?
                                    item.parentPost.user != null
                                      ?
                                      item.parentPost.user.company
                                      :
                                      item.user.company
                                    :
                                    item.user.company
                                }
                                </div>
                                <div>
                                  {moment(item.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                </div>
                              </div>
                            </Link>
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={1} sm={1} md={1} lg={1} xl={1}>
                            <div className={`tbs--contact--data--feedlist`}>
                              {
                                item.isFavourite == true
                                  ?
                                  /* Remove */
                                  /*<img className="tbs--favourite--image" src={IconBookmarksActive} alt="Bookmarks"/>*/
                                  <span>
                                    <PostBookmarkActiveSVG/>
                                  </span>
                                  :
                                  /* Add */
                                  /*<img className="tbs--favourite--image" src={IconBookmarks} alt="Bookmarks"/>*/
                                  <span>
                                    <PostBookmarkSVG/>
                                  </span>
                              }
                            </div>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={7} lg={7} xl={7}>
                            <div className="tbs--contact--data--feedlist">
                              <div className="tbs--contact--data--feedlist--title">
                                {
                                  item.title != null
                                  ?
                                    item.title
                                  :
                                    item.parentPost != null
                                    ?
                                      item.parentPost.title
                                    :
                                      ""
                                }
                              </div>
                            </div>
                            {
                              /*(((item.parentPost == null) || (item.parentPost != null && item.parentPost.file == null)) && item.file == null)
                              ?*/
                                <div className="read-more">
                                  <Linkify>
                                    {
                                      item.parentPost == null
                                        ?
                                        truncateMessage(item.description, 200)
                                        :
                                        truncateMessage(item.parentPost.description, 200)
                                    }
                                  </Linkify>
                                </div>
                              /*:
                                ""*/
                            }
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={12} sm={12} md={5} lg={5} xl={5}>
                            <div className="tbs--contact--data--feedlist">
                              <div className={
                                item.parentPost !== null
                                  ?
                                  item.parentPost.file === null && item.parentPost.audioId != null
                                    ?
                                    `tbs--contact--data--feedlist--image tbs--feed--image--${item.id} tbs--contact--data--feedlist--without--image`
                                    :
                                    `tbs--contact--data--feedlist--image tbs--feed--image--${item.id}`
                                  :
                                  item.file === null && item.audioId != null
                                    ?
                                    `tbs--contact--data--feedlist--image tbs--feed--image--${item.id} tbs--contact--data--feedlist--without--image`
                                    :
                                    `tbs--contact--data--feedlist--image tbs--feed--image--${item.id}`
                              }>
                                <img src={
                                  item.parentPost !== null
                                    ?
                                    item.parentPost.file !== null
                                      ?
                                      item.parentPost.file.url
                                      :
                                      ""
                                    :
                                    item.file !== null
                                      ?
                                      item.file.url
                                      :
                                      ""
                                }
                                     alt="Feed - Image"/>
                                {/*isBiggerTablet
                                ?
                                  NoImage
                                :
                                  ""*/}
                                {
                                  item.parentPost != null
                                    ?
                                    <div className="tbs--audio--feed" id={`audio-${item.parentPost.audioId}-player`} onClick={() => audioPlayer(item.id, item.parentPost.audioId)}>
                                      <img src={
                                        item.parentPost.file != null && item.parentPost.audioId != null
                                          ?
                                          IconPlayWhite
                                          :
                                          item.parentPost.file == null && item.parentPost.audioId != null
                                            ?
                                            IconPlayBlack
                                            :
                                            ""
                                      } alt="Play"/>
                                    </div>
                                    :
                                    item.audioId != null
                                      ?
                                      <div className="tbs--audio--feed" id={`audio-${item.audioId}-player`} onClick={() => audioPlayer(item.id, item.audioId)}>
                                        <img src={
                                          item.file != null && item.audioId != null
                                            ?
                                            IconPlayWhite
                                            :
                                            item.file == null && item.audioId != null
                                              ?
                                              IconPlayBlack
                                              :
                                              ""
                                        } alt="Play"/>
                                      </div>
                                      :
                                      ""
                                }
                                {
                                  item.parentPost != null
                                    ?
                                    <div className="tbs--contact--audio--overview">
                                      <audio controls controlsList="nodownload" id={`audio-${item.parentPost.audioId}`}>
                                        <source src={item.parentPost.audioFile != null ? item.parentPost.audioFile.url : ""} type="audio/mp3"/>
                                        Your browser does not support the audio element.
                                      </audio>
                                    </div>
                                    :
                                    item.audioFile != null
                                      ?
                                      <div className="tbs--contact--audio--overview">
                                        <audio controls controlsList="nodownload" id={`audio-${item.audioId}`}>
                                          <source src={item.audioFile != null ? item.audioFile.url : ""} type="audio/mp3"/>
                                          Your browser does not support the audio element.
                                        </audio>
                                      </div>
                                      :
                                      ""
                                }
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="tbs--contact--data--feeds">
                              <div className="tbs--contact--data--feeds--infos tbs--data--feeds--subline">
                                <span className="w20-flex">
                                  {/*<img src={IconComment} alt="Comments"/>*/}
                                  <PostCommentSVG/>
                                  <span>{item.totalComments}</span>
                                </span>
                                {
                                  item.parentId == null
                                  ?
                                    <span className="w20-flex">
                                      {/*<img src={IconRepost} alt="Repost"/>*/}
                                      <Link to={"#"} onClick={(e) => newRepostFeed(e, item)}>
                                        <PostRepostSVG/>
                                        <span>{item.repostedCount}</span>
                                      </Link>
                                    </span>
                                  :
                                    <span className="w20-flex">
                                      {/*<img src={IconRepost} alt="Repost"/>*/}
                                      <PostNoRepostSVG/>
                                      <span>{item.repostedCount}</span>
                                    </span>

                                }
                                <span className="w20-flex">
                                  {/*<img src={IconForward} alt="Forward"/>*/}
                                  <Link to={"#"} onClick={(e) => openForwardModal(e, item.id)}>
                                    <PostForwardSVG/>
                                    <span>{item.forwardedCount}</span>
                                  </Link>
                                </span>
                                <span className="w20-flex">
                                  {/*<img src={IconBookmarks} alt="Bookmarks"/>*/}
                                  <Link to={"#"} onClick={(e) => changeFavouriteStatus(e, item)}>
                                    {
                                      item.isFavourite == true
                                      ?
                                        <PostBookmarkActiveSVG/>
                                      :
                                        <PostBookmarkSVG/>
                                    }
                                    <span>{item.bookmarkedCount}</span>
                                  </Link>
                                </span>
                                <span className="w20-flex">
                                  {/*<img src={IconPulse} alt="Activity"/>*/}
                                  <PostActivitySVG/>
                                  <span>{item.activitiesCount}</span>
                                </span>
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </Link>
                );
              })
            }
            <ReactBootstrap.Row>
              <ReactBootstrap.Col>
                <ReactBootstrap.Modal
                  show={showForward}
                  onHide={() => setShowForward(false)}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <ReactBootstrap.Modal.Header closeButton>
                    <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                      Forward this post to
                    </ReactBootstrap.Modal.Title>
                  </ReactBootstrap.Modal.Header>
                  <ReactBootstrap.Modal.Body id="chat-group-select-contact">
                    <ReactBootstrap.Row>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                        <div className="error-text">Please select one or more contacts first.</div>
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                    {
                      chatList.map(list => {
                        if(list.type == "SINGLE"){
                          return (
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--image--select">
                                <img src={
                                  list.conversationReceiver.user.image != null
                                    ?
                                    list.conversationReceiver.user.image
                                    :
                                    list.conversationReceiver.user.profileImage != null
                                      ?
                                      list.conversationReceiver.user.profileImage.url
                                      :
                                      IconAvatar
                                }
                                     alt="Contact image"
                                />
                              </ReactBootstrap.Col>
                              <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="tbs--contact--name--select">
                                {list.conversationReceiver.user.name}
                              </ReactBootstrap.Col>
                              <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--checkbox--select">
                                <input type="checkbox" className="chat-list" name="contact" value={list.conversationReceiver.userId} />
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          )
                        }
                      })
                    }
                    {
                      chatList.map(list => {
                        if(list.type == "GROUP" && list.groupType == "introduce"){
                          return (
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--image--select tbs--introduce--image">
                                <img src={IconIntroduce} alt="Contact image"  />
                              </ReactBootstrap.Col>
                              <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="tbs--contact--name--select">
                                {list.name}
                              </ReactBootstrap.Col>
                              <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--checkbox--select">
                                <input type="checkbox" className="group-list" name="contact" value={list.id} />
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          )
                        }
                      })
                    }
                    {
                      groupList.map((list) => {
                        if(list.type == "GROUP"){
                          return (
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--image--select">
                                {
                                  list.groupType == "introduce"
                                    ?
                                    <img src={
                                      list.image != null
                                        ?
                                        list.image.url
                                        :
                                        list.conversationReceiver.user.image != null
                                          ?
                                          list.conversationReceiver.user.image
                                          :
                                          IconIntroduce
                                    }
                                         alt="Group image - Introduce"
                                         className="tbs--image--chats--introduce"
                                    />
                                    :
                                    <img src={
                                      list.image != null
                                        ?
                                        list.image.url
                                        :
                                        IconImage
                                    }
                                         alt="Group image"
                                    />
                                }
                              </ReactBootstrap.Col>
                              <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="tbs--contact--name--select">
                                {list.name}
                              </ReactBootstrap.Col>
                              <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--checkbox--select">
                                <input type="checkbox" className="group-list" name="contact" value={list.id} />
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          )
                        }
                      })
                    }
                    <ReactBootstrap.Row>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div>
                          <input type="text" className={`forward-message`} name="forwardMessage" id="forwardMessage" placeholder="Enter your message here." value={messageForward} onChange={handleChangeMessageForward} />
                        </div>
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                    <div className="text-center button--sign">
                      <ReactBootstrap.Button onClick={() => validationForwardPost(postItemId)}>Forward</ReactBootstrap.Button>
                    </div>
                    <ReactBootstrap.Row>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {
                          chatGroupAlert
                            ?
                            <ReactBootstrap.Alert variant="success" transition={true} id="chat-group-alert" className="text-center">
                              <ReactBootstrap.Alert.Heading>
                                Post has been forwarded.
                              </ReactBootstrap.Alert.Heading>
                            </ReactBootstrap.Alert>
                            :
                            ""
                        }
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </ReactBootstrap.Modal.Body>
                </ReactBootstrap.Modal>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        {/*
          isMobile
            ?
            <ReactBootstrap.Col>
              <ActionMenu/>
            </ReactBootstrap.Col>
            :
            ""
        */}
      </ReactBootstrap.Row>
    );
  }
}

export default FeedlistAll;
