import React from 'react';
import * as ReactBootstrap from 'react-bootstrap';

function MyCoins() {
  return (
    <ReactBootstrap.Container fluid={true} id="my-coins-info" className="tbs--container">
      <ReactBootstrap.Row id="my-coins-overview">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h2>My coins</h2>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          Get meoh coins when you invited contacts register (+100) and when you introduce them to each other (+20).
          Spend meoh coins by awarding outstanding contributors (-80). Extra coins: receive 2 coins each time an invitation is accepted in your network.
          <br/><br/>
          Meoh coins are an incentive to expand and to strengthen your network while giving value to contributions that makes a difference to you.
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    </ReactBootstrap.Container>
  )
}

export default MyCoins;
