import React, { useState, useEffect } from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import axios from 'axios';
import {Link, useRouteMatch} from "react-router-dom";
import API from "./../../config/config.json";
import moment from "moment";
import IconAvatar from "../../assets/svg/avatar.svg";

function Notification() {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get url from all notification
  const urlNotification = API.URL + 'notifications/';

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");

  // Store and set the state value
  const [notifications, setNotifications] = useState([]);

  // Execute the API and get all informations
  useEffect(async () => {
    const resultNotification = await axios.get(
      urlNotification, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setNotifications(resultNotification.data.items);
  }, []);

  useEffect(() => {
    const contentInfo = document.getElementById("no-data-notification");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading content";
      setTimeout( () => {
        contentInfo.innerHTML = "You don't have any favorites posts";
      }, 5000)
    }
  })

  if (!notifications || notifications.length === 0) {
    return (
      <ReactBootstrap.Row id="notification">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="">
            <ReactBootstrap.Row>
              <div className="tbs--headline">
                My Notifications
              </div>
              <div className="tbs--divider--title tbs--divider--title--notification">
                <hr></hr>
              </div>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center" id="no-data-notification">
                You don't have any notifications
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  } else {
    return (
      <ReactBootstrap.Row id="notification">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="">
            <ReactBootstrap.Row>
              <div className="tbs--headline tbs--headline--notification">
                My Notifications
              </div>
              <div className="tbs--divider--title tbs--divider--title--notification">
                <hr></hr>
              </div>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container">
              {
                notifications.map((notificationItem) => {
                  if(notificationItem.type === "INVITATION_ACCEPT"){
                    return (
                      <Link to={`/dashboard/profile/${notificationItem.actionOwnerId}`}>
                        <ReactBootstrap.Row className="notification--element">
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                            {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--notification--headline--info">
                                <div>
                                  <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                </div>
                                <div className="notification--contact">
                                  <div>
                                    {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                  </div>
                                  <br/>
                                  <div className="notification--details">
                                    <div>
                                      {notificationItem.text != null ? notificationItem.text : ""}
                                    </div>
                                  </div>
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      </Link>
                    )
                  }
                  if(notificationItem.type === "CONVERSATION_REQUEST"){
                    return (
                      <Link to={`/dashboard/communities`}>
                        <ReactBootstrap.Row className="notification--element">
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                            {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--notification--headline--info">
                                <div>
                                  <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                </div>
                                <div className="notification--contact">
                                  <div>
                                    {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                  </div>
                                  <br/>
                                  <div className="notification--details">
                                    <div>
                                      {notificationItem.text != null ? notificationItem.text : ""}
                                    </div>
                                  </div>
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      </Link>
                    )
                  }
                  if(notificationItem.type === "AWARD" || notificationItem.type === "AWARD_COMMENT" || notificationItem.type === "AWARD_USER" || notificationItem.type === "AWARD_POST"){
                    return (
                      <Link to={notificationItem.post != null ? `/dashboard/feeds/profile/${notificationItem.post.user.id}/feedlist/post/${notificationItem.postId}` : "#"}>
                        <ReactBootstrap.Row className="notification--element">
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                            {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--notification--headline--info">
                                <div>
                                  <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                </div>
                                <div className="notification--contact">
                                  <div>
                                    {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                  </div>
                                  <br/>
                                  <div className="notification--details">
                                    <div>
                                      {notificationItem.text != null ? notificationItem.text : ""}
                                    </div>
                                  </div>
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      </Link>
                    )
                  }
                  else if(notificationItem.type === "COMMENT" || notificationItem.type === "COMMENT_REPLY"){
                    return (
                      <Link to={notificationItem.post != null ? `/dashboard/feeds/profile/${notificationItem.post.user.id}/feedlist/post/${notificationItem.postId}` : "#"}>
                        <ReactBootstrap.Row className="notification--element">
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                            {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--notification--headline--info">
                                <div>
                                  <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                </div>
                                <div className="notification--contact">
                                  <div>
                                    {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                  </div>
                                  <br/>
                                  <div className="notification--details">
                                    <div>
                                      {notificationItem.text != null ? notificationItem.text : ""}
                                    </div>
                                  </div>
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      </Link>
                    )
                  }
                  else if(notificationItem.type === "BOOKMARK"){
                    return (
                      <Link to={notificationItem.post != null ? `/dashboard/feeds/profile/${notificationItem.post.user.id}/feedlist/post/${notificationItem.postId}` : "#"}>
                        <ReactBootstrap.Row className="notification--element">
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                            {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--notification--headline--info">
                                <div>
                                  <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                </div>
                                <div className="notification--contact">
                                  <div>
                                    {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                  </div>
                                  <br/>
                                  <div className="notification--details">
                                    <div>
                                      {notificationItem.text != null ? notificationItem.text : ""}
                                    </div>
                                  </div>
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      </Link>
                    )
                  }
                  else if(notificationItem.type === "FORWARD"){
                    return (
                      <Link to={notificationItem.post != null ? `/dashboard/feeds/profile/${notificationItem.post.user.id}/feedlist/post/${notificationItem.postId}` : "#"}>
                        <ReactBootstrap.Row className="notification--element">
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                            {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--notification--headline--info">
                                <div>
                                  <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                </div>
                                <div className="notification--contact">
                                  <div>
                                    {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                  </div>
                                  <br/>
                                  <div className="notification--details">
                                    <div>
                                      {notificationItem.text != null ? notificationItem.text : ""}
                                    </div>
                                  </div>
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      </Link>
                    )
                  }
                  else if(notificationItem.type === "INVITATION"){
                    return (
                      <Link to={`/dashboard/invitations`}>
                        <ReactBootstrap.Row className="notification--element">
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                            {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--notification--headline--info">
                                <div>
                                  <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                </div>
                                <div className="notification--contact">
                                  <div>
                                    {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                  </div>
                                  <br/>
                                  <div className="notification--details">
                                    <div>
                                      {notificationItem.text != null ? notificationItem.text : ""}
                                    </div>
                                  </div>
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      </Link>
                    )
                  }
                  if(notificationItem.type === "FEATURE"){
                    return (
                      <Link to={"#"}>
                        <ReactBootstrap.Row className="notification--element tbs--disabled">
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                            {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--notification--headline--info">
                                <div>
                                  <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                </div>
                                <div className="notification--contact">
                                  <div>
                                    {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                  </div>
                                  <br/>
                                  <div className="notification--details">
                                    <div>
                                      {notificationItem.text != null ? notificationItem.text : ""}
                                    </div>
                                  </div>
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      </Link>
                    )
                  }
                  else {
                    return (
                      <Link style={{ pointerEvents: "none" }}>
                        <ReactBootstrap.Row className="notification--element">
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                            {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--notification--headline--info">
                                <div>
                                  <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                </div>
                                <div className="notification--contact">
                                  <div>
                                    {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                  </div>
                                  <br/>
                                  <div className="notification--details">
                                    <div>
                                      {notificationItem.text != null ? notificationItem.text : ""}
                                    </div>
                                  </div>
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      </Link>
                    )
                  }
                })
              }
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  }
}

export default Notification;
