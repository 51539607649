import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import axios from "axios";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
/* import { yupResolver } from "@hookform/resolvers/yup"; */

import API from "../../../config/config.json";

function CreateInvitation() {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");

  // Store and set the state value
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');
  const [name, setName] = useState('');
  const [errorText, setErrorText] = useState('');
  const [show, setShow] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  // Main container for history
  let history = useHistory();

  // Make a redirect if value is true
  useEffect(() => {
    (() => {
      if(isRedirect) {
        history.push("/dashboard/newsfeed");
      }
    })()
  }, [isRedirect]);

  const handleChangeEmail = e => setEmail(e.target.value);
  const handleChangeText = e => setText(e.target.value);
  const handleChangeName = e => setName(e.target.value);

  // fetch data and validate
  const validationSendNewInvitation = (e) => {
    e.preventDefault();

    // Get url from your invitations
    const urlNewInvitation = API.URL + 'invitations';

    // Post value for validation
    const data = {
      email: email,
      /*publicText: text,
      name: name*/
    };

    axios
      .post(urlNewInvitation, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(email){
          console.log("Valid credentials");
          if(response.status === 200){
            setShow(true);
            console.log("Invitation successful sent.");

            document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "";
            document.getElementsByClassName("tbs--form--main--error")[0].style.display = "none";
            document.getElementsByClassName("invitation-email")[0].classList.remove("error-field");

            setTimeout(() => {
              setShow(false);
            }, 1000);

            setTimeout(() => {
              setIsRedirect(true);
            }, 2000);

            setTimeout(() => {
              window.location.reload();
            }, 2200);
          } else {
            console.log("Error: Access Denied");
          }
        } else {
          console.log("Invalid credentials");
        }
      })
      .catch(e => {
        console.log(e.response.data.error)
        setErrorText(e.response.data.error)

        setShow(true);

        setTimeout(() => {
          setShow(false);
          setErrorText("")
        }, 1000);

        const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!email || !regexEmail.test(String(email).toLowerCase())){
          document.getElementsByClassName("error-text")[0].style.display = "block";
          document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a valid e-mail.";
          document.getElementsByClassName("invitation-email")[0].classList.add("error-field");
        } else {
          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("invitation-email")[0].classList.remove("error-field");
        }

        /*if(!text){
          document.getElementsByClassName("error-text")[1].style.display = "block";
          document.getElementsByClassName("error-text")[1].innerHTML = "Please enter a valid text.";
          document.getElementsByClassName("invitation-text")[0].classList.add("error-field");
        } else {
          document.getElementsByClassName("error-text")[1].style.display = "none";
          document.getElementsByClassName("invitation-text")[0].classList.remove("error-field");
        }*/

        /*if(!name){
          document.getElementsByClassName("error-text")[2].style.display = "block";
          document.getElementsByClassName("error-text")[2].innerHTML = "Please enter a valid name.";
          document.getElementsByClassName("invitation-name")[0].classList.add("error-field");
        } else {
          document.getElementsByClassName("error-text")[2].style.display = "none";
          document.getElementsByClassName("invitation-name")[0].classList.remove("error-field");
        }*/

        //document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Invalid credentials.";
        document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
        document.getElementsByClassName("invitation-email")[0].classList.add("error-field");
        //document.getElementsByClassName("invitation-text")[0].classList.add("error-field");
        //document.getElementsByClassName("invitation-name")[0].classList.add("error-field");

        console.log(e)
      })
  }

  const Schema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('E-Mail is required'),
    publicText: Yup.string(),
    name: Yup.string()
      //.required('Name is required.')
  });

  return (
    <ReactBootstrap.Row id="new-invitation">
      <ReactBootstrap.Col></ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="tbs--headline tbs--headline--invitation">
                New Invitation
              </div>
              <div className="tbs--divider--title tbs--divider--title--invitation">
                <hr></hr>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <p>Add some heat to your trusted network, invite someone inspiring!</p>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="form--invitation">
                <div className="tbs--form--main--error text-center"></div>
                <div className="tbs--form--complete--invitation">
                  <Formik
                    initialValues={{
                      email: '',
                      publicText: '',
                      name: '',
                    }}
                    validationSchema={Schema}
                    onSubmit={values => {
                      console.log(values.text);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form onSubmit={validationSendNewInvitation}>
                        <div>
                          <label htmlFor="email">
                            E-MAIL
                          </label>
                        </div>
                        <div>
                          <input type="search" autocomplete="off" className={`invitation-email`} name="email" id="email" placeholder="Enter E-Mail" value={email} onChange={handleChangeEmail}/>
                        </div>
                        <div className="error-text">
                          {/*
                            errors.email && touched.email
                              ?
                              errors.email
                              :
                              null
                          */}
                          <ErrorMessage name="email">
                            {errors.email}
                          </ErrorMessage>
                        </div>
                        {/*<div>
                          <label htmlFor="password">
                            TEXT
                          </label>
                        </div>
                        <div>
                          <Field type="text" className={`invitation-text`} name="text" id="text" placeholder="Enter your text" value={text} onChange={handleChangeText}/>
                        </div>
                        <div className="error-text">*/}
                            {/*
                              errors.publicText && touched.publicText
                                ?
                                errors.publicText
                                :
                                null
                            */}
                          {/*<ErrorMessage name="publicText">
                            {errors.publicText}
                          </ErrorMessage>
                        </div>
                        <div>
                          <label htmlFor="name">
                            NAME
                          </label>
                        </div>
                        <div>
                          <Field type="text" className={`invitation-name`} name="name" id="name" placeholder="Enter the name" value={name} onChange={handleChangeName}/>
                        </div>*/}
                        <div className="text-center button--sign">
                          <ReactBootstrap.Button type="submit">Invite</ReactBootstrap.Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>We will send an invite code to this email address.</div>
              <div>This code remains valid for two weeks starting today.</div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          show
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="new-invitation-dashboard-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>
                {
                  errorText != ""
                    ?
                    errorText
                    :
                    "Invitation sent successfully."
                }
              </ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default CreateInvitation;
