import React from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';

/* SVG */
import NextButtonSVG from "./../assets/svg/nextButton.svg";
import OnboardingMailSVG from "./../assets/svg/onboardingMail.svg";
import OnboardingFeatureSVG from "./../assets/svg/onboardingFeature.svg";
import OnboardingIntroduceSVG from "./../assets/svg/onboardingIntroduce.svg";

function OnboardingStepOne() {
  return (
    <ReactBootstrap.Container fluid={true} id="onboarding-step-one">
      <ReactBootstrap.Row>
        <ReactBootstrap.Col md={5} lg={7} xl={7} id="login" className="d-none d-sm-none d-md-block">
          <div className="tbs--main--block">
            <div className="tbs--main--title">
              {/*<img src={Logo}/>*/}
              meoh TBS
            </div>
            <div className="tbs--main--subtitle text-center">
              Leverage your trust network
            </div>
          </div>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={7} lg={5} xl={5}>
          <div className="tbs--main--block--two d-block d-sm-block d-md-none d-lg-none d-xl-none">
            <div className="tbs--main--title">
              {/*<img src={LogoDark}/>*/}
              meoh TBS
            </div>
            {/*<div className="tbs--main--subtitle text-center">
              Trust Building System
            </div>*/}
          </div>
          <div className="tbs--form--block onboarding-details">
            <div className="tbs--form--title">
              <h1>Your network in 3 easy steps</h1>
            </div>
            <div className="tbs--onboarding--steps">
              <div>
                <img src={OnboardingMailSVG} alt="Mail" />
              </div>
              <div>
                1. Invite those you trust in a private network
              </div>
            </div>
            <div className="tbs--onboarding--steps">
              <div>
                <img src={OnboardingFeatureSVG} alt="Feature" />
              </div>
              <div>
                2. Feature them and discover their trusted network
              </div>
            </div>
            <div className="tbs--onboarding--steps">
              <div>
                <img src={OnboardingIntroduceSVG} alt="Introduce" />
              </div>
              <div>
                3. Introduce those who gain to know each other
              </div>
            </div>
            <div className="text-center">
              <Link to="/onboarding-step-two">
                <img src={NextButtonSVG} alt="Next" />
              </Link>
            </div>
          </div>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    </ReactBootstrap.Container>
  );
}

export default OnboardingStepOne;
