import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {useHistory} from "react-router-dom";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import autosize from "autosize";
/* import { yupResolver } from "@hookform/resolvers/yup";*/
import API from "../../../../config/config.json";

import IconClose from "../../../../assets/icons/IconDelete.png";
import IconTip from "../../../../assets/icons/IconTip.png";
//import IconImage from "../../../../assets/icons/IconImage.png";
import {Recorder} from "react-voice-recorder";
import AcceptSVG from "../../../../assets/svg/accept";

/* SVG */
import IconImage from "../../../../assets/svg/defaultImage.svg";
import UploadFile from "../../../../assets/svg/uploadFile.svg";
import DeleteFile from "../../../../assets/svg/deleteUploadFile.svg";

function CreatePost() {
  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Store and set the state value
  const [description, setDescription] = useState('');
  const [headline, setHeadline] = useState('');
  const [image, setImage] = useState('');
  const [imageId, setImageId] = useState('');
  const [audio, setAudio] = useState('');
  const [audioId, setAudioId] = useState('');
  const [recordAudio, setRecordAudio] = useState('');
  const [show, setShow] = useState(false);
  const [audioShow, setAudioShow] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [showOpenAudioModal, setShowOpenAudioModal] = useState(false);
  const [audioRecordFile, setAudioRecordFile] = useState(false);

  const [getFile, setGetFile] = useState("");
  const [getData, setGetData] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {
      h: null,
      m: null,
      s: null,
    }
  })

  // Main container for history
  let history = useHistory();

  // Make a redirect if value is true
  useEffect(() => {
    (() => {
      if(isRedirect) {
        history.push("/dashboard/profile");
      }
    })()

    setImageId(null);
    setAudioId(null);
  }, [isRedirect]);

  useEffect(() => {
    const textElement = document.querySelectorAll("textarea");
    autosize(textElement);
  })

  const handleChangeDescription = e => setDescription(e.target.value);
  const handleChangeHeadline = e => setHeadline(e.target.value);

  const imageUploadSave = (element) => {
    // URL for the files
    const urlFiles = API.URL + 'files';

    // Post value for validation - Files
    let dataFiles = new FormData();
    dataFiles.append("fileType", "IMAGE");
    dataFiles.append("file", element);
    dataFiles.append("type", "POST_IMAGE");

    // fetch data and validate
    axios
      .post(urlFiles, dataFiles, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setImageId(response.data.id);
          console.log("Successful");
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const audioUploadSave = async (element) => {
    // URL for the files
    const urlFiles = API.URL + 'files';

    const blobToBase64 = async blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };

    const convertAudioFile = await blobToBase64(element)
      .then((res) => {
        return res;
      })

    function urlToFile(url, filename, mimeType){
      return (fetch(url)
          .then(function(res){return res.arrayBuffer();})
          .then(function(buf){return new File([buf], filename,{type:mimeType});})
      );
    }
    const getAudioFile = await urlToFile(convertAudioFile, 'new-audio-record.mp3','audio/mp3')
      .then(function(file){
        return file;
      });

    // Post value for validation - Files
    let dataFiles = new FormData();
    dataFiles.append("fileType", "AUDIO");
    dataFiles.append("file", getAudioFile);
    dataFiles.append("type", "POST_AUDIO");

    // fetch data and validate
    axios
      .post(urlFiles, dataFiles, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setAudioId(response.data.id);
          console.log("Successful");
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Create post validation method
  const createPost = (e) => {
    e.preventDefault();

    // URL for create post
    const url = API.URL + 'posts';

    // Post value for validation
    const data = {
      type: "POST",
      title: headline,
      description: description,
      fileId: imageId,
      audioId: audioId
    };

    // fetch data and validate
    if(headline != ""){
      axios
        .post(url, data, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        })
        .then((response) => {
          if(response.status === 200){
            document.getElementsByClassName("error-text")[0].style.display = "none";
            document.getElementsByClassName("error-text")[0].innerHTML = "";
            document.getElementsByClassName("create-title")[0].classList.remove("error-field");

            setShow(true);

            document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "";
            document.getElementsByClassName("tbs--form--main--error")[0].style.display = "none";

            setTimeout(() => {
              setShow(false);
            }, 1000);

            setTimeout(() => {
              setIsRedirect(true);
            }, 2000);

          } else {
            document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Title can't be empty.";
            document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
            document.getElementsByClassName("create-title")[0].classList.add("error-field");
            document.getElementsByClassName("error-text")[0].style.display = "block";
            document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a title.";
          }
        })
        .catch(e => {
          document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Title can't be empty.";
          document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
          document.getElementsByClassName("create-title")[0].classList.add("error-field");
          document.getElementsByClassName("error-text")[0].style.display = "block";
          document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a title.";
          console.log(e)
        })
    } else {
      document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Title can't be empty.";
      document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
      document.getElementsByClassName("create-title")[0].classList.add("error-field");
      document.getElementsByClassName("error-text")[0].style.display = "block";
      document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a title.";
      console.log("Error");
    }
  }

  const Schema = Yup.object().shape({
    title: Yup.string()
      .required('Please enter a title.'),
    imageId: Yup.number(),
    audioId: Yup.number(),
    description: Yup.string()
  });

  const previewFile = () => {
    const preview = document.querySelector('.tbs--user--profile--image--default');
    const imageContainer = document.querySelector('.new-post-top-layout .tbs--default--image');
    const imageElement = document.querySelector('.new-post-top-layout tbs--default--image > img');

    const file = document.querySelector('#upload-image').files[0];
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      preview.src = reader.result;
    }, false);

    if (file) {
      reader.readAsDataURL(file);
      imageUploadSave(file);
      imageContainer.classList.remove("tbs--default--image")
      imageContainer.classList.add("tbs--upload--image--new--post")
      //imageElement.style.width = "100%"
    }
  }

  // Audio Recording - All
  const handleAudioStop = async (data) => {
    //console.log(data)
    setGetData(data);

    //console.log(data.url)
    setGetFile(data.url);

    setAudioRecordFile(true);

    setRecordAudio(data.url);

    await audioUploadSave(data.blob);

    document.querySelector("#audio-recording-update-post > div > div > div > div:nth-child(2) > a").classList.add("hide-record");
    document.querySelector("#audio-recording-update-post > div > div > div > div:nth-child(2) > a").classList.remove("show-record");
    document.querySelector("#audio-recording-update-post > div > div > div").classList.add("add-bottom");

    const x = document.querySelector("#audio-recording-update-post > div > div > div > div:nth-child(2) > div > p");
    x.innerHTML = "Tap the Reset button to erase your current recording and to record again.";
  }

  const handleAudioUpload = (file) => {
    console.log(file);
  }

  const handleReset = () => {
    setGetData({
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: null,
        m: null,
        s: null,
      }
    });
  }

  const completeReset = () => {
    setAudioRecordFile(false);

    document.querySelector("#audio-recording-update-post > div > div > div > div > div > button:nth-child(2)").click();

    document.querySelector("#audio-recording-update-post > div > div > div > div:nth-child(2) > a").classList.remove("hide-record");
    document.querySelector("#audio-recording-update-post > div > div > div > div:nth-child(2) > a").classList.add("show-record");
    document.querySelector("#audio-recording-update-post > div > div > div").classList.remove("add-bottom");

    const x = document.querySelector("#audio-recording-update-post > div > div > div > div:nth-child(2) > div > p");
    x.innerHTML = "Tap the microphone icon to start recording your audio message.";
  }

  // Get 'Tip - Create Post' - Storage
  let createPostTip = localStorage.getItem("tbs-tip-create-post-" + userID);

  // Execute the API and get all informations
  useEffect( () => {
    if(createPostTip){
      document.getElementById("tip-create-post").classList.add("tips-disabled");
    } else {
      document.getElementById("tip-create-post").classList.remove("tips-disabled");
    }
  }, []);

  const tipClose = () => {
    localStorage.setItem("tbs-tip-create-post-" + userID, "Create post tips accepted.");
    document.getElementById("tip-create-post").classList.add("tips-disabled");
  }

  const openRecordModal = () => {
    setShowOpenAudioModal(true)

    setTimeout(() => {
      const x = document.querySelector("#audio-recording-update-post > div > div > div > div:nth-child(2) > div > p");
      x.innerHTML = "Tap the microphone icon to start recording your audio message.";
    }, 300);
  }

  /*useEffect( () => {
    let newElement = document.createElement("span")

    const mainElement = document.querySelector("#audio-recording-update-post > div > div > div > div:nth-child(2)");
    const lastElement = document.querySelector("#audio-recording-update-post > div > div > div > div:nth-child(2) > a");

    mainElement.insertBefore(newElement, lastElement)
  })*/

  useEffect( () => {
    setTimeout(() => {
      const topElement = document.getElementById("new-post");

      if(typeof(topElement) != 'undefined' && topElement != null){
        const offsetTop = topElement.offsetTop-300;

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth"
        });
      }
    }, 1500)
  }, [])

  const selectImage = () => {
    document.getElementById("upload-image").click();
  }

  const deleteNewUploadFile = () => {
    setImageId(null)
  }

  useEffect(() => {
    let input_string = window.location.href;
    let url = new URL(input_string);
    let titleValue = url.searchParams.get("title");
    setHeadline(titleValue);
  }, [])

  return (
    <ReactBootstrap.Row id="new-post">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-create-post">
        <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
          <ReactBootstrap.Row>
            {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--tips--close">
              <img src={IconClose} alt="Tips - Close" onClick={tipClose}/>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-right">
              <img src={IconTip} alt="Tips" />
            </ReactBootstrap.Col>*/}
            <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9}>
              <div className="tbs--tips--subheadline">
                TIP
              </div>
              <div>
                Post to groups or to profile and edit your posts from your profile page.
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
              <div onClick={tipClose}>
                <AcceptSVG/>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container">
          <ReactBootstrap.Row>
            <div className="tbs--headline tbs--headline--update-post">
              New Post
            </div>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--update--post">
              <div className="form-update-post">
                <div className="tbs--form--main--error text-center"></div>
                <div className="tbs--form--complete--post">
                  <Formik
                    initialValues={{
                      type: "POST",
                      imageId: imageId,
                      audioId: audioId,
                      title: '',
                      description: '',
                    }}
                    validationSchema={Schema}
                    onSubmit={values => {
                      console.log(values.text);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form onSubmit={createPost}>
                        <div>
                          <label htmlFor="title">
                            TITLE
                          </label>
                        </div>
                        <div>
                          <Field type="text" maxLength={70} className={`create-title`} name="title" id="title" placeholder="Enter a title" value={headline} onChange={handleChangeHeadline}/>
                        </div>
                        <div className="error-text">
                          {/*
                            errors.title && touched.title
                              ?
                              errors.title
                              :
                              null
                          */}
                          <ErrorMessage name="title">
                            {errors.title}
                          </ErrorMessage>
                        </div>
                        <div>
                          <label htmlFor="description">
                            TEXT
                          </label>
                        </div>
                        <div>
                          <Field as="textarea" maxLength={1500} rows={4} className={`create-description`} name="description" id="description" placeholder="Enter your description" value={description} onChange={handleChangeDescription}/>
                        </div>
                        <div className="error-text">
                          {/*
                            errors.description && touched.description
                              ?
                              errors.description
                              :
                              null
                          */}
                          <ErrorMessage name="description">
                            {errors.description}
                          </ErrorMessage>
                        </div>
                        <div>
                          <label htmlFor="upload-image">
                            IMAGE
                          </label>
                        </div>
                        {
                          imageId == null
                          ?
                            <div>
                              <div className={`upload-new-image`} name="image" id="image" onClick={selectImage}>
                                Add image
                              </div>
                              <input type="file" id="upload-image" accept="image/*" onChange={previewFile} hidden/>
                            </div>
                          :
                            ""
                        }
                        <ReactBootstrap.Row className={imageId != null ? "new-post-top-layout-added" : "new-post-top-layout-added hide"}>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <label htmlFor="upload-image" className="new-image-upload">
                              <div className="tbs--default--image">
                                <img src={IconImage} alt="Profile - Image" className="tbs--user--profile--image--default"/>
                                <input type="file" id="upload-image" onChange={previewFile} hidden/>
                                <div id="upload-image-new-post" className="text-right">
                                  {/*<label htmlFor="upload-image" className="tbs--upload--image">
                                    UPLOAD AN IMAGE
                                    <img src={UploadFile} alt="Upload file" id="upload-file"/>
                                  </label>*/}
                                </div>
                                <div className="text-center" id="edit-file">
                                  <label htmlFor="upload-image" className="tbs--upload--image new-upload">
                                    {/*UPLOAD AN IMAGE*/}
                                    <img src={UploadFile} alt="Upload file" id="upload-file"/>
                                  </label>
                                </div>
                                <div className="text-center" id="delete-file" onClick={deleteNewUploadFile}>
                                  <label className="tbs--upload--image new-upload">
                                    {/*UPLOAD AN IMAGE*/}
                                    <img src={DeleteFile} alt="Upload file" id="delete-upload-file"/>
                                  </label>
                                </div>
                              </div>
                            </label>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <div className="text-left audio-message-record">
                          <label htmlFor="audio">
                            AUDIO
                          </label>
                        </div>
                        <div>
                          <Field type="text" className={`create-audio-message`} name="audio" id="audio" placeholder="Audio message added." onClick={openRecordModal}/>
                        </div>
                        {/*
                          recordAudio != ""
                          ?
                            <div className="text-center">
                              <audio controls>
                                <source src={recordAudio} type="audio/mp3"/>
                                Your browser does not support the audio element.
                              </audio>
                            </div>
                          :
                            ""
                        */}
                        {
                          /*
                            <div className="text-center button--sign" id="audio-recorder">
                              <ReactBootstrap.Button onClick={openRecordModal}>RECORD</ReactBootstrap.Button>
                            </div>
                          */
                        }
                        <ReactBootstrap.Modal
                          show={showOpenAudioModal}
                          onHide={() => setShowOpenAudioModal(false)}
                          size="md"
                          aria-labelledby="contained-modal-title-vcenter-record"
                          centered
                        >
                          <ReactBootstrap.Modal.Header closeButton>
                            <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-record">
                              Add audio message
                            </ReactBootstrap.Modal.Title>
                          </ReactBootstrap.Modal.Header>
                          <ReactBootstrap.Modal.Body id="audio-record-create-post">
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div id="audio-recording-update-post">
                                  <Recorder
                                    record={false}
                                    title={"Audio Recording"}
                                    audioURL={getData.url}
                                    showUIAudio
                                    hideHeader={false}
                                    handleAudioStop={data => handleAudioStop(data)}
                                    handleAudioUpload={data => handleAudioUpload(data)}
                                    handleReset={handleReset}
                                    //mimeTypeToUseWhenRecording={`audio/webm`}
                                  />
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                            {
                              audioRecordFile
                              ?
                                <ReactBootstrap.Row>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                    <div id="audio-recording-update-post-reset" onClick={() => completeReset()}>
                                      Reset
                                    </div>
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                              :
                                ""
                            }
                          </ReactBootstrap.Modal.Body>
                        </ReactBootstrap.Modal>
                        <div className="text-center button--sign">
                          <ReactBootstrap.Button type="submit">Post</ReactBootstrap.Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          show
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="create-post-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>Publication successful created.</ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default CreatePost;
