import React from 'react';

const AcceptSVG = () => {
  return(
    <svg id="Komponente_59_1" data-name="Komponente 59 – 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="420" height="180" viewBox="0 0 420 180">
      <defs>
        <filter id="Icon_awesome-check" x="46.694" y="56.337" width="92.532" height="73.579" filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur"/>
          <feFlood flood-opacity="0.161"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Icon_awesome-check-2" x="46.694" y="56.337" width="92.532" height="73.579" filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur-2"/>
          <feFlood flood-opacity="0.161"/>
          <feComposite operator="in" in2="blur-2"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g id="Gruppe_1415" data-name="Gruppe 1415" transform="translate(-982 -2857)">
        <g id="Rechteck_732" data-name="Rechteck 732" transform="translate(982 2857)" fill="#C6CDDC" stroke="none" stroke-width="3">
          <rect width="360" height="180" rx="26" stroke="#C6CDDC"/>
          <rect x="1.5" y="1.5" width="357" height="177"  fill="none"/>
        </g>
        <path id="Pfad_143" data-name="Pfad 143" d="M26,0H154a26,26,0,0,1,26,26V154a26,26,0,0,1-26,26H26A26,26,0,0,1,0,154V26A26,26,0,0,1,26,0Z" transform="translate(982 2857)" fill="#C6CDDC"/>
        <g id="Gruppe_1413" data-name="Gruppe 1413" transform="translate(-6735 -3454.999)">
          <g id="Gruppe_960" data-name="Gruppe 960" transform="translate(7730.242 6325.195)">
            <rect id="Rechteck_729" data-name="Rechteck 729" width="154" height="154" transform="translate(-0.242 -0.196)" fill="#fff"/>
            <g transform="matrix(1, 0, 0, 1, -13.24, -13.2)" filter="url(#Icon_awesome-check)">
              <path id="Icon_awesome-check-3" data-name="Icon awesome-check" d="M25.314,59.065,1.091,34.842a3.727,3.727,0,0,1,0-5.27l5.27-5.27a3.726,3.726,0,0,1,5.27,0L27.949,40.619,62.9,5.669a3.727,3.727,0,0,1,5.27,0l5.27,5.27a3.727,3.727,0,0,1,0,5.27L30.585,59.065A3.727,3.727,0,0,1,25.314,59.065Z" transform="translate(52.69 57.76)" fill="#1597b9"/>
            </g>
          </g>
          <text id="Got_it_" data-name="Got it!" transform="translate(7976.271 6416.836)" fill="none" font-size="55" font-family="Ubuntu-Light, Ubuntu" font-weight="300"><tspan x="-61.943" y="0">Got it!</tspan></text>
        </g>
        <g id="Gruppe_1414" data-name="Gruppe 1414" transform="translate(-6735 -3454.999)" stroke="none">
          <g id="Gruppe_960-2" data-name="Gruppe 960" transform="translate(7730.242 6315.195)" stroke="#C6CDDC">
            <rect id="Rechteck_729-2" data-name="Rechteck 729" width="180" height="180" transform="translate(-2.75 -2.0)" fill="#C6CDDC" stroke="#C6CDDC"/>
            <g transform="matrix(1.25, 0, 0, 1.25, -40.24, -40.2)" filter="url(#Icon_awesome-check-2)">
              <path id="Icon_awesome-check-4" data-name="Icon awesome-check" d="M25.314,59.065,1.091,34.842a3.727,3.727,0,0,1,0-5.27l5.27-5.27a3.726,3.726,0,0,1,5.27,0L27.949,40.619,62.9,5.669a3.727,3.727,0,0,1,5.27,0l5.27,5.27a3.727,3.727,0,0,1,0,5.27L30.585,59.065A3.727,3.727,0,0,1,25.314,59.065Z" transform="translate(52.69 57.76)" fill="#141C27"/>
            </g>
          </g>
          <text id="Got_it_2" data-name="Got it!" transform="translate(7976.271 6416.836)" fill="#141C27" font-size="80" font-family="Ubuntu-Light, Ubuntu" font-weight="500"><tspan x="-68.825" y="0">Got it!</tspan></text>
        </g>
      </g>
    </svg>
  )
}

export default AcceptSVG;

/* Original */

/*
<svg id="Komponente_59_1" data-name="Komponente 59 – 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="360" height="180" viewBox="0 0 360 180">
  <defs>
    <filter id="Icon_awesome-check" x="46.694" y="56.337" width="92.532" height="73.579" filterUnits="userSpaceOnUse">
      <feOffset dx="3" dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Icon_awesome-check-2" x="46.694" y="56.337" width="92.532" height="73.579" filterUnits="userSpaceOnUse">
      <feOffset dx="3" dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-2"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Gruppe_1415" data-name="Gruppe 1415" transform="translate(-982 -2857)">
    <g id="Rechteck_732" data-name="Rechteck 732" transform="translate(982 2857)" fill="#fefefe" stroke="#fefefe" stroke-width="3">
      <rect width="360" height="180" rx="26" stroke="none"/>
      <rect x="1.5" y="1.5" width="357" height="177" rx="24.5" fill="none"/>
    </g>
    <path id="Pfad_143" data-name="Pfad 143" d="M26,0H154a26,26,0,0,1,26,26V154a26,26,0,0,1-26,26H26A26,26,0,0,1,0,154V26A26,26,0,0,1,26,0Z" transform="translate(982 2857)" fill="#fefefe"/>
    <g id="Gruppe_1413" data-name="Gruppe 1413" transform="translate(-6735 -3454.999)">
      <g id="Gruppe_960" data-name="Gruppe 960" transform="translate(7730.242 6325.195)">
        <rect id="Rechteck_729" data-name="Rechteck 729" width="154" height="154" rx="26" transform="translate(-0.242 -0.196)" fill="#fff"/>
        <g transform="matrix(1, 0, 0, 1, -13.24, -13.2)" filter="url(#Icon_awesome-check)">
          <path id="Icon_awesome-check-3" data-name="Icon awesome-check" d="M25.314,59.065,1.091,34.842a3.727,3.727,0,0,1,0-5.27l5.27-5.27a3.726,3.726,0,0,1,5.27,0L27.949,40.619,62.9,5.669a3.727,3.727,0,0,1,5.27,0l5.27,5.27a3.727,3.727,0,0,1,0,5.27L30.585,59.065A3.727,3.727,0,0,1,25.314,59.065Z" transform="translate(52.69 57.76)" fill="#1597b9"/>
        </g>
      </g>
      <text id="Got_it_" data-name="Got it!" transform="translate(7976.271 6416.836)" fill="#fefefe" font-size="45" font-family="Ubuntu-Light, Ubuntu" font-weight="300"><tspan x="-61.943" y="0">Got it!</tspan></text>
    </g>
    <g id="Gruppe_1414" data-name="Gruppe 1414" transform="translate(-6735 -3454.999)">
      <g id="Gruppe_960-2" data-name="Gruppe 960" transform="translate(7730.242 6325.195)">
        <rect id="Rechteck_729-2" data-name="Rechteck 729" width="154" height="154" rx="26" transform="translate(-0.242 -0.196)" fill="#141c27"/>
        <g transform="matrix(1, 0, 0, 1, -13.24, -13.2)" filter="url(#Icon_awesome-check-2)">
          <path id="Icon_awesome-check-4" data-name="Icon awesome-check" d="M25.314,59.065,1.091,34.842a3.727,3.727,0,0,1,0-5.27l5.27-5.27a3.726,3.726,0,0,1,5.27,0L27.949,40.619,62.9,5.669a3.727,3.727,0,0,1,5.27,0l5.27,5.27a3.727,3.727,0,0,1,0,5.27L30.585,59.065A3.727,3.727,0,0,1,25.314,59.065Z" transform="translate(52.69 57.76)" fill="#fefefe"/>
        </g>
      </g>
      <text id="Got_it_2" data-name="Got it!" transform="translate(7976.271 6416.836)" fill="#141c27" font-size="50" font-family="Ubuntu-Light, Ubuntu" font-weight="300"><tspan x="-68.825" y="0">Got it!</tspan></text>
    </g>
  </g>
</svg>
*/
