import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
/* import { yupResolver } from "@hookform/resolvers/yup";*/
import API from "./../../config/config.json";

function Feedback() {

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");

  // Store and set the state value
  const [text, setText] = useState('');
  const [show, setShow] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  // Main container for history
  let history = useHistory();

  // Make a redirect if value is true
  useEffect(() => {
    (() => {
      if(isRedirect) {
        history.push("/dashboard/newsfeed");
      }
    })()
  }, [isRedirect]);

  const handleChangeText = e => setText(e.target.value);

  // Feedback validation method
  const validationFeedback = (e) => {
    e.preventDefault();

    // URL for the feedback
    const url = API.URL + 'feedback';

    // Post value for validation
    const data = {
      text: text,
    };

    // fetch data and validate
    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);

          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("feedback")[0].classList.remove("error-field");

          setTimeout(() => {
            setShow(false);
          }, 1500);

          setTimeout(() => {
            setIsRedirect(true);
          }, 2000);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        document.getElementsByClassName("error-text")[0].style.display = "block";
        document.getElementsByClassName("error-text")[0].innerHTML = "Please enter your feedback.";
        document.getElementsByClassName("feedback")[0].classList.add("error-field");

        console.log(e)
      })
  }

  const Schema = Yup.object().shape({
    text: Yup.string()
      .required('Please enter your feedback.')
  });

  return (
    <ReactBootstrap.Row id="feedback">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true}>
          <ReactBootstrap.Row>
            <div className="tbs--headline tbs--headline--feedback">
              Feedback
            </div>
            <div className="tbs--divider--title tbs--feedback--submenu--divider">
              <hr></hr>
            </div>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container">
          <ReactBootstrap.Row>
            {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--feedback">
              How can we improve?
            </ReactBootstrap.Col>*/}
            {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--feedback">
              Please let us know how we can improve.
            </ReactBootstrap.Col>*/}
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--feedback">
              <Formik
                initialValues={{
                  text: '',
                }}
                validationSchema={Schema}
                onSubmit={values => {
                  console.log(values.text);
                }}
              >
                {({ errors, touched }) => (
                  <Form onSubmit={validationFeedback}>
                    <Field as="textarea" className={`feedback`} rows="10" name="text" placeholder="Please let us know how we can improve." value={text} onChange={handleChangeText} />
                    {/*Provide your feedback here*/}
                    <div className="error-text">
                    {/*
                      errors.text && touched.text
                      ?
                        errors.text
                      :
                        null
                    */}
                      <ErrorMessage name="text">
                        {errors.text}
                      </ErrorMessage>
                    </div>
                    <div className="text-center">
                      <ReactBootstrap.Button className="feedback-btn" type="submit">Send</ReactBootstrap.Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--feedback text-center">
             Thank you.
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
        {
          show
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="feedback-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>Feedback submitted successfully to Meoh</ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default Feedback;
