import React from 'react';

const ChatSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="98" height="89.077" viewBox="0 0 98 89.077">
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" y1="-0.642" x2="0.5" y2="1.623" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#000"/>
          <stop offset="1" stop-color="#000"/>
        </linearGradient>
        <filter id="Icon_metro-bubbles" x="0" y="0" width="98" height="89.077" filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur"/>
          <feFlood flood-opacity="0.161"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Icon_metro-bubbles)">
        <path id="Icon_metro-bubbles-2" data-name="Icon metro-bubbles" d="M78.127,67.724a8.109,8.109,0,0,0,4.444,7.235v1.107a16.533,16.533,0,0,1-14.252-5,22.328,22.328,0,0,1-5.748.747c-11.046,0-20-7.959-20-17.778s8.954-17.778,20-17.778,20,7.959,20,17.778a16.4,16.4,0,0,1-3.705,10.31,8.066,8.066,0,0,0-.739,3.382Zm-40-62.581C57.464,5.143,73.2,17.686,73.671,33.3a27.251,27.251,0,0,0-11.1-2.328,26.464,26.464,0,0,0-17.648,6.54,21.938,21.938,0,0,0-7.629,16.515,21.309,21.309,0,0,0,1.926,8.874c-.363.009-.727.015-1.093.015a43.913,43.913,0,0,1-5.543-.35c-7.637,7.637-16.753,9.007-25.568,9.208V69.909C11.775,67.577,15.9,63.33,15.9,58.476a13.413,13.413,0,0,0-.15-1.993C7.713,51.187,2.571,43.1,2.571,34.032c0-15.955,15.919-28.889,35.556-28.889Z" transform="translate(3.43 0.86)" opacity="0.95" fill="url(#linear-gradient)"/>
      </g>
    </svg>
  )
}

export default ChatSVG;
