import React, { useState, useEffect } from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {Link} from "react-router-dom";
import axios from 'axios';
import moment from "moment";
import Linkify from "react-linkify";
import {useMediaQuery} from "react-responsive";
import {isSafari} from "react-device-detect";

import API from "./../../config/config.json";

import ActionMenu from "./actionMenu";

import IconAvatar from "../../assets/svg/avatar.svg";
import IconAwardsActive from "../../assets/icons/IconAwardsBlue.png";
import IconComment from "../../assets/icons/IconComment.png";
import IconRepost from "../../assets/icons/IconRepost.png";
import IconForward from "../../assets/icons/IconForward.png";
import IconBookmarks from "../../assets/icons/IconBookmarks.png";
import IconDelete from "../../assets/icons/IconDelete.png";
import IconPulse from "../../assets/icons/IconPulse.png";
import IconBookmarksActive from "../../assets/icons/IconBookmarksBlue.png";
import IconPen from "../../assets/icons/IconPenGrey.png";
//import IconHighlight from "../../assets/icons/IconBarChartWhite.png";

/* SVG */
import IconPlayWhite from "../../assets/svg/playWhite.svg";
import IconPlayBlack from "../../assets/svg/playBlack.svg";
import PostCommentSVG from "../../assets/svg/postComment";
import PostRepostSVG from "../../assets/svg/postRepost";
import PostNoRepostSVG from "../../assets/svg/postNoRepost";
import PostForwardSVG from "../../assets/svg/postForward";
import PostBookmarkSVG from "../../assets/svg/postBookmark";
import PostActivitySVG from "../../assets/svg/postActivity";
import PostEditSVG from "../../assets/svg/postEdit";
import PostDeleteSVG from "../../assets/svg/postDelete";
import FeaturedSVG from "../../assets/svg/featured";
import UnFeaturedSVG from "../../assets/svg/unfeatured";
import PostBookmarkActiveSVG from "../../assets/svg/postBookmarkActive";
import IconHighlight from "../../assets/svg/socialNetwork.svg";
import EditProfileIcon from "../../assets/svg/uploadFile.svg";
import EditProfileNewIcon from "../../assets/svg/editUpdatePen.svg";
import PostSubmenu from "../../assets/svg/dotMenu.svg";
import CommentButton from "../../assets/svg/commentButton.svg";
import NotificationWhiteSVG from "../../assets/svg/notificationWhite";

function Profile() {

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Get url from your profile
  const url = API.URL + 'users/profiles';

  // Get url from other user profile awards
  const urlTwo = API.URL + "users/" + userID + "/all-awards";

  // Get url from your profile - more informations
  const urlThree = API.URL + 'users';

  // Get url from feeds from own user
  // const urlFeeds = API.URL + 'users/' + userID + '/posts'; /* Old */
  const urlFeeds = API.URL + 'my-posts';

  // Store and set the state value
  const [responseData, setResponseData] = useState([]);
  const [responseTwoData, setResponseTwoData] = useState([]);
  const [responseThreeData, setResponseThreeData] = useState([]);
  const [responseFeedData, setResponseFeedData] = useState([]);
  const [toggleValue, setToggleValue] = useState(false);
  const [show, setShow] = useState(false);
  const [accordionStatus, setAccordionStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [showFavorite, setShowFavorite] = useState(false);
  const [postId, setPostId] = useState(0);
  const [title, setTitle] = useState("");
  const [showFavoriteText, setShowFavoriteText] = useState("");

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isBiggerTablet = useMediaQuery({ query: '(min-width: 991px)' })

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      url, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setResponseData(result.data.items[0]);

    const resultAward = await axios.get(
      urlTwo, {
        headers: {
          authorization: token
        }
      }
    );

    setResponseTwoData(resultAward.data.items);

    const resultSecond = await axios.get(
      urlThree, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setResponseThreeData(resultSecond.data);

    const resultFeed = await axios.get(
      urlFeeds, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setResponseFeedData(resultFeed.data.items);

    let allLinks = document.querySelectorAll("#moreProfile a");

    for(let i = 0; i < allLinks.length; i++){
      allLinks[i].setAttribute("target", "_blank");
    }

    const accStatus = responseData.position != "" && responseData.company != "" && responseData.expertise != null && responseData.fieldOfStudy != "" && responseData.education != "" && responseData.school != "" && responseData.website != null && responseData.linkedinUrl != null && responseData.twitterUrl != null && responseData.socialLinks != null && responseData.interests != null && responseData.sideProjects != null && responseData.askMeAbout != null && responseData.lookingFor != null && responseData.quoteILike != null && responseData.artOrMusicILike != null;

    setAccordionStatus(accStatus);
  }, []);

  // fetch data and validate
  const deletePost = (id) => {
    // URL for deleting my own posts
    const url = API.URL + 'posts/' + id;

    axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);
          setIsModalDeleteOpen(false);

          setTimeout(() => {
            setShow(false);
          }, 1200);

          setTimeout(async () => {

            const resultFeed = await axios.get(
              urlFeeds, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setResponseFeedData(resultFeed.data.items);
          }, 1500);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Change the text and icon in a toggle method
  const toggleText = () => {
    const elem = document.getElementById("moreProfile");
    if(elem.classList.contains("show")){
      setToggleValue(false);
    } else {
      setToggleValue(true);
    }
  }

  const savePostId = (id) => {
    setPostId(id);
    setIsModalDeleteOpen(true)
  }

  const truncateMessage = (str, num) => {

    if(str == null || !str) {
      return "Read more ...";
    }

    if(str.length <= num) {
      return str;
    }

    return str.slice(0, num) + ' ... Read more ...';
  }

  const createNewPost = () => {
    window.location.href = "/dashboard/profile/post/create?title=" + title;

    /*localStorage.setItem("Post_Title", title);*/
  }

  const handleChangeTitle = e => setTitle(e.target.value);

  // Favourite validation method
  const validationFavourite = (item) => {
    // URL for the post favourites
    const url = API.URL + 'posts/' + item.id + '/favourites';

    // fetch data and validate
    axios
      .put(url, "", {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShowFavorite(true);

          if(item.isFavourite == true){
            setShowFavoriteText("Remove from favourites")
          } else {
            setShowFavoriteText("Added to favourites")
          }

          setTimeout(() => {
            setShowFavorite(false);
          }, 1000);

          setTimeout(async () => {
            //window.location.reload();
            setShowFavorite(false);

            const resultFeed = await axios.get(
              urlFeeds, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setResponseFeedData(resultFeed.data.items);
          }, 1200);
        } else {
          console.log("Error: Cannot handle this method.");
        }
      })
      .catch(e => {
        console.log(e);
      })

  };

  return (
    <ReactBootstrap.Row id="profile">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="profile">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--header">
              <div>
                <img src={responseThreeData.profileImage == null ? IconAvatar : responseThreeData.profileImage.url} className="tbs--image--main" alt="Profile" onClick={() => setIsModalOpen(true)}/>
              </div>
              <ReactBootstrap.Modal
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <ReactBootstrap.Modal.Header closeButton>
                  <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                    Profile Image
                  </ReactBootstrap.Modal.Title>
                </ReactBootstrap.Modal.Header>
                <ReactBootstrap.Modal.Body id="image-profile-big">
                  <ReactBootstrap.Row>
                    <img src={responseThreeData.profileImage == null ? IconAvatar : responseThreeData.profileImage.url} alt="Profile"/>
                  </ReactBootstrap.Row>
                </ReactBootstrap.Modal.Body>
              </ReactBootstrap.Modal>
              <div id="edit-profile">
                <Link to={"/dashboard/settings/my-profile"}>
                  <img src={EditProfileNewIcon} alt="Edit profile"/>
                </Link>
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--main--data text-center">
              <div className="tbs--contact--data">
                <div className="tbs--contact--data--name">
                  {responseData.name}
                </div>
                {
                  responseData.position != "" && responseData.company == ""
                    ?
                    <div className="tbs--contact--data--work">
                      {responseData.position}
                    </div>
                    :
                      responseData.company != "" && responseData.position == ""
                      ?
                        <div className="tbs--contact--data--work">
                          {responseData.company}
                        </div>
                      :
                        responseData.company != "" && responseData.position != ""
                        ?
                          <div className="tbs--contact--data--work">
                            {responseData.position} at {responseData.company}
                          </div>
                        :
                          ""
                }
                {
                  responseData.city != "" && responseData.country == ""
                  ?
                    <div className="tbs--contact--data--region">
                      {responseData.city}
                    </div>
                  :
                    responseData.country != "" && responseData.city == ""
                    ?
                      <div className="tbs--contact--data--region">
                        {responseData.country}
                      </div>
                    :
                      responseData.country != "" && responseData.city != ""
                      ?
                        <div className="tbs--contact--data--region">
                          {responseData.city}, {responseData.country}
                        </div>
                      :
                        ""
                }
              </div>
              {
                responseData.about != ""
                ?
                  <div className="tbs--about--data">
                    {responseData.about}
                  </div>
                :
                  ""
              }
              <div>
                <hr className="divider--line" />
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--more--data">
              <ReactBootstrap.Accordion>
                <ReactBootstrap.Card>
                  <ReactBootstrap.Card.Header className="text-center">
                    <ReactBootstrap.Accordion.Toggle eventKey={1} id="tbs--more--data--headline" onClick={() => toggleText()}>
                      {!toggleValue ? "Show more informations" : "Show less informations"} {!toggleValue ? <span className={`arrow ${isSafari ? "arrow-browser-safari" : ""}`}></span> : <span className={`arrow--active ${isSafari ? "arrow-browser-safari" : ""}`}></span>}
                    </ReactBootstrap.Accordion.Toggle>
                  </ReactBootstrap.Card.Header>
                  <ReactBootstrap.Accordion.Collapse eventKey={1} id="moreProfile">
                    <ReactBootstrap.Card.Body>
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6} id="business">
                          <div className="tbs--category--data--headline">
                            Work
                          </div>
                          {
                            (responseData.position == "" && responseData.company == "" && responseData.expertise == null) == true
                              ?
                              <div className="tbs--accordion--info--block">
                                The user has not provided any information.
                              </div>
                              :
                              ""
                          }
                          {
                            responseData.position != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Role
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  {responseData.position != null ? responseData.position : "-"}
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            responseData.company != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Company or organization
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  {responseData.company != null ? responseData.company : "-"}
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            responseData.expertise != null
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Expertise
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  {responseData.expertise != null ? responseData.expertise : "-"}
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          <div className="tbs--category--data--headline">
                            Education
                          </div>
                          {
                            (responseData.fieldOfStudy == "" && responseData.education == "" && responseData.school == "") == true
                            ?
                              <div className="tbs--accordion--info--block">
                                The user has not provided any information.
                              </div>
                            :
                              ""
                          }
                          {
                            responseData.fieldOfStudy != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Field of study
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  {responseData.fieldOfStudy != null ? responseData.fieldOfStudy : "-"}
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            responseData.education != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Degree
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  {responseData.education != null ? responseData.education : "-"}
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            responseData.school != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  School
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  {responseData.school != null ? responseData.school : "-"}
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6} id="business-two">
                          <div className="tbs--category--data--headline">
                            Social
                          </div>
                          {
                            (responseData.website == "" && responseData.socialLinks == "") == true && ((typeof responseData.linkedinUrl == "string" && responseData.linkedinUrl == "" && typeof responseData.twitterUrl == "string" && responseData.twitterUrl == "") || (typeof responseData.linkedinUrl == "object" && responseData.linkedinUrl == null && typeof responseData.twitterUrl == "object" && responseData.twitterUrl == null)) == true
                              ?
                              <div className="tbs--accordion--info--block">
                                The user has not provided any information.
                              </div>
                              :
                              ""
                          }
                          {
                            responseData.website != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Website
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {responseData.website != null ? responseData.website : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            typeof responseData.linkedinUrl == "string" && responseData.linkedinUrl != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  LinkedIn
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {responseData.linkedinUrl != "" ? responseData.linkedinUrl : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              typeof responseData.linkedinUrl == "object" && responseData.linkedinUrl != null
                                ?
                                <ReactBootstrap.Row>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                    LinkedIn
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                    <Linkify>
                                      {responseData.linkedinUrl != null ? responseData.linkedinUrl : "-"}
                                    </Linkify>
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                                :
                                ""
                          }
                          {
                            typeof responseData.twitterUrl == "string" && responseData.twitterUrl != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Twitter
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {responseData.twitterUrl != "" ? responseData.twitterUrl : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              typeof responseData.twitterUrl == "object" && responseData.twitterUrl != null
                                ?
                                <ReactBootstrap.Row>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                    Twitter
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                    <Linkify>
                                      {responseData.twitterUrl != null ? responseData.twitterUrl : "-"}
                                    </Linkify>
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                                :
                                ""
                          }
                          {
                            responseData.socialLinks != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Other social profile
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {responseData.socialLinks != null ? responseData.socialLinks : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          <div className="tbs--category--data--headline">
                            Conversation Starters
                          </div>
                          {
                            ((typeof responseData.interests == "string" && responseData.interests == "") || (typeof responseData.interests == "object" && responseData.interests == null)) == true && (responseData.sideProjects == null && responseData.askMeAbout == null && responseData.lookingFor == null && responseData.quoteILike == null && responseData.artOrMusicILike == null) == true
                              ?
                              <div className="tbs--accordion--info--block">
                                The user has not provided any information.
                              </div>
                              :
                              ""
                          }
                          {
                            typeof responseData.interests == "string" && responseData.interests != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Stuff I'm interested
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {responseData.interests != "" ? responseData.interests : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              typeof responseData.interests == "object" && responseData.interests != null
                              ?
                                <ReactBootstrap.Row>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                    Stuff I'm interested
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                    <Linkify>
                                      {responseData.interests != null ? responseData.interests : "-"}
                                    </Linkify>
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                              :
                                ""
                          }
                          {
                            responseData.sideProjects != null && responseData.sideProjects != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Side projects I'm working on
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {responseData.sideProjects != null && responseData.sideProjects != "" ? responseData.sideProjects : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            responseData.askMeAbout != null && responseData.askMeAbout != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Ask me about
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {responseData.askMeAbout != null && responseData.askMeAbout != "" ? responseData.askMeAbout : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            responseData.lookingFor != null && responseData.lookingFor != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  What I'm looking for
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {responseData.lookingFor != null && responseData.lookingFor != "" ? responseData.lookingFor : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            responseData.quoteILike != null && responseData.quoteILike != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Quote that I like
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {responseData.quoteILike != null && responseData.quoteILike != "" ? responseData.quoteILike : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            responseData.artOrMusicILike != null && responseData.artOrMusicILike != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Art or music that I like
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {responseData.artOrMusicILike != null && responseData.artOrMusicILike != "" ? responseData.artOrMusicILike : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="awards">
                          {/*<hr className="divider--line" />
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center tbs--awards--headline">
                              <img src={IconAwardsActive} alt="User Awards"/><span>You have received {responseData.totalAwards} Awards</span>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>*/}
                          <ReactBootstrap.Row>
                            {
                              /*responseTwoData.map((item, index) => {
                                if(item.users.length !== 0){
                                  return (
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} key={item.id}>
                                      <div className="text-center">
                                        {item.users.length} people find you {item.name}
                                      </div>
                                      <div className="tbs--contact--friends--awards tbs--contact--awards">
                                        {
                                          item.users.map((user, index) => {
                                            return (
                                              <div className="text-center tbs--contact--awards--profiles" key={user.id}>
                                                <div className="tbs--contact--image">
                                                  <Link to={`/dashboard/profile/${user.id}`}>
                                                    <img src={user.profileImage == null ? IconAvatar : user.profileImage.url}
                                                         className="tbs--image--trusted"
                                                         alt="Profile"/>
                                                  </Link>
                                                </div>
                                                <div className="tbs--contact--feature">
                                                  {user.name}
                                                </div>
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                    </ReactBootstrap.Col>
                                  )
                                }
                              })*/
                            }
                          </ReactBootstrap.Row>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    </ReactBootstrap.Card.Body>
                  </ReactBootstrap.Accordion.Collapse>
                </ReactBootstrap.Card>
              </ReactBootstrap.Accordion>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row id="profile--submenu">
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist tbs--new--title">
                <ReactBootstrap.Row>
                  <div className="tbs--headline">
                    <span>My Posts</span>
                    <Link to={"/dashboard/profile/network"}>
                      <span>My Highlights</span>
                    </Link>
                  </div>
                  <div className="tbs--divider--title tbs--additional--menu">
                    <hr></hr>
                  </div>
                </ReactBootstrap.Row>
              </ReactBootstrap.Container>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tbs--create--new--post">
              <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist tbs--create--post--container">
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--container--feedlist--item">
                    <div className="tbs--title--new--post">
                      Create post
                    </div>
                    <div>
                      {
                        responseThreeData != null && responseThreeData.profileImage == null
                          ?
                          <img src={IconAvatar} alt="Profile" />
                          :
                          <img src={responseThreeData.profileImage.url} alt="Profile" />
                      }
                      {/*
                        <Link to={"/dashboard/profile/post/create"}>
                          <input type="text" value="" placeholder="Type a title for your post" />
                        </Link>
                      */}
                      <form onSubmit={createNewPost} action={`/dashboard/profile/post/create?title=` + title}>
                        <input type="text" maxLength={70} value={title} name="title" id="title" placeholder="Add title" onChange={handleChangeTitle}/>
                        <span className="button--sign">
                          {
                            /*
                              <ReactBootstrap.Button type="submit">
                                <img src={CommentButton} />
                              </ReactBootstrap.Button>
                            */
                          }
                          <ReactBootstrap.Button type="submit" className="create--new--post--button">
                            Next
                          </ReactBootstrap.Button>
                        </span>
                      </form>
                    </div>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </ReactBootstrap.Container>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          {/*<ReactBootstrap.Row id="network-publication">
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Link to={"/dashboard/profile/network"}>
                <div className="text-center button--sign">
                  <ReactBootstrap.Button type="submit">
                    <img src={IconHighlight} alt="Highlight"/>
                    <span>
                      Highlights
                    </span>
                  </ReactBootstrap.Button>
                </div>
              </Link>
              <Link to={"/dashboard/profile/post/create"}>
                <div className="text-center button--sign">
                  <ReactBootstrap.Button type="submit">Create a new post</ReactBootstrap.Button>
                </div>
              </Link>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>*/}
          <ReactBootstrap.Row id="own--feeds">
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                show
                ?
                  <ReactBootstrap.Alert variant="success" transition={true} id="publication-dashboard-alert" className="text-center">
                    <ReactBootstrap.Alert.Heading>
                      Post successful deleted.
                    </ReactBootstrap.Alert.Heading>
                  </ReactBootstrap.Alert>
                :
                  ""
              }
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                responseFeedData.map((item, index) => {
                  return (
                    <ReactBootstrap.Row key={item.id}>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {
                          showFavorite
                            ?
                            <ReactBootstrap.Alert variant="success" transition={true} id="profile-favorite-alert" className="text-center">
                              <ReactBootstrap.Alert.Heading>
                                {showFavoriteText}
                              </ReactBootstrap.Alert.Heading>
                            </ReactBootstrap.Alert>
                            :
                            ""
                        }
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--container--feedlist--item">
                        {/*<Link to={`/dashboard/feeds/profile/${item.userId}/feedlist/post/${item.id}`}>*/}
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--reposted">
                              { item.parentId != null ? "Reposted by " + item.user.name : "" }
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                          <ReactBootstrap.Row className="tbs-my-posts">
                            <ReactBootstrap.Col xs={11} sm={11} md={11} lg={11} xl={11} className="tbs--contact--details">
                              <Link to={`/dashboard/feeds/profile/${item.userId}/feedlist/post/${item.id}`}>
                                <div className="tbs--contact--image">
                                  <Link to={`/dashboard/profile/${item.parentPost != null ? item.parentPost.userId : item.userId}`}>
                                    {
                                      item.user != null && item.parentId == null
                                        ?
                                        <img src={item.user.profileImage != null ? item.user.profileImage.url : item.user.image != null ? item.user.image : IconAvatar} className="tbs--container--feedlist--image" alt="Profile"/>
                                        :
                                        item.parentPost != null && item.parentPost.user != null
                                          ?
                                          <img src={item.parentPost.user.profileImage != null ? item.parentPost.user.profileImage.url : item.parentPost.user.image} className="tbs--container--feedlist--image" alt="Profile"/>
                                          :
                                          item.user != null && item.user.image != null
                                            ?
                                            <img src={item.user.image} className="tbs--container--feedlist--image" alt="Profile"/>
                                            :
                                            item.user != null && item.user.image == null && item.parentId == null
                                            ?
                                              <img src={IconAvatar} className="tbs--container--feedlist--image" alt="Profile"/>
                                            :
                                              <img src={IconAvatar} className="tbs--container--feedlist--image" alt="Profile"/>
                                      /*
                                        <img src={
                                          item.parentPost != null
                                            ?
                                            item.parentPost.user != null
                                              ?
                                              item.parentPost.user.profileImage != null
                                                ?
                                                item.parentPost.user.profileImage != null
                                                  ?
                                                  item.parentPost.user.profileImage.url
                                                  :
                                                  IconAvatar
                                                :
                                                item.parentPost.user.image
                                              :
                                              item.user.image != null
                                                ?
                                                item.user.image
                                                :
                                                item.user.profileImage != null
                                                  ?
                                                  item.user.profileImage.url
                                                  :
                                                  IconAvatar
                                            :
                                            item.user.image != null
                                              ?
                                              item.user.image
                                              :
                                              item.user.profileImage != null
                                                ?
                                                item.user.profileImage.url
                                                :
                                                IconAvatar
                                        }
                                         className="tbs--container--feedlist--image"
                                         alt="Profile"/>
                                      */
                                    }
                                  </Link>
                                </div>
                                <div className="tbs--contact--data--feedlist">
                                  <div>
                                    <Link to={`/dashboard/profile/${item.parentPost != null ? item.parentPost.userId : item.userId}`}>
                                    {
                                      item.parentPost != null
                                        ?
                                        item.parentPost.user != null
                                          ?
                                          item.parentPost.user.name
                                          :
                                          item.user.name
                                        :
                                        item.user.name
                                    }
                                    </Link>
                                  </div>
                                  <div>
                                    <Link to={`/dashboard/profile/${item.parentPost != null ? item.parentPost.userId : item.userId}`}>
                                    {
                                      item.parentPost != null
                                        ?
                                        item.parentPost.user != null
                                          ?
                                          item.parentPost.user.position
                                          :
                                          item.user.position
                                        :
                                        item.user.position
                                      } at {
                                      item.parentPost != null
                                        ?
                                        item.parentPost.user != null
                                          ?
                                          item.parentPost.user.company
                                          :
                                          item.user.company
                                        :
                                        item.user.company
                                    }
                                    </Link>
                                  </div>
                                  <div>
                                    <Link to={`/dashboard/profile/${item.parentPost != null ? item.parentPost.userId : item.userId}`}>
                                      {moment(item.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                    </Link>
                                  </div>
                                </div>
                              </Link>
                            </ReactBootstrap.Col>
                            <ReactBootstrap.Col xs={1} sm={1} md={1} lg={1} xl={1}>
                              <div className="tbs--contact--data--feedlist">
                                <div className="tbs--contact--data--post--favourite">
                                  {/*
                                    item.isFavourite == true
                                      ?
                                      <span>
                                        <PostBookmarkActiveSVG/>
                                      </span>
                                      :
                                      <span>
                                        <PostBookmarkSVG/>
                                      </span>
                                  */}
                                  <span>
                                    <ReactBootstrap.DropdownButton
                                      menuAlign="right"
                                      title={
                                        <img src={PostSubmenu} alt="Submenu - Post" />
                                      }
                                      id="dropdown-menu-post"
                                      //onToggle={() => toggleNotification()}
                                    >
                                      <div>
                                        <div className="report-post-container">
                                          <div className="button--sign">
                                            <Link to={`/dashboard/feeds/profile/${userID}/feedlist/post/${item.id}/report`}>
                                              {/*<img src={ReportPostSVG} alt="Report post" className="report-post-icon"/>*/}
                                              <div>Report Post</div>
                                            </Link>
                                          </div>
                                        </div>
                                        <div className="bookmark-post-container">
                                          <div className="button--sign" onClick={() => validationFavourite(item)}>
                                            <div>Bookmark</div>
                                          </div>
                                        </div>
                                        <div className="tbs--own--post--submenu" onClick={() => savePostId(item.id)}>
                                          {/*<img src={IconDelete} alt="Delete"/>*/}
                                          {/*<PostDeleteSVG/>*/}
                                          <span>Delete</span>
                                        </div>
                                        {
                                          item.parentId == null
                                          ?
                                            <div className="tbs--own--post--submenu">
                                              <Link to={`/dashboard/profile/post/${item.id}/edit`}>
                                                {/*<img src={IconPen} alt="Update"/>*/}
                                                {/*<PostEditSVG/>*/}
                                                <span>Edit</span>
                                              </Link>
                                            </div>
                                          :
                                            ""
                                        }
                                      </div>
                                    </ReactBootstrap.DropdownButton>
                                  </span>
                                </div>
                              </div>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Link to={`/dashboard/feeds/profile/${item.userId}/feedlist/post/${item.id}`}>
                                <ReactBootstrap.Row>
                                  <ReactBootstrap.Col xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <div className="tbs--contact--data--feedlist">
                                      <div className="tbs--contact--data--feedlist--title">
                                        {item.title == null ? item.parentPost == null ? "" : item.parentPost.title : item.title}
                                      </div>
                                    </div>
                                    {
                                      /*(((item.parentPost == null) || (item.parentPost != null && item.parentPost.file == null)) && item.file == null)
                                        ?*/
                                        <div className="read-more">
                                          <Linkify>
                                            {
                                              item.parentPost == null
                                                ?
                                                truncateMessage(item.description, 200)
                                                :
                                                truncateMessage(item.parentPost.description, 200)
                                            }
                                          </Linkify>
                                        </div>
                                        /*:
                                          ""*/
                                        /*isBiggerTablet
                                          ?
                                          <div className="no-description-and-image">Nothing</div>
                                          :
                                          ""*/
                                    }
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={12} sm={12} md={5} lg={5} xl={5}>
                                    <div className="tbs--contact--data--feedlist">
                                      <div className={
                                        item.parentPost !== null
                                        ?
                                          item.parentPost.file === null && item.parentPost.audioId != null
                                          ?
                                            `tbs--contact--data--feedlist--image tbs--feed--image--${item.id} tbs--contact--data--feedlist--without--image`
                                          :
                                            `tbs--contact--data--feedlist--image tbs--feed--image--${item.id}`
                                        :
                                          item.file === null && item.audioId != null
                                          ?
                                            `tbs--contact--data--feedlist--image tbs--feed--image--${item.id} tbs--contact--data--feedlist--without--image`
                                          :
                                            `tbs--contact--data--feedlist--image tbs--feed--image--${item.id}`
                                      }>
                                        <img src={
                                          item.parentPost !== null
                                            ?
                                            item.parentPost.file !== null
                                              ?
                                              item.parentPost.file.url
                                              :
                                              ""
                                            :
                                            item.file !== null
                                              ?
                                              item.file.url
                                              :
                                              ""
                                        }
                                             alt="Feed - Image"/>
                                        {
                                          item.parentPost != null
                                            ?
                                            <div className="tbs--audio--feed" id={`audio-${item.parentPost.audioId}-player`}>
                                              <img src={
                                                item.parentPost.file != null && item.parentPost.audioId != null
                                                  ?
                                                  IconPlayWhite
                                                  :
                                                  item.parentPost.file == null && item.parentPost.audioId != null
                                                    ?
                                                    IconPlayBlack
                                                    :
                                                    ""
                                              } alt="Play"/>
                                            </div>
                                            :
                                            item.audioId != null
                                              ?
                                              <div className="tbs--audio--feed" id={`audio-${item.audioId}-player`}>
                                                <img src={
                                                  item.file != null && item.audioId != null
                                                    ?
                                                    IconPlayWhite
                                                    :
                                                    item.file == null && item.audioId != null
                                                      ?
                                                      IconPlayBlack
                                                      :
                                                      ""
                                                } alt="Play"/>
                                              </div>
                                              :
                                              ""
                                        }
                                        {
                                          item.parentPost != null
                                            ?
                                            <div className="tbs--contact--audio--overview">
                                              <audio controls controlsList="nodownload" id={`audio-${item.parentPost.audioId}`}>
                                                <source src={item.parentPost.audioFile != null ? item.parentPost.audioFile.url : ""} type="audio/mp3"/>
                                                Your browser does not support the audio element.
                                              </audio>
                                            </div>
                                            :
                                            item.audioFile != null
                                              ?
                                              <div className="tbs--contact--audio--overview">
                                                <audio controls controlsList="nodownload" id={`audio-${item.audioId}`}>
                                                  <source src={item.audioFile != null ? item.audioFile.url : ""} type="audio/mp3"/>
                                                  Your browser does not support the audio element.
                                                </audio>
                                              </div>
                                              :
                                              ""
                                        }
                                      </div>
                                    </div>
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                              </Link>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                        {/*</Link>*/}
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="tbs--contact--data--feeds">
                              <div className="tbs--contact--data--feeds--infos tbs--data--feeds--subline">
                                <span className={"w20-flex"}>
                                  {/*<img src={IconComment} alt="Comments"/>*/}
                                  <PostCommentSVG/>
                                  <span>{item.totalComments}</span>
                                </span>
                                <span className={"w20-flex"}>
                                  {/*<img src={IconRepost} alt="Repost"/>*/}
                                  {
                                    item.parentId == null
                                    ?
                                      <PostRepostSVG/>
                                    :
                                      <PostNoRepostSVG/>
                                  }
                                  <span>{item.repostedCount}</span>
                                </span>
                                <span className={"w20-flex"}>
                                  {/*<img src={IconForward} alt="Forward"/>*/}
                                  <PostForwardSVG/>
                                  <span>{item.forwardedCount}</span>
                                </span>
                                <span className={"w20-flex"}>
                                  {/*<img src={IconBookmarks} alt="Bookmarks"/>*/}
                                  {
                                    item.isFavourite == true
                                    ?
                                      <PostBookmarkActiveSVG/>
                                    :
                                      <PostBookmarkSVG/>
                                  }
                                  <span>{item.bookmarkedCount}</span>
                                </span>
                                <span className={"w20-flex"}>
                                  {/*<img src={IconPulse} alt="Activity"/>*/}
                                  <PostActivitySVG/>
                                  <span>{item.activitiesCount}</span>
                                </span>
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                          {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--sub--post--line tbs--data--feeds--subline d-block d-sm-block d-md-none d-lg-none d-xl-none">
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span onClick={() => savePostId(item.id)}>
                                  <PostDeleteSVG/>
                                  <span className={isMobile ? "" : "hide"}>Delete</span>
                                </span>
                              </ReactBootstrap.Col>
                              <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                {
                                  item.parentId == null
                                    ?
                                    <span>
                                      <Link to={`/dashboard/profile/post/${item.id}/edit`}>
                                        <PostEditSVG/>
                                        <span className={isMobile ? "" : "hide"}>Edit</span>
                                      </Link>
                                    </span>
                                    :
                                    ""
                                }
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          </ReactBootstrap.Col>*/}
                        </ReactBootstrap.Row>
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  )
                })
              }
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      {/*
        isMobile
          ?
          <ReactBootstrap.Col>
            <ActionMenu/>
          </ReactBootstrap.Col>
          :
          ""
      */}
      <ReactBootstrap.Modal
        show={isModalDeleteOpen}
        onHide={() => setIsModalDeleteOpen(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter-delete-post"
        centered
      >
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-delete-post">
            Delete post
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Modal.Body className="delete-post-confirmation">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="text-left">
                Are you sure you want to delete this post?
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="text-center button--sign">
                <ReactBootstrap.Button type="submit" onClick={() => setIsModalDeleteOpen(false)}>Cancel</ReactBootstrap.Button>
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="text-center button--sign">
                <ReactBootstrap.Button type="submit" onClick={() => deletePost(postId)}>Delete</ReactBootstrap.Button>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Modal.Body>
      </ReactBootstrap.Modal>
    </ReactBootstrap.Row>
  );
}

export default Profile;
