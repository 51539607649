import React from 'react';

const PrivacySVG = () => {
  return(
    <svg id="Icon_feather-shield" data-name="Icon feather-shield" xmlns="http://www.w3.org/2000/svg" width="64" height="80" viewBox="0 0 64 80">
      <path id="Icon_feather-shield-2" data-name="Icon feather-shield" d="M38,83S70,67,70,43V15L38,3,6,15V43C6,67,38,83,38,83Z" transform="translate(-6 -3)" fill="#141c27"/>
    </svg>
  )
}

export default PrivacySVG;
