import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';

const acceptMobileAppBanner = () => {
  const mainContainer = document.getElementById("mobile-app-banner");

  mainContainer.classList.add("accepted");
  setCookie("accepted_mobile_app", "accepted_mobile_app", 7);
}

const setCookie = (cname,cvalue,exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const checkCookie = () => {
  const mainContainer = document.getElementById("mobile-app-banner");

  let acceptedMobileAppBanner = getCookie("accepted_mobile_app");
  if (acceptedMobileAppBanner != "") {
    mainContainer.classList.add("accepted");
  } else {
    if (acceptedMobileAppBanner != "" && acceptedMobileAppBanner != null) {
      setCookie("accepted_mobile_app", acceptedMobileAppBanner, 7);
    }
  }
}

function MobileAppBanner() {
  useEffect(() => {
    checkCookie();
  }, [])

  return (
    <ReactBootstrap.Row id="mobile-app-banner">
      <ReactBootstrap.Col xs={12} sm={12} className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
        <div className="tbs--mobile-app-banner">
          <div>
            Meoh looks better on the app.
          </div>
          <div>
            Download on <a href="https://play.google.com/store/apps/details?id=com.luezoid.meoh" target="_blank">Google Play</a> or <a href="https://apps.apple.com/de/app/meoh/id1466786850" target="_blank">Apple store</a>.
          </div>
        </div>
        <div className="tbs--hide--mobile-app-banner" onClick={acceptMobileAppBanner}>X</div>
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default MobileAppBanner;
