import React from 'react';

const PostForwardSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="60" height="45" viewBox="0 0 60 45">
      <defs>
        <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 139 92">
          <image width="139" height="92" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIsAAABcCAYAAABeIkZoAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAUVSURBVHic7d1baBxVHMfxbzexmiYxiq3GWBHrhWqxeGkRRKmXKAqKIIgGwRYfxCsiiL6oKGh8Ear4JIhSseIFfKgXFFEffFAxeL+UWqyxqA0lsbVabdXVh/8mxs3smf9md3LObn4f2IcNszP/Jr/O/HfmzBmQoiwBXgcmgNeA0+KWIyl7Avhn2utvYANwdMyiJE1v8f+wTL72AsPAwfFKk9SsJTssk68x4CagM1aBkpZLgc2EQ/M1cHmsAiUtncD1wA7CoXkPOCtSjZKYHuA+rGepFZgy8AKwLE6JkpqlwOPAX9QOzT7gUeCQSDVKYk4H3iZ8aBoH7gIOjFSjJGYQ+IxwaLYAVwILItUoCSkB1wI/EQ7N+8DZkWqUxHRjh51fCIfmZeC4SDVKYo4ivwneX1lmSaQaJTEnA68Q3stMoCZYphkEPiEcmlGs71ETLFNN8I+EQ/MBcE6kGiUxi/A3wcdHqlESM4C/CT48Uo2SmJOw60meJvigSDVKYgaBjwmH5nvsCngpUo2SkBJ2WeA7wqH5EFgTp0RJzWQTvJtwaN4EVkSqURKzGBvq8Cf5TfARkWqUxCwnvwnegw3O6opToqTmfOAjwqHZjppgqViANcHbCIdmBDiv+oOtog9/2g91LleqrNejE+h1LjtdN7BwFp/LUs/vIE8XcDNwQs5ym7BmeXMzw9KHXZNYVSlgGdZgLaL2cbAX3TfTCvYD6xoNSz9wFXA1sBroaLQqSdbobP9XrwTuwEJyQPPqkXYyADyN3d8Sao70aq/XPmConsPQdcAj+Jq8MWxE+g5gF/A7/13E+g07Bnrswa6qeuzGQuzxs3O5cmW9XnuxX2wz1PPvmY0u4HZgHeH2YarB9ay0h/wTOr8CG4EhbO8j6SphN+1vJ/w3HQHOrWfF/ZUP1VrhN8CN2NdDSd8F5J+UGwWuoc7TKgPA1horHAduQV97W8UK4FXCIdnFLMe8LAa+rLHSN4AjGy5f5kI/vtF0j2F/87p1UHvWomFa66zvfNUN3IN9QQjtTV4CTmxkQ8MZKy0DtzayUpkTJexb6w+EQ9KUOwBWkb3LurPRFUvhLgI+JRySb7ETqQ0fHTrI7pQ3NLpiKdQp2BSqoZBMYGfcm3bX4lDGRrZgFwIlPQPY9Kl5kwKtBw5r5oZLwFdVGypjg2UkLT3A/diJ0FohKQMvUtBNZhdmbHBTERuSWUvm9tVnMza6usgNSl0uAb4gHJKtzMHsUAuZuUsbKXKD4nYqdrtGKCTj2AXBZo3GC1qTUcBtc7FhqWkp8BQ233+tkPwBPIx/CGlT3JtRyPK5LECm9AIPYMM4Qs3rc8CxMQp8vqqYsRhFzHOdwA3kz8L9LnBmpBqBmWf93olZzDx0GTNPW2Sd77oiVoHTVU+n+WTccuaNM8ifNHkndk0umXHO1cfH9XHLaXstPR179eDr4bjltK0+4CHyH/SwETgmUo25qgt+MG45baetHiGjsBSn7R5OpbAUYy3hkLTkY+8UlmK03QM1WyrVLWZb1fsy8AxwN3bPTkvSnqUYbfcQcO1ZirMTuDh2Ec2kqaDETWERN4VF3BQWcVNYxE1hETeFRdwUFnFTWMRNYRE3hUXcFBZxU1jETWERN4VF3BQWcVNYxE1hETeFRdwUFnFTWMRNYRE3hUXcFBZxU1jETWERN4VF3BQWcVNYxE1hEbcSNifLdNXvRQALy+dVP6t+LzJlJfbImDHsKRM6NEmmfwFXlu+XPCHKXwAAAABJRU5ErkJggg=="/>
        </pattern>
      </defs>
      <rect id="forward_icon_12" width="60" height="45" fill="url(#pattern)"/>
    </svg>
  )
}

export default PostForwardSVG;
