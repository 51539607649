import React from 'react';

const FeaturedSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1024" height="1024" viewBox="0 0 1024 1024">
      <defs>
        <filter id="Icon_ionic-ios-star" x="246.641" y="267.986" width="536.623" height="498.935" filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="1.5" result="blur"/>
          <feFlood flood-opacity="0.161"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g id="Featured_button" data-name="Featured button" transform="translate(-375 -737)">
        <g id="Gruppe_710" data-name="Gruppe 710" transform="translate(375 737)">
          <g id="Ellipse_2" data-name="Ellipse 2" fill="#141c27" stroke="#fff" stroke-width="3">
            <circle cx="512" cy="512" r="512" stroke="none"/>
            <circle cx="512" cy="512" r="510.5" fill="none"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Icon_ionic-ios-star)">
            <path id="Icon_ionic-ios-star-2" data-name="Icon ionic-ios-star" d="M509.851,172.968H336.607L283.963,15.859a19.077,19.077,0,0,0-35.8,0L195.515,172.968H21.094A18.9,18.9,0,0,0,2.25,191.812a13.849,13.849,0,0,0,.353,3.18c.236,4.122,2.12,8.715,7.891,13.308L152.882,308.642,98.235,467.518a18.9,18.9,0,0,0,6.478,21.2c3.415,2.473,6.6,4.593,10.6,4.593,3.886,0,8.48-2,11.777-4.24l138.972-99.047L405.033,489.07c3.3,2.355,7.891,4.24,11.777,4.24,4,0,7.184-2,10.482-4.593a18.667,18.667,0,0,0,6.478-21.2L379.123,308.642l141.21-101.285,3.415-2.944c3.062-3.3,6.124-7.773,6.124-12.6C529.873,181.448,520.215,172.968,509.851,172.968Z" transform="translate(245.89 266.11)" fill="#fefefe"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default FeaturedSVG;
