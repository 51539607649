import React, {useEffect, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import axios from "axios";
import moment from "moment";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import { useMediaQuery } from "react-responsive";
import autosize from "autosize";

import API from "../../../config/config.json";

//import IconImage from "./../../../assets/icons/IconImage.png";
import IconAvatar from "./../../../assets/svg/avatar.svg";
import IconBellWhite from "./../../../assets/svg/notificationWhite.svg";
import IconBellWhiteCrossedOut from "../../../assets/svg/notificationWhiteCrossedOut.svg";
import IconChatsWhite from "./../../../assets/svg/chatWhite.svg";
import IconOpenDoorWhite from "./../../../assets/svg/communitiesLeave.svg";
import IconDeleteWhite from "./../../../assets/svg/pinDelete.svg";

/* SVG */
import IconImage from "./../../../assets/svg/defaultImage.svg";
import NoMember from "./../../../assets/svg/noMember.svg";
import UploadFile from "./../../../assets/svg/uploadFile.svg";
import InvitationWhiteSVG from "./../../../assets/svg/invitationWhite";

function CommunityPublic(props) {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID;

  if(localStorage.getItem("tbs-data")){
    userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;
  }

  // Get the params
  // const { match: { params } } = props;

  let urlCommunity;
  let urlCommunityRequestJoin;
  let urlCommunityMembers;

  if(token){
    const { match: { params } } = props;
    // Get url from friends
    urlCommunity = API.URL + 'conversations/' + params.id + '/info';

    // Request for join the community
    urlCommunityRequestJoin = API.URL + 'conversations/' + params.id + '/requests';

    // Get url from group [Community Info]
    urlCommunityMembers = "https://meoh-integration.nurogate.com/api/v2/conversations/" + params.id + "/members";
  } else {

    // Get url from friends
    urlCommunity = API.URL + 'conversations/' + localStorage.getItem("public-group-id") + '/info'

    // Get url from group [Community Info]
    urlCommunityMembers = "https://meoh-integration.nurogate.com/api/v2/conversations/" + localStorage.getItem("public-group-id") + "/members";
  }

  // Get url from creator of groups
  const urlCommunityCreator = API.URL + 'conversations?isNew=true&type=GROUP';

  // Get url from group [Community Info]
  const urlFriends = API.URL + 'friends';

  // Get url from chat
  const urlGroupRoomGlobal = API.URL + "conversations?isNew=true&type=GROUP";

  // Store and set the state value
  const [cData, setCData] = useState([]);
  const [cDataTwo, setCDataTwo] = useState([]);
  const [friends, setFriends] = useState([]);
  const [allFriends, setAllFriends] = useState([]);
  const [cDataThree, setCDataThree] = useState({});
  const [cDataFour, setCDataFour] = useState({});
  const [cDataCreator, setCDataCreator] = useState("");
  const [imageId, setImageId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [rule, setRule] = useState('');
  const [messageText, setMessageText] = useState('');
  const [invitationMail, setInvitationMail] = useState('');
  const [mute, setMute] = useState(0);
  const [members, setMembers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [alertShow, setAlertShow] = useState(false);
  const [showUpdateCommunity, setShowUpdateCommunity] = useState(false);
  const [showOpenAdminModal, setShowOpenAdminModal] = useState(false);
  const [showOpenMembersModal, setShowOpenMembersModal] = useState(false);
  const [showMuteAlert, setShowMuteAlert] = useState(false);
  const [showMuteMessage, setShowMuteMessage] = useState(false);
  const [showUnmuteMessage, setShowUnmuteMessage] = useState(false);
  const [showOpenMuteModal, setShowOpenMuteModal] = useState(false);
  const [showMemberAlert, setShowMemberAlert] = useState(false);
  const [showAdminAlert, setShowAdminAlert] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNoMember, setShowNoMember] = useState(false);
  const [lastGroup, setLastGroup] = useState(false);
  const [allData, setAllData] = useState([]);
  const [showJoinRequestAlert, setShowJoinRequestAlert] = useState(false);
  const [showJoinRequestLogoutAlert, setShowJoinRequestLogoutAlert] = useState(false);
  const [showJoinRequestMessage, setShowJoinRequestMessage] = useState("");

  const [isBiggerThanTablet, setIsBiggerThanTablet] = useState(window.innerWidth > 991);
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' })

  useEffect(async () => {
    const resultMain = await axios.get(
      urlCommunity, {
        headers: {
          'Content-Type': 'application/json',
          //authorization: token
        }
      }
    );

    setCData(resultMain.data.data);
    setCDataThree(resultMain.data.data.conversationUser);
    setCDataFour(resultMain.data.data.conversationReceiver.user);
    setCDataTwo(resultMain.data.data.conversationReceivers);

    setAllData(resultMain.data.data);

    setName(resultMain.data.data.name);
    setDescription(resultMain.data.data.description);
    setRule(resultMain.data.data.rule);
    setImageId(resultMain.data.data.imageId);
    setMute(resultMain.data.data.conversationUser.muteDuration);

    /*const resultFriends = await axios.get(
      urlFriends, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    // const allFriendsId = [...resultFriends.data.items].map(e => Number(e.friendId));

    setFriends(resultFriends.data.items);

    let friendsID = [];

    resultFriends.data.items.map(item => {
      friendsID.push(item.friendId)
    })

    setAllFriends(friendsID);

    const resultCreator = await axios.get(
      urlCommunityCreator, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    const creator = resultCreator.data.items;

    creator.map((userData) => {
      if(userData.id === resultMain.data.data.conversationUser.conversationId){
        setCDataCreator(userData.createdByUser != null ? userData.createdByUser.name : resultMain.data.data.conversationUser.user.name)
      }
    })

    const resultMembers = await axios.get(
      urlCommunityMembers, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    let adminMember = [...resultMembers.data.items.admins].map(e => Number(e.id));
    let userMember = [...resultMembers.data.items.users].map(e => Number(e.id));

    if(adminMember == ""){
      adminMember = [userID];
    }
    if(userMember == ""){
      userMember = [userID];
    }

    let membersAll = adminMember.concat(userMember);

    setAdmins(adminMember);
    setMembers(userMember);
    setAllMembers(membersAll);

    if(userMember.length === 0 || (userMember.length === 1 && userMember.includes(userID))){
      setShowNoMember(true)
    } else {
      setShowNoMember(false)
    }

    let userRoles = [];
    cDataTwo.map(dataUser => {
      userRoles.push(dataUser.role)
    })
    setAllRoles(userRoles);*/

    const handleWindowResize = () => setIsBiggerThanTablet(window.innerWidth > 991);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  /*useEffect(() => {
    let userRoles = [];
    cDataTwo.map(dataUser => {
      userRoles.push(dataUser.role)
    })
    setAllRoles(userRoles);
  })*/

  const sendRequestJoin = (e) => {
    if(token){
      e.preventDefault();

      // URL for the mute status
      const urlJoinRequest = urlCommunityRequestJoin;

      // fetch data and validate
      axios
        .post(urlJoinRequest, "", {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        })
        .then((response) => {
          if(response.status === 200){
            setShowJoinRequestAlert(true);
            setShowJoinRequestMessage('Your request has been successfully submitted to the group admins.');

            setTimeout(() => {
              setShowJoinRequestAlert(false);
              setShowJoinRequestMessage("");
            }, 2000);
          }
        })
        .catch(e => {
          setShowJoinRequestAlert(true);
          setShowJoinRequestMessage(e.response.data.error == "Request already exist" ? "You have already sent a request to join." : "Retry to send the request.");

          setTimeout(() => {
            setShowJoinRequestAlert(false);
            setShowJoinRequestMessage("");
          }, 2000);
          console.log(e)
        })
    } else {
      setShowJoinRequestLogoutAlert(true);
    }
  }

  const loginAfterGroup = () => {
    let url = window.location.href;
      let groupData = url.match(/public-groups\/(.*)$/)[1];
      let cm = groupData.slice(-10);
      let groupPublicId = groupData.replace(cm, "");

      localStorage.setItem("public-group-number", groupPublicId)

      setTimeout(() => {
        window.location.href = "/login";
      }, 1000)
  }

  const registerAfterGroup = () => {
    let url = window.location.href;
    let groupData = url.match(/public-groups\/(.*)$/)[1];
    let cm = groupData.slice(-10);
    let groupPublicId = groupData.replace(cm, "");

    localStorage.setItem("public-group-number", groupPublicId)

    setTimeout(() => {
      window.location.href = "/register";
    }, 1000)
  }

  /*const deleteConversation = (id) => {
    // Get url from group conversation for the deleting
    const deleteGroup = API.URL + 'conversations/' + id;

    axios
      .delete(deleteGroup, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then(async (response) => {
        if(response.status === 200){
          const resultUser = await axios.get(
            urlGroupRoomGlobal, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          const allGroups = resultUser.data.items;

          setLastGroup(allGroups[0].id)

          setTimeout(() => {
            window.location.replace(isTablet ? `/dashboard/communities/${allGroups[0].id}/channel` : `/dashboard/communities/${allGroups[0].id}`);
          }, 500);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }*/

  const handleChangeName = e => setName(e.target.value);
  const handleChangeDescription = e => setDescription(e.target.value);
  const handleChangeRule = e => setRule(e.target.value);
  const handleChangeMute = e => setMute(Number(e.target.value));

  const updateCommunity = (e) => {
    e.preventDefault();

    // URL for updating the data in the community
    const communityDataUrl = API.URL /*+ 'conversations/' + params.id*/;

    // Post value for validation
    const data = {
      name: name,
      description: description,
      rule: rule,
      receiverIds: allMembers,
      imageId: imageId
    };

    if(name != "" && allMembers.length > 0){

      document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "";
      document.getElementsByClassName("tbs--form--main--error")[0].style.display = "none";
      document.getElementsByClassName("update-name")[0].classList.remove("error-field");
      document.getElementsByClassName("error-text")[0].style.display = "none";
      document.getElementsByClassName("error-text")[0].innerHTML = "";

      axios
        .put(communityDataUrl, data, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        })
        .then((response) => {
          if(response.status == 200){
            setShowUpdateCommunity(true);

            setTimeout(() => {
              setShowUpdateCommunity(false);
            }, 1000);

            setTimeout(async () => {
              const resultMain = await axios.get(
                urlCommunity, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setCData(resultMain.data.data);
              setCDataThree(resultMain.data.data.conversationUser);
              setCDataFour(resultMain.data.data.conversationReceiver.user);
              setCDataTwo(resultMain.data.data.conversationReceivers);

              setName(resultMain.data.data.name);
              setDescription(resultMain.data.data.description);
              setRule(resultMain.data.data.rule);
              setImageId(resultMain.data.data.imageId);

              const resultMembers = await axios.get(
                urlCommunityMembers, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              let adminMember = [...resultMembers.data.items.Admins].map(e => Number(e.id));
              let userMember = [...resultMembers.data.items.Users].map(e => Number(e.id));

              if(adminMember == ""){
                adminMember = [userID];
              }
              if(userMember == ""){
                userMember = [userID];
              }

              let membersAll = adminMember.concat(userMember);

              setAdmins(adminMember);
              setMembers(userMember);
              setAllMembers(membersAll);
            }, 1200);
          }
        })
    } else {
      document.getElementsByClassName("update-name")[0].classList.add("error-field");
      document.getElementsByClassName("error-text")[0].style.display = "block";
      document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a name.";

      document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "The group name cannot be empty and the members to be added to this group must contain at least one contact.";
      document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
    }
  }

  const Schema = Yup.object().shape({
    name: Yup.string()
      .required('Please enter a name for the group.'),
    description: Yup.string(),
    rule: Yup.string(),
    receivers: Yup.array()
      .required('Please add more than one trusted contacts to create a group.'),
    imageId: Yup.number()
  });

  const SchemaMute = Yup.object().shape({
    duration: Yup.number()
      .required('Please select a mute option.')
  });

  const imageUploadSave = (element) => {
    // URL for the files
    const urlFiles = API.URL + 'files';

    // Post value for validation - Files
    let dataFiles = new FormData();
    dataFiles.append("fileType", "IMAGE");
    dataFiles.append("file", element);
    dataFiles.append("type", "POST_IMAGE");

    // fetch data and validate
    axios
      .post(urlFiles, dataFiles, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setImageId(response.data.id);
          console.log("Successful");
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const previewFile = () => {
    const preview = document.querySelector('.tbs--user--profile--image');
    const imageContainer = document.getElementById('community-main-block');

    const file = document.querySelector('#upload-image').files[0];
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      preview.src = reader.result;
    }, false);

    if (file) {
      reader.readAsDataURL(file);
      imageUploadSave(file);
      imageContainer.classList.remove("tbs--default--image")
      imageContainer.classList.add("tbs--community--image")
    }
  }

  const muteConversation = (e) => {
    e.preventDefault();

    // URL for the mute status
    const urlMute = API.URL /*+ 'conversations/' + params.id + '/mute'*/;

    // Data validation
    const data = {
      duration: mute
    }

    // fetch data and validate
    axios
      .put(urlMute, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){

          document.querySelector(".form-update-community--un-mute .tbs--form--main--error").innerHTML = "";
          document.querySelector(".form-update-community--un-mute .tbs--form--main--error").style.display = "none";

          setShowMuteAlert(true);
          setShowMuteMessage(true);
          setShowOpenMuteModal(false);

          setTimeout(() => {
            setShowMuteAlert(false);
            setShowMuteMessage(false);
          }, 1000);

          setTimeout(async () => {
            const resultMain = await axios.get(
              urlCommunity, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setCData(resultMain.data.data);
            setCDataThree(resultMain.data.data.conversationUser);
            setCDataFour(resultMain.data.data.conversationReceiver.user);
            setCDataTwo(resultMain.data.data.conversationReceivers);

            setName(resultMain.data.data.name);
            setDescription(resultMain.data.data.description);
            setRule(resultMain.data.data.rule);
            setImageId(resultMain.data.data.imageId);
          }, 1200);
        }
      })
      .catch(e => {
        console.log(e)

        document.querySelector(".form-update-community--un-mute .tbs--form--main--error").innerHTML = "The mute duration cannot be empty.";
        document.querySelector(".form-update-community--un-mute .tbs--form--main--error").style.display = "block";
      })
  }

  const unmuteConversation = (e) => {
    e.preventDefault();

    // URL for the unmute status
    const urlUnmute = API.URL /*+ 'conversations/' + params.id + '/unmute'*/;

    // Data validation
    const data = {
      duration: 0
    }

    // fetch data and validate
    axios
      .put(urlUnmute, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShowMuteAlert(true);
          setShowUnmuteMessage(true);

          setTimeout(() => {
            setShowMuteAlert(false);
            setShowUnmuteMessage(false);
          }, 1000);

          setTimeout(async () => {
            const resultMain = await axios.get(
              urlCommunity, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setCData(resultMain.data.data);
            setCDataThree(resultMain.data.data.conversationUser);
            setCDataFour(resultMain.data.data.conversationReceiver.user);
            setCDataTwo(resultMain.data.data.conversationReceivers);

            setName(resultMain.data.data.name);
            setDescription(resultMain.data.data.description);
            setRule(resultMain.data.data.rule);
            setImageId(resultMain.data.data.imageId);
          }, 1200);
        }
      })
  }

  const exitConversation = (id) => {

    // Get url from group conversation for exit this group
    const exitGroup = API.URL + 'conversations/' + id + '/exit';

    axios
      .put(exitGroup, "", {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setTimeout(() => {
            window.location.replace("/dashboard/communities");
          }, 500);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const toggleCheck = (e) => {
    e = e || window.event;
    e = e.target || e.srcElement;

    if (e.nodeName === 'INPUT') {
      if(e.hasAttribute("checked")){
        e.removeAttribute("checked")
      } else {
        e.setAttribute("checked", "true")
      }
    }
  }

  const getSelectAdmin = () => {
    // Get all values of the checkboxes which is checked.
    const selectedData = [...document.querySelectorAll('.tbs--admins--community:checked')].map(e => Number(e.value));

    let membersAdmin = [...new Set(selectedData)];

    membersAdmin.push(userID);

    let allMembers = admins.concat(members);

    // URL for updating the data in the community
    const communityDataUrl = API.URL /*+ 'conversations/' + params.id*/;

    // Post value for validation
    const data = {
      receiverIds: allMembers,
      groupAdminIds: membersAdmin
    };

    axios
      .put(communityDataUrl, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status == 200){
          setShowAdminAlert(true);
          setShowOpenAdminModal(false);

          setTimeout(() => {
            setShowAdminAlert(false);
          }, 1000);

          setTimeout(async () => {
            const resultMain = await axios.get(
              urlCommunity, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setCData(resultMain.data.data);
            setCDataThree(resultMain.data.data.conversationUser);
            setCDataFour(resultMain.data.data.conversationReceiver.user);
            setCDataTwo(resultMain.data.data.conversationReceivers);

            setName(resultMain.data.data.name);
            setDescription(resultMain.data.data.description);
            setRule(resultMain.data.data.rule);
            setImageId(resultMain.data.data.imageId);

            const resultMembers = await axios.get(
              urlCommunityMembers, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            let adminMember = [...resultMembers.data.items.Admins].map(e => Number(e.id));
            let userMember = [...resultMembers.data.items.Users].map(e => Number(e.id));

            if(adminMember == ""){
              adminMember = [userID];
            } else {
              adminMember.push(userID);
            }

            if(adminMember.length == 0){
              adminMember = [userID];
            }

            if(userMember == ""){
              userMember = [userID];
            }

            if(userMember.length == 0){
              userMember = [userID];
            }

            let membersAll = adminMember.concat(userMember);

            setAdmins(adminMember);
            setMembers(userMember);
            setAllMembers(membersAll);

            if(userMember.length == 0 || (userMember.length == 1 && userMember.includes(userID))){
              setShowNoMember(true)
            } else {
              setShowNoMember(false)
            }
          }, 1200);
        }
      })
      .catch((e) => {
        console.log(e);
      })
  }

  const getSelectContact = () => {
    // Get all values of the checkboxes which is checked.
    const selectedData = [...document.querySelectorAll('.tbs--contacts--community:checked')].map(e => Number(e.value));

    selectedData.push(userID);

    let membersAll = [...new Set(selectedData)];

    // URL for updating the data in the community
    const communityDataUrl = API.URL /*+ 'conversations/' + params.id*/;

    // Post value for validation
    const data = {
      receiverIds: membersAll
    };

    axios
      .put(communityDataUrl, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status == 200){
          document.querySelector(".tbs--error--message--members.error-text").style.display = "none";
          document.querySelector(".tbs--error--message--members.error-text").innerHTML = "";

          setShowMemberAlert(true);
          setShowOpenMembersModal(false);

          setTimeout(() => {
            setShowMemberAlert(false);
          }, 1000);

          setTimeout(async () => {
            const resultMain = await axios.get(
              urlCommunity, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setCData(resultMain.data.data);
            setCDataThree(resultMain.data.data.conversationUser);
            setCDataFour(resultMain.data.data.conversationReceiver.user);
            setCDataTwo(resultMain.data.data.conversationReceivers);

            setName(resultMain.data.data.name);
            setDescription(resultMain.data.data.description);
            setRule(resultMain.data.data.rule);
            setImageId(resultMain.data.data.imageId);

            const resultMembers = await axios.get(
              urlCommunityMembers, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            let adminMember = [...resultMembers.data.items.Admins].map(e => Number(e.id));
            let userMember = [...resultMembers.data.items.Users].map(e => Number(e.id));

            if(adminMember == ""){
              adminMember = [userID];
            } else {
              adminMember.push(userID);
            }

            if(userMember == ""){
              userMember = [userID];
            }

            let membersAll = adminMember.concat(userMember);

            setAdmins(adminMember);
            setMembers(userMember);
            setAllMembers(membersAll);

            if(userMember.length == 0 || (userMember.length == 1 && userMember.includes(userID))){
              setShowNoMember(true)
            } else {
              setShowNoMember(false)
            }
          }, 1200);
        }
      })
      .catch((e) => {
        console.log(e);
        // Error message - Custom
        document.querySelector(".tbs--error--message--members.error-text").style.display = "block";
        document.querySelector(".tbs--error--message--members.error-text").innerHTML = "Please select a contact.";
      })
  }

  // fetch data and validate
  const validationSendNewInvitation = async (e, id) => {
    e.preventDefault();

    // Get url from your invitations
    const urlNewInvitation = API.URL + 'invitations/' + id + '/send';

    axios
      .post(urlNewInvitation, "", {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setAlertShow(true);
          setMessageText("Invitation successful sent");

          setTimeout(() => {
            setAlertShow(false);
            setMessageText("");
          }, 1500);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        setMessageText(e.response.data.error)
        setAlertShow(true);

        setTimeout(() => {
          setAlertShow(false);
          setMessageText("")
        }, 1500);

        console.log(e)
      })

    // Get url from members email
    //const urlMembersEmail = API.URL + 'users/profile/' + id;

    /*await axios
      .get(urlMembersEmail, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then(response => {
        if(response.status === 200){
          console.log("ID available")

          // Post value for validation
          const data = {
            email: response.data.email,
          };

          axios
            .post(urlNewInvitation, data, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            })
            .then((response) => {
              if(response.status === 200){
                setAlertShow(true);
                setMessageText("Invitation successful sent");

                setTimeout(() => {
                  setAlertShow(false);
                  setMessageText("");
                }, 1500);
              } else {
                console.log("Error: Access Denied");
              }
            })
            .catch(e => {
              setMessageText(e.response.data.error)
              setAlertShow(true);

              setTimeout(() => {
                setAlertShow(false);
                setMessageText("")
              }, 1500);

              console.log(e)
            })
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })*/
  }

  useEffect(() => {
    const textElement = document.querySelectorAll("textarea");
    autosize(textElement);
  })

  return (
    <ReactBootstrap.Row id="community">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="community">
          <ReactBootstrap.Row>
            <div className="tbs--headline tbs--headline--community">
              {cData.name}
            </div>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div id="community-main-block" className={cData.image != null ? "tbs--community--image" : "tbs--default--image"}>
                <img src={cData.image != null ? cData.image.url : IconImage} alt="Community Image" className="tbs--user--profile--image"/>
                <div className="tbs--created--chat--date">
                <span>
                  Created by xxx,{/*cDataCreator*/}
                </span>
                  <span>
                  {" " + moment(cData.createdAt).format('DD MMMM YYYY')}
                </span>
                </div>
                {/*
                  cDataThree.role == "ADMIN"
                    ?
                    <div className="text-center" id="edit-file">
                      <input type="file" id="upload-image" onChange={() => previewFile()}/>
                      <label htmlFor="upload-image" className="tbs--upload--image">
                        <img src={UploadFile} alt="Upload file" id="upload-file"/>
                      </label>
                    </div>
                    :
                    ""
                */}
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          {/*
            cDataThree.role == "ADMIN"
              ?
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={6} sm={6} md={6} lg={3} xl={3}>
                  <div className="tbs--community--button" onClick={cDataThree.muteDuration > 0 ? unmuteConversation : () => setShowOpenMuteModal(true)}>
                    {
                      cDataThree.muteDuration > 0
                        ?
                        <span className="unmute">
                          <img src={IconBellWhiteCrossedOut} alt="Unmute" />
                          <span>Unmute</span>
                        </span>
                        :
                        <span>
                          <img src={IconBellWhite} alt="Mute" />
                          <span>Mute</span>
                        </span>
                    }
                  </div>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={6} sm={6} md={6} lg={3} xl={3} className="tbs--chat--link">
                  {
                    isBiggerThanTablet
                      ?
                      <Link to={`/dashboard/communities/${params.id}`}>
                        <div className="tbs--community--button">
                          <span>
                            <img src={IconChatsWhite} alt="Chat" />
                            <span>
                              Chat
                            </span>
                          </span>
                        </div>
                      </Link>
                      :
                      <Link to={`/dashboard/communities/${params.id}/channel`}>
                        <div className="tbs--community--button">
                          <span>
                            <img src={IconChatsWhite} alt="Chat" />
                            <span>
                              Chat
                            </span>
                          </span>
                        </div>
                      </Link>
                  }
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={6} sm={6} md={6} lg={3} xl={3}>
                  <div className="tbs--community--button" onClick={() => setShowLeaveModal(true)}>
                    <span className={cDataThree.muteDuration > 0 ? "leave-additional" : ""}>
                      <img src={IconOpenDoorWhite} alt="Leave"/>
                      <span>
                        Leave
                      </span>
                    </span>
                  </div>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={6} sm={6} md={6} lg={3} xl={3}>
                  <div className="tbs--community--button" onClick={() => setShowDeleteModal(true)}>
                    <span>
                      <img src={IconDeleteWhite} alt="Delete" id="delete-community" />
                      <span>
                        Delete
                      </span>
                    </span>
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              :
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <div className="tbs--community--button" onClick={cDataThree.muteDuration > 0 ? unmuteConversation : () => setShowOpenMuteModal(true)}>
                    {
                      cDataThree.muteDuration > 0
                        ?
                        <span className="unmute">
                          <img src={IconBellWhiteCrossedOut} alt="Unmute" />
                          <span>Unmute</span>
                        </span>
                        :
                        <span>
                          <img src={IconBellWhite} alt="Mute" />
                          <span>Mute</span>
                        </span>
                    }
                  </div>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4} className="tbs--chat--link">
                  {
                    isBiggerThanTablet
                      ?
                      <Link to={`/dashboard/communities/${params.id}`}>
                        <div className="tbs--community--button">
                          <span>
                            <img src={IconChatsWhite} alt="Chat" />
                            <span>Chat</span>
                          </span>
                        </div>
                      </Link>
                      :
                      <Link to={`/dashboard/communities/${params.id}/channel`}>
                        <div className="tbs--community--button">
                          <span>
                            <img src={IconChatsWhite} alt="Chat" />
                            <span>Chat</span>
                          </span>
                        </div>
                      </Link>
                  }
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <div className="tbs--community--button" onClick={() => setShowLeaveModal(true)}>
                    <span className={cDataThree.muteDuration > 0 ? "leave-additional" : ""}>
                      <img src={IconOpenDoorWhite} alt="Leave"/>
                      <span>
                        Leave
                      </span>
                    </span>
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
          */}
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                showMuteAlert
                  ?
                  <ReactBootstrap.Alert variant="success" transition={true} id="mute-alert" className="text-center">
                    <ReactBootstrap.Alert.Heading>
                      {
                        showMuteMessage
                          ?
                          "Muted conversation successfully"
                          :
                          showUnmuteMessage
                            ?
                            "Unmuted conversation successfully"
                            :
                            ""
                      }
                    </ReactBootstrap.Alert.Heading>
                  </ReactBootstrap.Alert>
                  :
                  ""
              }
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="tbs--community--button tbs--public--communinity--request" onClick={sendRequestJoin}>
                <span>
                  <span>
                    Request access
                  </span>
                </span>
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Modal
              show={showJoinRequestLogoutAlert}
              onHide={() => setShowJoinRequestLogoutAlert(false)}
              size="md"
              aria-labelledby="contained-modal-title-vcenter-request-success"
              centered
            >
              <ReactBootstrap.Modal.Header closeButton>
                <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-request-success">
                  Choose an option
                </ReactBootstrap.Modal.Title>
              </ReactBootstrap.Modal.Header>
              <ReactBootstrap.Modal.Body id="group--public--logout--success">
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                    <div className="text-center additional-request-text">
                      Before you can send your request...
                    </div>
                    <div className="text-center additional-request-text">
                      Already have a TBS account?
                    </div>
                    <div className="text-center button--sign">
                      <ReactBootstrap.Button onClick={loginAfterGroup}>Log in</ReactBootstrap.Button>
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                    <div className="text-center additional-request-text">
                      New to the TBS?
                    </div>
                    <div className="text-center additional-request-text">
                      Register and create an account so that group admins know who you are.
                    </div>
                    <div className="text-center button--sign">
                      <ReactBootstrap.Button onClick={registerAfterGroup}>Register</ReactBootstrap.Button>
                    </div>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </ReactBootstrap.Modal.Body>
            </ReactBootstrap.Modal>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                showJoinRequestAlert
                ?
                  <ReactBootstrap.Alert variant="success" transition={true} id="request-join-alert" className="text-center">
                    <ReactBootstrap.Alert.Heading>
                      {
                        showJoinRequestMessage
                      }
                    </ReactBootstrap.Alert.Heading>
                  </ReactBootstrap.Alert>
                :
                  ""
              }
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <hr/>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="form-update-community">
                <div className="tbs--form--main--error text-center"></div>
                <div className="tbs--form--complete--post">
                  <Formik
                    initialValues={{
                      name: '',
                      description: '',
                      rule: '',
                      receiverIds: [],
                      imageId: imageId
                    }}
                    validationSchema={Schema}
                    onSubmit={values => {
                      console.log(values.text);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form onSubmit={updateCommunity}>
                        {/*<div className="text-left">
                          <label htmlFor="name">
                            NAME
                          </label>
                        </div>
                        <div className="text-left">
                          <Field type="text"
                                 className={`update-name`}
                                 name="name" id="name" placeholder="Group Name" value={cData.name}
                                 onChange={handleChangeName} disabled/>
                        </div>
                        <div className="error-text text-left">
                          <ErrorMessage name="name">
                            {errors.name}
                          </ErrorMessage>
                        </div>*/}
                        <div className="text-left">
                          <label htmlFor="description">
                            DESCRIPTION
                            {/*
                              cDataThree.role == "ADMIN"
                                ?
                                <span className="tbs--headline--users--admin">Edit</span>
                                :
                                ""
                            */}
                          </label>
                        </div>
                        <div className="text-left">
                          {/*
                            cDataThree.role == "ADMIN"
                              ?
                              <Field as="textarea"
                                     className={`update-description ${errors.description && touched.description ? "error-field" : ""}`}
                                     name="description" id="description" placeholder="Add description" value={description}
                                     onChange={handleChangeDescription}/>
                              :*/
                              <Field as="textarea"
                                     className={`update-description tbs--public--community--description ${errors.description && touched.description ? "error-field" : ""}`}
                                     name="description" id="description" placeholder={cData.description == "" || cData.description == null ? "There is no group description at this time." : "Add description"} value={cData.description} disabled/>
                          }
                        </div>
                        <div className="error-text text-left">
                          {
                            errors.description && touched.description
                              ?
                              errors.description
                              :
                              null
                          }
                        </div>
                        <div className="text-left">
                          <label htmlFor="rule">
                            RULES
                            {/*
                              cDataThree.role == "ADMIN"
                                ?
                                <span className="tbs--headline--users--admin">Edit</span>
                                :
                                ""
                            */}
                          </label>
                        </div>
                        <div className="text-left">
                          {/*
                            cDataThree.role == "ADMIN"
                              ?
                              <Field as="textarea"
                                     className={`update-rule ${errors.rule && touched.rule ? "error-field" : ""}`}
                                     name="rule" id="rule" placeholder="Rules" value={rule}
                                     onChange={handleChangeRule}/>
                              :*/
                              <Field as="textarea"
                                     className={`update-rule ${errors.rule && touched.rule ? "error-field" : ""}`}
                                     name="rule" id="rule" placeholder={cData.rule == "" || cData.rule == null ? "Keep the conversation civil and courteous and be sure to follow our community guidelines available in the menu." : "Rules"} value={cData.rule} disabled/>
                          }
                        </div>
                        <div className="error-text text-left">
                          {
                            errors.rule && touched.rule
                              ?
                              errors.rule
                              :
                              null
                          }
                        </div>
                        {/*
                          cDataThree.role == "ADMIN"
                            ?
                            <div className="text-center button--sign">
                              <ReactBootstrap.Button type="submit">Save</ReactBootstrap.Button>
                            </div>
                            :
                            ""
                        */}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                showUpdateCommunity
                  ?
                  <ReactBootstrap.Alert variant="success" transition={true} id="update-community-alert" className="text-center">
                    <ReactBootstrap.Alert.Heading>Group info successful updated.</ReactBootstrap.Alert.Heading>
                  </ReactBootstrap.Alert>
                  :
                  ""
              }
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          {/*<ReactBootstrap.Row id="community-admin-user">
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="tbs--headline--users">
                ADMINS
                {
                  cDataThree.role == "ADMIN"
                    ?
                    <span className="tbs--headline--users--admin" onClick={() => setShowOpenAdminModal(true)}>Edit</span>
                    :
                    ""
                }
              </div>
              <ReactBootstrap.Modal
                show={showOpenAdminModal}
                onHide={() => setShowOpenAdminModal(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <ReactBootstrap.Modal.Header closeButton>
                  <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                    Add admins
                  </ReactBootstrap.Modal.Title>
                </ReactBootstrap.Modal.Header>
                <ReactBootstrap.Modal.Body id="community-admin">
                  <ReactBootstrap.Row>
                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                      {
                        friends.map(contact => {
                          if(members.includes(contact.friendId) || admins.includes(contact.friendId)){
                            return (
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                  <img src={contact.user.image != null ? contact.user.image : contact.user.profileImage != null ? contact.user.profileImage.url : IconAvatar} />
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="text-left">
                                  {contact.user.name}
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                  {
                                    admins.includes(contact.friendId)
                                      ?
                                      <input type="checkbox" className="tbs--admins--community" name="contacts" value={contact.friendId} defaultChecked={true} />
                                      :
                                      <input type="checkbox" className="tbs--admins--community" name="contacts" value={contact.friendId} />
                                  }
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                            )
                          }
                        })
                      }
                    </ReactBootstrap.Col>
                  </ReactBootstrap.Row>
                  <ReactBootstrap.Row>
                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                      <div className="text-center button--sign">
                        <ReactBootstrap.Button onClick={() => getSelectAdmin()}>Update</ReactBootstrap.Button>
                      </div>
                    </ReactBootstrap.Col>
                  </ReactBootstrap.Row>
                </ReactBootstrap.Modal.Body>
              </ReactBootstrap.Modal>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--group--info--admin">
              {
                cDataThree.role == "ADMIN"
                  ?
                  <div className="tbs--group--admin--member">
                    <Link to={`/dashboard/profile/${userID}`}>
                      <div className="tbs--group--admin--member">
                        <img src={
                          cDataThree.user != null
                            ?
                            cDataThree.user.image != null
                              ?
                              cDataThree.user.image
                              :
                              cDataThree.user.profileImage != null
                                ?
                                cDataThree.user.profileImage.url
                                :
                                IconAvatar
                            :
                            IconAvatar
                        } alt="Profile Image" />
                      </div>
                      <div className="text-center">
                        {cDataThree.user != null ? cDataThree.user.name : ""}
                      </div>
                    </Link>
                    {
                      cDataThree.user.id == userID || allFriends.includes(cDataThree.user.id)
                        ?
                        ""
                        :
                        <span className="tbs--group--info--invite" onClick={(e) => validationSendNewInvitation(e, cDataThree.user.id)}>
                          <InvitationWhiteSVG/>
                        </span>
                    }
                  </div>
                  :
                  ""
              }
              {
                cDataTwo.map(contact => {
                  if(contact.role == "ADMIN"){
                    return(
                      <div className="tbs--group--admin--member">
                        <Link to={`/dashboard/profile/${contact.user.id}`}>
                          <div className="tbs--group--admin--member">
                            <img src={contact.user.image != null ? contact.user.image : contact.user.profileImage != null ? contact.user.profileImage.url : IconAvatar} alt="Profile Image" />
                          </div>
                          <div className="text-center">
                            {contact.user.name}
                          </div>
                        </Link>
                        {
                          contact.user.id == userID || allFriends.includes(contact.user.id)
                            ?
                            ""
                            :
                            <span className="tbs--group--info--invite" onClick={(e) => validationSendNewInvitation(e, contact.user.id)}>
                              <InvitationWhiteSVG/>
                            </span>
                        }
                      </div>
                    )
                  }
                })
              }
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                showAdminAlert
                  ?
                  <ReactBootstrap.Alert variant="success" transition={true} id="admin-alert" className="text-center">
                    <ReactBootstrap.Alert.Heading>
                      Group admins updated successfully.
                    </ReactBootstrap.Alert.Heading>
                  </ReactBootstrap.Alert>
                  :
                  ""
              }
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="tbs--headline--users">
                MEMBERS
                {
                  cDataThree.role == "ADMIN"
                    ?
                    <span className="tbs--headline--users--admin" onClick={() => setShowOpenMembersModal(true)}>Edit</span>
                    :
                    ""
                }
              </div>
              <ReactBootstrap.Modal
                show={showOpenMembersModal}
                onHide={() => setShowOpenMembersModal(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <ReactBootstrap.Modal.Header closeButton>
                  <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                    Add members
                  </ReactBootstrap.Modal.Title>
                </ReactBootstrap.Modal.Header>
                <ReactBootstrap.Modal.Body id="community-member">
                  <ReactBootstrap.Row>
                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                      <div className="tbs--error--message--members error-text"></div>
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                      {
                        friends.map(contact => {
                          return (
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <img src={contact.user.image != null ? contact.user.image : contact.user.profileImage != null ? contact.user.profileImage.url : IconAvatar} />
                              </ReactBootstrap.Col>
                              <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="text-left">
                                {contact.user.name}
                              </ReactBootstrap.Col>
                              <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                {
                                  members.includes(contact.friendId) || admins.includes(contact.friendId)
                                    ?
                                    <input type="checkbox" className="tbs--contacts--community" name="contacts" value={contact.friendId} defaultChecked={true} />
                                    :
                                    <input type="checkbox" className="tbs--contacts--community" name="contacts" value={contact.friendId} />
                                }
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          )
                        })
                      }
                    </ReactBootstrap.Col>
                  </ReactBootstrap.Row>
                  <ReactBootstrap.Row>
                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                      <div className="text-center button--sign">
                        <ReactBootstrap.Button onClick={() => getSelectContact()}>Update</ReactBootstrap.Button>
                      </div>
                    </ReactBootstrap.Col>
                  </ReactBootstrap.Row>
                </ReactBootstrap.Modal.Body>
              </ReactBootstrap.Modal>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--group--info--user">
              {
                showNoMember
                  ?
                  <img src={NoMember} id="no-member" alt="No members"/>
                  :
                  ""
              }
              {
                cDataThree.role == "USER"
                  ?
                  <div className="tbs--group--user--member">
                    <Link to={`/dashboard/profile/${userID}`}>
                      <div>
                        <img src={
                          cDataThree.user != null
                            ?
                            cDataThree.user.image != null
                              ?
                              cDataThree.user.image
                              :
                              cDataThree.user.profileImage != null
                                ?
                                cDataThree.user.profileImage.url
                                :
                                IconAvatar
                            :
                            IconAvatar
                        } alt="Profile Image" />
                      </div>
                      <div className="text-center">
                        {cDataThree.user != null ? cDataThree.user.name : ""}
                      </div>
                    </Link>
                    {
                      cDataThree.user.id == userID || allFriends.includes(cDataThree.user.id)
                        ?
                        ""
                        :
                        <span className="tbs--group--info--invite" onClick={(e) => validationSendNewInvitation(e, cDataThree.user.id)}>
                          <InvitationWhiteSVG/>
                        </span>
                    }
                  </div>
                  :
                  ""
              }
              {
                cDataTwo.map(contact => {
                  if(contact.role == "USER"){
                    return(
                      <div className="tbs--group--user--member">
                        <Link to={`/dashboard/profile/${contact.user.id}`}>
                          <div>
                            <img src={contact.user.image != null ? contact.user.image : contact.user.profileImage != null ? contact.user.profileImage.url : IconAvatar} alt="Profile Image" />
                          </div>
                          <div className="text-center">
                            {contact.user.name}
                          </div>
                        </Link>
                        {
                          contact.user.id == userID || allFriends.includes(contact.user.id)
                            ?
                            ""
                            :
                            <span className="tbs--group--info--invite" onClick={(e) => validationSendNewInvitation(e, contact.user.id)}>
                              <InvitationWhiteSVG/>
                            </span>
                        }
                      </div>
                    )
                  }
                })
              }
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                showMemberAlert
                  ?
                  <ReactBootstrap.Alert variant="success" transition={true} id="member-alert" className="text-center">
                    <ReactBootstrap.Alert.Heading>
                      Group members updated successfully.
                    </ReactBootstrap.Alert.Heading>
                  </ReactBootstrap.Alert>
                  :
                  ""
              }
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>*/}
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>

      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Modal
            show={showLeaveModal}
            onHide={() => setShowLeaveModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ReactBootstrap.Modal.Header closeButton>
              <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                Leave
              </ReactBootstrap.Modal.Title>
            </ReactBootstrap.Modal.Header>
            <ReactBootstrap.Modal.Body id="leave-community">
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div>
                    Are you sure you want to leave this group?
                  </div>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-center">
                  <div className="text-center button--sign" onClick={() => setShowLeaveModal(false)}>
                    <ReactBootstrap.Button type="submit">Cancel</ReactBootstrap.Button>
                  </div>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-center">
                  <div className="text-center button--sign">
                    <ReactBootstrap.Button type="submit">Leave</ReactBootstrap.Button>
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
            </ReactBootstrap.Modal.Body>
          </ReactBootstrap.Modal>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ReactBootstrap.Modal.Header closeButton>
              <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-delete">
                Delete
              </ReactBootstrap.Modal.Title>
            </ReactBootstrap.Modal.Header>
            <ReactBootstrap.Modal.Body id="delete-community-modal">
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div>
                    Are you sure you to delete this group?
                  </div>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-center">
                  <div className="text-center button--sign" onClick={() => setShowDeleteModal(false)}>
                    <ReactBootstrap.Button type="submit">Cancel</ReactBootstrap.Button>
                  </div>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-center">
                  <div className="text-center button--sign">
                    <ReactBootstrap.Button type="submit">Delete</ReactBootstrap.Button>
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
            </ReactBootstrap.Modal.Body>
          </ReactBootstrap.Modal>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col>
          <ReactBootstrap.Modal
            show={showOpenMuteModal}
            onHide={() => setShowOpenMuteModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ReactBootstrap.Modal.Header closeButton>
              <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                Mute notifications for ...
              </ReactBootstrap.Modal.Title>
            </ReactBootstrap.Modal.Header>
            <ReactBootstrap.Modal.Body id="mute-create">
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form-update-community--un-mute">
                    <div className="tbs--form--main--error text-center"></div>
                    <div className="tbs--form--complete--post">
                      <Formik
                        initialValues={{
                          duration: 0
                        }}
                        validationSchema={Schema}
                        onSubmit={values => {
                          console.log(values.text);
                        }}
                      >
                        {({ errors, touched }) => (
                          <Form onSubmit={muteConversation} id="communityMute">
                            <div>
                              <label>
                                <input type="radio"
                                       className="muted"
                                       name="muted" value={8}
                                       onChange={handleChangeMute}/>
                                <span>For the next 2 hours</span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input type="radio"
                                       className="muted"
                                       name="muted" value={168}
                                       onChange={handleChangeMute}/>
                                <span>Until next day (7 am)</span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input type="radio"
                                       className="muted"
                                       name="muted" value={8760}
                                       onChange={handleChangeMute}/>
                                <span>For 1 week</span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input type="radio"
                                       className="muted"
                                       name="muted" value={99999}
                                       onChange={handleChangeMute}/>
                                <span>Disable notifications for this conversation</span>
                              </label>
                            </div>
                            <div>
                              <hr/>
                            </div>
                            <div className="text-right button--sign">
                              <ReactBootstrap.Button onClick={() => setShowOpenMuteModal(false)}>Cancel</ReactBootstrap.Button>
                              <ReactBootstrap.Button type="submit">OK</ReactBootstrap.Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
            </ReactBootstrap.Modal.Body>
          </ReactBootstrap.Modal>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          alertShow
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="new-invitation-chat-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>
                {messageText}
              </ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default CommunityPublic;

