import React, {useEffect, useRef, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import axios from "axios";
import moment from 'moment';
import Linkify from 'react-linkify';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useMediaQuery} from "react-responsive";
import autosize from "autosize";
import InputEmoji from 'react-input-emoji';
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";

import API from "../../../config/config.json";

import IconAvatar from "./../../../assets/svg/avatar.svg";
//import IconImage from "../../../assets/icons/IconImage.png";
import IconSmileyBlack from "../../../assets/icons/IconSmileyBlack.png";
//import IconPaperClipBlack from "../../../assets/icons/IconPaperClipBlack.png";
import IconForward from "../../../assets/icons/IconForward.png";
//import IconDelete from "../../../assets/icons/IconDeleteWhite.png";
//import IconPin from "../../../assets/icons/IconPinWhite.png";
//import IconClipboard from "../../../assets/icons/IconClipboardWhite.png";
//import IconForwardWhite from "../../../assets/icons/IconForwardWhite.png";
import IconIntroduce from "../../../assets/icons/IconIntroduce.png";
//import IconTickWhite from "../../../assets/icons/IconTickWhite.png";
//import IconTickBlue from "../../../assets/icons/IconTickBlue.png";
//import IconTickGrey from "../../../assets/icons/IconTickGrey.png";
import IconDownload from "../../../assets/icons/IconDownloadWhite.png";
import IconPlayWhite from "../../../assets/svg/playWhite.svg";
import IconPlayBlack from "../../../assets/svg/playBlack.svg";

/* SVG */
import IconDelete from "../../../assets/svg/delete.svg";
import IconPinDelete from "../../../assets/svg/pinDelete.svg";
import IconPin from "../../../assets/svg/pin.svg";
import IconClipboard from "../../../assets/svg/clipboard.svg";
import IconForwardWhite from "../../../assets/svg/forwardWhite.svg";
import IconTickWhite from "../../../assets/svg/acceptInvitation.svg";
import IconTickBlue from "../../../assets/svg/acceptInvitationBlue.svg";
import IconTickGrey from "../../../assets/svg/acceptInvitationBlack.svg";
import IconPaperClipBlack from "../../../assets/svg/attachmentMessage.svg";
import ChatStatus from "../../../assets/svg/chatView.svg";
import IconImage from "../../../assets/svg/defaultImage.svg";
import CommentButton from "../../../assets/svg/commentButton.svg";
import UploadFile from "../../../assets/svg/uploadFile.svg";
import Smiley from "../../../assets/svg/smiley.svg";

function Channel(props) {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get the params
  const { match: { params } } = props;

  // Get url from chat
  const urlGroupRoom = API.URL + "conversations/" + params.id + "/messages";
  const urlPinMessages = API.URL + "conversations/" + params.id + "/pinned-messages";
  const urlGroupRoomGlobal = API.URL + "conversations?isNew=true&type=GROUP";
  const urlChatAndGroupRoom = API.URL + "conversations?isNew=true";

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Store and set the state value
  const [groupData, setGroupData] = useState([]);
  const [chatGroupData, setChatGroupData] = useState([]);
  const [allPinMessages, setAllPinMessages] = useState([]);
  const [text, setText] = useState('');
  const [imageId, setImageId] = useState('');
  const [textImage, setTextImage] = useState('');
  const [showMenuId, setShowMenuId] = useState('');
  const [userRole, setUserRole] = useState('');
  const [postMessageTitle, setPostMessageTitle] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [showChatMessageAlert, setShowChatMessageAlert] = useState(false);
  const [showModalImageText, setShowModalImageText] = useState(false);
  const [submenuAlert, setSubmenuAlert] = useState(false);
  const [unpinAlert, setUnpinAlert] = useState(false);
  const [pinMessageShow, setPinMessageShow] = useState(false);
  const [deleteMessageShow, setDeleteMessageShow] = useState(false);
  const [copyMessageShow, setCopyMessageShow] = useState(false);
  const [repostMessageShow, setRepostMessageShow] = useState(false);
  const [deleteReplyMessageShow, setDeleteReplyMessageShow] = useState(false);
  const [forwardMessageShow, setForwardMessageShow] = useState(false);
  const [acceptRequestMessageShow, setAcceptRequestMessageShow] = useState(false);
  const [acceptRequestExistingUserMessageShow, setAcceptRequestExistingUserMessageShow] = useState(false);
  const [showConversations, setShowConversations] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteModalOwn, setShowDeleteModalOwn] = useState(false);
  const [showDeleteModalContact, setShowDeleteModalContact] = useState(false);
  const [showDeleteModalContactRepost, setShowDeleteModalContactRepost] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' })

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      urlGroupRoom, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setGroupData(result.data.items);

    const resultChatGroup = await axios.get(
      urlChatAndGroupRoom, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setChatGroupData(resultChatGroup.data.items);

    const resultUser = await axios.get(
      urlGroupRoomGlobal, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    const allGroups = resultUser.data.items;

    allGroups.map(group => {
      if(group.id == params.id){
        setUserRole(group.conversationUser.role)
      }
    });

    const resultPinMessages = await axios.get(
      urlPinMessages, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setAllPinMessages(resultPinMessages.data.items);

    let allLinks = document.querySelectorAll(".tbs--chat--message--contact--description > a");

    for(let i = 0; i < allLinks.length; i++){
      allLinks[i].setAttribute("target", "_blank");
    }

    let pathId = window.location.pathname.substring(23);

    if(pathId == params.id){
      document.getElementById("group-row-" + params.id).classList.add("active-chat");
    }

    // Default image ID
    setImageId(null)
  }, [])

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();

  /*useEffect(() => {
    //let comments = document.querySelectorAll("#group-channel > div:nth-child(3) > div > div > div").length;
    if(window.location.href.indexOf("/channel") > -1){
      window.scrollTo(0,document.body.scrollHeight);
    } else {
      const lastElement = document.getElementById("group-new-message");

      if(typeof(lastElement) != 'undefined' && lastElement != null){
        setTimeout(() => {
          const offsetTop = lastElement.offsetTop-300;

          window.scrollTo({
            top: offsetTop,
            behavior: "smooth"
          });
        }, 1500)
      }
    }
  }, [])*/

  //const useMountEffect = (func) => useEffect(func, []);

  useEffect(() => {
    setTimeout(() => {
      if(isTablet){
        window.scrollTo(0,document.body.scrollHeight);
      } else {
        executeScroll();
      }
    }, 1500)
  }, []);

  const handleChangeText = e => setText(e.target.value);
  const handleChangeTextImage = e => setTextImage(e.target.value);

  const createNewMessage = (e) => {
    e.preventDefault();

    // URL for create a new group
    const urlNewMessage = API.URL + 'conversations/' + params.id + '/messages';

    // Post value for validation
    const data = {
      text: text,
    };

    if(text != "" && showModalImageText == false){

      document.getElementsByClassName("create-comment")[0].classList.remove("error-field");
      document.getElementsByClassName("error-text")[0].style.display = "none";
      document.getElementsByClassName("error-text")[0].innerHTML = "";

      let emojiContainer = document.querySelector(".tbs--message--complete--block--one > div:nth-child(2) > .emoji-mart")
      let emojiToggle = document.querySelector(".tbs--comment--start")

      if (typeof(emojiContainer) != 'undefined' && emojiContainer != null) {
        emojiToggle.click();
      } else {
        console.log("nothing")
      }

      axios
        .post(urlNewMessage, data, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        })
        .then((response) => {
          if(response.status == 200){
            setShowChatMessageAlert(true);
            setText("");
            setImageId("");

            setTimeout(() => {
              setShowChatMessageAlert(false);
              setText("");
              setImageId("");
            }, 1000);

            setTimeout(async () => {
              const result = await axios.get(
                urlGroupRoom, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setGroupData(result.data.items);
            }, 1200);

            /*setTimeout(() => {
              if(window.location.href.indexOf("/channel") > -1){
                window.scrollTo(0,document.body.scrollHeight);
              } else {
                const lastElement = document.getElementById("group-new-message");

                if(typeof(lastElement) != 'undefined' && lastElement != null){
                  const offsetTop = lastElement.offsetTop-300;

                  window.scrollTo({
                    top: offsetTop,
                    behavior: "smooth"
                  });
                }
              }
            }, 1500)*/

            setTimeout(() => {
              if(isTablet){
                window.scrollTo(0,document.body.scrollHeight);
              } else {
                executeScroll();
              }
            }, 1500)
          }
        })
    } else {
      document.getElementsByClassName("create-comment")[0].classList.add("error-field");
      document.getElementsByClassName("error-text")[0].style.display = "none";
      document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a message.";
    }
  }

  const createNewMessageWithImage = (e) => {
    e.preventDefault();

    // URL for create a new group
    const urlNewMessage = API.URL + 'conversations/' + params.id + '/messages';

    // Post value for validation
    const data = {
      text: text.length > 0 ? text : " ",
      fileId: imageId
    };

    // Old: text: textImage.length > 0 ? textImage : " ",

    if(imageId != null){

      document.querySelector(".form-create-message-with-image .tbs--form--main--error").innerHTML = "";
      document.querySelector(".form-create-message-with-image .tbs--form--main--error").style.display = "none";
      /*document.getElementsByClassName("create-comment-with-image")[0].classList.remove("error-field");
      document.querySelector(".form-create-message-with-image .error-text").style.display = "none";
      document.querySelector(".form-create-message-with-image .error-text").innerHTML = "";*/

      axios
        .post(urlNewMessage, data, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        })
        .then((response) => {
          if(response.status == 200){
            setShowChatMessageAlert(true);
            setShowModalImageText(false)
            setTextImage("");
            setText("");
            setImageId("");

            setTimeout(() => {
              setShowChatMessageAlert(false);
              setTextImage("");
              setText("");
              setImageId("");
            }, 1000);

            setTimeout(async () => {
              const result = await axios.get(
                urlGroupRoom, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setGroupData(result.data.items);
            }, 1200);

            /*setTimeout(() => {
              if(window.location.href.indexOf("/channel") > -1){
                window.scrollTo(0,document.body.scrollHeight);
              } else {
                const lastElement = document.getElementById("group-new-message");

                if(typeof(lastElement) != 'undefined' && lastElement != null){
                  const offsetTop = lastElement.offsetTop-300;

                  window.scrollTo({
                    top: offsetTop,
                    behavior: "smooth"
                  });
                }
              }
            }, 1500)*/

            setTimeout(() => {
              if(isTablet){
                window.scrollTo(0,document.body.scrollHeight);
              } else {
                executeScroll();
              }
            }, 1500)
          }
        })
    } else {
      /*document.getElementsByClassName("create-comment-with-image")[0].classList.add("error-field");
      document.querySelector(".form-create-message-with-image .error-text").style.display = "none";
      document.querySelector(".form-create-message-with-image .error-text").innerHTML = "Please enter a message.";*/
      document.querySelector(".form-create-message-with-image .tbs--form--main--error").innerHTML = "Please add an image.";
      document.querySelector(".form-create-message-with-image .tbs--form--main--error").style.display = "block";
    }
  }

  const imageUploadSave = (element) => {
    // URL for the files
    const urlFiles = API.URL + 'files';

    // Post value for validation - Files
    let dataFiles = new FormData();
    dataFiles.append("fileType", "IMAGE");
    dataFiles.append("file", element);
    dataFiles.append("type", "POST_IMAGE");

    // fetch data and validate
    axios
      .post(urlFiles, dataFiles, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setImageId(response.data.id);
          //console.log(response.data);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const previewFile = () => {
    const preview = document.querySelector('.tbs--post--image--default');

    const file = document.querySelector('#upload-image').files[0];
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      preview.src = reader.result;
    }, false);

    if (file) {
      reader.readAsDataURL(file);
      imageUploadSave(file);
    }
  }

  // Pin a message
  const pinMessage = (conversationId, messageId, userMessageId) => {
    // URL for the pin
    const urlPin = API.URL + 'conversations/' + conversationId + '/messages/' + messageId + '/user-message/' + userMessageId + '/pin';

    // fetch data and validate
    axios
      .put(urlPin, "", {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setSubmenuAlert(true);
          setPinMessageShow(true);

          setTimeout(() => {
            setSubmenuAlert(false);
            setPinMessageShow(false);
          }, 1000);

          setTimeout(async () => {
            const resultPinMessages = await axios.get(
              urlPinMessages, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setAllPinMessages(resultPinMessages.data.items);
          }, 1200);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Unpin a message
  const unpinMessage = (conversationId, messageId, userMessageId) => {
    // URL for the pin
    const urlUnpin = API.URL + 'conversations/' + conversationId + '/messages/' + messageId + '/user-message/' + userMessageId + '/unpin';

    // fetch data and validate
    axios
      .put(urlUnpin, "", {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setUnpinAlert(true);

          setTimeout(() => {
            setUnpinAlert(false);
          }, 1000);

          setTimeout(async () => {
            const resultPinMessages = await axios.get(
              urlPinMessages, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setAllPinMessages(resultPinMessages.data.items);
          }, 1200);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Delete own message
  const deleteOwnMessage = (conversationId, messageId) => {
    // URL for deleting own message
    const urlDeleteMessage = API.URL + 'conversations/' + conversationId + '/messages/' + messageId;

    // fetch data and validate
    axios
      .delete(urlDeleteMessage, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setSubmenuAlert(true);
          setDeleteMessageShow(true);

          setTimeout(() => {
            setSubmenuAlert(false);
            setDeleteMessageShow(false);
          }, 1000);

          setTimeout(async () => {
            const result = await axios.get(
              urlGroupRoom, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setGroupData(result.data.items);

            const resultPinMessages = await axios.get(
              urlPinMessages, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setAllPinMessages(resultPinMessages.data.items);
          }, 1200);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Delete own repost message
  const deleteOwnRepostMessage = (conversationId, messageId) => {
    // URL for deleting own message
    const urlDeleteRepostMessage = API.URL + 'conversations/' + conversationId + '/messages/' + messageId;

    // fetch data and validate
    axios
      .delete(urlDeleteRepostMessage, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setSubmenuAlert(true);
          setDeleteReplyMessageShow(true);

          setTimeout(() => {
            setSubmenuAlert(false);
            setDeleteReplyMessageShow(false);
          }, 1000);

          setTimeout(async () => {
            const result = await axios.get(
              urlGroupRoom, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setGroupData(result.data.items);

            const resultPinMessages = await axios.get(
              urlPinMessages, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setAllPinMessages(resultPinMessages.data.items);
          }, 1200);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Delete contact message (Admin role)
  const deleteContactMessage = (conversationId, messageId, userMessageId) => {
    // URL for deleting contact message
    const urlDeleteContactMessage = API.URL + 'conversations/' + conversationId + '/messages/' + messageId + '/user-message/' + userMessageId;

    // fetch data and validate
    axios
      .delete(urlDeleteContactMessage, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setSubmenuAlert(true);
          setDeleteMessageShow(true);

          setTimeout(() => {
            setSubmenuAlert(false);
            setDeleteMessageShow(false);
          }, 1000);

          setTimeout(async () => {
            const result = await axios.get(
              urlGroupRoom, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setGroupData(result.data.items);

            const resultPinMessages = await axios.get(
              urlPinMessages, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setAllPinMessages(resultPinMessages.data.items);
          }, 1200);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Delete contact repost message
  const deleteContactRepostMessage = (conversationId, messageId, userMessageId) => {
    // URL for deleting own message
    const urlDeleteRepostMessage = API.URL + 'conversations/' + conversationId + '/messages/' + messageId + '/user-message/' + userMessageId;

    // fetch data and validate
    axios
      .delete(urlDeleteRepostMessage, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setSubmenuAlert(true);
          setDeleteReplyMessageShow(true);

          setTimeout(() => {
            setSubmenuAlert(false);
            setDeleteReplyMessageShow(false);
          }, 1000);

          setTimeout(async () => {
            const result = await axios.get(
              urlGroupRoom, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setGroupData(result.data.items);

            const resultPinMessages = await axios.get(
              urlPinMessages, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setAllPinMessages(resultPinMessages.data.items);
          }, 1200);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Repost a comment
  const repostMessageToChatGroup = (replyId) => {
    // URL for repost message
    const urlRepostMessage = API.URL + 'conversations/' + params.id + '/messages';

    // Post data
    const data = {
      replyId: replyId
    }

    // fetch data and validate
    axios
      .post(urlRepostMessage, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setSubmenuAlert(true)
          setRepostMessageShow(true)

          setTimeout( () => {
            setSubmenuAlert(false)
            setRepostMessageShow(false)
          }, 1000);

          setTimeout(async () => {
            const result = await axios.get(
              urlGroupRoom, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setGroupData(result.data.items);
          }, 1200);

          console.log("Success");

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Forward message to other conversations
  const forwardMessageToChatGroup = (conversationId, messageId) => {
    // URL for forward message
    const urlForwardMessage = API.URL + 'conversations/' + conversationId + '/messages/' + messageId + '/forward';

    // Get all values of the checkboxes which is checked.
    const chatGroupData = [...document.querySelectorAll('.contact-friend:checked')].map(e => Number(e.value));

    if(chatGroupData.length != 0){
      // Post data
      const data = {
        conversationIds: chatGroupData
      }

      // fetch data and validate
      axios
        .post(urlForwardMessage, data, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        })
        .then((response) => {
          if(response.status === 200){
            document.querySelector("#chat-forward-select-contact .tbs--error--forward").innerHTML = "";
            document.querySelector("#chat-forward-select-contact .tbs--error--forward").style.display = "none";

            setShowConversations(false);
            setSubmenuAlert(true);
            setForwardMessageShow(true);

            setTimeout(() => {
              setSubmenuAlert(false);
              setForwardMessageShow(false);
            }, 1000);

            console.log("Success");

          } else {
            console.log("Error: Access Denied");
          }
        })
        .catch(e => {
          console.log(e)
          document.querySelector("#chat-forward-select-contact .tbs--error--forward").innerHTML = "Please select at least one chat or community.";
          document.querySelector("#chat-forward-select-contact .tbs--error--forward").style.display = "block";
        })
    } else {
      console.log("Failed")
      document.querySelector("#chat-forward-select-contact .tbs--error--forward").innerHTML = "Please select at least one chat or community.";
      document.querySelector("#chat-forward-select-contact .tbs--error--forward").style.display = "block";
    }
  }

  // Copy message to the clipboard
  const copyMessageToClipboard = (message) => {
    const el = document.createElement("textarea");
    el.value = message;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setSubmenuAlert(true);
    setCopyMessageShow(true);

    setTimeout(() => {
      setSubmenuAlert(false);
      setCopyMessageShow(false);
    }, 1000);
  }

  const acceptRequestForInviteGroup = (conversationId, messageId, userMessageId) => {
    // URL for accept request in group
    const urlAcceptRequest = API.URL + 'conversations/' + conversationId + '/messages/' + messageId + '/accept-friend-request';

    // fetch data and validate
    axios
      .put(urlAcceptRequest, "", {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setSubmenuAlert(true);
          setAcceptRequestMessageShow(true);

          setTimeout(() => {
            setSubmenuAlert(false);
            setAcceptRequestMessageShow(false);
          }, 1000);

          setTimeout(async () => {
            const result = await axios.get(
              urlGroupRoom, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setGroupData(result.data.items);
          }, 1200);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)

        if(e.response.status === 409){
          deleteContactMessage(conversationId, messageId, userMessageId);
          setSubmenuAlert(true);
          setAcceptRequestExistingUserMessageShow(true);

          setTimeout(() => {
            setSubmenuAlert(false);
            setAcceptRequestExistingUserMessageShow(false);
          }, 1000);

          setTimeout(async () => {
            const result = await axios.get(
              urlGroupRoom, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setGroupData(result.data.items);
          }, 1200);
        }
      })
  }

  // Get post message data
  const getPostMessage = (conversationId, messageId, userMessageId) => {
    // URL for the post messages
    const urlPostMessage = API.URL + 'conversations/' + conversationId + '/messages';

    // fetch data and validate
    axios
      .get(urlPostMessage, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          const allData = response.data.items;

          allData.map(message => {
            if(message.id == userMessageId && message.conversationMessageId == messageId){
              setPostMessageTitle(message.conversationMessage.post.title);
            }
          })
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Download post image
  const downloadImage = (filename) => {
    let link = document.createElement('a');
    link.href = filename;
    link.download = 'tbs-post-image';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const Schema = Yup.object().shape({
    text: Yup.string()
      .required('Please enter a message.')
  });

  const SchemaTwo = Yup.object().shape({
    text: Yup.string()
      .required('Please enter a message.'),
    imageId: Yup.number()
      .required('Please upload an image.'),
  });

  const urlify = (text) => {
    let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    return text.replace(urlRegex, function(url) {

      return '<a href="' + url + '" target="_blank">' + url + '</a>';

    })
  }

  // Toggle method for show and hide "submenu" form
  const toggleSubMenu = (id) => {
    if(showMenuId == id) {
      setShowMenu(!showMenu);
      setShowMenuId(id);
    } else {
      setShowMenu(true);
      setShowMenuId(id);
    }
  }

  const audioPlayer = (elementId, audioId) => {
    let audioPlayer = document.getElementById("audio-" + audioId + "-player");
    let audioElement = document.getElementById("audio-" + audioId);
    let postContainer = document.getElementById("post-link-" + elementId);

    postContainer.addEventListener("click", (evt) => {
      evt.preventDefault();
      let targetElement = evt.target;

      do {
        if (targetElement == audioPlayer) {
          if(audioElement.paused) {
            audioElement.play();
          }
          else {
            audioElement.pause();
            audioElement.currentTime = 0;
          }
        }

        if (targetElement != audioPlayer && targetElement == postContainer) {
          window.location.href = `${url}/post/${elementId}`;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);

      window.location.href = `${url}/post/${elementId}`;

    });
  }

  const deleteMessage = (cId, cMId) => {
    deleteOwnRepostMessage(cId, cMId);
    setShowDeleteModal(false);
  }

  const deleteMessageOwn = (cId, cMId) => {
    deleteOwnMessage(cId, cMId);
    setShowDeleteModalOwn(false);
  }

  const deleteMessageContactRepost = (cId, cMId, uMId) => {
    deleteContactRepostMessage(cId, cMId, uMId);
    setShowDeleteModalContactRepost(false);
  }

  const deleteMessageContact = (cId, cMId, uMId) => {
    deleteContactMessage(cId, cMId, uMId);
    setShowDeleteModalContact(false);
  }

  useEffect(() => {
    const textElement = document.querySelectorAll("textarea");
    autosize(textElement);
  })

  const addEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setText(text + emoji);
  };

  if(!groupData || groupData.length == 0){
    return (
      <ReactBootstrap.Row id="group-channel">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true}>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center no-message-available">
                Start the conversation by posting the first message to the group!
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} ref={myRef} id="group-new-message" className={isMobile ? "tbs-group-mobile no-messages" : "no-messages"}>
          <ReactBootstrap.Container fluid={true}>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form-create-message">
                  <div className="tbs--form--main--error text-center"></div>
                  <div className="tbs--form--complete--post">
                    <Formik
                      initialValues={{
                        text: ''
                      }}
                      validationSchema={Schema}
                      onSubmit={values => {
                        console.log(values.text);
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form onSubmit={createNewMessage}>
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--message--complete--block--one">
                              <div className="text-left">
                                <Field as="textarea" className={`create-comment`} rows="1" name="text" id="textComment" placeholder="Type a message" value={text} onChange={handleChangeText}/>
                                <img className="tbs--comment--start tbs--comment--start--group" src={Smiley} alt="Smiley" onClick={() => setShowEmojis(!showEmojis)}/>
                                <img className="tbs--comment--end" src={IconPaperClipBlack} alt="Paper clip" onClick={() => setShowModalImageText(true)}/>
                                <div className="tbs--message--complete--block--two">
                                  <div className="text-right button--sign" id="conversation-message-submit">
                                    {
                                      /*
                                        <ReactBootstrap.Button type="submit">
                                          Post
                                        </ReactBootstrap.Button>
                                      */
                                    }
                                    <ReactBootstrap.Button type="submit">
                                      <img src={CommentButton} />
                                    </ReactBootstrap.Button>
                                  </div>
                                </div>
                                <ReactBootstrap.Modal
                                  show={showModalImageText}
                                  onHide={() => setShowModalImageText(false)}
                                  size="md"
                                  aria-labelledby="contained-modal-title-vcenter"
                                  centered
                                >
                                  <ReactBootstrap.Modal.Header closeButton>
                                    <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                                      Add image
                                    </ReactBootstrap.Modal.Title>
                                  </ReactBootstrap.Modal.Header>
                                  <ReactBootstrap.Modal.Body id="comment-with-image">
                                    <ReactBootstrap.Row>
                                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                        <div className="form-create-message-with-image">
                                          <div className="tbs--form--main--error text-center"></div>
                                          <div className="tbs--form--complete--post">
                                            <Formik
                                              initialValues={{
                                                text: '',
                                                imageId: imageId
                                              }}
                                              validationSchema={SchemaTwo}
                                              onSubmit={values => {
                                                console.log(values.text);
                                              }}
                                            >
                                              {({ errors, touched }) => (
                                                <Form onSubmit={createNewMessageWithImage}>
                                                  <label htmlFor="upload-image" className="new-image-upload">
                                                    <div className="tbs--default--image">
                                                      <img src={IconImage} alt="Post - Image" className="tbs--post--image--default"/>
                                                      <input type="file" id="upload-image" accept="image/*" onChange={previewFile} hidden/>
                                                      {/*<label className="tbs--upload--image upload-file-button">
                                                        UPLOAD AN IMAGE
                                                        <img src={UploadFile} alt="Upload file" id="upload-file"/>
                                                      </label>*/}
                                                    </div>
                                                  </label>
                                                  <div className="image-comment-submit">
                                                    {/*<Field as="textarea" className={`create-comment-with-image`} rows="1" name="textImage" id="textImage" placeholder="Type a message" value={textImage} onChange={handleChangeTextImage}/>*/}
                                                    <div className="text-center button--sign" id="conversation-image-message-submit">
                                                      {
                                                        /*
                                                          <ReactBootstrap.Button type="submit">
                                                            <img src={CommentButton} />
                                                          </ReactBootstrap.Button>
                                                        */
                                                      }
                                                      <ReactBootstrap.Button type="submit">
                                                        Post
                                                      </ReactBootstrap.Button>
                                                    </div>
                                                  </div>
                                                  <div className="error-text text-left">
                                                    {/*
                                                      errors.textImage && touched.textImage
                                                        ?
                                                        errors.textImage
                                                        :
                                                        null
                                                    */}
                                                    <ErrorMessage name="textImage">
                                                      {errors.textImage}
                                                    </ErrorMessage>
                                                  </div>
                                                </Form>
                                              )}
                                            </Formik>
                                          </div>
                                        </div>
                                      </ReactBootstrap.Col>
                                    </ReactBootstrap.Row>
                                  </ReactBootstrap.Modal.Body>
                                </ReactBootstrap.Modal>
                              </div>
                              {showEmojis && (
                                <div>
                                  <Picker onSelect={addEmoji} />
                                </div>
                              )}
                              <div className="error-text text-left">
                                {/*
                                  errors.text && touched.text
                                    ?
                                    errors.text
                                    :
                                    null
                                */}
                                <ErrorMessage name="text">
                                  {errors.text}
                                </ErrorMessage>
                              </div>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {
            showChatMessageAlert
            ?
              <ReactBootstrap.Alert variant="success" transition={true} id="new-comment-alert" className="text-center">
                <ReactBootstrap.Alert.Heading>
                  New comment successful created.
                </ReactBootstrap.Alert.Heading>
              </ReactBootstrap.Alert>
            :
              ""
          }
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  } else {
    return (
      <ReactBootstrap.Row id="group-channel">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div>
            {
              unpinAlert
              ?
                <ReactBootstrap.Alert variant="success" transition={true} id="unpin-alert" className="text-center">
                  <ReactBootstrap.Alert.Heading>
                    Message unpinned successful.
                  </ReactBootstrap.Alert.Heading>
                </ReactBootstrap.Alert>
              :
                ""
            }
          </div>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {
            allPinMessages.length != 0
            ?
              <ReactBootstrap.Row id="group--messages">
                {
                  allPinMessages.map(pins => {
                    return (
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--pin--messages--available">
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="text-left">
                            <img src={IconPin} alt="Pinned message" onClick={() => unpinMessage(pins.conversationId, pins.conversationMessageId, pins.id)}/>
                          </ReactBootstrap.Col>
                          {
                            pins.conversationMessage.text != ""
                              ?
                              <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                {pins.conversationMessage.text}
                              </ReactBootstrap.Col>
                              :
                              <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} onLoad={() => getPostMessage(pins.conversationId, pins.conversationMessageId, pins.id)}>
                                {postMessageTitle}
                              </ReactBootstrap.Col>
                          }
                          <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="text-right">
                            <img src={IconPinDelete} alt="Remove pin message" onClick={() => unpinMessage(pins.conversationId, pins.conversationMessageId, pins.id)}/>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      </ReactBootstrap.Col>
                    )
                  })
                }
              </ReactBootstrap.Row>
            :
              ""
          }
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true}>
            <ReactBootstrap.Row>
              {
                groupData.map((groupRoom, index) => {
                  return (
                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      {
                        groupRoom.conversationMessage.userId != userID
                        ?
                          <div>
                            <div className="tbs--chat--message--contact" onClick={() => toggleSubMenu(groupRoom.id)}>
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                  {
                                    groupRoom.conversationMessage.originalMessageCreator != null
                                    ?
                                      <div className="tbs--chat--forward">
                                        <img src={IconForward} alt="Forward message"/>
                                        <span>
                                          {`Forwarded message from ${groupRoom.conversationMessage.originalMessageCreator.name}`}
                                        </span>
                                      </div>
                                    :
                                      ""
                                  }
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--contact--chat--messages">
                                  <div>
                                    <img src={groupRoom.conversationMessage.user.image != null ? groupRoom.conversationMessage.user.image : groupRoom.conversationMessage.user.profileImage != null ? groupRoom.conversationMessage.user.profileImage.url : IconAvatar} alt="Profile Image" />
                                  </div>
                                  <div>
                                    <div>
                                      {groupRoom.conversationMessage.user.name}
                                      {
                                        groupRoom.conversationMessage.conversationUser.role == "ADMIN"
                                          ?
                                          <span className="tbs--role--admin">Admin</span>
                                          :
                                          ""
                                      }
                                      <span className="tbs--comment--chat--date">{moment(groupRoom.createdAt).format('MMMM DD YYYY, hh:mm:A')}</span>
                                    </div>
                                    {
                                      groupRoom.conversationMessage.post != null
                                        ?
                                        <Link to={`/dashboard/feeds/profile/${groupRoom.conversationMessage.post.userId}/feedlist/post/${groupRoom.conversationMessage.post.id}`} className="tbs--post--link community-post-link-contact">
                                          <ReactBootstrap.Row>
                                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                              <div className="tbs--image--chat--post--profile">
                                                <img src={groupRoom.conversationMessage.post.user.image != null ? groupRoom.conversationMessage.post.user.image : groupRoom.conversationMessage.post.user.profileImage != null ? groupRoom.conversationMessage.post.user.profileImage.url : ""} alt="Image"/>
                                              </div>
                                              <div className="tbs--chat--message--contact--description">
                                                <div>
                                                  {groupRoom.conversationMessage.post.user.name}
                                                </div>
                                                <div>
                                                  {groupRoom.conversationMessage.post.user.position} at {groupRoom.conversationMessage.post.user.company}
                                                </div>
                                                <div>
                                                  {moment(groupRoom.conversationMessage.post.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                                </div>
                                              </div>
                                            </ReactBootstrap.Col>
                                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--post--conversation--details">
                                              <div>
                                                {groupRoom.conversationMessage.post.parentPost != null ? groupRoom.conversationMessage.post.parentPost.title : groupRoom.conversationMessage.post.title}
                                              </div>
                                            </ReactBootstrap.Col>
                                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                              <div className="tbs--post--image">
                                                <img src={
                                                  groupRoom.conversationMessage.post != null && groupRoom.conversationMessage.post.file != null
                                                    ? groupRoom.conversationMessage.post.file.url
                                                    : groupRoom.conversationMessage.post.parentPost != null && groupRoom.conversationMessage.post.parentPost.file != null
                                                      ? groupRoom.conversationMessage.post.parentPost.file.url
                                                      : ""
                                                } alt="Image"/>
                                              </div>
                                              {
                                                groupRoom.conversationMessage.post.parentPost != null
                                                  ?
                                                  <div className="tbs--audio--feed" id={`audio-${groupRoom.conversationMessage.post.parentPost.audioId}-player`} onClick={() => audioPlayer(groupRoom.id, groupRoom.conversationMessage.post.parentPost.audioId)}>
                                                    <img src={
                                                      groupRoom.conversationMessage.post.parentPost != null && groupRoom.conversationMessage.post.parentPost.file != null && groupRoom.conversationMessage.post.parentPost.audioId != null
                                                        ?
                                                        IconPlayWhite
                                                        :
                                                        groupRoom.conversationMessage.post.parentPost != null && groupRoom.conversationMessage.post.parentPost.file == null && groupRoom.conversationMessage.post.parentPost.audioId != null
                                                          ?
                                                          IconPlayBlack
                                                          :
                                                          ""
                                                    } alt="Play"/>
                                                  </div>
                                                  :
                                                  <div className="tbs--audio--feed" id={`audio-${groupRoom.conversationMessage.post.audioId}-player`} onClick={() => audioPlayer(groupRoom.id, groupRoom.conversationMessage.post.audioId)}>
                                                    <img src={
                                                      groupRoom.conversationMessage.post != null && groupRoom.conversationMessage.post.file != null && groupRoom.conversationMessage.post.audioId != null
                                                        ?
                                                        IconPlayWhite
                                                        :
                                                        groupRoom.conversationMessage.post != null && groupRoom.conversationMessage.post.file == null && groupRoom.conversationMessage.post.audioId != null
                                                          ?
                                                          IconPlayBlack
                                                          :
                                                          ""
                                                    } alt="Play"/>
                                                  </div>
                                              }
                                            </ReactBootstrap.Col>
                                            {
                                              groupRoom.conversationMessage.text != ""
                                                ?
                                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--post--conversation--details">
                                                  <div>
                                                    {groupRoom.conversationMessage.text}
                                                  </div>
                                                </ReactBootstrap.Col>
                                                :
                                                ""
                                            }
                                          </ReactBootstrap.Row>
                                        </Link>
                                        :
                                        groupRoom.conversationMessage.reply != null
                                          ?
                                          <div className="tbs--reply--message--chats">
                                            <div className="tbs--headline--name">
                                              <span>{groupRoom.conversationMessage.reply.user.name}</span>
                                            </div>
                                            <div className="tbs--chat--message--contact--description">
                                              <Linkify>
                                                {groupRoom.conversationMessage.reply.text}
                                              </Linkify>
                                            </div>
                                          </div>
                                          :
                                          <div>
                                            <div className="tbs--image--chat--post">
                                              <img src={groupRoom.conversationMessage.file != null ? groupRoom.conversationMessage.file.url : ""} alt="Image"/>
                                            </div>
                                            <div className="tbs--chat--message--contact--description tbs--additional--post--text">
                                              <Linkify>
                                                {groupRoom.conversationMessage.text}
                                              </Linkify>
                                            </div>
                                          </div>
                                    }
                                  </div>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                            </div>
                            {
                              showMenu
                              ?
                                showMenuId == groupRoom.id
                                ?
                                  groupRoom.conversationMessage.introducedUserId != null
                                  ?
                                    <div>
                                      <div className="tbs--chat--message--contact tbs--chat--message--submenu--contact">
                                        {
                                          userRole == "ADMIN" && groupRoom.conversationMessage.introducedUserId != userID
                                          ?
                                            <div onClick={() => acceptRequestForInviteGroup(groupRoom.conversationId, groupRoom.conversationMessageId, groupRoom.id)}>
                                              <img src={IconTickBlue} alt="Accept" className="tbs--accept--request" className="tbs--accept--request--activate" />
                                              <span>
                                                Accept
                                              </span>
                                            </div>
                                          :
                                            <div>
                                              <img src={IconTickWhite} alt="Accept" className="tbs--accept--request" className="tbs--accept--request--activate" />
                                              <span>
                                                Accept
                                              </span>
                                            </div>
                                        }
                                        {
                                          userRole == "ADMIN"
                                          ?
                                            <div>
                                              <div onClick={() => setShowDeleteModalContact(true)}>
                                                <img src={IconDelete} alt="Delete" />
                                                <span>
                                                Delete
                                              </span>
                                              </div>
                                              <ReactBootstrap.Modal
                                                show={showDeleteModalContact}
                                                onHide={() => setShowDeleteModalContact(false)}
                                                size="md"
                                                aria-labelledby="contained-modal-title-vcenter-two"
                                                centered
                                              >
                                                <ReactBootstrap.Modal.Header closeButton>
                                                  <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-two">
                                                    Delete Message?
                                                  </ReactBootstrap.Modal.Title>
                                                </ReactBootstrap.Modal.Header>
                                                <ReactBootstrap.Modal.Body id="chat-forward-select-contact">
                                                  <ReactBootstrap.Row>
                                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                      <div className="text-center button--sign">
                                                        <ReactBootstrap.Button onClick={() => deleteMessageContact(groupRoom.conversationId, groupRoom.conversationMessageId, groupRoom.id)}>Delete</ReactBootstrap.Button>
                                                      </div>
                                                    </ReactBootstrap.Col>
                                                  </ReactBootstrap.Row>
                                                </ReactBootstrap.Modal.Body>
                                              </ReactBootstrap.Modal>
                                            </div>
                                          :
                                            ""
                                        }
                                      </div>
                                      <div>
                                        {
                                          submenuAlert
                                          ?
                                            <ReactBootstrap.Alert variant="success" transition={true} id="submenu-alert-contact" className="text-center">
                                              <ReactBootstrap.Alert.Heading>
                                                {
                                                  acceptRequestMessageShow
                                                  ?
                                                    "Message accepted successful."
                                                  :
                                                    acceptRequestExistingUserMessageShow
                                                    ?
                                                      "Message accepted successful. (User is already in this group)"
                                                    :
                                                      deleteMessageShow
                                                      ?
                                                        "Message deleted successful."
                                                      :
                                                        ""
                                                }
                                              </ReactBootstrap.Alert.Heading>
                                            </ReactBootstrap.Alert>
                                          :
                                            ""
                                        }
                                      </div>
                                    </div>
                                  :
                                    groupRoom.conversationMessage.reply == null
                                    ?
                                      <div>
                                        <div className="tbs--chat--message--contact tbs--chat--message--submenu--contact">
                                          <img src={IconPin} alt="Pin" onClick={() => pinMessage(groupRoom.conversationId, groupRoom.conversationMessageId, groupRoom.id)}/>
                                          {
                                            groupRoom.conversationMessage.post != null
                                            ?
                                              groupRoom.conversationMessage.post.file != null
                                              ?
                                                <span><a href={groupRoom.conversationMessage.post.file.url} download><img src={IconDownload} alt="Download" /></a></span>
                                              :
                                                ""
                                            :
                                              groupRoom.conversationMessage.file != null
                                              ?
                                                <span><a href={groupRoom.conversationMessage.file != null ? groupRoom.conversationMessage.file.url : ""} download><img src={IconDownload} alt="Download" /></a></span>
                                              :
                                                ""
                                          }
                                          <img src={IconForwardWhite} alt="Post" onClick={() => repostMessageToChatGroup(groupRoom.conversationMessageId)} className="tbs--reverse--image"/>
                                          <img src={IconForwardWhite} alt="Forward" onClick={() => setShowConversations(true)}/>
                                          <ReactBootstrap.Modal
                                            show={showConversations}
                                            onHide={() => setShowConversations(false)}
                                            size="md"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                          >
                                            <ReactBootstrap.Modal.Header closeButton>
                                              <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                                                Forward to ...
                                              </ReactBootstrap.Modal.Title>
                                            </ReactBootstrap.Modal.Header>
                                            <ReactBootstrap.Modal.Body id="chat-forward-select-contact">
                                              <div className="tbs--error--forward"></div>
                                              {
                                                chatGroupData.map(allRooms => {
                                                  if(allRooms.id != params.id){
                                                    return(
                                                      <ReactBootstrap.Row>
                                                        <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--image--select">
                                                          <div className={allRooms.groupType == "introduce" ? "tbs--image--chats tbs--image--chats--introduce" : "tbs--image--chats"}>
                                                            <img src={
                                                              allRooms.type == "GROUP"
                                                                ?
                                                                allRooms.image != null
                                                                  ?
                                                                  allRooms.image.url
                                                                  :
                                                                  allRooms.groupType == "introduce"
                                                                    ?
                                                                    IconIntroduce
                                                                    :
                                                                    IconImage
                                                                :
                                                                allRooms.conversationReceiver.user.image != null
                                                                  ?
                                                                  allRooms.conversationReceiver.user.image
                                                                  :
                                                                  allRooms.conversationReceiver.user.profileImage != null
                                                                    ?
                                                                    allRooms.conversationReceiver.user.profileImage.url
                                                                    :
                                                                    IconAvatar
                                                            }
                                                            />
                                                          </div>
                                                        </ReactBootstrap.Col>
                                                        <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="tbs--contact--name--select">
                                                          {
                                                            allRooms.type == "GROUP"
                                                            ?
                                                              allRooms.name
                                                            :
                                                              allRooms.conversationReceiver.user.name
                                                          }
                                                        </ReactBootstrap.Col>
                                                        <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--checkbox--select text-center">
                                                          <input type="checkbox" className="contact-friend" name="contact" value={allRooms.id} />
                                                        </ReactBootstrap.Col>
                                                      </ReactBootstrap.Row>
                                                    )
                                                  }
                                                })
                                              }
                                              <div className="text-center button--sign">
                                                <ReactBootstrap.Button onClick={() => forwardMessageToChatGroup(groupRoom.conversationId, groupRoom.conversationMessageId)}>Forward</ReactBootstrap.Button>
                                              </div>
                                            </ReactBootstrap.Modal.Body>
                                          </ReactBootstrap.Modal>
                                          {
                                            userRole == "ADMIN"
                                            ?
                                              <div>
                                                <img src={IconDelete} alt="Delete" onClick={() => setShowDeleteModalContact(true)}/>
                                                <ReactBootstrap.Modal
                                                  show={showDeleteModalContact}
                                                  onHide={() => setShowDeleteModalContact(false)}
                                                  size="md"
                                                  aria-labelledby="contained-modal-title-vcenter-two"
                                                  centered
                                                >
                                                  <ReactBootstrap.Modal.Header closeButton>
                                                    <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-two">
                                                      Delete Message?
                                                    </ReactBootstrap.Modal.Title>
                                                  </ReactBootstrap.Modal.Header>
                                                  <ReactBootstrap.Modal.Body id="chat-forward-select-contact">
                                                    <ReactBootstrap.Row>
                                                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className="text-center button--sign">
                                                          <ReactBootstrap.Button onClick={() => deleteMessageContact(groupRoom.conversationId, groupRoom.conversationMessageId, groupRoom.id)}>Delete</ReactBootstrap.Button>
                                                        </div>
                                                      </ReactBootstrap.Col>
                                                    </ReactBootstrap.Row>
                                                  </ReactBootstrap.Modal.Body>
                                                </ReactBootstrap.Modal>
                                              </div>
                                            :
                                              ""
                                          }
                                          <img src={IconClipboard} alt="Copy Clipboard" onClick={() => copyMessageToClipboard(groupRoom.conversationMessage.text != "" ? groupRoom.conversationMessage.text : groupRoom.conversationMessage.post.title)}/>
                                        </div>
                                        <div>
                                          {
                                            submenuAlert
                                              ?
                                              <ReactBootstrap.Alert variant="success" transition={true} id="submenu-alert-contact" className="text-center">
                                                <ReactBootstrap.Alert.Heading>
                                                  {
                                                    pinMessageShow
                                                    ?
                                                      "Message pinned successful."
                                                    :
                                                      deleteMessageShow
                                                      ?
                                                        "Message deleted successful."
                                                      :
                                                        forwardMessageShow
                                                        ?
                                                          "Message forwarded successful."
                                                        :
                                                          copyMessageShow
                                                          ?
                                                            "Message copy to clipboard."
                                                          :
                                                            repostMessageShow
                                                            ?
                                                              "Message reposted successful."
                                                            :
                                                              ""
                                                  }
                                                </ReactBootstrap.Alert.Heading>
                                              </ReactBootstrap.Alert>
                                              :
                                              ""
                                          }
                                        </div>
                                      </div>
                                    :
                                      <div>
                                        <div className="tbs--chat--message--contact tbs--chat--message--submenu--contact" onClick={() => setShowDeleteModalContactRepost(true)}>
                                          <img src={IconDelete} alt="Delete" /><span>Delete</span>
                                          <ReactBootstrap.Modal
                                            show={showDeleteModalContactRepost}
                                            onHide={() => setShowDeleteModalContactRepost(false)}
                                            size="md"
                                            aria-labelledby="contained-modal-title-vcenter-two"
                                            centered
                                          >
                                            <ReactBootstrap.Modal.Header closeButton>
                                              <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-two">
                                                Delete Message?
                                              </ReactBootstrap.Modal.Title>
                                            </ReactBootstrap.Modal.Header>
                                            <ReactBootstrap.Modal.Body id="chat-forward-select-contact">
                                              <ReactBootstrap.Row>
                                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                  <div className="text-center button--sign">
                                                    <ReactBootstrap.Button onClick={() => deleteMessageContactRepost(groupRoom.conversationId, groupRoom.conversationMessageId, groupRoom.id)}>Delete</ReactBootstrap.Button>
                                                  </div>
                                                </ReactBootstrap.Col>
                                              </ReactBootstrap.Row>
                                            </ReactBootstrap.Modal.Body>
                                          </ReactBootstrap.Modal>
                                        </div>
                                        <div>
                                          {
                                            submenuAlert
                                            ?
                                              <ReactBootstrap.Alert variant="success" transition={true} id="submenu-alert-contact" className="text-center">
                                                <ReactBootstrap.Alert.Heading>
                                                  {
                                                    deleteReplyMessageShow
                                                    ?
                                                      "Message deleted successful."
                                                    :
                                                      ""
                                                  }
                                                </ReactBootstrap.Alert.Heading>
                                              </ReactBootstrap.Alert>
                                            :
                                              ""
                                          }
                                        </div>
                                      </div>
                                :
                                  ""
                              :
                                ""
                            }
                          </div>
                        :
                          <div>
                            <div className="tbs--chat--message--own" onClick={() => toggleSubMenu(groupRoom.id)}>
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                  {
                                    groupRoom.conversationMessage.originalMessageCreator != null
                                      ?
                                      <div className="tbs--chat--forward">
                                        <img src={IconForward} alt="Forward message"/>
                                        <span>
                                          {`Forwarded message from ${groupRoom.conversationMessage.originalMessageCreator.name}`}
                                        </span>
                                      </div>
                                      :
                                      ""
                                  }
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <div>
                                    {moment(groupRoom.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                  </div>
                                  {
                                    groupRoom.conversationMessage.post != null
                                    ?
                                      <Link to={`/dashboard/feeds/profile/${groupRoom.conversationMessage.post.userId}/feedlist/post/${groupRoom.conversationMessage.post.id}`} className="tbs--post--link community-post-link-own">
                                        <ReactBootstrap.Row>
                                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--my--chat--messages">
                                            <div className="tbs--image--chat--post--profile">
                                              <img src={groupRoom.conversationMessage.post.user.image != null ? groupRoom.conversationMessage.post.user.image : groupRoom.conversationMessage.post.user.profileImage != null ? groupRoom.conversationMessage.post.user.profileImage.url : ""} alt="Image"/>
                                            </div>
                                            <div className="tbs--chat--message--contact--description">
                                              <div>
                                                {groupRoom.conversationMessage.post.user.name}
                                              </div>
                                              <div>
                                                {groupRoom.conversationMessage.post.user.position} at {groupRoom.conversationMessage.post.user.company}
                                              </div>
                                              <div>
                                                {moment(groupRoom.conversationMessage.post.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                              </div>
                                            </div>
                                          </ReactBootstrap.Col>
                                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--post--conversation--details">
                                            <div>
                                              {groupRoom.conversationMessage.post.parentPost != null ? groupRoom.conversationMessage.post.parentPost.title : groupRoom.conversationMessage.post.title}
                                            </div>
                                          </ReactBootstrap.Col>
                                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className="tbs--post--image">
                                              <img src={
                                                groupRoom.conversationMessage.post != null && groupRoom.conversationMessage.post.file != null
                                                  ? groupRoom.conversationMessage.post.file.url
                                                  : groupRoom.conversationMessage.post.parentPost != null && groupRoom.conversationMessage.post.parentPost.file != null
                                                    ? groupRoom.conversationMessage.post.parentPost.file.url
                                                    : ""
                                              } alt="Image"/>
                                            </div>
                                            {/*
                                              <div className="tbs--audio--feed" id={`audio-${groupRoom.conversationMessage.post.parentPost == null ? groupRoom.conversationMessage.post.parentPost.audioId : groupRoom.conversationMessage.post.audioId}-player`} onClick={() => audioPlayer(groupRoom.id, groupRoom.conversationMessage.post.audioId)}>
                                                <img src={
                                                  (groupRoom.conversationMessage.post.file != null && groupRoom.conversationMessage.post.audioId != null) ||
                                                  (groupRoom.conversationMessage.post.parentPost != null && groupRoom.conversationMessage.post.parentPost.file != null && groupRoom.conversationMessage.post.parentPost.audioId != null)
                                                    ?
                                                    IconPlayWhite
                                                    :
                                                    (groupRoom.conversationMessage.post.file == null && groupRoom.conversationMessage.post.audioId != null) ||
                                                    (groupRoom.conversationMessage.post.parentPost != null && groupRoom.conversationMessage.post.parentPost.file == null && groupRoom.conversationMessage.post.parentPost.audioId != null)
                                                      ?
                                                      IconPlayBlack
                                                      :
                                                      ""
                                                } alt="Play"/>
                                              </div>
                                            */}
                                            {
                                              groupRoom.conversationMessage.post.parentPost != null
                                                ?
                                                  <div className="tbs--audio--feed" id={`audio-${groupRoom.conversationMessage.post.parentPost.audioId}-player`} onClick={() => audioPlayer(groupRoom.id, groupRoom.conversationMessage.post.parentPost.audioId)}>
                                                    <img src={
                                                      groupRoom.conversationMessage.post.parentPost != null && groupRoom.conversationMessage.post.parentPost.file != null && groupRoom.conversationMessage.post.parentPost.audioId != null
                                                        ?
                                                        IconPlayWhite
                                                        :
                                                        groupRoom.conversationMessage.post.parentPost != null && groupRoom.conversationMessage.post.parentPost.file == null && groupRoom.conversationMessage.post.parentPost.audioId != null
                                                          ?
                                                          IconPlayBlack
                                                          :
                                                          ""
                                                    } alt="Play"/>
                                                  </div>
                                                :
                                                  <div className="tbs--audio--feed" id={`audio-${groupRoom.conversationMessage.post.audioId}-player`} onClick={() => audioPlayer(groupRoom.id, groupRoom.conversationMessage.post.audioId)}>
                                                    <img src={
                                                      groupRoom.conversationMessage.post != null && groupRoom.conversationMessage.post.file != null && groupRoom.conversationMessage.post.audioId != null
                                                        ?
                                                        IconPlayWhite
                                                        :
                                                        groupRoom.conversationMessage.post != null && groupRoom.conversationMessage.post.file == null && groupRoom.conversationMessage.post.audioId != null
                                                          ?
                                                          IconPlayBlack
                                                          :
                                                          ""
                                                    } alt="Play"/>
                                                  </div>
                                            }
                                          </ReactBootstrap.Col>
                                          {
                                            groupRoom.conversationMessage.text != ""
                                            ?
                                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--post--conversation--details">
                                                <div>
                                                  {groupRoom.conversationMessage.text}
                                                </div>
                                              </ReactBootstrap.Col>
                                            :
                                              ""
                                          }
                                        </ReactBootstrap.Row>
                                      </Link>
                                    :
                                      groupRoom.conversationMessage.reply != null
                                      ?
                                        <div className="tbs--reply--message--chats">
                                          <div className="tbs--headline--name">
                                            <span>{groupRoom.conversationMessage.reply.user.name}</span>
                                          </div>
                                          <div className="tbs--chat--message--contact--description">
                                            <Linkify>
                                              {groupRoom.conversationMessage.reply.text}
                                            </Linkify>
                                          </div>
                                        </div>
                                      :
                                        <div>
                                          <div className="tbs--image--chat--post">
                                            <img src={groupRoom.conversationMessage.file != null ? groupRoom.conversationMessage.file.url : ""} alt="Image"/>
                                          </div>
                                          <div className="tbs--chat--message--contact--description tbs--additional--post--text">
                                            <Linkify>
                                              {groupRoom.conversationMessage.text}
                                            </Linkify>
                                          </div>
                                        </div>
                                  }
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              <div className="tbs--status">
                                {/*<img src={groupRoom.status == "READ" ? IconTickWhite: IconTickGrey} alt="Message status"/>*/}
                                <img src={ChatStatus} alt="Message status"/>
                                {/* DELIVERED is the other status*/}
                              </div>
                            </div>
                            {
                              showMenu
                                ?
                                  showMenuId == groupRoom.id
                                  ?
                                    groupRoom.conversationMessage.introducedUserId != null
                                      ?
                                      <div>
                                        <div className="tbs--chat--message--own tbs--chat--message--submenu--own">
                                          {
                                            userRole == "ADMIN" && groupRoom.conversationMessage.introducedUserId != userID
                                            ?
                                              <div>
                                                <img src={IconTickBlue} alt="Accept" className="tbs--accept--request" className="tbs--accept--request--activate" />
                                                <span>
                                                  Accept
                                                </span>
                                              </div>
                                            :
                                              <div>
                                                <img src={IconTickWhite} alt="Accept" className="tbs--accept--request" className="tbs--accept--request--activate" />
                                                <span>
                                                  Accept
                                                </span>
                                              </div>
                                          }
                                          <div onClick={() => setShowDeleteModalContact(true)}>
                                            <img src={IconDelete} alt="Delete" />
                                            <span>
                                              Delete
                                            </span>
                                          </div>
                                          <ReactBootstrap.Modal
                                            show={showDeleteModalContact}
                                            onHide={() => setShowDeleteModalContact(false)}
                                            size="md"
                                            aria-labelledby="contained-modal-title-vcenter-two"
                                            centered
                                          >
                                            <ReactBootstrap.Modal.Header closeButton>
                                              <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-two">
                                                Delete Message?
                                              </ReactBootstrap.Modal.Title>
                                            </ReactBootstrap.Modal.Header>
                                            <ReactBootstrap.Modal.Body id="chat-forward-select-contact">
                                              <ReactBootstrap.Row>
                                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                  <div className="text-center button--sign">
                                                    <ReactBootstrap.Button onClick={() => deleteMessageContact(groupRoom.conversationId, groupRoom.conversationMessageId, groupRoom.id)}>Delete</ReactBootstrap.Button>
                                                  </div>
                                                </ReactBootstrap.Col>
                                              </ReactBootstrap.Row>
                                            </ReactBootstrap.Modal.Body>
                                          </ReactBootstrap.Modal>
                                        </div>
                                        <div>
                                          {
                                            submenuAlert
                                              ?
                                              <ReactBootstrap.Alert variant="success" transition={true} id="submenu-alert" className="text-center">
                                                <ReactBootstrap.Alert.Heading>
                                                  {
                                                    acceptRequestMessageShow
                                                    ?
                                                      "Message accepted successful."
                                                    :
                                                      acceptRequestExistingUserMessageShow
                                                      ?
                                                        "Message accepted successful. (User is already in this group)"
                                                        :
                                                          deleteMessageShow
                                                          ?
                                                            "Message deleted successful."
                                                          :
                                                            ""
                                                  }
                                                </ReactBootstrap.Alert.Heading>
                                              </ReactBootstrap.Alert>
                                              :
                                              ""
                                          }
                                        </div>
                                      </div>
                                    :
                                      groupRoom.conversationMessage.reply == null
                                      ?
                                        <div>
                                          <div className="tbs--chat--message--own tbs--chat--message--submenu--own">
                                            <img src={IconPin} alt="Pin" onClick={() => pinMessage(groupRoom.conversationId, groupRoom.conversationMessageId, groupRoom.id)}/>
                                            {
                                              groupRoom.conversationMessage.post != null
                                              ?
                                                groupRoom.conversationMessage.post.file != null
                                                ?
                                                  <span><a href={groupRoom.conversationMessage.post.file.url} download><img src={IconDownload} alt="Download" /></a></span>
                                                :
                                                  ""
                                              :
                                                groupRoom.conversationMessage.file != null
                                                ?
                                                  <span><a href={groupRoom.conversationMessage.file != null ? groupRoom.conversationMessage.file.url : ""} download><img src={IconDownload} alt="Download" /></a></span>
                                                :
                                                  ""
                                            }
                                            <img src={IconForwardWhite} alt="Post" onClick={() => repostMessageToChatGroup(groupRoom.conversationMessageId)} className="tbs--reverse--image"/>
                                            <img src={IconForwardWhite} alt="Forward" onClick={() => setShowConversations(true)}/>
                                            <ReactBootstrap.Modal
                                              show={showConversations}
                                              onHide={() => setShowConversations(false)}
                                              size="md"
                                              aria-labelledby="contained-modal-title-vcenter-two"
                                              centered
                                            >
                                              <ReactBootstrap.Modal.Header closeButton>
                                                <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-two">
                                                  Forward to ...
                                                </ReactBootstrap.Modal.Title>
                                              </ReactBootstrap.Modal.Header>
                                              <ReactBootstrap.Modal.Body id="chat-forward-select-contact">
                                                <div className="tbs--error--forward"></div>
                                                {
                                                  chatGroupData.map(allRooms => {
                                                    if(allRooms.id != params.id){
                                                      return(
                                                        <ReactBootstrap.Row>
                                                          <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--image--select">
                                                            <div className={allRooms.groupType == "introduce" ? "tbs--image--chats tbs--image--chats--introduce" : "tbs--image--chats"}>
                                                              <img src={
                                                                allRooms.type == "GROUP"
                                                                ?
                                                                  allRooms.image != null
                                                                  ?
                                                                    allRooms.image.url
                                                                  :
                                                                    allRooms.groupType == "introduce"
                                                                    ?
                                                                      IconIntroduce
                                                                    :
                                                                      IconImage
                                                                :
                                                                  allRooms.conversationReceiver.user.image != null
                                                                  ?
                                                                    allRooms.conversationReceiver.user.image
                                                                  :
                                                                    allRooms.conversationReceiver.user.profileImage != null
                                                                    ?
                                                                      allRooms.conversationReceiver.user.profileImage.url
                                                                    :
                                                                      IconAvatar
                                                              }
                                                              />
                                                            </div>
                                                          </ReactBootstrap.Col>
                                                          <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="tbs--contact--name--select">
                                                            {
                                                              allRooms.type == "GROUP"
                                                              ?
                                                                allRooms.name
                                                              :
                                                                allRooms.conversationReceiver.user.name
                                                            }
                                                          </ReactBootstrap.Col>
                                                          <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--checkbox--select text-center">
                                                            <input type="checkbox" className="contact-friend" name="contact" value={allRooms.id} />
                                                          </ReactBootstrap.Col>
                                                        </ReactBootstrap.Row>
                                                      )
                                                    }
                                                  })
                                                }
                                                <div className="text-center button--sign">
                                                  <ReactBootstrap.Button onClick={() => forwardMessageToChatGroup(groupRoom.conversationId, groupRoom.conversationMessageId)}>Forward</ReactBootstrap.Button>
                                                </div>
                                              </ReactBootstrap.Modal.Body>
                                            </ReactBootstrap.Modal>
                                            <img src={IconDelete} alt="Delete" onClick={() => setShowDeleteModalOwn(true)}/>
                                            <ReactBootstrap.Modal
                                              show={showDeleteModalOwn}
                                              onHide={() => setShowDeleteModalOwn(false)}
                                              size="md"
                                              aria-labelledby="contained-modal-title-vcenter-two"
                                              centered
                                            >
                                              <ReactBootstrap.Modal.Header closeButton>
                                                <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-two">
                                                  Delete Message?
                                                </ReactBootstrap.Modal.Title>
                                              </ReactBootstrap.Modal.Header>
                                              <ReactBootstrap.Modal.Body id="chat-forward-select-contact">
                                                <ReactBootstrap.Row>
                                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <div className="text-center button--sign">
                                                      <ReactBootstrap.Button onClick={() => deleteMessageOwn(groupRoom.conversationId, groupRoom.conversationMessageId)}>Delete</ReactBootstrap.Button>
                                                    </div>
                                                  </ReactBootstrap.Col>
                                                </ReactBootstrap.Row>
                                              </ReactBootstrap.Modal.Body>
                                            </ReactBootstrap.Modal>
                                            <img src={IconClipboard} alt="Copy Clipboard" onClick={() => copyMessageToClipboard(groupRoom.conversationMessage.text != "" ? groupRoom.conversationMessage.text : groupRoom.conversationMessage.post.title)}/>
                                          </div>
                                          <div>
                                            {
                                              submenuAlert
                                              ?
                                                <ReactBootstrap.Alert variant="success" transition={true} id="submenu-alert" className="text-center">
                                                  <ReactBootstrap.Alert.Heading>
                                                    {
                                                      pinMessageShow
                                                      ?
                                                        "Message pinned successful."
                                                      :
                                                        deleteMessageShow
                                                        ?
                                                          "Message deleted successful."
                                                        :
                                                          forwardMessageShow
                                                          ?
                                                            "Message forwarded successful."
                                                          :
                                                            copyMessageShow
                                                            ?
                                                              "Message copy to clipboard."
                                                            :
                                                              repostMessageShow
                                                              ?
                                                                "Message reposted successful."
                                                              :
                                                                ""
                                                    }
                                                  </ReactBootstrap.Alert.Heading>
                                                </ReactBootstrap.Alert>
                                              :
                                                ""
                                            }
                                          </div>
                                        </div>
                                      :
                                        <div>
                                          <div className="tbs--chat--message--own tbs--chat--message--submenu--own" onClick={() => setShowDeleteModal(true)}>
                                            <img src={IconDelete} alt="Delete" /><span>Delete</span>
                                            <ReactBootstrap.Modal
                                              show={showDeleteModal}
                                              onHide={() => setShowDeleteModal(false)}
                                              size="md"
                                              aria-labelledby="contained-modal-title-vcenter-two"
                                              centered
                                            >
                                              <ReactBootstrap.Modal.Header closeButton>
                                                <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-two">
                                                  Delete Message?
                                                </ReactBootstrap.Modal.Title>
                                              </ReactBootstrap.Modal.Header>
                                              <ReactBootstrap.Modal.Body id="chat-forward-select-contact">
                                                <ReactBootstrap.Row>
                                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <div className="text-center button--sign">
                                                      <ReactBootstrap.Button onClick={() => deleteMessage(groupRoom.conversationId, groupRoom.conversationMessageId)}>Delete</ReactBootstrap.Button>
                                                    </div>
                                                  </ReactBootstrap.Col>
                                                </ReactBootstrap.Row>
                                              </ReactBootstrap.Modal.Body>
                                            </ReactBootstrap.Modal>
                                          </div>
                                          <div>
                                            {
                                              submenuAlert
                                                ?
                                                <ReactBootstrap.Alert variant="success" transition={true} id="submenu-alert" className="text-center">
                                                  <ReactBootstrap.Alert.Heading>
                                                    {
                                                      deleteReplyMessageShow
                                                      ?
                                                        "Message deleted successful."
                                                      :
                                                        ""
                                                    }
                                                  </ReactBootstrap.Alert.Heading>
                                                </ReactBootstrap.Alert>
                                                :
                                                ""
                                            }
                                          </div>
                                        </div>
                                  :
                                    ""
                                :
                                  ""
                            }
                          </div>
                      }
                    </ReactBootstrap.Col>
                  )
                })
              }
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} ref={myRef} id="group-new-message" className={isMobile ? "tbs-group-mobile" : ""}>
          <ReactBootstrap.Container fluid={true}>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form-create-message">
                  <div className="tbs--form--main--error text-center"></div>
                  <div className="tbs--form--complete--post">
                    <Formik
                      initialValues={{
                        text: ''
                      }}
                      validationSchema={Schema}
                      onSubmit={values => {
                        console.log(values.text);
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form onSubmit={createNewMessage}>
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--message--complete--block--one">
                              <div className="text-left">
                                <Field as="textarea" className={`create-comment`} rows="1" name="text" id="textComment" placeholder="Type a message" value={text} onChange={handleChangeText}/>
                                <img className="tbs--comment--start tbs--comment--start--group" src={Smiley} alt="Smiley" onClick={() => setShowEmojis(!showEmojis)}/>
                                <img className="tbs--comment--end" src={IconPaperClipBlack} alt="Paper clip" onClick={() => setShowModalImageText(true)}/>
                                <div className="tbs--message--complete--block--two">
                                  <div className="text-right button--sign" id="conversation-message-submit">
                                    {
                                      /*
                                        <ReactBootstrap.Button type="submit">
                                          Post
                                        </ReactBootstrap.Button>
                                      */
                                    }
                                    <ReactBootstrap.Button type="submit">
                                      <img src={CommentButton} />
                                    </ReactBootstrap.Button>
                                  </div>
                                </div>
                                <ReactBootstrap.Modal
                                  show={showModalImageText}
                                  onHide={() => setShowModalImageText(false)}
                                  size="md"
                                  aria-labelledby="contained-modal-title-vcenter"
                                  centered
                                >
                                  <ReactBootstrap.Modal.Header closeButton>
                                    <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                                      Add image
                                    </ReactBootstrap.Modal.Title>
                                  </ReactBootstrap.Modal.Header>
                                  <ReactBootstrap.Modal.Body id="comment-with-image">
                                    <ReactBootstrap.Row>
                                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                        <div className="form-create-message-with-image">
                                          <div className="tbs--form--main--error text-center"></div>
                                          <div className="tbs--form--complete--post">
                                            <Formik
                                              initialValues={{
                                                text: '',
                                                imageId: imageId
                                              }}
                                              validationSchema={SchemaTwo}
                                              onSubmit={values => {
                                                console.log(values.text);
                                              }}
                                            >
                                              {({ errors, touched }) => (
                                                <Form onSubmit={createNewMessageWithImage}>
                                                  <label htmlFor="upload-image" className="new-image-upload">
                                                    <div className="tbs--default--image">
                                                      <img src={IconImage} alt="Post - Image" className="tbs--post--image--default"/>
                                                      <input type="file" id="upload-image" accept="image/*" onChange={previewFile} hidden/>
                                                      {/*<label className="tbs--upload--image upload-file-button">
                                                        UPLOAD AN IMAGE
                                                        <img src={UploadFile} alt="Upload file" id="upload-file"/>
                                                      </label>*/}
                                                    </div>
                                                  </label>
                                                  <div className="image-comment-submit">
                                                    {/*<Field as="textarea" className={`create-comment-with-image`} rows="1" name="textImage" id="textImage" placeholder="Type a message" value={textImage} onChange={handleChangeTextImage}/>*/}
                                                    <div className="text-center button--sign" id="conversation-image-message-submit">
                                                      {
                                                        /*
                                                          <ReactBootstrap.Button type="submit">
                                                            <img src={CommentButton} />
                                                          </ReactBootstrap.Button>
                                                        */
                                                      }
                                                      <ReactBootstrap.Button type="submit">
                                                        Post
                                                      </ReactBootstrap.Button>
                                                    </div>
                                                  </div>
                                                  <div className="error-text text-left">
                                                    {/*
                                                      errors.textImage && touched.textImage
                                                        ?
                                                        errors.textImage
                                                        :
                                                        null
                                                    */}
                                                    <ErrorMessage name="textImage">
                                                      {errors.textImage}
                                                    </ErrorMessage>
                                                  </div>
                                                </Form>
                                              )}
                                            </Formik>
                                          </div>
                                        </div>
                                      </ReactBootstrap.Col>
                                    </ReactBootstrap.Row>
                                  </ReactBootstrap.Modal.Body>
                                </ReactBootstrap.Modal>
                              </div>
                              {showEmojis && (
                                <div>
                                  <Picker onSelect={addEmoji} />
                                </div>
                              )}
                              <div className="error-text text-left">
                                {/*
                                  errors.text && touched.text
                                  ?
                                    errors.text
                                  :
                                    null
                                */}
                                <ErrorMessage name="text">
                                  {errors.text}
                                </ErrorMessage>
                              </div>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {
            showChatMessageAlert
            ?
              <ReactBootstrap.Alert variant="success" transition={true} id="new-comment-alert" className="text-center">
                <ReactBootstrap.Alert.Heading>
                  New comment successful created.
                </ReactBootstrap.Alert.Heading>
              </ReactBootstrap.Alert>
            :
              ""
          }
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  }
}

export default Channel;
