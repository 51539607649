import React, { useState, useEffect } from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import axios from 'axios';
import {Link, useRouteMatch} from "react-router-dom";
import API from "./../../config/config.json";

import IconAvatar from './../../assets/svg/avatar.svg';
import IconClose from "../../assets/icons/IconDelete.png";
import IconTip from "../../assets/icons/IconTip.png";
import AcceptSVG from "../../assets/svg/accept";

function Feeds() {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get url from all friends
  const urlFriends = API.URL + 'friends';
  const urlFriendsCounter = API.URL + 'friends-posts';

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Store and set the state value
  const [feedsProfile, setFeedsProfile] = useState([]);
  const [feedsCounter, setFeedsCounter] = useState([]);

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      urlFriends, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setFeedsProfile(result.data.items);

    const resultCounter = await axios.get(
      urlFriendsCounter, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setFeedsCounter(resultCounter.data.items);
  }, []);

  // Get 'Tip - Feeds' - Storage
  let createPostTip = localStorage.getItem("tbs-tip-feeds-" + userID);

  // Execute the API and get all informations
  useEffect( () => {
    if(createPostTip){
      document.getElementById("tip-feeds").classList.add("tips-disabled");
    } else {
      document.getElementById("tip-feeds").classList.remove("tips-disabled");
    }
  }, []);

  const tipClose = () => {
    localStorage.setItem("tbs-tip-feeds-" + userID, "Feeds tips accepted.");
    document.getElementById("tip-feeds").classList.add("tips-disabled");
  }

  useEffect(() => {
    const contentInfo = document.getElementById("no-data-feed");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading content";
      setTimeout( () => {
        contentInfo.innerHTML = "You don't have any posts available";
      }, 5000)
    }
  })

  if (!feedsProfile || feedsProfile.length === 0) {
    return (
      <ReactBootstrap.Row id="feeds">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-feeds">
          <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
            <ReactBootstrap.Row>
              {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--tips--close">
                <img src={IconClose} alt="Tips - Close" onClick={tipClose}/>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-right">
                <img src={IconTip} alt="Tips" />
              </ReactBootstrap.Col>*/}
              <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9}>
                <div className="tbs--tips--subheadline">
                  TIP
                </div>
                <div>
                  Your newsfeed shows posts from your network and from featured contacts.
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
                <div onClick={tipClose}>
                  <AcceptSVG/>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container">
            <ReactBootstrap.Row>
              <div className="tbs--headline">
                Posts from my network
              </div>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center" id="no-data-feed">
                You don't have any posts available
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  } else {
    return (
      <ReactBootstrap.Row id="feeds">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-feeds">
          <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
            <ReactBootstrap.Row>
              {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--tips--close">
                <img src={IconClose} alt="Tips - Close" onClick={tipClose}/>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-right">
                <img src={IconTip} alt="Tips" />
              </ReactBootstrap.Col>*/}
              <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9}>
                <div className="tbs--tips--subheadline">
                  TIP
                </div>
                <div>
                  Your newsfeed shows posts from your network and from featured contacts.
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
                <div onClick={tipClose}>
                  <AcceptSVG/>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container">
            <ReactBootstrap.Row>
              <div className="tbs--headline tbs--headline--feeds">
                Posts from my network
              </div>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row id="feeds-trusted-profile">
              {
                feedsCounter.slice(1).map((item, index) => {
                  return (
                    <ReactBootstrap.Col xs={6} sm={4} md={3} lg={3} xl={2} key={item.id} className="text-center">
                      <Link to={`${path}/profile/${item.user.id}/feedlist`}>
                        <div className="tbs--contact--image">
                          <div className="tbs--image--profile">
                            {
                              item.postCount !== 0
                                ?
                                <div className="tbs--feeds--counter">
                                  {item.postCount}
                                </div>
                                :
                                ""
                            }
                            <img src={item.user.image != null ? item.user.image : item.user.profileImage != null ? item.user.profileImage.url : IconAvatar} className={item.user.image != null ? "tbs--image--feeds" : "tbs--image--feeds--custom"} alt="Profile" />
                          </div>
                        </div>
                        <div className="tbs--contact--feeds">
                          {item.user.name}
                        </div>
                        <div className="tbs--contact--feeds tbs--contact--feeds--position">
                          {item.user.position}
                        </div>
                      </Link>
                    </ReactBootstrap.Col>
                  );
                })
              }
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  }
}

export default Feeds;
