import React from 'react';

const VersionSVG = () => {
  return(
    <svg id="Komponente_7_1" data-name="Komponente 7 – 1" xmlns="http://www.w3.org/2000/svg" width="64" height="80" viewBox="0 0 64 80">
      <g id="Gruppe_1198" transform="translate(0 48.079)">
        <g id="Rechteck_806" transform="translate(0 15.921)" fill="#141c27" stroke="#fefefe" stroke-width="0.2">
          <rect width="16" height="16" stroke="none"/>
          <rect x="0.1" y="0.1" width="15.8" height="15.8" fill="none"/>
        </g>
        <g id="Rechteck_807" transform="translate(16 15.921)" fill="#141c27" stroke="#fefefe" stroke-width="0.2">
          <rect width="16" height="16" stroke="none"/>
          <rect x="0.1" y="0.1" width="15.8" height="15.8" fill="none"/>
        </g>
        <g id="Rechteck_808" transform="translate(32 15.921)" fill="#141c27" stroke="#fefefe" stroke-width="0.2">
          <rect width="16" height="16" stroke="none"/>
          <rect x="0.1" y="0.1" width="15.8" height="15.8" fill="none"/>
        </g>
        <g id="Rechteck_809" transform="translate(16 -0.079)" fill="#141c27" stroke="#fefefe" stroke-width="0.2">
          <rect width="16" height="16" stroke="none"/>
          <rect x="0.1" y="0.1" width="15.8" height="15.8" fill="none"/>
        </g>
      </g>
      <g id="Gruppe_1199" transform="translate(32.053)">
        <g id="Rechteck_810" transform="translate(15.947) rotate(90)" fill="#141c27" stroke="#fefefe" stroke-width="0.2">
          <rect width="16" height="16" stroke="none"/>
          <rect x="0.1" y="0.1" width="15.8" height="15.8" fill="none"/>
        </g>
        <g id="Rechteck_811" transform="translate(15.947 16) rotate(90)" fill="#141c27" stroke="#fefefe" stroke-width="0.2">
          <rect width="16" height="16" stroke="none"/>
          <rect x="0.1" y="0.1" width="15.8" height="15.8" fill="none"/>
        </g>
        <g id="Rechteck_812" transform="translate(31.947 32) rotate(90)" fill="#141c27" stroke="#fefefe" stroke-width="0.2">
          <rect width="16" height="16" stroke="none"/>
          <rect x="0.1" y="0.1" width="15.8" height="15.8" fill="none"/>
        </g>
        <g id="Rechteck_814" transform="translate(31.947 16) rotate(90)" fill="#141c27" stroke="#fefefe" stroke-width="0.2">
          <rect width="16" height="16" stroke="none"/>
          <rect x="0.1" y="0.1" width="15.8" height="15.8" fill="none"/>
        </g>
      </g>
    </svg>

  )
}

export default VersionSVG;
