import React, {useEffect, useState, useRef} from 'react';
import {Switch, Route, NavLink, Link, useRouteMatch, useHistory, useLocation, Redirect, BrowserRouter as Router} from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import axios from "axios";
import moment from "moment";
//import {isMobile} from 'react-device-detect';
import {Form} from "formik";
import { useMediaQuery } from 'react-responsive';

import API from "./../config/config.json";

import 'mmenu-js/dist/mmenu'
import 'mmenu-js/dist/mmenu.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/js/all.js';

import { Navi, NaviList, NaviListItem, NaviListText, NaviListLink} from "./../components/mmenu";
import useOutsideClick from './../helper/useOutsideClick';

import Settings from "./dashboard/settings";
import Favourites from "./dashboard/favourites";
import Invitation from "./dashboard/invitations";
import Notification from "./dashboard/notification";
import Feedback from "./dashboard/feedback";
import Feature from "./dashboard/features";
import Friends from "./dashboard/friends";
import Report from "./dashboard/report";
import Trusted from "./dashboard/trusted";
import Profile from "./dashboard/profile";
import OtherProfile from "./dashboard/otherProfile";
import ReportUser from "./dashboard/otherProfile/reportUser";
import Feed from "./dashboard/feeds";
import Main from "./dashboard/main";
import Error from "./dashboard/error";
import FeedList from "./dashboard/feeds/feedList";
import PostFeed from "./dashboard/feeds/posts/postFeed";
import MyAccount from "./dashboard/settings/myAccount";
import MyProfile from "./dashboard/settings/myProfile";
import CreateInvitation from "./dashboard/invitations/createInvitation";
import UpdateFeed from "./dashboard/feeds/posts/updateFeed";
import CreatePost from "./dashboard/feeds/posts/createPost";
import ReportPost from "./dashboard/feeds/posts/reportPost";
import Chats from "./dashboard/chats";
import ChatChannel from "./dashboard/chats/channel";
import ReportChat from "./dashboard/chats/report";
import PublicationChat from "./dashboard/chats/publication";
import Groups from "./dashboard/groups";
import PublicGroups from "./dashboard/publicGroups";
import GroupChannel from "./dashboard/groups/channel";
import ReportGroup from "./dashboard/groups/report";
import Community from "./dashboard/groups/community";
import CommunityPublic from "./dashboard/groups/communityPublic";
import PublicationGroup from "./dashboard/groups/publication";
import SocialNetwork from "./dashboard/socialNetwork/socialNetwork";
import TBS from "./dashboard/tbs";
import Network from "./dashboard/network";
import FeedlistAll from "./dashboard/newsfeed/feedlist";
import MyCoins from "./dashboard/myCoins/myCoins";
import CreateCommunity from "./dashboard/groups/createCommunity";
import ActionMenu from "./dashboard/actionMenu";
import AllAwards from "./dashboard/socialNetwork/allAwards";
import VersionSVG from "../assets/svg/appInformationVersion";
import MobileApp from "./dashboard/mobileApp/mobileApp";

import MenuIcon from './../assets/icons/IconMenu.png';
import MenuIconBlue from './../assets/icons/IconMenuBlue.png';
import MenuSetting from './../assets/icons/IconSettings.png';
import MenuSettingBlue from './../assets/icons/IconSettingsBlue.png';
import MenuInfo from './../assets/icons/IconInfo.png';
import MenuInfoBlue from './../assets/icons/IconInfoBlue.png';
import MenuFAQ from './../assets/icons/IconFAQ.png';
import MenuDonation from './../assets/icons/IconDonations.png';
import MenuCommunityGuideline from './../assets/icons/IconCommunity.png';
import MenuPrivacy from './../assets/icons/IconPrivacy.png';
import MenuUserTerms from './../assets/icons/IconUserTerms.png';
import MenuFeedback from './../assets/icons/IconFeedback.png';
import MenuFeedbackBlue from './../assets/icons/IconFeedbackBlue.png';
import MenuBug from './../assets/icons/IconBug.png';
import MenuBugBlue from './../assets/icons/IconBugBlue.png';
import MenuFeeds from './../assets/icons/IconFeed.png';
import MenuFeedsBlue from './../assets/icons/IconFeedBlue.png';
import MenuBookmarks from './../assets/icons/IconBookmarks.png';
import MenuBookmarksBlue from './../assets/icons/IconBookmarksBlue.png';
import MenuFeature from './../assets/icons/IconFeatured.png';
import MenuFeatureBlue from './../assets/icons/IconFeaturedBlue.png';
import MenuTrusted from './../assets/icons/IconTrusted.png';
import MenuTrustedBlue from './../assets/icons/IconTrustedBlue.png';
import MenuNotification from './../assets/icons/IconNotifications.png';
import MenuNotificationBlue from './../assets/icons/IconNotificationsBlue.png';
import MenuInvitation from './../assets/icons/IconInvatations.png';
import MenuInvitationBlue from './../assets/icons/IconInvatationsBlue.png';
import MenuLogout from './../assets/icons/IconLogOut.png';
import IconResend from './../assets/icons/IconResend.png';
//import IconClear from './../assets/icons/IconDelete.png';
import IconAvatar from './../assets/svg/avatar.svg';
//import IconCheckmark from "../assets/icons/IconTickGrey.png";
import IconChat from "../assets/icons/IconChatsGrey.png";
import IconChatBlue from "../assets/icons/IconChatsBlue.png";
import IconGroup from "../assets/icons/IconGroupGrey.png";
import IconGroupBlue from "../assets/icons/IconGroupBlue.png";

/* SVG */
import IconClear from './../assets/svg/deleteBlack.svg';
import IconCheckmark from "../assets/svg/accept.svg";
import InvitationSVG from "../assets/svg/invitation";
import AvatarSVG from "../assets/svg/avatar.svg";
import AvatarWhite from "../assets/svg/avatarWhite.svg";
import NewsfeedSVG from "../assets/svg/newsfeed";
import NetworkSVG from "../assets/svg/network";
import CommunitiesSVG from "../assets/svg/communities";
import ChatSVG from "../assets/svg/chat";
import PostChatSVG from "../assets/svg/postChat";
import LogoutSVG from "../assets/svg/logout";
import InvitationWhiteSVG from "../assets/svg/invitationWhite";
import NotificationWhiteSVG from "../assets/svg/notificationWhite";
import LogoutWhiteSVG from "../assets/svg/logoutWhite";
import FavoritesWhiteSVG from "../assets/svg/favoritesWhite";
import BurgerNavSVG from "../assets/svg/burgerNav";
import CoinsSVG from "../assets/svg/coins";
import Coins from "../assets/svg/coins.svg";
import CoinsWhite from "../assets/svg/coinsWhite.svg";
import CoinsWhiteSVG from "../assets/svg/coinsWhite";
import SettingsSVG from "../assets/svg/settings";
import SettingsWhiteSVG from "../assets/svg/settingsWhite";
import InfoWhiteSVG from "../assets/svg/infoWhite";
import InfoSVG from "../assets/svg/info";
import FaqSVG from "../assets/svg/faq";
import FaqWhiteSVG from "../assets/svg/faqWhite";
import DonateWhiteSVG from "../assets/svg/donateWhite";
import DonateSVG from "../assets/svg/donate";
import CommunitySVG from "../assets/svg/community";
import CommunityWhiteSVG from "../assets/svg/communityWhite";
import PrivacyWhiteSVG from "../assets/svg/privacyWhite";
import PrivacySVG from "../assets/svg/privacy";
import NotificationSVG from "../assets/svg/notification";
import FavoritesSVG from "../assets/svg/favorites";
import TermsSVG from "../assets/svg/terms";
import FeedbackSVG from "../assets/svg/feedback";
import BugSVG from "../assets/svg/bug";
import ChatWhiteSVG from "../assets/svg/chatWhite";
import ChatWhite from "../assets/svg/chatWhite.svg";
import NewsfeedWhite from "../assets/svg/newsfeedWhite.svg";
import NetworkWhite from "../assets/svg/networkWhite.svg";
import CommunitiesWhite from "../assets/svg/communitiesWhite.svg";
//import InformationVersion from "../assets/svg/informationVersion.svg";
import CreatePostButton from "../assets/svg/createPost.svg";
import SendInviteButton from "../assets/svg/sendInvite.svg";
import CreateGroupButton from "../assets/svg/createGroup.svg";

function Dashboard() {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // ref specification
  const ref = useRef();

  // location
  const { pathname } = useLocation();

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Get url from your profile
  const urlProfile = API.URL + 'users';

  // Get url from your notifications
  const urlNotification = API.URL + 'notifications/';

  // Get url from your invitations
  const urlInvitationSent = API.URL + 'invitations?type=SENT';
  const urlInvitationReceived = API.URL + 'invitations?type=RECEIVED';

  // Get url from chat
  const urlChatRoomGlobal = API.URL + "conversations?isNew=true&type=SINGLE";
  const urlGroupRoomGlobal = API.URL + "conversations?isNew=true&type=GROUP";

  // Get url from build informations
  const urlBuildInformations = API.URL + "configurations";

  // Store and set the state value
  const [profileData, setProfileData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [invitationSentData, setInvitationSentData] = useState([]);
  const [invitationReceivedData, setInvitationReceivedData] = useState([]);
  const [buildData, setBuildData] = useState([]);
  const [isRedirect, setIsRedirect] = useState(false);
  //const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isInvitationOpen, setIsInvitationOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [showDeleteSent, setShowDeleteSent] = useState(false);
  const [showRejectSent, setShowRejectSent] = useState(false);
  const [showAcceptSent, setShowAcceptSent] = useState(false);
  const [showResendSent, setShowResendSent] = useState(false);
  const [showMyCoinsInfo, setShowMyCoinsInfo] = useState(false);
  const [toggleInvitationImage, setToggleInvitationImage] = useState(true);
  const [toggleNotificationImage, setToggleNotificationImage] = useState(true);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [lastChat, setLastChat] = useState(false);
  const [lastGroup, setLastGroup] = useState(false);
  const [isBiggerThanMobile, setIsBiggerThanMobile] = useState(window.innerWidth > 767);
  const [isBiggerThanTablet, setIsBiggerThanTablet] = useState(window.innerWidth > 991);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' })

  // Main container for history
  let history = useHistory();

  // Make a redirect if value is true
  useEffect(() => {
    (() => {
      if(isRedirect) {
        history.push("/dashboard");
      }
    })()
  }, [isRedirect]);

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      urlProfile, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setProfileData(result.data);

    const resultNotification = await axios.get(
      urlNotification, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    let notificationResult = resultNotification.data.items.slice(0,2);

    setNotificationData(notificationResult);

    const resultInvitationSent = await axios.get(
      urlInvitationSent, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    let invitationResultSent = resultInvitationSent.data.items.slice(0,2);

    setInvitationSentData(invitationResultSent);

    const resultInvitationReceived = await axios.get(
      urlInvitationReceived, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    let invitationResultReceived = resultInvitationReceived.data.items.slice(0,2);

    setInvitationReceivedData(invitationResultReceived);
  }, []);

  useEffect(async () => {
    const resultUser = await axios.get(
      urlGroupRoomGlobal, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    const allGroups = resultUser.data.items;

    setLastGroup(allGroups[0].id)

    const resultUserChat = await axios.get(
      urlChatRoomGlobal, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    const allChats = resultUserChat.data.items;

    setLastChat(allChats[0].id)

    const resultBuildData = await axios.get(
      urlBuildInformations, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setBuildData(resultBuildData.data.data)
  }, [])

  useEffect(() => {
    const titleElement = document.querySelector(".mm-navbar__title span");
    const mainElement = document.getElementById("root");

    if(typeof(titleElement) != 'undefined' && titleElement != null) {
      document.addEventListener("click", function(event){
        console.log("True");
        if (event.target.closest('.mm-navbar__title span')){
          mainElement.click();
        }
      });
    }
  }, []);

  useEffect(() => {
    let isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));

    if(typeof window.ontouchstart !== 'undefined' || isTouch){
      document.addEventListener('touchend', function (event) {
        if (event.target.closest('#burger-menu')){

          setIsMenuOpen(!isMenuOpen);

          return;
        }

        if (event.target.matches('#menu nav') || event.target.matches('#menu div') || event.target.matches('#menu ul') || event.target.matches('#menu li') || event.target.matches('#menu a') || event.target.matches('#menu img') || event.target.matches('#menu span'))  {
          setIsMenuOpen(true);

          return;
        } else {
          setIsMenuOpen(false);

          return;
        }

        /*if (!event.target.matches('#invitation-overlay')) {

          toggleInvitation()

          return;
        }

        if (event.target.matches('#notification-overlay')) {

          toggleNotification()

          return;
        }*/

        /*setIsMenuOpen(false)
        setIsNotificationOpen(false)
        setIsInvitationOpen(false)*/
      })
    } else {
      document.addEventListener('click', function (event) {
        if (event.target.closest('#burger-menu')){

          setIsMenuOpen(!isMenuOpen);

          return;
        }

        if (event.target.matches('#menu nav') || event.target.matches('#menu div') || event.target.matches('#menu ul') || event.target.matches('#menu li') || event.target.matches('#menu a') || event.target.matches('#menu img') || event.target.matches('#menu span')) {
          setIsMenuOpen(true);

          return;
        } else {
          setIsMenuOpen(false);

          return;
        }

        /*if (!event.target.matches('#invitation-overlay')) {

          toggleInvitation()

          return;
        }

        if (event.target.matches('#notification-overlay')) {

          toggleNotification()

          return;
        }*/

        /*setIsMenuOpen(false)
        setIsNotificationOpen(false)
        setIsInvitationOpen(false)*/
      })
    }
  },[])

  // Logout validation method
  const validationLogout = () => {

    localStorage.removeItem("tbs-token");
    localStorage.removeItem("tbs-data");
    localStorage.removeItem("public-group-number");

    setIsRedirect(true);

    if(!localStorage.getItem("tbs-token")){
      window.location.replace("/");
    }

  };

  // fetch data and validate
  const validationDeleteInvitationSent = (id) => {
    // URL for clear sent invitation
    const url = API.URL + 'invitations/' + id + '/clear';

    axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);
          setShowDeleteSent(true);

          setTimeout(() => {
            setShow(false);
          }, 1000);

          setTimeout(async () => {
            const resultInvitationSent = await axios.get(
              urlInvitationSent, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            let invitationResultSent = resultInvitationSent.data.items.slice(0,2);

            setInvitationSentData(invitationResultSent);
          }, 2000);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const validationDeclineInvitation = (id) => {
    // URL for decline received invitation
    const url = API.URL + 'invitations/' + id + '/reject';

    axios
      .put(url, '', {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);
          setShowRejectSent(true);

          setTimeout(() => {
            setShow(false);
          }, 1000);

          setTimeout(async () => {
            const resultInvitationReceived = await axios.get(
              urlInvitationReceived, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            let invitationResultReceived = resultInvitationReceived.data.items.slice(0,2);

            setInvitationReceivedData(invitationResultReceived);
          }, 2000);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const validationAcceptInvitation = (id, userId) => {
    // URL for accept received invitation
    const url = API.URL + 'invitations/' + id + '/accept';

    // Post value for validation
    const data = {
      userId: userId
    };

    axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);
          setShowAcceptSent(true);

          setTimeout(() => {
            setShow(false);
          }, 1000);

          setTimeout(async () => {
            const resultInvitationReceived = await axios.get(
              urlInvitationReceived, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            let invitationResultReceived = resultInvitationReceived.data.items.slice(0,2);

            setInvitationReceivedData(invitationResultReceived);
          }, 2000);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const validationResendInvitationSent = (id, text, email) => {
    // URL for clear and sent (new) invitation
    const url = API.URL + 'invitations/' + id + '/clear';
    const urlResend = API.URL + 'invitations';

    axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          console.log("Invitation successful deleted.")
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })

    // Post value for validation
    const data = {
      email: email,
      publicText: text
    };

    axios
      .post(urlResend, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);
          setShowResendSent(true);

          setTimeout(() => {
            setShow(false);
          }, 1000);

          setTimeout( async () => {
            const resultInvitationSent = await axios.get(
              urlInvitationSent, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            let invitationResultSent = resultInvitationSent.data.items.slice(0,2);

            setInvitationSentData(invitationResultSent);
          }, 2000)
          console.log("Invitation successful resend.");
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const hideDropdown = (elementId) => {
    if(elementId == "invitation-overlay"){
      let elem = document.getElementById(elementId);
      let tabOne = document.querySelector("#" + elementId + " nav a:first-child");

      tabOne.click();

      elem.click();
    } else {
      let elem = document.getElementById(elementId);

      elem.click();
    }
  }

  /*const toggleInvitation = () => {
    const invitationIcon = document.querySelector("#invitation-overlay img");

    if(window.location.href.indexOf("/dashboard/invitations") > -1 || window.location.href.indexOf("/dashboard/new-invitation") > -1){
      invitationIcon.src = MenuInvitationBlue;

      setToggleInvitationImage(true);
    } else {
      if(toggleInvitationImage){
        invitationIcon.src = MenuInvitationBlue;

        setToggleInvitationImage(false);
      } else {
        invitationIcon.src = MenuInvitation;

        setToggleInvitationImage(true);
      }
    }
  }*/

  /*const toggleNotification = () => {
    const notificationIcon = document.querySelector("#notification-overlay img");

    if(window.location.href.indexOf("/dashboard/notifications") > -1){
      notificationIcon.src = MenuNotificationBlue;

      setToggleNotificationImage(true);
    } else {
      if(toggleNotificationImage){
        notificationIcon.src = MenuNotificationBlue;

        setToggleNotificationImage(false);
      } else {
        notificationIcon.src = MenuNotification;

        setToggleNotificationImage(true);
      }
    }
  }*/

  /*const newCommunityCreate = () => {
    window.location.href = `${path}/communities/${lastGroup}`;

    if(window.location.href.indexOf("/communities") >= -1){
      console.log("step 1")
      const mainElement = document.getElementById("createCommunityButton");
      if(typeof(mainElement) != 'undefined' && mainElement != null){
        console.log("step 2")
        setTimeout(() => {
          mainElement.click()
        },5000)
      }
    }
  }*/

  const disableLink = (e) => {
    e.preventDefault();
  }

  return (
    <ReactBootstrap.Container fluid={true} id="dashboard">
      <ReactBootstrap.Row>
        <ReactBootstrap.Col md={3} lg={3} xl={3} id="sidebar" className="d-none d-sm-none d-md-block">
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center sidebar--menu--headline">
            <Link to={"/dashboard/newsfeed"} id="homepage-dashboard">
              <div className="link--custom--headline">
                <h1>meoh TBS</h1>
              </div>
            </Link>
          </ReactBootstrap.Col>
          {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="sidebar--menu--item text-left">
            <div className="tbs--quick--action">
              <Link to="/dashboard/profile/post/create">
                <img src={CreatePostButton} alt="Create Post"/>
              </Link>
            </div>
            <div className="tbs--quick--action">
              <Link to="/dashboard/new-invitation">
                <img src={SendInviteButton} alt="Send Invite"/>
              </Link>
            </div>
            <div className="tbs--quick--action">
              <Link to={`${path}/communities/create`} id="new-community-action">
                <img src={CreateGroupButton} alt="Create Group"/>
              </Link>
            </div>
          </ReactBootstrap.Col>*/}
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="sidebar--menu--item text-left">
            <ol className="tbs--sidebar--menu">
              <li>
                <NavLink to={`${path}/profile`} isActive={() => ['/dashboard/profile', '/dashboard/profile/post/create', '/dashboard/profile/network'].includes(pathname)} className="link--navi--style" activeClassName="tbs--active--link">
                  {
                    profileData.profileImage == null
                    ?
                      <img src={AvatarSVG} className="sidebar--menu--image tbs--image" alt="Profile" />
                    :
                      <img src={profileData.profileImage.url} className="sidebar--menu--image tbs--image" alt="Profile" />
                  }
                  <span className={window.location.pathname.indexOf('/edit') > -1 ? "tbs--active--link" : ""}>
                    {/*profileData.name*/}
                    My profile
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`${path}/newsfeed`} className="link--navi--style" activeClassName="tbs--active--link">
                  {/*<img src={window.location.href.indexOf("/dashboard/newsfeed") > -1 ? MenuFeedsBlue : MenuFeeds} className="sidebar--menu--image" alt="Feeds" />*/}
                  <NewsfeedSVG/>
                  <span>
                    My newsfeed
                  </span>
                </NavLink>
              </li>
              {/*
              <li>
                <NavLink to={`${path}/feeds`} className="link--navi--style" activeClassName="tbs--active--link">
                  <img src={window.location.href.indexOf("/dashboard/feeds") > -1 ? MenuFeedsBlue : MenuFeeds} className="sidebar--menu--image" alt="Feeds" />
                  <span>Feeds</span>
                </NavLink>
              </li>
              */}
              <li>
                <NavLink to={`${path}/network`} isActive={() => ['/dashboard/network'].includes(pathname)} className="link--navi--style" activeClassName="tbs--active--link">
                  {/*<img src={((window.location.pathname.indexOf('/dashboard/network') > -1 || (!isNaN(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)) && (window.location.pathname.indexOf('/profile/') > -1 && !window.location.pathname.indexOf("feeds/")) && !window.location.pathname.indexOf('/feeds/') > -1) || window.location.pathname.indexOf('/friends/') > -1) && (!window.location.pathname.indexOf('/dashboard/profile/post/create') > -1 || !window.location.pathname.indexOf('/edit') > -1)) ? MenuTrustedBlue : MenuTrusted} alt="Network" className="sidebar--menu--image"/>*/}
                  <NetworkSVG/>
                  <span className={((window.location.pathname.indexOf('/dashboard/network') > -1 || (!isNaN(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)) && (window.location.pathname.indexOf('/profile/') > -1 && !window.location.pathname.indexOf("feeds/")) && !window.location.pathname.indexOf('/feeds/') > -1) || window.location.pathname.indexOf('/friends/') > -1) && (!window.location.pathname.indexOf('/dashboard/profile/post/create') > -1 || !window.location.pathname.indexOf('/edit') > -1)) ? "nav-is-active" : ""}>
                    My network
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={isTablet ? `${path}/chats` : `${path}/chats/${lastChat}`} className="link--navi--style" activeClassName="tbs--active--link">
                  {/*<img src={window.location.href.indexOf("/dashboard/chats") > -1 ? IconChatBlue : IconChat} className="sidebar--menu--image" alt="Chats" />*/}
                  <ChatSVG/>
                  <span>
                    My chats
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={isTablet ? `${path}/communities` : `${path}/communities/${lastGroup}`} className="link--navi--style" activeClassName="tbs--active--link">
                  {/*<img src={window.location.href.indexOf("/dashboard/communities") > -1 ? IconGroupBlue : IconGroup} className="sidebar--menu--image" alt="Groups" />*/}
                  <CommunitiesSVG/>
                  <span>
                    My groups
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`${path}/public-groups`} className="link--navi--style" activeClassName="tbs--active--link">
                  {/*<img src={window.location.href.indexOf("/dashboard/communities") > -1 ? IconGroupBlue : IconGroup} className="sidebar--menu--image" alt="Groups" />*/}
                  <CommunitiesSVG/>
                  <span>
                    Explore public groups
                  </span>
                </NavLink>
              </li>
              <li>
                <Link className="link--navi--style bottom--menu--item" to={window.location.pathname} onClick={() => setShowLogoutModal(true)}>
                  {/*<img src={MenuLogout} className="sidebar--menu--image" alt="Logout" />*/}
                  <LogoutSVG/>
                  <span>Logout</span>
                </Link>
              </li>
            </ol>
          </ReactBootstrap.Col>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={9} lg={9} xl={9} id="mainContent">
          <ReactBootstrap.Container fluid={true}>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <ReactBootstrap.Navbar expand="xl" id="mainNavi" className="nav ml-auto fixed-top">
                  <ReactBootstrap.Navbar.Brand className="mr-auto d-block d-sm-block d-md-none d-lg-none d-xl-none">
                    <Link to={"/dashboard/newsfeed"}>
                      <div className="logo--headline">
                        meoh TBS
                      </div>
                    </Link>
                  </ReactBootstrap.Navbar.Brand>
                  <Link to={window.location.pathname} className="d-block d-sm-block d-md-none d-lg-none d-xl-none" onClick={() => setShowLogoutModal(true)}>
                    {/*<img src={MenuLogout} alt="Logout" />*/}
                    <LogoutWhiteSVG/>
                  </Link>
                  <NavLink to={`${path}/favourites`} className="d-none d-sm-none d-md-block" isActive={() => ['/dashboard/favourites'].includes(pathname)} activeClassName="tbs--active--link">
                    {/*<img src={window.location.href.indexOf("/dashboard/favourites") > -1 ? MenuBookmarksBlue : MenuBookmarks} alt="Favourites" />*/}
                    <FavoritesWhiteSVG/>
                  </NavLink>
                  <div id="invitation-overlay">
                    <ReactBootstrap.DropdownButton
                      menuAlign="right"
                      title={
                        /*<img src={(window.location.href.indexOf("/dashboard/invitations") > -1 || window.location.href.indexOf("/dashboard/new-invitation") > -1) || isInvitationOpen ? MenuInvitationBlue : MenuInvitation} alt="Invitations" />*/
                        <div className="tbs--notification--header--block">
                          <InvitationWhiteSVG/>
                          {
                            invitationReceivedData.length > 0
                            ?
                              <span className="tbs--notification--red--dot">
                                Red
                              </span>
                            :
                              ""
                          }
                        </div>
                      }
                      id="dropdown-menu-invitation"
                      //onToggle={() => toggleInvitation()}
                    >
                      <ReactBootstrap.Tabs defaultActiveKey="received" id="invitation--tabs" fill transition={false}>
                        <ReactBootstrap.Tab eventKey="received" title="Received">
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              {
                                show
                                  ?
                                  <ReactBootstrap.Alert variant="success" transition={true} id="invitation-alert" className="text-center">
                                    <ReactBootstrap.Alert.Heading>
                                      {
                                        showAcceptSent
                                          ?
                                          "Invitation accepted."
                                          :
                                          showRejectSent
                                            ?
                                            "Invitation declined."
                                            :
                                            ""
                                      }
                                    </ReactBootstrap.Alert.Heading>
                                  </ReactBootstrap.Alert>
                                  :
                                  ""
                              }
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                          {
                            !invitationReceivedData || invitationReceivedData.length === 0
                              ?
                              <div className="text-center">
                                You don't have any invitations (Received)
                              </div>
                              :
                              invitationReceivedData.map((invitationItemReceived) => {
                                return (
                                  <ReactBootstrap.Row className="dropdown--element">
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                                      {moment(invitationItemReceived.updatedAt).format('MMMM DD YYYY, hh:mm:A')}
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                          <img src={invitationItemReceived.user.profileImage != null ? invitationItemReceived.user.profileImage.url : IconAvatar} alt="Other Profile - Image" className="invitation--image"/>
                                        </ReactBootstrap.Col>
                                        <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10} className="invitation--contact">
                                          <div>
                                            {invitationItemReceived.user.name}
                                          </div>
                                          <div>
                                            {invitationItemReceived.user.email}
                                          </div>
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="invitation--details text-center">
                                      <div onClick={() => validationAcceptInvitation(invitationItemReceived.id,invitationItemReceived.userId)}>
                                        <img src={IconCheckmark} alt="Accept" className="invitation--image--custom"/>
                                        <span>
                                          Accept
                                        </span>
                                      </div>
                                      <div onClick={() => validationDeclineInvitation(invitationItemReceived.id)}>
                                        <img src={IconClear} alt="Decline" className="invitation--image--custom"/>
                                        <span>
                                          Decline
                                        </span>
                                      </div>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                )
                              })
                          }
                        </ReactBootstrap.Tab>
                        {/*<ReactBootstrap.Tab eventKey="sent" title="Sent">
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              {
                                show
                                  ?
                                  <ReactBootstrap.Alert variant="success" transition={true} id="invitation-alert" className="text-center">
                                    <ReactBootstrap.Alert.Heading>
                                      {
                                        showDeleteSent
                                          ?
                                            "Invitation cleared from list."
                                          :
                                            showResendSent
                                            ?
                                              "Invitation resent successfully."
                                            :
                                              ""
                                      }
                                    </ReactBootstrap.Alert.Heading>
                                  </ReactBootstrap.Alert>
                                  :
                                  ""
                              }
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                          {
                            !invitationSentData || invitationSentData.length === 0
                              ?
                              <div className="text-center">
                                You don't have any invitations (Sent)
                              </div>
                              :
                              invitationSentData.map((invitationItemSent) => {
                                return (
                                  <ReactBootstrap.Row className="dropdown--element">
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                                      {moment(invitationItemSent.updatedAt).format('MMMM DD YYYY, hh:mm:A')}
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                          <img src={invitationItemSent.friend != null ? invitationItemSent.friend.profileImage != null ? invitationItemSent.friend.profileImage.url : IconAvatar : IconAvatar} alt="Other Profile - Image" className="invitation--image"/>
                                        </ReactBootstrap.Col>
                                        <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10} className="invitation--contact">
                                          <div>
                                            {invitationItemSent.friend == null ? invitationItemSent.name : invitationItemSent.friend.name}
                                          </div>
                                          <div>
                                            {invitationItemSent.friend == null ? invitationItemSent.email : invitationItemSent.friend.email}
                                          </div>
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="invitation--details text-center">
                                      <div onClick={() => validationResendInvitationSent(invitationItemSent.id, invitationItemSent.publicText, invitationItemSent.friend == null ? invitationItemSent.email : invitationItemSent.friend.email)}>
                                        <img src={IconResend} alt="Resend"/>
                                        <span>
                                        Resend
                                      </span>
                                      </div>
                                      <div onClick={() => validationDeleteInvitationSent(invitationItemSent.id)}>
                                        <img src={IconClear} alt="Clear"/>
                                        <span>
                                          Clear
                                        </span>
                                      </div>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                )
                              })
                          }
                        </ReactBootstrap.Tab>*/}
                      </ReactBootstrap.Tabs>
                      <ReactBootstrap.Dropdown.Divider />
                      <div className="dropdown-item text-center">
                        <Link onClick={() => hideDropdown("invitation-overlay")} to={`${path}/invitations`}>View all invitations</Link>
                      </div>
                      <ReactBootstrap.Dropdown.Divider />
                      <div className="dropdown-item text-center send--invitation--new">
                        <Link onClick={() => hideDropdown("invitation-overlay")} to={`${path}/new-invitation`}>Send invitation</Link>
                      </div>
                    </ReactBootstrap.DropdownButton>
                  </div>
                  <div id="notification-overlay">
                    <ReactBootstrap.DropdownButton
                      menuAlign="right"
                      title={
                        /*<img src={window.location.href.indexOf("/dashboard/notifications") > -1 || isNotificationOpen ? MenuNotificationBlue : MenuNotification} alt="Notifications" />*/
                        <NotificationWhiteSVG/>
                      }
                      id="dropdown-menu-notifications"
                      //onToggle={() => toggleNotification()}
                    >
                      {
                        !notificationData || notificationData.length === 0
                        ?
                          <div className="dropdown-item text-center">
                            You don't have any notifications
                          </div>
                        :
                          notificationData.map((notificationItem) => {
                            if(notificationItem.type === "INVITATION_ACCEPT"){
                              return (
                                <Link onClick={() => hideDropdown("notification-overlay")} to={`/dashboard/profile/${notificationItem.actionOwnerId}`}>
                                  <ReactBootstrap.Row className="dropdown--element">
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                                      {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                          <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                        </ReactBootstrap.Col>
                                        <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10} className="notification--contact">
                                          <div>
                                            {notificationItem.actionOwner != null ? notificationItem.actionOwner != null ? notificationItem.actionOwner.name : "" : ""}
                                          </div>
                                          <div>
                                            {notificationItem.text != null ? notificationItem.text : ""}
                                          </div>
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                </Link>
                              )
                            }
                            if(notificationItem.type === "CONVERSATION_REQUEST"){
                              return (
                                <Link onClick={() => hideDropdown("notification-overlay")} to={`/dashboard/communities`}>
                                  <ReactBootstrap.Row className="dropdown--element">
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                                      {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                          <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                        </ReactBootstrap.Col>
                                        <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10} className="notification--contact">
                                          <div>
                                            {notificationItem.actionOwner != null ? notificationItem.actionOwner != null ? notificationItem.actionOwner.name : "" : ""}
                                          </div>
                                          <div>
                                            {notificationItem.text != null ? notificationItem.text : ""}
                                          </div>
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                </Link>
                              )
                            }
                            if(notificationItem.type === "AWARD" || notificationItem.type === "AWARD_COMMENT" || notificationItem.type === "AWARD_USER" || notificationItem.type === "AWARD_POST"){
                              return (
                                <Link onClick={() => hideDropdown("notification-overlay")} to={notificationItem.post != null ? `/dashboard/feeds/profile/${notificationItem.post.user.id}/feedlist/post/${notificationItem.postId}` : "#"}>
                                  <ReactBootstrap.Row className="dropdown--element">
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                                      {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                          <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                        </ReactBootstrap.Col>
                                        <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10} className="notification--contact">
                                          <div>
                                            {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                          </div>
                                          <div>
                                            {notificationItem.text != null ? notificationItem.text : ""}
                                          </div>
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                </Link>
                              )
                            }
                            if(notificationItem.type === "COMMENT" || notificationItem.type === "COMMENT_REPLY"){
                              return (
                                <Link onClick={() => hideDropdown("notification-overlay")} to={notificationItem.post != null ? `/dashboard/feeds/profile/${notificationItem.post.user.id}/feedlist/post/${notificationItem.postId}` : "#"}>
                                  <ReactBootstrap.Row className="dropdown--element">
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                                      {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                          <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                        </ReactBootstrap.Col>
                                        <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10} className="notification--contact">
                                          <div>
                                            {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                          </div>
                                          <div>
                                            {notificationItem.text != null ? notificationItem.text : ""}
                                          </div>
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                </Link>
                              )
                            }
                            if(notificationItem.type === "BOOKMARK"){
                              return (
                                <Link onClick={() => hideDropdown("notification-overlay")} to={notificationItem.post != null ? `/dashboard/feeds/profile/${notificationItem.post.user.id}/feedlist/post/${notificationItem.postId}` : "#"}>
                                  <ReactBootstrap.Row className="dropdown--element">
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                                      {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                          <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                        </ReactBootstrap.Col>
                                        <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10} className="notification--contact">
                                          <div>
                                            {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                          </div>
                                          <div>
                                            {notificationItem.text != null ? notificationItem.text : ""}
                                          </div>
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                </Link>
                              )
                            }
                            if(notificationItem.type === "FORWARD"){
                              return (
                                <Link onClick={() => hideDropdown("notification-overlay")} to={notificationItem.post != null ? `/dashboard/feeds/profile/${notificationItem.post.user.id}/feedlist/post/${notificationItem.postId}` : "#"}>
                                  <ReactBootstrap.Row className="dropdown--element">
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                                      {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                          <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                        </ReactBootstrap.Col>
                                        <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10} className="notification--contact">
                                          <div>
                                            {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                          </div>
                                          <div>
                                            {notificationItem.text != null ? notificationItem.text : ""}
                                          </div>
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                </Link>
                              )
                            }
                            if(notificationItem.type === "INVITATION"){
                              return (
                                <Link onClick={() => hideDropdown("notification-overlay")} to={`/dashboard/invitations`}>
                                  <ReactBootstrap.Row className="dropdown--element">
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                                      {moment(notificationItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                          <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                        </ReactBootstrap.Col>
                                        <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10} className="notification--contact">
                                          <div>
                                            {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                          </div>
                                          <div>
                                            {notificationItem.text != null ? notificationItem.text : ""}
                                          </div>
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                </Link>
                              )
                            }
                            if(notificationItem.type === "FEATURE"){
                              return (
                                <ReactBootstrap.Row className="dropdown--element">
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                                    {moment(notificationItem.updatedAt).format('MMMM DD YYYY, hh:mm:A')}
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ReactBootstrap.Row>
                                      <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                        <img src={notificationItem.actionOwner != null ? notificationItem.actionOwner.profileImage != null ? notificationItem.actionOwner.profileImage.url : notificationItem.actionOwner.image != null ? notificationItem.actionOwner.image: IconAvatar : IconAvatar} alt="Other Profile - Image" className="notification--image"/>
                                      </ReactBootstrap.Col>
                                      <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10} className="notification--contact">
                                        <div>
                                          {notificationItem.actionOwner != null ? notificationItem.actionOwner.name : ""}
                                        </div>
                                        <div>
                                          {notificationItem.text != null ? notificationItem.text : ""}
                                        </div>
                                      </ReactBootstrap.Col>
                                    </ReactBootstrap.Row>
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                              )
                            }
                          })
                      }
                      {
                        notificationData.length > 0
                        ?
                          <ReactBootstrap.Dropdown.Divider />
                        :
                          ""
                      }
                      <div className="dropdown-item text-center">
                        <Link onClick={() => hideDropdown("notification-overlay")} to={`${path}/notifications`}>View all notifications</Link>
                      </div>
                    </ReactBootstrap.DropdownButton>
                  </div>
                  <div>
                    <a id="burger-menu" href="#menu">
                      {/*
                        isMenuOpen
                        ?
                          <img src={MenuIconBlue} alt="Menu"/>
                        :
                          <img src={MenuIcon} alt="Menu"/>
                      */}
                      <BurgerNavSVG/>
                    </a>
                  </div>
                  <Navi
                    id="menu"
                    options={{
                      "extensions": [
                        "pagedim-black",
                        "position-right"
                      ],
                      "navbars": [
                        {
                          "position": "top",
                          "content": [
                            "breadcrumbs",
                            "close"
                          ]
                        }
                      ],
                      onClick: { close: true }
                    }}
                  >
                    <NaviList>
                      <NaviListItem>
                        <NavLink className="dropdown-item link--navi--style" to={`${url}/invitations`} isActive={() => ['/dashboard/invitations'].includes(pathname)} activeClassName="tbs--active--link">
                          {/*<img src={window.location.href.indexOf("/dashboard/invitations") > -1 || window.location.href.indexOf("/dashboard/new-invitation") > -1 ? MenuInvitationBlue : MenuInvitation} alt="Invitation" />*/}
                          <InvitationSVG/>
                          Invitation
                        </NavLink>
                      </NaviListItem>
                      <NaviListItem>
                        <NavLink className="dropdown-item link--navi--style" to={`${url}/notifications`} isActive={() => ['/dashboard/notifications'].includes(pathname)} activeClassName="tbs--active--link">
                          {/*<img src={window.location.href.indexOf("/dashboard/notifications") > -1 ? MenuNotificationBlue : MenuNotification} alt="Notification" />*/}
                          <NotificationSVG/>
                          Notification
                        </NavLink>
                      </NaviListItem>
                      <NaviListItem>
                        <NavLink className="dropdown-item link--navi--style" to={`${url}/favourites`} isActive={() => ['/dashboard/favourites'].includes(pathname)} activeClassName="tbs--active--link">
                          {/*<img src={window.location.href.indexOf("/dashboard/favourites") > -1 ? MenuBookmarksBlue : MenuBookmarks} alt="Favourites" />*/}
                          <FavoritesSVG/>
                          Favourites
                        </NavLink>
                      </NaviListItem>
                      {/*<NaviListItem>
                        <NavLink className="dropdown-item link--navi--style" to={`${url}/coins`} activeClassName="tbs--active--link" id="my-coins">
                          {<i className="fas fa-coins fa-2x"></i>
                          <span className={window.location.href.indexOf("/dashboard/coins") > -1 ? "my-coins-active" : ""}>
                            My Coins ({profileData.coins})
                          </span>}
                          <CoinsSVG/>
                          My Coins ({profileData.coins})
                        </NavLink>
                      </NaviListItem>*/}
                      {
                        isMobile
                        ?
                          <NaviListItem>
                            <NavLink className="dropdown-item link--navi--style" to={`${url}/public-groups`} activeClassName="tbs--active--link" id="public-group">
                              <CommunitiesSVG/>
                              Explore public groups
                            </NavLink>
                          </NaviListItem>
                        :
                          ""
                      }
                      <NaviListItem>
                        <NavLink className="dropdown-item link--navi--style" to={`${url}/settings`} activeClassName="tbs--active--link">
                          {/*<img src={window.location.href.indexOf("/dashboard/settings") > -1 ? MenuSettingBlue : MenuSetting} alt="Settings" />*/}
                          <SettingsSVG/>
                          Settings
                        </NavLink>
                      </NaviListItem>
                      <NaviListItem>
                        <Link className="dropdown-item link--navi--style" to={{ pathname: "https://www.meoh.io/tbs" }} target="_blank">
                          {/*<img src={MenuInfo} alt="About" />*/}
                          <FaqSVG/>
                          About
                        </Link>
                      </NaviListItem>
                      <NaviListItem>
                        <Link className="dropdown-item link--navi--style" to={{ pathname: "https://www.meoh.io/faq" }} target="_blank">
                          {/*<img src={MenuFAQ} alt="FAQs" />*/}
                          <InfoSVG/>
                          FAQs
                        </Link>
                      </NaviListItem>
                      <NaviListItem>
                        <Link className="dropdown-item link--navi--style" to={{ pathname: "https://www.meoh.io/donate" }} target="_blank">
                          {/*<img src={MenuDonation} alt="Donate" />*/}
                          <DonateSVG/>
                          Donate
                        </Link>
                      </NaviListItem>
                      <NaviListItem>
                        <Link className="dropdown-item link--navi--style" to={{ pathname: "https://www.meoh.io/guidelines" }} target="_blank">
                          {/*<img src={MenuCommunityGuideline} alt="Community Guidelines" />*/}
                          <CommunitySVG/>
                          Community Guidelines
                        </Link>
                      </NaviListItem>
                      <NaviListItem>
                        <Link className="dropdown-item link--navi--style" to={{ pathname: "https://www.meoh.io/privacy" }} target="_blank">
                          {/*<img src={MenuPrivacy} className="custom--image--one" alt="Privacy Policy" />*/}
                          <PrivacySVG/>
                          Privacy Policy
                        </Link>
                      </NaviListItem>
                      <NaviListItem>
                        <Link className="dropdown-item link--navi--style" to={{ pathname: "https://www.meoh.io/terms" }} target="_blank">
                          {/*<img src={MenuUserTerms} alt="User Terms" />*/}
                          <TermsSVG/>
                          User Terms
                        </Link>
                      </NaviListItem>
                      <NaviListItem>
                        <NavLink className="dropdown-item link--navi--style" to={`${url}/feedback`} isActive={() => ['/dashboard/feedback'].includes(pathname)} activeClassName="tbs--active--link">
                          {/*<img src={window.location.href.indexOf("/dashboard/feedback") > -1 ? MenuFeedbackBlue : MenuFeedback} className="custom--image--two" alt="Feedback" />*/}
                          <FeedbackSVG/>
                          Feedback
                        </NavLink>
                      </NaviListItem>
                      <NaviListItem>
                        <NavLink className="dropdown-item link--navi--style" to={`${url}/report`} isActive={() => ['/dashboard/report'].includes(pathname)} activeClassName="tbs--active--link">
                          {/*<img src={window.location.href.indexOf("/dashboard/report") > -1 ? MenuBugBlue : MenuBug} alt="Bug Report" />*/}
                          <BugSVG/>
                          Bug Report
                        </NavLink>
                      </NaviListItem>
                      {/*
                        <NaviListItem>
                          <NavLink className="dropdown-item link--navi--style" to={`${url}/tbs`} isActive={() => ['/dashboard/tbs'].includes(pathname)} activeClassName="tbs--active--link">
                            //<img src={window.location.href.indexOf("/dashboard/tbs") > -1 ? MenuInfoBlue : MenuInfo} alt="TBS - Info" />
                            <InfoSVG/>
                            Trust Building System (TBS)
                          </NavLink>
                        </NaviListItem>
                      */}
                      {
                        isMobile
                        ?
                          <NaviListItem>
                            <NavLink className="dropdown-item link--navi--style" to={`${url}/mobile-app`} isActive={() => ['/dashboard/mobile-app'].includes(pathname)} activeClassName="tbs--active--link">
                              {/*<img src={window.location.href.indexOf("/dashboard/mobile-app") > -1 ? MenuBugBlue : MenuBug} alt="Mobile App" />*/}
                              <i className="fas fa-mobile-alt fa-2x"></i>
                              Download mobile app
                            </NavLink>
                          </NaviListItem>
                        :
                          ""
                      }
                      <NaviListItem>
                        <NaviListText className="dropdown-item link--navi--style" to={`#`} onClick={disableLink}>
                          <VersionSVG/>
                          Build information {buildData.android}
                        </NaviListText>
                      </NaviListItem>
                    </NaviList>
                  </Navi>
                </ReactBootstrap.Navbar>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="dashboardContent">
                <Switch>
                  <Redirect
                    from={`${path}/profile/` + userID}
                    to={`${path}/profile`}
                  />
                  <Redirect
                    from={`${path}/profile/:id/friends/` + userID}
                    to={`${path}/profile`}
                  />
                  <Redirect
                    from={`${path}/profile/`+ userID + `/report`}
                    to={`${path}/profile`}
                  />
                  <Route exact path={`${path}/mobile-app`}>
                    <MobileApp/>
                  </Route>
                  <Route exact path={`${path}/coins`}>
                    <MyCoins/>
                  </Route>
                  <Route strict path={`/dashboard/newsfeed/post/:id/report`} component={ReportPost} />
                  <Route strict path={`/dashboard/newsfeed/post/:id`} component={PostFeed} />
                  <Route strict path={`/dashboard/communities/:id/publication`} component={PublicationGroup} />
                  <Route strict path={`/dashboard/communities/:id/report`} component={ReportGroup} />
                  <Route strict path={`/dashboard/communities/:id/community`} component={Community} />
                  <Route strict path={`/dashboard/communities/:id/channel`} component={GroupChannel} />
                  <Route strict path={`/dashboard/communities/create`} component={CreateCommunity} />
                  <Route strict path={`/dashboard/communities`} component={Groups} />
                  <Route strict path={`/dashboard/public-groups/:id/community`} component={CommunityPublic} />
                  <Route strict path={`/dashboard/public-groups`} component={PublicGroups} />
                  <Route strict path={`/dashboard/chats/:id/publication`} component={PublicationChat} />
                  <Route strict path={`/dashboard/chats/:id/report`} component={ReportChat} />
                  <Route strict path={`/dashboard/chats/:id/channel`} component={ChatChannel} />
                  <Route strict path={`/dashboard/chats`} component={Chats} />
                  <Route strict path={`/dashboard/profile/:id/report`} component={ReportUser} />
                  <Route strict path={`/dashboard/profile/post/create`} component={CreatePost} />
                  <Route strict path={`/dashboard/profile/post/:id/edit`} component={UpdateFeed} />
                  <Route strict path={`${path}/feeds/profile/:id/feedlist/post/:id/report`} component={ReportPost} />
                  <Route strict path={`${path}/feeds/profile/:id/feedlist/post/:id`} component={PostFeed} />
                  <Route strict path={`${path}/feeds/profile/:id/feedlist`} component={FeedList} />
                  <Route path={`${path}/feeds/profile/:id/*`} render={props => (
                    <Redirect to={`${path}/feeds/profile/${props.match.params.id}/feedlist`} />
                  )}/>
                  <Route strict path={`/dashboard/feeds/feedlist/post/:id/report`} component={ReportPost} />
                  <Route strict path={`/dashboard/feeds/feedlist/post/:id`} component={PostFeed} />
                  <Route strict exact path={`${path}/profile/:id/friends/:id`} component={Friends} />
                  <Route path={`${path}/profile/:id/friends/*`} render={props => (
                    <Redirect to={`${path}/profile/${props.match.params.id}`} />
                  )}/>
                  <Route exact path={`${path}/profile/network`}>
                    <SocialNetwork />
                  </Route>
                  <Route strict path={`${path}/profile/:id`} component={OtherProfile} />
                  <Route exact path={`${path}/profile`}>
                    <Profile />
                  </Route>
                  <Route exact path={`${path}/settings/my-profile`}>
                    <MyProfile />
                  </Route>
                  <Route exact path={`${path}/settings/my-account`}>
                    <MyAccount />
                  </Route>
                  <Route exact path={`${path}/settings`}>
                    <Settings />
                  </Route>
                  <Route exact path={`${path}/network`}>
                    <Network />
                  </Route>
                  {
                    /*
                      <Route exact path={`${path}/trusted`}>
                        <Trusted />
                      </Route>
                      <Route exact path={`${path}/features`}>
                        <Feature />
                      </Route>
                    */
                  }
                  <Route path={`${path}/feeds/*`} render={() => (
                    <Redirect to={`${path}/feeds`} />
                  )}/>
                  <Route exact path={`${path}/feeds`}>
                    <Feed />
                  </Route>
                  <Route exact path={`${path}/report`}>
                    <Report />
                  </Route>
                  <Route exact path={`${path}/feedback`}>
                    <Feedback />
                  </Route>
                  <Route exact path={`${path}/favourites`}>
                    <Favourites />
                  </Route>
                  <Route exact path={`${path}/new-invitation`}>
                    <CreateInvitation />
                  </Route>
                  <Route exact path={`${path}/invitations`}>
                    <Invitation />
                  </Route>
                  <Route exact path={`${path}/notifications`}>
                    <Notification />
                  </Route>
                  <Route exact path={`${path}/newsfeed`}>
                    <FeedlistAll />
                  </Route>
                  <Route exact path={`${path}/tbs`}>
                    <TBS />
                  </Route>
                  <Route path={`${path}/*`} render={() => (
                    <Redirect to={`${path}/newsfeed`} />
                  )}/>
                  <Route exact path={`${path}/*`}>
                    <Error />
                  </Route>
                  <Route exact path={`${path}`} render={() => (
                    <Redirect to={`${path}/newsfeed`} />
                  )}/>
                </Switch>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={12} className="tbs--main--block--bottom d-block d-sm-block d-md-none d-lg-none d-xl-none bg-light">
                <ReactBootstrap.Row id="menuBottom" className="fixed-bottom bg-light">
                  <ReactBootstrap.Col className="w20-flex">
                    <NavLink to={`${path}/network`} isActive={() => ['/dashboard/network'].includes(pathname)} activeClassName="tbs--active--link">
                      {/*<img src={((window.location.pathname.indexOf('/dashboard/network') > -1 || (!isNaN(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)) && (window.location.pathname.indexOf('/profile/') > -1 && !window.location.pathname.indexOf("feeds/")) && !window.location.pathname.indexOf('/feeds/') > -1) || window.location.pathname.indexOf('/friends/') > -1) && (!window.location.pathname.indexOf('/dashboard/profile/post/create') > -1 || !window.location.pathname.indexOf('/edit') > -1)) ? MenuTrustedBlue : MenuTrusted} alt="Network" />*/}
                      <img src={NetworkWhite} alt="Network" />
                    </NavLink>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col className="w20-flex">
                    <NavLink to={`${url}/newsfeed`} activeClassName="tbs--active--link">
                      {/*<img src={window.location.href.indexOf("/dashboard/newsfeed") > -1 ? MenuFeedsBlue : MenuFeeds} alt="Feeds" />*/}
                      <img src={NewsfeedWhite} alt="Feeds" />
                    </NavLink>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col className="w20-flex">
                    <NavLink to={`${path}/communities/`} activeClassName="tbs--active--link">
                      {/*<img src={window.location.pathname.indexOf('/dashboard/groups') > -1 ? IconGroupBlue : IconGroup} alt="Groups" />*/}
                      <img src={CommunitiesWhite} alt="Groups" />
                    </NavLink>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col className="w20-flex">
                    <NavLink to={`${path}/chats/`} activeClassName="tbs--active--link">
                      {/*<img src={window.location.pathname.indexOf('/dashboard/chats') > -1 ? IconChatBlue : IconChat} alt="Chat" />*/}
                      <img src={ChatWhite} alt="Chat" />
                    </NavLink>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col className="w20-flex">
                    <NavLink to={`${path}/profile`} activeClassName="tbs--active--link">
                      {
                        profileData.profileImage == null
                        ?
                          <img src={AvatarWhite} className="tbs--image" alt="Profile" />
                        :
                          <img src={profileData.profileImage.url} className="tbs--image" alt="Profile" />
                      }
                    </NavLink>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter-logout"
        centered
      >
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-logout">
            Log out
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Modal.Body id="logout-modal">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              Are you sure you want to Log out?
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="text-center button--sign">
                <ReactBootstrap.Button type="submit" onClick={() => setShowLogoutModal(false)}>No</ReactBootstrap.Button>
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="text-center button--sign">
                <ReactBootstrap.Button type="submit" onClick={validationLogout}>Yes</ReactBootstrap.Button>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Modal.Body>
      </ReactBootstrap.Modal>
      {/*
        (isMobile && (window.location.href.indexOf("/dashboard/profile") > -1 || window.location.href.indexOf("/dashboard/newsfeed") > -1 || window.location.href.indexOf("/dashboard/network") > -1 || window.location.href.indexOf("/dashboard/communities") > -1 || window.location.href.indexOf("/dashboard/chats") > -1))
        ?
          <ReactBootstrap.Row>
            <ActionMenu/>
          </ReactBootstrap.Row>
        :
          ""
      */}
    </ReactBootstrap.Container>
  );
}

export default Dashboard;
