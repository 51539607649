import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import axios from 'axios';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
/* import { yupResolver } from "@hookform/resolvers/yup"; */

import MobileAppBanner from "../components/mobileAppBanner";

import API from "./../config/config.json";

/* SVG */
import Logo from "./../assets/svg/logo.svg";
import LogoDark from "./../assets/svg/logoDark.svg";

function ForgotPassword() {

  // Store and set the state value
  const [email, setEmail] = useState('');
  const [isRedirect, setIsRedirect] = useState(false);

  // Main container for history
  let history = useHistory();

  // Make a redirect if value is true
  useEffect(() => {
    (() => {
      if(isRedirect) {
        history.push("/reset-password");
      }
    })()
  }, [isRedirect]);

  const handleChangeEmail = e => setEmail(e.target.value);

  // Login validation method
  const validationPasswordForgotten = (e) => {
    e.preventDefault();

    // URL for the user authentication in the login area
    const url = API.URL + 'forgot-password';

    // Post value for validation
    const data = {
      email: email
    };

    // fetch data and validate
    axios
      .post(url, data)
      .then((response) => {
        if(response.status === 200){
          localStorage.setItem("tbs-reset-password", email);

          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("email")[0].classList.remove("error-field");

          setIsRedirect(true);

          if(localStorage.getItem("tbs-reset-password")){
            window.location.replace("/reset-password");
          }
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        const regexEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/;
        if(!email || email === null || !regexEmail.test(String(email).toLowerCase())){
          document.getElementsByClassName("error-text")[0].style.display = "block";
          document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a valid e-mail.";
          document.getElementsByClassName("email")[0].classList.add("error-field");
        } else {
          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("email")[0].classList.remove("error-field");
        }

        document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Invalid credentials.\nThis account does not exist.";
        document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
        document.getElementsByClassName("email")[0].classList.add("error-field");

        console.log(e)
      })

  };

  const passwordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid e-mail')
      .required('E-Mail is required')
  });

  return (
    <ReactBootstrap.Container fluid={true}>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col md={5} lg={7} xl={7} id="forgotPassword" className="d-none d-sm-none d-md-block">
          <div className="tbs--main--block">
            <div className="tbs--main--title">
              {/*<img src={Logo}/>*/}
              meoh TBS
            </div>
            <div className="tbs--main--subtitle text-center">
              Leverage your trust network
            </div>
          </div>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={7} lg={5} xl={5} id="forgotPasswordForm">
          <div className="tbs--main--block--two d-block d-sm-block d-md-none d-lg-none d-xl-none">
            <div className="tbs--main--title">
              {/*<img src={LogoDark}/>*/}
              meoh TBS
            </div>
            {/*<div className="tbs--main--subtitle text-center">
              Trust Building System
            </div>*/}
          </div>
          <div className="tbs--form--block">
            <div className="tbs--form--title tbs--form--title--forgot--password">
              FORGOT PASSWORD
            </div>
            <div className="tbs--form--main--error text-center"></div>
            <div className="tbs--form--complete">
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={passwordSchema}
                onSubmit={values => {
                  console.log(values.text);
                }}
              >
                {({ errors, touched }) => (
                  <Form onSubmit={validationPasswordForgotten}>
                    <div>
                      <label htmlFor="passwordForgotten">
                        PASSWORD
                      </label>
                    </div>
                    <div>
                      <Field type="email" className={`email`} name="passwordForgotten" id="passwordForgotten" placeholder="Enter E-Mail" onChange={handleChangeEmail}/>
                    </div>
                    <div className="error-text">
                      {/*
                        errors.email && touched.email
                          ?
                          errors.email
                          :
                          null
                      */}
                      <ErrorMessage name="email">
                        {errors.email}
                      </ErrorMessage>
                    </div>
                    <div className="text-center button--sign">
                      <ReactBootstrap.Button type="submit">Send</ReactBootstrap.Button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="text-center">
                <p><Link to="/login" className="link--custom--style">Login</Link></p>
              </div>
              <div className="link--custom--public">
                <p>
                  <Link to="/public-groups" className="link--custom--style">
                    <div className="text-center button--sign">
                      <ReactBootstrap.Button type="submit">Explore public groups</ReactBootstrap.Button>
                    </div>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <MobileAppBanner/>
    </ReactBootstrap.Container>
  );
}

export default ForgotPassword;
