import React from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';

/* SVG */
import Logo from "./../assets/svg/logo.svg";
import LogoDark from "./../assets/svg/logoDark.svg";

function Error() {
  return (
    <ReactBootstrap.Container fluid={true} id="errorNotLogin">
      <ReactBootstrap.Row>
        <ReactBootstrap.Col md={5} lg={7} xl={7} id="login" className="d-none d-sm-none d-md-block">
          <div className="tbs--main--block">
            <div className="tbs--main--title">
              {/*<img src={Logo}/>*/}
              meoh TBS
            </div>
            <div className="tbs--main--subtitle text-center">
              Leverage your trust network
            </div>
          </div>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={7} lg={5} xl={5} id="loginForm">
          <div className="tbs--main--block--two d-block d-sm-block d-md-none d-lg-none d-xl-none">
            <div className="tbs--main--title">
              {/*<img src={LogoDark}/>*/}
              meoh TBS
            </div>
            {/*<div className="tbs--main--subtitle text-center">
              Trust Building System
            </div>*/}
          </div>
          <div className="tbs--form--block">
            <div className="tbs--form--title">
              <h1>Page not found.</h1>
            </div>
            <div>
              The page you are looking for does not exist.
            </div>
            <div>
              But you can click here below to go back to the login.
            </div>
            <div>
              <Link to="/login" className="link--custom--style">Login</Link>
            </div>
          </div>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    </ReactBootstrap.Container>
  );
}

export default Error;
