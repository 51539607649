import React from 'react';

const DonateSVG = () => {
  return(
    <svg id="Icon_awesome-hand-holding-heart" data-name="Icon awesome-hand-holding-heart" xmlns="http://www.w3.org/2000/svg" width="80" height="71.11" viewBox="0 0 80 71.11">
      <path id="Icon_awesome-hand-holding-heart-2" data-name="Icon awesome-hand-holding-heart" d="M38.234,34.791a2.419,2.419,0,0,0,3.541,0L56.9,18.931a11.512,11.512,0,0,0-.778-16.5A10.557,10.557,0,0,0,41.553,3.5L40,5.126,38.456,3.515A10.545,10.545,0,0,0,23.888,2.432a11.524,11.524,0,0,0-.778,16.5ZM78.51,45.568a4.6,4.6,0,0,0-5.916,0L59.761,55.832a8.842,8.842,0,0,1-5.555,1.944H37.776a2.222,2.222,0,1,1,0-4.444H48.65a4.624,4.624,0,0,0,4.625-3.694,4.449,4.449,0,0,0-4.389-5.194H26.665A16.346,16.346,0,0,0,16.374,48.1L9.916,53.332H2.222A2.229,2.229,0,0,0,0,55.554V68.887a2.229,2.229,0,0,0,2.222,2.222H51.775a8.9,8.9,0,0,0,5.555-1.944l21-16.8A4.442,4.442,0,0,0,78.51,45.568Z" transform="translate(0 0.001)" fill="#141c27"/>
    </svg>
  )
}

export default DonateSVG;
