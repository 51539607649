import React, {useEffect, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';

import Logo from './../../assets/images/tbs.jpeg';

function TBS() {
  return (
    <ReactBootstrap.Row id="tbs-detail">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--logo--description">
              <img src={Logo} alt="TBS - Logo" />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--header--description text-center">
              <h1>
                Trust Building System (TBS)
              </h1>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--block--description">
              <h2>
                AN INNOVATIVE SERVICE OF THE GOTRIPLE PLATFORM
              </h2>
              <div>
                The Trust Building System (TBS) is one of the innovative services of the <a href="https://www.gotriple.eu/" target="_blank">GOTRIPLE</a> platform. It is a human curated referral system whose purpose is to help SSH researchers connect to reliable partners through trusted contacts. Examples include finding the right:
              </div>
              <div>
                <ul>
                  <li>
                    partner e.g. coordinator for Horizon Europe consortiums
                  </li>
                  <li>
                    expert e.g. PhD student or policy maker
                  </li>
                  <li>
                    end-user e.g. workshop participant or pilot community
                  </li>
                </ul>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--block--description">
              <h2>
                Trust Building System
              </h2>
              <div>
                The TBS is a social platform informed by research in social sciences that aims to mimic the way cohesive communities meet and interact. It focuses on building trusted realtionships instead of on promoting unfettered connectivity. Architectural designs include:
              </div>
              <div>
                <ul>
                  <li>
                    human-sized networks (&lt; Dunbar number)
                  </li>
                  <li>
                    human-curated relationships (algorithm-free)
                  </li>
                  <li>
                    fractal architecture to bring trusted interactions to scale
                  </li>
                  <li>
                    trusted intermediaries everywhere: invite-only platform and triangulation of new introductions
                  </li>
                  <li>
                    coopetition model to foster emulation while taking shared interests into account
                  </li>
                </ul>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--block--description">
              <h2>
                FUNCTIONALITIES
              </h2>
              <div>
                To help our users reach out to reliable partners, basic functionalities include:
              </div>
              <div>
                <ul>
                  <li>
                    inviting and featuring selected users for increased discoverability
                  </li>
                  <li>
                    personalized introductions to help users reach their next step
                  </li>
                  <li>
                    posting (audio) publications and privately or collectively solving users’ requests
                  </li>
                  <li>
                    creating private groups around specific topics of interest
                  </li>
                  <li>
                    assessing positive contributions to enhance cooperation profiles
                  </li>
                </ul>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default TBS;
