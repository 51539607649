import React from 'react';

const BurgerNavSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="40" viewBox="0 0 60 40">
      <path id="Icon_metro-menu" data-name="Icon metro-menu" d="M6.427,50.283h60V43.616h-60v6.667Zm0-16.667h60V26.95h-60v6.667Zm0-23.333v6.667h60V10.283h-60Z" transform="translate(-6.427 -10.283)" fill="#fefefe"/>
    </svg>
  )
}

export default BurgerNavSVG;
