import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';

/* SVG */
import MenuAction from '../../assets/svg/actionMenu.svg';
import MenuActionOpen from '../../assets/svg/actionMenuOpen.svg';
import MenuActionPost from '../../assets/svg/actionPost.svg';
import MenuActionInvitation from '../../assets/svg/actionInvitation.svg';
import MenuActionCommunity from '../../assets/svg/actionCommunity.svg';
import MenuActionPostText from '../../assets/svg/actionPostText.svg';
import MenuActionInvitationText from '../../assets/svg/actionInvitationText.svg';
import MenuActionCommunityText from '../../assets/svg/actionCommunityText.svg';

function ActionMenu() {
  // Store and set the state value
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const toggleMenu = () => {
    if(isOpenMenu){
      setIsOpenMenu(false)
    } else {
      setIsOpenMenu(true)
    }
    //document.getElementById("main-menu-action").classList.toggle("action-menu-rotation")
  }
  return (
    <div id="action-menu">
      {
        isOpenMenu
        ?
          <div id="all-menu-action">
            <div className="menu-action-element">
              <span>
                <img src={MenuActionPostText} alt="Post"/>
              </span>
              <Link to="/dashboard/profile/post/create">
                <img src={MenuActionPost} alt="Post"/>
              </Link>
            </div>
            <div className="menu-action-element">
              <span>
                <img src={MenuActionInvitationText} alt="Invitation"/>
              </span>
              <Link to="/dashboard/new-invitation">
                <img src={MenuActionInvitation} alt="Invitation"/>
              </Link>
            </div>
            <div className="menu-action-element">
              <span>
                <img src={MenuActionCommunityText} alt="Community"/>
              </span>
              <Link to="/dashboard/communities/create">
                <img src={MenuActionCommunity} alt="Community"/>
              </Link>
            </div>
          </div>
        :
          ""
      }
      <div onClick={toggleMenu} id="main-menu-action">
        <img src={isOpenMenu ? MenuActionOpen : MenuAction} alt="Action Menu"/>
      </div>
    </div>
  );
}

export default ActionMenu;
