import React from 'react';

const InvitationWhiteSVG = () => {
  return(
    <svg id="Icon_material-mail" data-name="Icon material-mail" xmlns="http://www.w3.org/2000/svg" width="80" height="64" viewBox="0 0 80 64">
      <path id="Icon_material-mail-2" data-name="Icon material-mail" d="M75,6H11a7.989,7.989,0,0,0-7.96,8L3,62a8.024,8.024,0,0,0,8,8H75a8.024,8.024,0,0,0,8-8V14A8.024,8.024,0,0,0,75,6Zm0,16L43,42,11,22V14L43,34,75,14Z" transform="translate(-3 -6)" fill="#fff"/>
    </svg>
  )
}

export default InvitationWhiteSVG;
