import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {useHistory, useRouteMatch} from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import API from "../../../config/config.json";

function ReportUser(props) {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get the params
  const { match: { params } } = props;

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");

  // Store and set the state value
  const [description, setDescription] = useState('');
  const [show, setShow] = useState(false);
  //const [isRedirect, setIsRedirect] = useState(false);

  // Main container for history
  let history = useHistory();

  const handleChangeDescription = e => setDescription(e.target.value);

  // Create post validation method
  const reportUser = (e) => {
    e.preventDefault();

    // URL for report user
    const url = API.URL + 'users/' + params.id + '/report';

    // Post value for validation
    const data = {
      text: description
    };

    // fetch data and validate
    if(description != ""){
      axios
        .post(url, data, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        })
        .then((response) => {
          if(response.status === 200){
            document.getElementsByClassName("error-text")[0].style.visibility = "hidden";
            document.getElementsByClassName("error-text")[0].innerHTML = "";
            document.getElementsByClassName("create-description")[0].classList.remove("error-field");

            setShow(true);

            document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "";
            document.getElementsByClassName("tbs--form--main--error")[0].style.visibility = "hidden";

            setTimeout(() => {
              setShow(false);
            }, 1000);

            setTimeout(() => {
              window.location.replace("/dashboard/profile/" + params.id);
            }, 1200);

          } else {
            document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Message can't be empty.";
            document.getElementsByClassName("tbs--form--main--error")[0].style.visibility = "show";
            document.getElementsByClassName("create-description")[0].classList.add("error-field");
            document.getElementsByClassName("error-text")[0].style.visibility = "show";
            document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a message.";
          }
        })
        .catch(e => {
          document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Message can't be empty.";
          document.getElementsByClassName("tbs--form--main--error")[0].style.visibility = "show";
          document.getElementsByClassName("create-description")[0].classList.add("error-field");
          document.getElementsByClassName("error-text")[0].style.visibility = "show";
          document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a message.";
          console.log(e)
        })
    } else {
      document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Message can't be empty.";
      document.getElementsByClassName("tbs--form--main--error")[0].style.visibility = "show";
      document.getElementsByClassName("create-description")[0].classList.add("error-field");
      document.getElementsByClassName("error-text")[0].style.visibility = "show";
      document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a message.";
      console.log("Error");
    }
  }

  const Schema = Yup.object().shape({
    description: Yup.string()
      .required('Please enter a message.')
  });

  return (
    <ReactBootstrap.Row id="report-user">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true}>
          <ReactBootstrap.Row>
            <div className="tbs--headline tbs--headline--bug">
              Bug report
            </div>
            <div className="tbs--divider--title tbs--report--submenu--divider">
              <hr></hr>
            </div>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--update--post">
              <div className="tbs--report--message">
                Something inappropriate?
              </div>
              <div className="form-update-post">
                <div className="tbs--form--main--error text-center"></div>
                <div className="tbs--form--complete--post">
                  <Formik
                    initialValues={{
                      description: '',
                    }}
                    validationSchema={Schema}
                    onSubmit={values => {
                      console.log(values.text);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form onSubmit={reportUser}>
                        <div>
                          <Field as="textarea" className={`create-description`} rows="10" name="description" id="description" placeholder="Report your issue here" value={description} onChange={handleChangeDescription}/>
                        </div>
                        <div className="error-text">
                          {/*
                            errors.description && touched.description
                              ?
                              errors.description
                              :
                              null
                          */}
                          <ErrorMessage name="description">
                            {errors.description}
                          </ErrorMessage>
                        </div>
                        <div className="text-center button--sign">
                          <ReactBootstrap.Button type="submit">Report</ReactBootstrap.Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          show
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="report-user-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>Message for this user has been reported.</ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default ReportUser;
