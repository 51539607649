import React from 'react';

const CommunitySVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="70" viewBox="0 0 80 70">
      <path id="Icon_awesome-heart" data-name="Icon awesome-heart" d="M72.234,7.034c-8.562-7.3-21.3-5.984-29.156,2.125L40,12.331,36.922,9.159C29.079,1.05,16.329-.263,7.767,7.034A22.436,22.436,0,0,0,6.22,39.518L36.453,70.736a4.9,4.9,0,0,0,7.078,0L73.765,39.518A22.422,22.422,0,0,0,72.234,7.034Z" transform="translate(0.001 -2.248)" fill="#141c27"/>
    </svg>
  )
}

export default CommunitySVG;
