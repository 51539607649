import React from 'react';

const PostActivitySVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63">
      <g id="Gruppe_1925" data-name="Gruppe 1925" transform="translate(-1168 -3106)">
        <g id="Gruppe_1924" data-name="Gruppe 1924">
          <circle id="Ellipse_146" data-name="Ellipse 146" cx="31.5" cy="31.5" r="31.5" transform="translate(1168 3106)" fill="#374D6C"/>
          <path id="Icon_feather-activity" data-name="Icon feather-activity" d="M36.333,19.5H29.667l-5,15-10-30-5,15H3" transform="translate(1179.5 3118)" fill="none" stroke="#fefefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        </g>
      </g>
    </svg>
  )
}

export default PostActivitySVG;
