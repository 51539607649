import React from 'react';

const AccountSVG = () => {
  return(
    <svg id="Komponente_29_5" data-name="Komponente 29 – 5" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="240" height="119" viewBox="0 0 240 119">
      <defs>
        <filter id="Icon_ionic-md-key" x="28.143" y="40.132" width="68" height="43" filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur"/>
          <feFlood flood-opacity="0.161"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <rect id="Rechteck_732" data-name="Rechteck 732" width="240" height="119" rx="26" fill="#141c27"/>
      <path id="Pfad_145" data-name="Pfad 145" d="M26,0H93a26,26,0,0,1,26,26V93a26,26,0,0,1-26,26H26A26,26,0,0,1,0,93V26A26,26,0,0,1,26,0Z" fill="#141c27"/>
      <text id="Go_" data-name="Go!" transform="translate(175.406 77.563)" fill="#fefefe" font-size="50" font-family="Ubuntu-Light, Ubuntu" font-weight="300"><tspan x="-37.85" y="0">Go!</tspan></text>
      <g id="Gruppe_1096" data-name="Gruppe 1096" transform="translate(-1852 -2946)">
        <path id="Pfad_237" data-name="Pfad 237" d="M26,0H76c14.359,0,26,26,26,26V76a26,26,0,0,1-26,26H26A26,26,0,0,1,0,76V26A26,26,0,0,1,26,0Z" transform="translate(1860 2954)" fill="#fefefe"/>
        <g transform="matrix(1, 0, 0, 1, 1852, 2946)" filter="url(#Icon_ionic-md-key)">
          <path id="Icon_ionic-md-key-2" data-name="Icon ionic-md-key" d="M29.192,19.341a13.177,13.177,0,0,1,12.043-8.654,12.233,12.233,0,0,1,12.139,12.5A12.434,12.434,0,0,1,41,35.688a12.848,12.848,0,0,1-11.8-8.654H16.837v7.692H8.531V27.034H3.375V19.341Zm11.731,7.993a4.147,4.147,0,1,0-4.147-4.147A4.155,4.155,0,0,0,40.923,27.334Z" transform="translate(30.77 35.44)" fill="#141c27"/>
        </g>
      </g>
    </svg>
  )
}

export default AccountSVG;
