import React from 'react';

const BugSVG = () => {
  return(
    <svg id="Komponente_6_1" data-name="Komponente 6 – 1" xmlns="http://www.w3.org/2000/svg" width="80" height="58" viewBox="0 0 80 58">
      <rect id="Rechteck_743" data-name="Rechteck 743" width="7" height="7" transform="translate(15 15)" fill="#141c27"/>
      <rect id="Rechteck_744" data-name="Rechteck 744" width="7" height="7" transform="translate(22 15)" fill="#141c27"/>
      <rect id="Rechteck_745" data-name="Rechteck 745" width="7" height="7" transform="translate(29 15)" fill="#141c27"/>
      <rect id="Rechteck_746" data-name="Rechteck 746" width="7" height="7" transform="translate(29 22)" fill="#141c27"/>
      <rect id="Rechteck_747" data-name="Rechteck 747" width="8" height="7" transform="translate(36 15)" fill="#141c27"/>
      <rect id="Rechteck_748" data-name="Rechteck 748" width="7" height="7" transform="translate(44 15)" fill="#141c27"/>
      <rect id="Rechteck_749" data-name="Rechteck 749" width="7" height="7" transform="translate(51 15)" fill="#141c27"/>
      <rect id="Rechteck_750" data-name="Rechteck 750" width="7" height="7" transform="translate(58 15)" fill="#141c27"/>
      <rect id="Rechteck_751" data-name="Rechteck 751" width="7" height="8" transform="translate(51 7)" fill="#141c27"/>
      <rect id="Rechteck_752" data-name="Rechteck 752" width="7" height="8" transform="translate(22 7)" fill="#141c27"/>
      <rect id="Rechteck_753" data-name="Rechteck 753" width="7" height="7" transform="translate(15)" fill="#141c27"/>
      <rect id="Rechteck_754" data-name="Rechteck 754" width="7" height="7" transform="translate(58)" fill="#141c27"/>
      <rect id="Rechteck_755" data-name="Rechteck 755" width="8" height="7" transform="translate(7 22)" fill="#141c27"/>
      <rect id="Rechteck_756" data-name="Rechteck 756" width="7" height="7" transform="translate(15 22)" fill="#141c27"/>
      <rect id="Rechteck_757" data-name="Rechteck 757" width="8" height="7" transform="translate(36 22)" fill="#141c27"/>
      <rect id="Rechteck_758" data-name="Rechteck 758" width="7" height="7" transform="translate(44 22)" fill="#141c27"/>
      <rect id="Rechteck_759" data-name="Rechteck 759" width="7" height="7" transform="translate(58 22)" fill="#141c27"/>
      <rect id="Rechteck_760" data-name="Rechteck 760" width="8" height="7" transform="translate(65 22)" fill="#141c27"/>
      <rect id="Rechteck_761" data-name="Rechteck 761" width="8" height="7" transform="translate(65 29)" fill="#141c27"/>
      <rect id="Rechteck_762" data-name="Rechteck 762" width="7" height="7" transform="translate(73 29)" fill="#141c27"/>
      <rect id="Rechteck_763" data-name="Rechteck 763" width="7" height="7" transform="translate(58 29)" fill="#141c27"/>
      <rect id="Rechteck_764" data-name="Rechteck 764" width="7" height="7" transform="translate(51 29)" fill="#141c27"/>
      <rect id="Rechteck_765" data-name="Rechteck 765" width="7" height="7" transform="translate(44 29)" fill="#141c27"/>
      <rect id="Rechteck_766" data-name="Rechteck 766" width="8" height="7" transform="translate(36 29)" fill="#141c27"/>
      <rect id="Rechteck_767" data-name="Rechteck 767" width="7" height="7" transform="translate(29 29)" fill="#141c27"/>
      <rect id="Rechteck_768" data-name="Rechteck 768" width="7" height="7" transform="translate(22 29)" fill="#141c27"/>
      <rect id="Rechteck_769" data-name="Rechteck 769" width="7" height="7" transform="translate(15 29)" fill="#141c27"/>
      <rect id="Rechteck_770" data-name="Rechteck 770" width="8" height="7" transform="translate(7 29)" fill="#141c27"/>
      <rect id="Rechteck_771" data-name="Rechteck 771" width="7" height="7" transform="translate(0 29)" fill="#141c27"/>
      <rect id="Rechteck_772" data-name="Rechteck 772" width="7" height="8" transform="translate(0 36)" fill="#141c27"/>
      <rect id="Rechteck_773" data-name="Rechteck 773" width="7" height="7" transform="translate(0 44)" fill="#141c27"/>
      <rect id="Rechteck_774" data-name="Rechteck 774" width="7" height="8" transform="translate(73 36)" fill="#141c27"/>
      <rect id="Rechteck_775" data-name="Rechteck 775" width="7" height="7" transform="translate(73 44)" fill="#141c27"/>
      <rect id="Rechteck_776" data-name="Rechteck 776" width="7" height="8" transform="translate(15 36)" fill="#141c27"/>
      <rect id="Rechteck_777" data-name="Rechteck 777" width="7" height="8" transform="translate(22 36)" fill="#141c27"/>
      <rect id="Rechteck_778" data-name="Rechteck 778" width="7" height="8" transform="translate(29 36)" fill="#141c27"/>
      <rect id="Rechteck_779" data-name="Rechteck 779" width="8" height="8" transform="translate(36 36)" fill="#141c27"/>
      <rect id="Rechteck_780" data-name="Rechteck 780" width="7" height="8" transform="translate(44 36)" fill="#141c27"/>
      <rect id="Rechteck_781" data-name="Rechteck 781" width="7" height="8" transform="translate(51 36)" fill="#141c27"/>
      <rect id="Rechteck_782" data-name="Rechteck 782" width="7" height="8" transform="translate(58 36)" fill="#141c27"/>
      <rect id="Rechteck_783" data-name="Rechteck 783" width="7" height="7" transform="translate(58 44)" fill="#141c27"/>
      <rect id="Rechteck_784" data-name="Rechteck 784" width="7" height="7" transform="translate(15 44)" fill="#141c27"/>
      <rect id="Rechteck_785" data-name="Rechteck 785" width="7" height="7" transform="translate(22 51)" fill="#141c27"/>
      <rect id="Rechteck_786" data-name="Rechteck 786" width="7" height="7" transform="translate(29 51)" fill="#141c27"/>
      <rect id="Rechteck_787" data-name="Rechteck 787" width="7" height="7" transform="translate(51 51)" fill="#141c27"/>
      <rect id="Rechteck_788" data-name="Rechteck 788" width="7" height="7" transform="translate(44 51)" fill="#141c27"/>
    </svg>
  )
}

export default BugSVG;
