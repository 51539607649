import React from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';

function Error() {
  return (
    <ReactBootstrap.Row id="errorLogin">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <h1>Page not found.</h1>
        <div>
          The page you are looking for does not exist.
        </div>
        <div>
          But you can click here below to go back to the dashboard.
        </div>
        <div>
          <Link to="/dashboard">Dashboard</Link>
        </div>
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default Error;
