import React, { useState, useEffect } from 'react';
import {Link, Route, Switch, useRouteMatch} from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import axios from 'axios';
import API from "./../../config/config.json";
import Glide from '@glidejs/glide';

import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';

import IconAvatar from './../../assets/svg/avatar.svg';
import IconAvatarMain from './../../assets/svg/featuredContact.svg';
import IconClose from "../../assets/icons/IconDelete.png";
import IconTip from "../../assets/icons/IconTip.png";
import AcceptSVG from "../../assets/svg/accept";

function Feature() {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get url from all friends
  const urlMain = API.URL + 'friends';
  const urlMainFeature = API.URL + 'feature-friends';

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Store and set the state value
  const [features, setFeatures] = useState([]);
  const [featuresSecond, setFeaturesSecond] = useState([]);

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      urlMain, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    const sortResult = result.data.items.sort(function(a,b){
      return new Date(b.lastPostAt) - new Date(a.lastPostAt);
    });

    setFeatures(sortResult);

    const resultTwo = await axios.get(
      urlMainFeature, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    const fData = resultTwo.data.items;
    const fDataNew = fData.reverse();

    setFeaturesSecond(fDataNew);
  }, []);

  useEffect(() => {
    const contentInfo = document.getElementById("no-data-features");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading content";
      setTimeout( () => {
        contentInfo.innerHTML = "You don't have any feature contacts";
      }, 5000)
    }
  })

  /* Feature contacts navigation */
  const movingLeft = (element) => {
    //let leftNavi = document.querySelector('#' + element + ' ' + naviElement + ' .right');

    document.querySelector(element).scrollLeft -= 400;
  }

  const movingRight = (element) => {
    //let rightNavi = document.querySelector('#' + element + ' ' + naviElement + ' .left');

    document.querySelector(element).scrollLeft += 400;
  }

  /* Glide Slide */
  useEffect(() => {
    let sliders = document.querySelectorAll('.glide');

    for (let i = 0; i < sliders.length; i++) {
      let glide = new Glide(sliders[i], {
        type: 'slider',
        gap: 100,
        swipeThreshold: 150,
        dragThreshold: 150,
        perTouch: 2,
        keyboard: false,
        animationTimingFunc: 150,
        animationDuration: 800,
        startAt: 0,
        autoPlay: false,
        perView: 4,
        /*perMove: 3,
        perSwipe: 3,*/
        slidesToScroll: 2,
        breakpoints: {
          1680: {
            perView: 8
          },
          1300: {
            perView: 7
          },
          991: {
            perView: 5
          },
          771: {
            perView: 5
          },
          600: {
            perView: 4
          },
          400: {
            perView: 3
          },
          270: {
            perView: 2
          },
          200: {
            perView: 1
          }
        }
      })

      glide.mount()
    }
  })

  if (!featuresSecond || featuresSecond.length == 0) {
    return (
      <ReactBootstrap.Row id="feature">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container tbs--container--features">
            {/*<ReactBootstrap.Row>
              <div className="tbs--headline">
                My Featured Contacts
              </div>
            </ReactBootstrap.Row>*/}
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center" id="no-data-features">
                You don't have any feature contacts
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  } else {
    return (
      <ReactBootstrap.Row id="feature">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container">
            {/*<ReactBootstrap.Row>
              <div className="tbs--headline">
                My Featured Contacts
              </div>
            </ReactBootstrap.Row>*/}
              {
                features.map((item, index) => {
                  if(item.isFavorite === true){
                    return (
                      <ReactBootstrap.Row id="scroll">
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} key={item.id} className={"glide feature-container container-" + index}>
                          <div className="glide__track" data-glide-el="track">
                            <ul className="glide__slides">
                              <li className="glide__slide">
                                <Link to={`/dashboard/profile/${item.user.id}`}>
                                  <div className="tbs--contact">
                                    <div className="text-center">
                                      <div className="tbs--contact--image tbs--contact--main">
                                        <img src={
                                          item.user != null && item.user.image != null
                                            ?
                                            item.user.image
                                            :
                                            item.user.profileImage != null
                                              ?
                                              item.user.profileImage.url
                                              :
                                              item.user == null || (item.user.image == null && item.user.profileImage == null)
                                                ?
                                                IconAvatarMain
                                                :
                                                IconAvatarMain
                                        }
                                             className={item.user == null || (item.user.image == null && item.user.profileImage == null) ? "tbs--image--trusted--custom" : "tbs--image--trusted"}
                                             alt="Profile"/>
                                      </div>
                                      <div className="tbs--contact--feature">
                                        {item.user.name}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                              {item.user.friends.map(friend => {
                                // className="tbs--own--profile" --> Hide own profile
                                if(friend.user.id === userID){
                                  return (
                                    <li className="glide__slide">
                                      <Link to={`/dashboard/profile`} key={friend.id}>
                                        <div className="tbs--contact--friends">
                                          <div className="text-center">
                                            <div className="tbs--contact--image">
                                              <img src={
                                                friend.user.image != null
                                                  ?
                                                  friend.user.image
                                                  :
                                                  friend.user.profileImage != null
                                                    ?
                                                    friend.user.profileImage.url
                                                    :
                                                    friend.user == null || (friend.user.image == null && friend.user.profileImage == null)
                                                      ?
                                                      IconAvatar
                                                      :
                                                      IconAvatar
                                              }
                                                   className={friend.user.image != null ? "tbs--image--trusted" : "tbs--image--trusted--custom"}
                                                   alt="Profile"/>
                                            </div>
                                            <div className="tbs--contact--feature">
                                              {friend.user.name}
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li className="glide__slide">
                                      <Link to={`/dashboard/profile/${item.user.id}/friends/${friend.user.id}`}>
                                        <div className="tbs--contact--friends">
                                          <div className="text-center">
                                            <div className="tbs--contact--image">
                                              <img src={
                                                friend.user.image != null
                                                  ?
                                                  friend.user.image
                                                  :
                                                  friend.user.profileImage != null
                                                    ?
                                                    friend.user.profileImage.url
                                                    :
                                                    friend.user == null || (friend.user.image == null && friend.user.profileImage == null)
                                                      ?
                                                      IconAvatar
                                                      :
                                                      IconAvatar
                                              }
                                                   className={friend.user.image != null ? "tbs--image--trusted" : "tbs--image--trusted--custom"}
                                                   alt="Profile"/>
                                            </div>
                                            <div className="tbs--contact--feature">
                                              {friend.user.name}
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                            {<div class="glide__arrows hide" data-glide-el="controls">
                              <button className="glide__arrow glide__arrow--left" data-glide-dir="<">&larr;</button> {/* "|<" */}
                              <button className="glide__arrow glide__arrow--right" data-glide-dir=">">&rarr;</button> {/* "|>" */}
                            </div>}
                          </div>
                          {/*<Link to={`/dashboard/profile/${item.user.id}`}>
                            <div className="tbs--contact">
                              <div className="text-center">
                                <div className="tbs--contact--image tbs--contact--main">
                                  <img src={
                                    item.user != null && item.user.image != null
                                      ?
                                      item.user.image
                                      :
                                      item.user.profileImage != null
                                        ?
                                        item.user.profileImage.url
                                        :
                                        item.user == null || (item.user.image == null && item.user.profileImage == null)
                                          ?
                                          IconAvatarMain
                                          :
                                          IconAvatarMain
                                  }
                                       className={item.user == null || (item.user.image == null && item.user.profileImage == null) ? "tbs--image--trusted--custom" : "tbs--image--trusted"}
                                       alt="Profile"/>
                                </div>
                                <div className="tbs--contact--feature">
                                  {item.user.name}
                                </div>
                              </div>
                            </div>
                          </Link>*/}
                          {/*item.user.friends.map(friend => {
                            // className="tbs--own--profile" --> Hide own profile
                            if(friend.user.id === userID){
                              return (
                                <Link to={`/dashboard/profile`} key={friend.id}>
                                  <div className="tbs--contact--friends">
                                    <div className="text-center">
                                      <div className="tbs--contact--image">
                                        <img src={
                                          friend.user.image != null
                                            ?
                                            friend.user.image
                                            :
                                            friend.user.profileImage != null
                                              ?
                                              friend.user.profileImage.url
                                              :
                                              friend.user == null || (friend.user.image == null && friend.user.profileImage == null)
                                                ?
                                                IconAvatar
                                                :
                                                IconAvatar
                                        }
                                             className={friend.user.image != null ? "tbs--image--trusted" : "tbs--image--trusted--custom"}
                                             alt="Profile"/>
                                      </div>
                                      <div className="tbs--contact--feature">
                                        {friend.user.name}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              );
                            } else {
                              return (
                                <Link to={`/dashboard/profile/${item.user.id}/friends/${friend.user.id}`}>
                                  <div className="tbs--contact--friends">
                                    <div className="text-center">
                                      <div className="tbs--contact--image">
                                        <img src={
                                          friend.user.image != null
                                            ?
                                            friend.user.image
                                            :
                                            friend.user.profileImage != null
                                              ?
                                              friend.user.profileImage.url
                                              :
                                              friend.user == null || (friend.user.image == null && friend.user.profileImage == null)
                                                ?
                                                IconAvatar
                                                :
                                                IconAvatar
                                        }
                                             className={friend.user.image != null ? "tbs--image--trusted" : "tbs--image--trusted--custom"}
                                             alt="Profile"/>
                                      </div>
                                      <div className="tbs--contact--feature">
                                        {friend.user.name}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              );
                            }
                          })*/}
                        </ReactBootstrap.Col>
                        {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} key={item.id}>
                          <div className="feature-navi">
                            <div className="left" onClick={() => movingLeft('.container-' + index)}>&lt;</div>
                            <div className="right" onClick={() => movingRight('.container-' + index)}>&gt;</div>
                          </div>
                        </ReactBootstrap.Col>*/}
                      </ReactBootstrap.Row>
                    );
                  }
                })
              }
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  }
}

export default Feature;
