import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import axios from "axios";
import moment from "moment";

import AllAwards from "./allAwards";

import API from "../../../config/config.json";

//import IconAvatar from "../../../assets/svg/avatar.svg";
import IconImage from "../../../assets/icons/IconImage.png";
import IconGroup from "../../../assets/svg/communitiesBlack.svg";
import IconAwards from "../../../assets/icons/IconAwards.png";
//import IconAwardsDark from "../../../assets/icons/IconAwardsDark.png";
//import IconBookmarks from "../../../assets/icons/IconBookmarks.png";
//import IconBookmarksActive from "../../../assets/icons/IconBookmarksBlue.png";

/* SVG */
import IconBookmarks from "../../../assets/svg/bookmark.svg";
import IconBookmarksActive from "../../../assets/svg/bookmarkActive.svg";
import IconAwardsDark from "../../../assets/svg/awardDark.svg";
import IconAvatar from "../../../assets/svg/avatar.svg";
import IconAvatarHighlight from "../../../assets/svg/networkMyHighlight.svg";
import IconAvatarHighlightAlone from "../../../assets/svg/networkHighlight.svg";
import IconHuman from "../../../assets/svg/humanIcon.svg";

function SocialNetwork() {

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");

  // Store and set the state value
  const [networkData, setNetworkData] = useState([]);
  const [firstMostAwardedUser, setFirstMostAwardedUser] = useState([]);
  const [secondMostAwardedUser, setSecondMostAwardedUser] = useState([]);
  const [thirdMostAwardedUser, setThirdMostAwardedUser] = useState([]);
  const [firstMostFeaturedUser, setFirstMostFeaturedUser] = useState([]);
  const [secondMostFeaturedUser, setSecondMostFeaturedUser] = useState([]);
  const [thirdMostFeaturedUser, setThirdMostFeaturedUser] = useState([]);
  const [mostCommentedPost, setMostCommentedPost] = useState([]);
  const [mostRepostedPost, setMostRepostedPost] = useState([]);
  const [mostForwardedPost, setMostForwardedPost] = useState([]);
  const [mostBookmarkedPost, setMostBookmarkedPost] = useState([]);
  const [mostAwardedPost, setMostAwardedPost] = useState([]);
  const [mostTrendingPost, setMostTrendingPost] = useState([]);
  const [awardUserFirstId, setAwardUserFirstId] = useState("");
  const [awardUserSecondId, setAwardUserSecondId] = useState("");
  const [awardUserThirdId, setAwardUserThirdId] = useState("");
  const [featuredUserFirstId, setFeaturedUserFirstId] = useState("");
  const [featuredUserSecondId, setFeaturedUserSecondId] = useState("");
  const [featuredUserThirdId, setFeaturedUserThirdId] = useState("");
  const [mostCommentedPostId, setMostCommentedPostId] = useState("");
  const [mostRepostedPostId, setMostRepostedPostId] = useState("");
  const [mostForwardedPostId, setMostForwardedPostId] = useState("");
  const [mostBookmarkedPostId, setMostBookmarkedPostId] = useState("");
  const [mostAwardedPostId, setMostAwardedPostId] = useState("");
  const [mostTrendingPostId, setMostTrendingPostId] = useState("");
  const [showNetwork, setShowNetwork] = useState(false);
  const [showMyAwards, setShowMyAwards] = useState(false);
  const [timeData, setTimeData] = useState("alltime");

  useEffect(async () => {

    // URL for the highlights
    const urlHighlight = 'https://meoh-integration.nurogate.com/api/v2/users/dashboard?type=network&withInTime=' + timeData;

    // fetch data and validate
    const results = await axios.get(
      urlHighlight, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setNetworkData([results.data.dashboardData])

    const awardUserFirstID = results.data.dashboardData.firstMostAwardedUser != null ? results.data.dashboardData.firstMostAwardedUser.id : "";
    const awardUserSecondID = results.data.dashboardData.secondMostAwardedUser != null ? results.data.dashboardData.secondMostAwardedUser.id : "";
    const awardUserThirdID = results.data.dashboardData.thirdMostAwardedUser != null ? results.data.dashboardData.thirdMostAwardedUser.id : "";
    const featuredUserFirstID = results.data.dashboardData.firstMostFeaturedUser != null ? results.data.dashboardData.firstMostFeaturedUser.id : "";
    const featuredUserSecondID = results.data.dashboardData.secondMostFeaturedUser != null ? results.data.dashboardData.secondMostFeaturedUser.id : "";
    const featuredUserThirdID = results.data.dashboardData.thirdMostFeaturedUser != null ? results.data.dashboardData.thirdMostFeaturedUser.id : "";

    const cID = results.data.dashboardData.mostCommentedPost != null ? results.data.dashboardData.mostCommentedPost.id : "";
    const rID = results.data.dashboardData.mostRepostedPost != null ? results.data.dashboardData.mostRepostedPost.id : "";
    const fID = results.data.dashboardData.mostForwardedPost != null ? results.data.dashboardData.mostForwardedPost.id : "";
    const bID = results.data.dashboardData.mostBookmarkedPost != null ? results.data.dashboardData.mostBookmarkedPost.id : "";
    const aID = results.data.dashboardData.mostAwardedPost != null ? results.data.dashboardData.mostAwardedPost.id : "";
    const tID = results.data.dashboardData.mostTrendingPost != null ? results.data.dashboardData.mostTrendingPost.id : "";

    // Get url from the different profiles
    const urlAwardFirstProfile = API.URL + 'users/profile/' + awardUserFirstID;
    const urlAwardSecondProfile = API.URL + 'users/profile/' + awardUserSecondID;
    const urlAwardThirdProfile = API.URL + 'users/profile/' + awardUserThirdID;
    const urlFeaturedFirstProfile = API.URL + 'users/profile/' + featuredUserFirstID;
    const urlFeaturedSecondProfile = API.URL + 'users/profile/' + featuredUserSecondID;
    const urlFeaturedThirdProfile = API.URL + 'users/profile/' + featuredUserThirdID;

    // Get url from the different posts
    const urlCommentedPost = API.URL + 'posts/' + cID;
    const urlRepostedPost = API.URL + 'posts/' + rID;
    const urlForwardedPost = API.URL + 'posts/' + fID;
    const urlBookmarkedPost = API.URL + 'posts/' + bID;
    const urlAwardedPost = API.URL + 'posts/' + aID;
    const urlTrendingPost = API.URL + 'posts/' + tID;

    // Data for profile
    if(results.data.dashboardData.firstMostAwardedUser != null && awardUserFirstID != ""){
      const resultFirstMostAwardedUser = await axios.get(
        urlAwardFirstProfile, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setFirstMostAwardedUser([resultFirstMostAwardedUser.data])
      setAwardUserFirstId(awardUserFirstID)
    }
    if(results.data.dashboardData.secondMostAwardedUser != null && awardUserSecondID != ""){
      const resultSecondMostAwardedUser = await axios.get(
        urlAwardSecondProfile, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setSecondMostAwardedUser([resultSecondMostAwardedUser.data])
      setAwardUserSecondId(awardUserSecondID)
    }
    if(results.data.dashboardData.thirdMostAwardedUser != null && awardUserThirdID != ""){
      const resultThirdMostAwardedUser = await axios.get(
        urlAwardThirdProfile, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setThirdMostAwardedUser([resultThirdMostAwardedUser.data])
      setAwardUserThirdId(awardUserThirdID)
    }
    if(results.data.dashboardData.firstMostFeaturedUser != null && featuredUserFirstID != ""){
      const resultFirstMostFeaturedUser = await axios.get(
        urlFeaturedFirstProfile, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setFirstMostFeaturedUser([resultFirstMostFeaturedUser.data])
      setFeaturedUserFirstId(featuredUserFirstID)
    }
    if(results.data.dashboardData.secondMostFeaturedUser != null && featuredUserSecondID != ""){
      const resultSecondMostFeaturedUser = await axios.get(
        urlFeaturedSecondProfile, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setSecondMostFeaturedUser([resultSecondMostFeaturedUser.data])
      setFeaturedUserSecondId(featuredUserSecondID)
    }
    if(results.data.dashboardData.thirdMostFeaturedUser != null && featuredUserThirdID != ""){
      const resultThirdMostFeaturedUser = await axios.get(
        urlFeaturedThirdProfile, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setThirdMostFeaturedUser([resultThirdMostFeaturedUser.data])
      setFeaturedUserThirdId(featuredUserThirdID)
    }

    // Data for commented post
    if(results.data.dashboardData.mostCommentedPost != null && cID != ""){
      const resultCommentedPost = await axios.get(
        urlCommentedPost, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setMostCommentedPost([resultCommentedPost.data])
      setMostCommentedPostId(cID)
    }

    if(results.data.dashboardData.mostRepostedPost != null && rID != ""){
      const resultRepostedPost = await axios.get(
        urlRepostedPost, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setMostRepostedPost([resultRepostedPost.data])
      setMostRepostedPostId(rID)
    }

    if(results.data.dashboardData.mostForwardedPost != null && fID != ""){
      const resultForwardedPost = await axios.get(
        urlForwardedPost, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setMostForwardedPost([resultForwardedPost.data])
      setMostForwardedPostId(fID)
    }

    if(results.data.dashboardData.mostBookmarkedPost != null && bID != ""){
      const resultBookmarkedPost = await axios.get(
        urlBookmarkedPost, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setMostBookmarkedPost([resultBookmarkedPost.data])
      setMostBookmarkedPostId(bID)
    }

    if(results.data.dashboardData.mostAwardedPost != null && aID != ""){
      const resultAwardedPost = await axios.get(
        urlAwardedPost, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setMostAwardedPost([resultAwardedPost.data])
      setMostAwardedPostId(aID)
    }

    if(results.data.dashboardData.mostTrendingPost != null && tID != ""){
      const resultTrendingPost = await axios.get(
        urlTrendingPost, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setMostTrendingPost([resultTrendingPost.data])
      setMostTrendingPostId(tID)
    }

    /* Slider */
    let elem = document.querySelector("#network-slider");

    if(showNetwork){
      elem.removeAttribute("checked");
      setShowNetwork(false);
    } else {
      elem.setAttribute("checked","true");
      setShowNetwork(true);
    }

    document.querySelector(".tbs--network--slider > .switch > .slider").classList.remove("tbs--network--deactivate");
    document.querySelector(".tbs--network--slider > .switch > .slider").classList.add("tbs--network--active");

    document.querySelector(".tbs--times--highlight > div:nth-child(5)").classList.add("tbs--highlight--active--link");

    const completeCommentedDetails = document.querySelectorAll(".tbs--most--commented--post");
    const completeRepostedDetails = document.querySelectorAll(".tbs--most--reposted--post");
    const completeForwardedDetails = document.querySelectorAll(".tbs--most--forwarded--post");
    const completeBookmarkedDetails = document.querySelectorAll(".tbs--most--bookmarked--post");
    const completeAwardedDetails = document.querySelectorAll(".tbs--most--awarded--post");
    const completeTrendingDetails = document.querySelectorAll(".tbs--most--trending--post");

    const noDataImage = document.querySelector(".no--image--container--network");

    if(document.querySelector(".post--most--commented--tbs").getAttribute("href") == "/dashboard/profile/network"){
      for(let i = 0; i < completeCommentedDetails.length; i++){
        completeCommentedDetails[i].classList.remove("show")
        completeCommentedDetails[i].classList.add("hide")

        document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.remove("show")
        document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.add("hide")
        document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
      }
    } else {
      for(let i = 0; i < completeCommentedDetails.length; i++){
        completeCommentedDetails[i].classList.add("show")
        completeCommentedDetails[i].classList.remove("hide")

        document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.add("show")
        document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.remove("hide")
        document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
      }
    }
    if(document.querySelector(".post--most--reposted--tbs").getAttribute("href") == "/dashboard/profile/network"){
      for(let i = 0; i < completeRepostedDetails.length; i++){
        completeRepostedDetails[i].classList.remove("show")
        completeRepostedDetails[i].classList.add("hide")

        document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.remove("show")
        document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.add("hide")
        document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
      }
    } else {
      for(let i = 0; i < completeRepostedDetails.length; i++){
        completeRepostedDetails[i].classList.add("show")
        completeRepostedDetails[i].classList.remove("hide")

        document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.add("show")
        document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.remove("hide")
        document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
      }
    }
    if(document.querySelector(".post--most--forwarded--tbs").getAttribute("href") == "/dashboard/profile/network"){
      for(let i = 0; i < completeForwardedDetails.length; i++){
        completeForwardedDetails[i].classList.remove("show")
        completeForwardedDetails[i].classList.add("hide")

        document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.remove("show")
        document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.add("hide")
        document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
      }
    } else {
      for(let i = 0; i < completeForwardedDetails.length; i++){
        completeForwardedDetails[i].classList.add("show")
        completeForwardedDetails[i].classList.remove("hide")

        document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.add("show")
        document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.remove("hide")
        document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
      }
    }
    if(document.querySelector(".post--most--bookmarked--tbs").getAttribute("href") == "/dashboard/profile/network"){
      for(let i = 0; i < completeBookmarkedDetails.length; i++){
        completeBookmarkedDetails[i].classList.remove("show")
        completeBookmarkedDetails[i].classList.add("hide")

        document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.remove("show")
        document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.add("hide")
        document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
      }
    } else {
      for(let i = 0; i < completeBookmarkedDetails.length; i++){
        completeBookmarkedDetails[i].classList.add("show")
        completeBookmarkedDetails[i].classList.remove("hide")

        document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.add("show")
        document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.remove("hide")
        document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
      }
    }
    if(document.querySelector(".post--most--awarded--tbs").getAttribute("href") == "/dashboard/profile/network"){
      for(let i = 0; i < completeAwardedDetails.length; i++){
        completeAwardedDetails[i].classList.remove("show")
        completeAwardedDetails[i].classList.add("hide")

        document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.remove("show")
        document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.add("hide")
        document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
      }
    } else {
      for(let i = 0; i < completeAwardedDetails.length; i++){
        completeAwardedDetails[i].classList.add("show")
        completeAwardedDetails[i].classList.remove("hide")

        document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.add("show")
        document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.remove("hide")
        document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
      }
    }
    if(document.querySelector(".post--most--trending--tbs").getAttribute("href") == "/dashboard/profile/network"){
      for(let i = 0; i < completeTrendingDetails.length; i++){
        completeTrendingDetails[i].classList.remove("show")
        completeTrendingDetails[i].classList.add("hide")

        document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.remove("show")
        document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.add("hide")
        document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
      }
    } else {
      for(let i = 0; i < completeTrendingDetails.length; i++){
        completeTrendingDetails[i].classList.add("show")
        completeTrendingDetails[i].classList.remove("hide")

        document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.add("show")
        document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.remove("hide")
        document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
      }
    }

    /* Toggle contact informations in blocks - Awards and Features */
    if(awardUserFirstID != ""){
      document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("show")
      document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("hide")
      document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
    } else {
      document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("show")
      document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("hide")
      document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
    }
    if(awardUserSecondID != ""){
      document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("show")
      document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("hide")
      document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
    } else {
      document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("show")
      document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("hide")
      document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
    }
    if(awardUserThirdID != ""){
      document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("show")
      document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("hide")
      document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
    } else {
      document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("show")
      document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("hide")
      document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
    }
    if(featuredUserFirstID != ""){
      document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("show")
      document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("hide")
      document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
    } else {
      document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("show")
      document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("hide")
      document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
    }
    if(featuredUserSecondID != ""){
      document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("show")
      document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("hide")
      document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
    } else {
      document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("show")
      document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("hide")
      document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
    }
    if(featuredUserThirdID != ""){
      document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("show")
      document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("hide")
      document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
    } else {
      document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("show")
      document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("hide")
      document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
    }

    /* Toggle contact informations in blocks */
    if(cID != ""){
      document.querySelector(".tbs--most--commented--post").classList.add("show")
      document.querySelector(".tbs--most--commented--post").classList.remove("hide")
      document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
    } else {
      document.querySelector(".tbs--most--commented--post").classList.remove("show")
      document.querySelector(".tbs--most--commented--post").classList.add("hide")
      document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
    }
    if(rID != ""){
      document.querySelector(".tbs--most--reposted--post").classList.add("show")
      document.querySelector(".tbs--most--reposted--post").classList.remove("hide")
      document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
    } else {
      document.querySelector(".tbs--most--reposted--post").classList.remove("show")
      document.querySelector(".tbs--most--reposted--post").classList.add("hide")
      document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
    }
    if(fID != ""){
      document.querySelector(".tbs--most--forwarded--post").classList.add("show")
      document.querySelector(".tbs--most--forwarded--post").classList.remove("hide")
      document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
    } else {
      document.querySelector(".tbs--most--forwarded--post").classList.remove("show")
      document.querySelector(".tbs--most--forwarded--post").classList.add("hide")
      document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
    }
    if(bID != ""){
      document.querySelector(".tbs--most--bookmarked--post").classList.add("show")
      document.querySelector(".tbs--most--bookmarked--post").classList.remove("hide")
      document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
    } else {
      document.querySelector(".tbs--most--bookmarked--post").classList.remove("show")
      document.querySelector(".tbs--most--bookmarked--post").classList.add("hide")
      document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
    }
    if(aID != ""){
      document.querySelector(".tbs--most--awarded--post").classList.add("show")
      document.querySelector(".tbs--most--awarded--post").classList.remove("hide")
      document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
    } else {
      document.querySelector(".tbs--most--awarded--post").classList.remove("show")
      document.querySelector(".tbs--most--awarded--post").classList.add("hide")
      document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
    }
    if(tID != "" || tID != NaN){
      document.querySelector(".tbs--most--trending--post").classList.add("show")
      document.querySelector(".tbs--most--trending--post").classList.remove("hide")
      document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
    } else {
      document.querySelector(".tbs--most--trending--post").classList.remove("show")
      document.querySelector(".tbs--most--trending--post").classList.add("hide")
      document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
    }
  }, [])

  const toggleHighlights = async () => {
    let elem = document.querySelector("#network-slider");

    /*setFirstMostAwardedUser([]);
    setSecondMostAwardedUser([]);
    setThirdMostAwardedUser([]);
    setFirstMostFeaturedUser([]);
    setSecondMostFeaturedUser([]);
    setThirdMostFeaturedUser([]);*/
    setMostCommentedPost([]);
    setMostRepostedPost([]);
    setMostForwardedPost([]);
    setMostBookmarkedPost([]);
    setMostAwardedPost([]);
    setMostTrendingPost([]);
    /*setAwardUserFirstId("");
    setAwardUserSecondId("");
    setAwardUserThirdId("");
    setFeaturedUserFirstId("");
    setFeaturedUserSecondId("");
    setFeaturedUserThirdId("");
    setMostCommentedPostId("");
    setMostRepostedPostId("");
    setMostForwardedPostId("");
    setMostBookmarkedPostId("");
    setMostAwardedPostId("");
    setMostTrendingPostId("");*/

    if(showNetwork){
      elem.removeAttribute("checked");
      setShowNetwork(false);
      document.querySelector(".tbs--network--slider > .switch > .slider").classList.add("tbs--network--deactivate");
      document.querySelector(".tbs--network--slider > .switch > .slider").classList.remove("tbs--network--active");

      // URL for the highlights
      const urlHighlight = 'https://meoh-integration.nurogate.com/api/v2/users/dashboard?type=user&withInTime=' + timeData;

      // fetch data and validate
      const results = await axios.get(
        urlHighlight, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setNetworkData([results.data.dashboardData])

      setTimeout(async () => {
        const awardUserFirstID = results.data.dashboardData.firstMostAwardedUser != null ? results.data.dashboardData.firstMostAwardedUser.id : "";
        const awardUserSecondID = results.data.dashboardData.secondMostAwardedUser != null ? results.data.dashboardData.secondMostAwardedUser.id : "";
        const awardUserThirdID = results.data.dashboardData.thirdMostAwardedUser != null ? results.data.dashboardData.thirdMostAwardedUser.id : "";
        const featuredUserFirstID = results.data.dashboardData.firstMostFeaturedUser != null ? results.data.dashboardData.firstMostFeaturedUser.id : "";
        const featuredUserSecondID = results.data.dashboardData.secondMostFeaturedUser != null ? results.data.dashboardData.secondMostFeaturedUser.id : "";
        const featuredUserThirdID = results.data.dashboardData.thirdMostFeaturedUser != null ? results.data.dashboardData.thirdMostFeaturedUser.id : "";

        const cID = results.data.dashboardData.mostCommentedPost != null ? results.data.dashboardData.mostCommentedPost.id : "";
        const rID = results.data.dashboardData.mostRepostedPost != null ? results.data.dashboardData.mostRepostedPost.id : "";
        const fID = results.data.dashboardData.mostForwardedPost != null ? results.data.dashboardData.mostForwardedPost.id : "";
        const bID = results.data.dashboardData.mostBookmarkedPost != null ? results.data.dashboardData.mostBookmarkedPost.id : "";
        const aID = results.data.dashboardData.mostAwardedPost != null ? results.data.dashboardData.mostAwardedPost.id : "";
        const tID = results.data.dashboardData.mostTrendingPost != null ? results.data.dashboardData.mostTrendingPost.id : "";

        // Get url from the different profiles
        const urlAwardFirstProfile = API.URL + 'users/profile/' + awardUserFirstID;
        const urlAwardSecondProfile = API.URL + 'users/profile/' + awardUserSecondID;
        const urlAwardThirdProfile = API.URL + 'users/profile/' + awardUserThirdID;
        const urlFeaturedFirstProfile = API.URL + 'users/profile/' + featuredUserFirstID;
        const urlFeaturedSecondProfile = API.URL + 'users/profile/' + featuredUserSecondID;
        const urlFeaturedThirdProfile = API.URL + 'users/profile/' + featuredUserThirdID;

        // Get url from the different posts
        const urlCommentedPost = API.URL + 'posts/' + cID;
        const urlRepostedPost = API.URL + 'posts/' + rID;
        const urlForwardedPost = API.URL + 'posts/' + fID;
        const urlBookmarkedPost = API.URL + 'posts/' + bID;
        const urlAwardedPost = API.URL + 'posts/' + aID;
        const urlTrendingPost = API.URL + 'posts/' + tID;

        // Data for profile
        if(results.data.dashboardData.firstMostAwardedUser != null && awardUserFirstID != ""){
          const resultFirstMostAwardedUser = await axios.get(
            urlAwardFirstProfile, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setFirstMostAwardedUser([resultFirstMostAwardedUser.data])
          setAwardUserFirstId(awardUserFirstID)
        }
        if(results.data.dashboardData.secondMostAwardedUser != null && awardUserSecondID != ""){
          const resultSecondMostAwardedUser = await axios.get(
            urlAwardSecondProfile, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setSecondMostAwardedUser([resultSecondMostAwardedUser.data])
          setAwardUserSecondId(awardUserSecondID)
        }
        if(results.data.dashboardData.thirdMostAwardedUser != null && awardUserThirdID != ""){
          const resultThirdMostAwardedUser = await axios.get(
            urlAwardThirdProfile, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setThirdMostAwardedUser([resultThirdMostAwardedUser.data])
          setAwardUserThirdId(awardUserThirdID)
        }
        if(results.data.dashboardData.firstMostFeaturedUser != null && featuredUserFirstID != ""){
          const resultFirstMostFeaturedUser = await axios.get(
            urlFeaturedFirstProfile, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setFirstMostFeaturedUser([resultFirstMostFeaturedUser.data])
          setFeaturedUserFirstId(featuredUserFirstID)
        }
        if(results.data.dashboardData.secondMostFeaturedUser != null && featuredUserSecondID != ""){
          const resultSecondMostFeaturedUser = await axios.get(
            urlFeaturedSecondProfile, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setSecondMostFeaturedUser([resultSecondMostFeaturedUser.data])
          setFeaturedUserSecondId(featuredUserSecondID)
        }
        if(results.data.dashboardData.thirdMostFeaturedUser != null && featuredUserThirdID != ""){
          const resultThirdMostFeaturedUser = await axios.get(
            urlFeaturedThirdProfile, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setThirdMostFeaturedUser([resultThirdMostFeaturedUser.data])
          setFeaturedUserThirdId(featuredUserThirdID)
        }

        // Data for commented post
        if(results.data.dashboardData.mostCommentedPost != null && cID != ""){
          const resultCommentedPost = await axios.get(
            urlCommentedPost, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setMostCommentedPost([resultCommentedPost.data])
          setMostCommentedPostId(cID)
        }

        if(results.data.dashboardData.mostRepostedPost != null && rID != ""){
          const resultRepostedPost = await axios.get(
            urlRepostedPost, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setMostRepostedPost([resultRepostedPost.data])
          setMostRepostedPostId(rID)
        }

        if(results.data.dashboardData.mostForwardedPost != null && fID != ""){
          const resultForwardedPost = await axios.get(
            urlForwardedPost, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setMostForwardedPost([resultForwardedPost.data])
          setMostForwardedPostId(fID)
        }

        if(results.data.dashboardData.mostBookmarkedPost != null && bID != ""){
          const resultBookmarkedPost = await axios.get(
            urlBookmarkedPost, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setMostBookmarkedPost([resultBookmarkedPost.data])
          setMostBookmarkedPostId(bID)
        }

        if(results.data.dashboardData.mostAwardedPost != null && aID != ""){
          const resultAwardedPost = await axios.get(
            urlAwardedPost, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setMostAwardedPost([resultAwardedPost.data])
          setMostAwardedPostId(aID)
        }

        if(results.data.dashboardData.mostTrendingPost != null && tID != ""){
          const resultTrendingPost = await axios.get(
            urlTrendingPost, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setMostTrendingPost([resultTrendingPost.data])
          setMostTrendingPostId(tID)
        }

        const completeCommentedDetails = document.querySelectorAll(".tbs--most--commented--post");
        const completeRepostedDetails = document.querySelectorAll(".tbs--most--reposted--post");
        const completeForwardedDetails = document.querySelectorAll(".tbs--most--forwarded--post");
        const completeBookmarkedDetails = document.querySelectorAll(".tbs--most--bookmarked--post");
        const completeAwardedDetails = document.querySelectorAll(".tbs--most--awarded--post");
        const completeTrendingDetails = document.querySelectorAll(".tbs--most--trending--post");

        if(document.querySelector(".post--most--commented--tbs").getAttribute("href") == "/dashboard/profile/network"){
          for(let i = 0; i < completeCommentedDetails.length; i++){
            completeCommentedDetails[i].classList.remove("show")
            completeCommentedDetails[i].classList.add("hide")

            document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.remove("show")
            document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.add("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        } else {
          for(let i = 0; i < completeCommentedDetails.length; i++){
            completeCommentedDetails[i].classList.add("show")
            completeCommentedDetails[i].classList.remove("hide")

            document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.add("show")
            document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.remove("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        }
        if(document.querySelector(".post--most--reposted--tbs").getAttribute("href") == "/dashboard/profile/network"){
          for(let i = 0; i < completeRepostedDetails.length; i++){
            completeRepostedDetails[i].classList.remove("show")
            completeRepostedDetails[i].classList.add("hide")

            document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.remove("show")
            document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.add("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        } else {
          for(let i = 0; i < completeRepostedDetails.length; i++){
            completeRepostedDetails[i].classList.add("show")
            completeRepostedDetails[i].classList.remove("hide")

            document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.add("show")
            document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.remove("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        }
        if(document.querySelector(".post--most--forwarded--tbs").getAttribute("href") == "/dashboard/profile/network"){
          for(let i = 0; i < completeForwardedDetails.length; i++){
            completeForwardedDetails[i].classList.remove("show")
            completeForwardedDetails[i].classList.add("hide")

            document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.remove("show")
            document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.add("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        } else {
          for(let i = 0; i < completeForwardedDetails.length; i++){
            completeForwardedDetails[i].classList.add("show")
            completeForwardedDetails[i].classList.remove("hide")

            document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.add("show")
            document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.remove("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        }
        if(document.querySelector(".post--most--bookmarked--tbs").getAttribute("href") == "/dashboard/profile/network"){
          for(let i = 0; i < completeBookmarkedDetails.length; i++){
            completeBookmarkedDetails[i].classList.remove("show")
            completeBookmarkedDetails[i].classList.add("hide")

            document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.remove("show")
            document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.add("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        } else {
          for(let i = 0; i < completeBookmarkedDetails.length; i++){
            completeBookmarkedDetails[i].classList.add("show")
            completeBookmarkedDetails[i].classList.remove("hide")

            document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.add("show")
            document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.remove("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        }
        if(document.querySelector(".post--most--awarded--tbs").getAttribute("href") == "/dashboard/profile/network"){
          for(let i = 0; i < completeAwardedDetails.length; i++){
            completeAwardedDetails[i].classList.remove("show")
            completeAwardedDetails[i].classList.add("hide")

            document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.remove("show")
            document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.add("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        } else {
          for(let i = 0; i < completeAwardedDetails.length; i++){
            completeAwardedDetails[i].classList.add("show")
            completeAwardedDetails[i].classList.remove("hide")

            document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.add("show")
            document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.remove("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        }
        if(document.querySelector(".post--most--trending--tbs").getAttribute("href") == "/dashboard/profile/network"){
          for(let i = 0; i < completeTrendingDetails.length; i++){
            completeTrendingDetails[i].classList.remove("show")
            completeTrendingDetails[i].classList.add("hide")

            document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.remove("show")
            document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.add("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        } else {
          for(let i = 0; i < completeTrendingDetails.length; i++){
            completeTrendingDetails[i].classList.add("show")
            completeTrendingDetails[i].classList.remove("hide")

            document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.add("show")
            document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.remove("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        }

        /* Toggle contact informations in blocks - Awards and Features */
        if(awardUserFirstID != ""){
          document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("show")
          document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
        } else {
          document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("show")
          document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
        }
        if(awardUserSecondID != ""){
          document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("show")
          document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
        } else {
          document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("show")
          document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
        }
        if(awardUserThirdID != ""){
          document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("show")
          document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
        } else {
          document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("show")
          document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
        }
        if(featuredUserFirstID != ""){
          document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("show")
          document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
        } else {
          document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("show")
          document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
        }
        if(featuredUserSecondID != ""){
          document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("show")
          document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
        } else {
          document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("show")
          document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
        }
        if(featuredUserThirdID != ""){
          document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("show")
          document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
        } else {
          document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("show")
          document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
        }

        /* Toggle contact informations in blocks */
        if(cID != ""){
          document.querySelector(".tbs--most--commented--post").classList.add("show")
          document.querySelector(".tbs--most--commented--post").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
        } else {
          document.querySelector(".tbs--most--commented--post").classList.remove("show")
          document.querySelector(".tbs--most--commented--post").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
        }
        if(rID != ""){
          document.querySelector(".tbs--most--reposted--post").classList.add("show")
          document.querySelector(".tbs--most--reposted--post").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
        } else {
          document.querySelector(".tbs--most--reposted--post").classList.remove("show")
          document.querySelector(".tbs--most--reposted--post").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
        }
        if(fID != ""){
          document.querySelector(".tbs--most--forwarded--post").classList.add("show")
          document.querySelector(".tbs--most--forwarded--post").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
        } else {
          document.querySelector(".tbs--most--forwarded--post").classList.remove("show")
          document.querySelector(".tbs--most--forwarded--post").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
        }
        if(bID != ""){
          document.querySelector(".tbs--most--bookmarked--post").classList.add("show")
          document.querySelector(".tbs--most--bookmarked--post").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
        } else {
          document.querySelector(".tbs--most--bookmarked--post").classList.remove("show")
          document.querySelector(".tbs--most--bookmarked--post").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
        }
        if(aID != ""){
          document.querySelector(".tbs--most--awarded--post").classList.add("show")
          document.querySelector(".tbs--most--awarded--post").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
        } else {
          document.querySelector(".tbs--most--awarded--post").classList.remove("show")
          document.querySelector(".tbs--most--awarded--post").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
        }
        if(tID != "" || tID != NaN){
          document.querySelector(".tbs--most--trending--post").classList.add("show")
          document.querySelector(".tbs--most--trending--post").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
        } else {
          document.querySelector(".tbs--most--trending--post").classList.remove("show")
          document.querySelector(".tbs--most--trending--post").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
        }
      }, 1000)

    } else {
      elem.setAttribute("checked","true");
      setShowNetwork(true);
      document.querySelector(".tbs--network--slider > .switch > .slider").classList.remove("tbs--network--deactivate");
      document.querySelector(".tbs--network--slider > .switch > .slider").classList.add("tbs--network--active");

      // URL for the highlights
      const urlHighlight = 'https://meoh-integration.nurogate.com/api/v2/users/dashboard?type=network&withInTime=' + timeData;

      // fetch data and validate
      const results = await axios.get(
        urlHighlight, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      setNetworkData([results.data.dashboardData])

      setTimeout(async () => {
        const awardUserFirstID = results.data.dashboardData.firstMostAwardedUser != null ? results.data.dashboardData.firstMostAwardedUser.id : "";
        const awardUserSecondID = results.data.dashboardData.secondMostAwardedUser != null ? results.data.dashboardData.secondMostAwardedUser.id : "";
        const awardUserThirdID = results.data.dashboardData.thirdMostAwardedUser != null ? results.data.dashboardData.thirdMostAwardedUser.id : "";
        const featuredUserFirstID = results.data.dashboardData.firstMostFeaturedUser != null ? results.data.dashboardData.firstMostFeaturedUser.id : "";
        const featuredUserSecondID = results.data.dashboardData.secondMostFeaturedUser != null ? results.data.dashboardData.secondMostFeaturedUser.id : "";
        const featuredUserThirdID = results.data.dashboardData.thirdMostFeaturedUser != null ? results.data.dashboardData.thirdMostFeaturedUser.id : "";

        const cID = results.data.dashboardData.mostCommentedPost != null ? results.data.dashboardData.mostCommentedPost.id : "";
        const rID = results.data.dashboardData.mostRepostedPost != null ? results.data.dashboardData.mostRepostedPost.id : "";
        const fID = results.data.dashboardData.mostForwardedPost != null ? results.data.dashboardData.mostForwardedPost.id : "";
        const bID = results.data.dashboardData.mostBookmarkedPost != null ? results.data.dashboardData.mostBookmarkedPost.id : "";
        const aID = results.data.dashboardData.mostAwardedPost != null ? results.data.dashboardData.mostAwardedPost.id : "";
        const tID = results.data.dashboardData.mostTrendingPost != null ? results.data.dashboardData.mostTrendingPost.id : "";

        // Get url from the different profiles
        const urlAwardFirstProfile = API.URL + 'users/profile/' + awardUserFirstID;
        const urlAwardSecondProfile = API.URL + 'users/profile/' + awardUserSecondID;
        const urlAwardThirdProfile = API.URL + 'users/profile/' + awardUserThirdID;
        const urlFeaturedFirstProfile = API.URL + 'users/profile/' + featuredUserFirstID;
        const urlFeaturedSecondProfile = API.URL + 'users/profile/' + featuredUserSecondID;
        const urlFeaturedThirdProfile = API.URL + 'users/profile/' + featuredUserThirdID;

        // Get url from the different posts
        const urlCommentedPost = API.URL + 'posts/' + cID;
        const urlRepostedPost = API.URL + 'posts/' + rID;
        const urlForwardedPost = API.URL + 'posts/' + fID;
        const urlBookmarkedPost = API.URL + 'posts/' + bID;
        const urlAwardedPost = API.URL + 'posts/' + aID;
        const urlTrendingPost = API.URL + 'posts/' + tID;

        // Data for profile
        if(results.data.dashboardData.firstMostAwardedUser != null && awardUserFirstID != ""){
          const resultFirstMostAwardedUser = await axios.get(
            urlAwardFirstProfile, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setFirstMostAwardedUser([resultFirstMostAwardedUser.data])
          setAwardUserFirstId(awardUserFirstID)
        }
        if(results.data.dashboardData.secondMostAwardedUser != null && awardUserSecondID != ""){
          const resultSecondMostAwardedUser = await axios.get(
            urlAwardSecondProfile, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setSecondMostAwardedUser([resultSecondMostAwardedUser.data])
          setAwardUserSecondId(awardUserSecondID)
        }
        if(results.data.dashboardData.thirdMostAwardedUser != null && awardUserThirdID != ""){
          const resultThirdMostAwardedUser = await axios.get(
            urlAwardThirdProfile, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setThirdMostAwardedUser([resultThirdMostAwardedUser.data])
          setAwardUserThirdId(awardUserThirdID)
        }
        if(results.data.dashboardData.firstMostFeaturedUser != null && featuredUserFirstID != ""){
          const resultFirstMostFeaturedUser = await axios.get(
            urlFeaturedFirstProfile, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setFirstMostFeaturedUser([resultFirstMostFeaturedUser.data])
          setFeaturedUserFirstId(featuredUserFirstID)
        }
        if(results.data.dashboardData.secondMostFeaturedUser != null && featuredUserSecondID != ""){
          const resultSecondMostFeaturedUser = await axios.get(
            urlFeaturedSecondProfile, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setSecondMostFeaturedUser([resultSecondMostFeaturedUser.data])
          setFeaturedUserSecondId(featuredUserSecondID)
        }
        if(results.data.dashboardData.thirdMostFeaturedUser != null && featuredUserThirdID != ""){
          const resultThirdMostFeaturedUser = await axios.get(
            urlFeaturedThirdProfile, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setThirdMostFeaturedUser([resultThirdMostFeaturedUser.data])
          setFeaturedUserThirdId(featuredUserThirdID)
        }

        // Data for commented post
        if(results.data.dashboardData.mostCommentedPost != null && cID != ""){
          const resultCommentedPost = await axios.get(
            urlCommentedPost, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setMostCommentedPost([resultCommentedPost.data])
          setMostCommentedPostId(cID)
        }

        if(results.data.dashboardData.mostRepostedPost != null && rID != ""){
          const resultRepostedPost = await axios.get(
            urlRepostedPost, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setMostRepostedPost([resultRepostedPost.data])
          setMostRepostedPostId(rID)
        }

        if(results.data.dashboardData.mostForwardedPost != null && fID != ""){
          const resultForwardedPost = await axios.get(
            urlForwardedPost, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setMostForwardedPost([resultForwardedPost.data])
          setMostForwardedPostId(fID)
        }

        if(results.data.dashboardData.mostBookmarkedPost != null && bID != ""){
          const resultBookmarkedPost = await axios.get(
            urlBookmarkedPost, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setMostBookmarkedPost([resultBookmarkedPost.data])
          setMostBookmarkedPostId(bID)
        }

        if(results.data.dashboardData.mostAwardedPost != null && aID != ""){
          const resultAwardedPost = await axios.get(
            urlAwardedPost, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setMostAwardedPost([resultAwardedPost.data])
          setMostAwardedPostId(aID)
        }

        if(results.data.dashboardData.mostTrendingPost != null && tID != ""){
          const resultTrendingPost = await axios.get(
            urlTrendingPost, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setMostTrendingPost([resultTrendingPost.data])
          setMostTrendingPostId(tID)
        }

        const completeCommentedDetails = document.querySelectorAll(".tbs--most--commented--post");
        const completeRepostedDetails = document.querySelectorAll(".tbs--most--reposted--post");
        const completeForwardedDetails = document.querySelectorAll(".tbs--most--forwarded--post");
        const completeBookmarkedDetails = document.querySelectorAll(".tbs--most--bookmarked--post");
        const completeAwardedDetails = document.querySelectorAll(".tbs--most--awarded--post");
        const completeTrendingDetails = document.querySelectorAll(".tbs--most--trending--post");

        if(document.querySelector(".post--most--commented--tbs").getAttribute("href") == "/dashboard/profile/network"){
          for(let i = 0; i < completeCommentedDetails.length; i++){
            completeCommentedDetails[i].classList.remove("show")
            completeCommentedDetails[i].classList.add("hide")

            document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.remove("show")
            document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.add("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        } else {
          for(let i = 0; i < completeCommentedDetails.length; i++){
            completeCommentedDetails[i].classList.add("show")
            completeCommentedDetails[i].classList.remove("hide")

            document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.add("show")
            document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.remove("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        }
        if(document.querySelector(".post--most--reposted--tbs").getAttribute("href") == "/dashboard/profile/network"){
          for(let i = 0; i < completeRepostedDetails.length; i++){
            completeRepostedDetails[i].classList.remove("show")
            completeRepostedDetails[i].classList.add("hide")

            document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.remove("show")
            document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.add("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        } else {
          for(let i = 0; i < completeRepostedDetails.length; i++){
            completeRepostedDetails[i].classList.add("show")
            completeRepostedDetails[i].classList.remove("hide")

            document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.add("show")
            document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.remove("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        }
        if(document.querySelector(".post--most--forwarded--tbs").getAttribute("href") == "/dashboard/profile/network"){
          for(let i = 0; i < completeForwardedDetails.length; i++){
            completeForwardedDetails[i].classList.remove("show")
            completeForwardedDetails[i].classList.add("hide")

            document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.remove("show")
            document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.add("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        } else {
          for(let i = 0; i < completeForwardedDetails.length; i++){
            completeForwardedDetails[i].classList.add("show")
            completeForwardedDetails[i].classList.remove("hide")

            document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.add("show")
            document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.remove("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        }
        if(document.querySelector(".post--most--bookmarked--tbs").getAttribute("href") == "/dashboard/profile/network"){
          for(let i = 0; i < completeBookmarkedDetails.length; i++){
            completeBookmarkedDetails[i].classList.remove("show")
            completeBookmarkedDetails[i].classList.add("hide")

            document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.remove("show")
            document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.add("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        } else {
          for(let i = 0; i < completeBookmarkedDetails.length; i++){
            completeBookmarkedDetails[i].classList.add("show")
            completeBookmarkedDetails[i].classList.remove("hide")

            document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.add("show")
            document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.remove("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        }
        if(document.querySelector(".post--most--awarded--tbs").getAttribute("href") == "/dashboard/profile/network"){
          for(let i = 0; i < completeAwardedDetails.length; i++){
            completeAwardedDetails[i].classList.remove("show")
            completeAwardedDetails[i].classList.add("hide")

            document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.remove("show")
            document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.add("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        } else {
          for(let i = 0; i < completeAwardedDetails.length; i++){
            completeAwardedDetails[i].classList.add("show")
            completeAwardedDetails[i].classList.remove("hide")

            document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.add("show")
            document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.remove("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        }
        if(document.querySelector(".post--most--trending--tbs").getAttribute("href") == "/dashboard/profile/network"){
          for(let i = 0; i < completeTrendingDetails.length; i++){
            completeTrendingDetails[i].classList.remove("show")
            completeTrendingDetails[i].classList.add("hide")

            document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.remove("show")
            document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.add("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        } else {
          for(let i = 0; i < completeTrendingDetails.length; i++){
            completeTrendingDetails[i].classList.add("show")
            completeTrendingDetails[i].classList.remove("hide")

            document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.add("show")
            document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.remove("hide")
            document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
          }
        }

        /* Toggle contact informations in blocks - Awards and Features */
        if(awardUserFirstID != ""){
          document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("show")
          document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
        } else {
          document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("show")
          document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
        }
        if(awardUserSecondID != ""){
          document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("show")
          document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
        } else {
          document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("show")
          document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
        }
        if(awardUserThirdID != ""){
          document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("show")
          document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
        } else {
          document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("show")
          document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
        }
        if(featuredUserFirstID != ""){
          document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("show")
          document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
        } else {
          document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("show")
          document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
        }
        if(featuredUserSecondID != ""){
          document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("show")
          document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
        } else {
          document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("show")
          document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
        }
        if(featuredUserThirdID != ""){
          document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("show")
          document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
        } else {
          document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("show")
          document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
        }

        /* Toggle contact informations in blocks */
        if(cID != ""){
          document.querySelector(".tbs--most--commented--post").classList.add("show")
          document.querySelector(".tbs--most--commented--post").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
        } else {
          document.querySelector(".tbs--most--commented--post").classList.remove("show")
          document.querySelector(".tbs--most--commented--post").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
        }
        if(rID != ""){
          document.querySelector(".tbs--most--reposted--post").classList.add("show")
          document.querySelector(".tbs--most--reposted--post").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
        } else {
          document.querySelector(".tbs--most--reposted--post").classList.remove("show")
          document.querySelector(".tbs--most--reposted--post").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
        }
        if(fID != ""){
          document.querySelector(".tbs--most--forwarded--post").classList.add("show")
          document.querySelector(".tbs--most--forwarded--post").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
        } else {
          document.querySelector(".tbs--most--forwarded--post").classList.remove("show")
          document.querySelector(".tbs--most--forwarded--post").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
        }
        if(bID != ""){
          document.querySelector(".tbs--most--bookmarked--post").classList.add("show")
          document.querySelector(".tbs--most--bookmarked--post").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
        } else {
          document.querySelector(".tbs--most--bookmarked--post").classList.remove("show")
          document.querySelector(".tbs--most--bookmarked--post").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
        }
        if(aID != ""){
          document.querySelector(".tbs--most--awarded--post").classList.add("show")
          document.querySelector(".tbs--most--awarded--post").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
        } else {
          document.querySelector(".tbs--most--awarded--post").classList.remove("show")
          document.querySelector(".tbs--most--awarded--post").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
        }
        if(tID != "" || tID != NaN){
          document.querySelector(".tbs--most--trending--post").classList.add("show")
          document.querySelector(".tbs--most--trending--post").classList.remove("hide")
          document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
        } else {
          document.querySelector(".tbs--most--trending--post").classList.remove("show")
          document.querySelector(".tbs--most--trending--post").classList.add("hide")
          document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
        }
      }, 1000)
    }
  }

  // Method for getting the highlight (Own and network)
  const getHighlights = (highlightType, timeRange) => {

    setTimeData(timeRange);

    /*setFirstMostAwardedUser([]);
    setSecondMostAwardedUser([]);
    setThirdMostAwardedUser([]);
    setFirstMostFeaturedUser([]);
    setSecondMostFeaturedUser([]);
    setThirdMostFeaturedUser([]);*/
    setMostCommentedPost([]);
    setMostRepostedPost([]);
    setMostForwardedPost([]);
    setMostBookmarkedPost([]);
    setMostAwardedPost([]);
    setMostTrendingPost([]);
    /*setAwardUserFirstId("");
    setAwardUserSecondId("");
    setAwardUserThirdId("");
    setFeaturedUserFirstId("");
    setFeaturedUserSecondId("");
    setFeaturedUserThirdId("");
    setMostCommentedPostId("");
    setMostRepostedPostId("");
    setMostForwardedPostId("");
    setMostBookmarkedPostId("");
    setMostAwardedPostId("");
    setMostTrendingPostId("");*/

    // URL for the highlights
    const urlHighlight = 'https://meoh-integration.nurogate.com/api/v2/users/dashboard?type=' + highlightType + '&withInTime=' + timeRange;

    // fetch data and validate
    axios
      .get(urlHighlight, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setNetworkData([response.data.dashboardData])

          setTimeout(async () => {
            const awardUserFirstID = response.data.dashboardData.firstMostAwardedUser != null ? response.data.dashboardData.firstMostAwardedUser.id : "";
            const awardUserSecondID = response.data.dashboardData.secondMostAwardedUser != null ? response.data.dashboardData.secondMostAwardedUser.id : "";
            const awardUserThirdID = response.data.dashboardData.thirdMostAwardedUser != null ? response.data.dashboardData.thirdMostAwardedUser.id : "";
            const featuredUserFirstID = response.data.dashboardData.firstMostFeaturedUser != null ? response.data.dashboardData.firstMostFeaturedUser.id : "";
            const featuredUserSecondID = response.data.dashboardData.secondMostFeaturedUser != null ? response.data.dashboardData.secondMostFeaturedUser.id : "";
            const featuredUserThirdID = response.data.dashboardData.thirdMostFeaturedUser != null ? response.data.dashboardData.thirdMostFeaturedUser.id : "";

            const cID = response.data.dashboardData.mostCommentedPost != null ? response.data.dashboardData.mostCommentedPost.id : "";
            const rID = response.data.dashboardData.mostRepostedPost != null ? response.data.dashboardData.mostRepostedPost.id : "";
            const fID = response.data.dashboardData.mostForwardedPost != null ? response.data.dashboardData.mostForwardedPost.id : "";
            const bID = response.data.dashboardData.mostBookmarkedPost != null ? response.data.dashboardData.mostBookmarkedPost.id : "";
            const aID = response.data.dashboardData.mostAwardedPost != null ? response.data.dashboardData.mostAwardedPost.id : "";
            const tID = response.data.dashboardData.mostTrendingPost != null ? response.data.dashboardData.mostTrendingPost.id : "";

            // Get url from the different profiles
            const urlAwardFirstProfile = API.URL + 'users/profile/' + awardUserFirstID;
            const urlAwardSecondProfile = API.URL + 'users/profile/' + awardUserSecondID;
            const urlAwardThirdProfile = API.URL + 'users/profile/' + awardUserThirdID;
            const urlFeaturedFirstProfile = API.URL + 'users/profile/' + featuredUserFirstID;
            const urlFeaturedSecondProfile = API.URL + 'users/profile/' + featuredUserSecondID;
            const urlFeaturedThirdProfile = API.URL + 'users/profile/' + featuredUserThirdID;

            // Get url from the different posts
            const urlCommentedPost = API.URL + 'posts/' + cID;
            const urlRepostedPost = API.URL + 'posts/' + rID;
            const urlForwardedPost = API.URL + 'posts/' + fID;
            const urlBookmarkedPost = API.URL + 'posts/' + bID;
            const urlAwardedPost = API.URL + 'posts/' + aID;
            const urlTrendingPost = API.URL + 'posts/' + tID;

            // Data for profile
            if(response.data.dashboardData.firstMostAwardedUser != null && awardUserFirstID != ""){
              const resultFirstMostAwardedUser = await axios.get(
                urlAwardFirstProfile, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setFirstMostAwardedUser([resultFirstMostAwardedUser.data])
              setAwardUserFirstId(awardUserFirstID)
            }
            if(response.data.dashboardData.secondMostAwardedUser != null && awardUserSecondID != ""){
              const resultSecondMostAwardedUser = await axios.get(
                urlAwardSecondProfile, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setSecondMostAwardedUser([resultSecondMostAwardedUser.data])
              setAwardUserSecondId(awardUserSecondID)
            }
            if(response.data.dashboardData.thirdMostAwardedUser != null && awardUserThirdID != ""){
              const resultThirdMostAwardedUser = await axios.get(
                urlAwardThirdProfile, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setThirdMostAwardedUser([resultThirdMostAwardedUser.data])
              setAwardUserThirdId(awardUserThirdID)
            }
            if(response.data.dashboardData.firstMostFeaturedUser != null && featuredUserFirstID != ""){
              const resultFirstMostFeaturedUser = await axios.get(
                urlFeaturedFirstProfile, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setFirstMostFeaturedUser([resultFirstMostFeaturedUser.data])
              setFeaturedUserFirstId(featuredUserFirstID)
            }
            if(response.data.dashboardData.secondMostFeaturedUser != null && featuredUserSecondID != ""){
              const resultSecondMostFeaturedUser = await axios.get(
                urlFeaturedSecondProfile, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setSecondMostFeaturedUser([resultSecondMostFeaturedUser.data])
              setFeaturedUserSecondId(featuredUserSecondID)
            }
            if(response.data.dashboardData.thirdMostFeaturedUser != null && featuredUserThirdID != ""){
              const resultThirdMostFeaturedUser = await axios.get(
                urlFeaturedThirdProfile, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setThirdMostFeaturedUser([resultThirdMostFeaturedUser.data])
              setFeaturedUserThirdId(featuredUserThirdID)
            }

            // Data for commented post
            if(response.data.dashboardData.mostCommentedPost != null && cID != ""){
              const resultCommentedPost = await axios.get(
                urlCommentedPost, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setMostCommentedPost([resultCommentedPost.data])
              setMostCommentedPostId(cID)
            }

            if(response.data.dashboardData.mostRepostedPost != null && rID != ""){
              const resultRepostedPost = await axios.get(
                urlRepostedPost, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setMostRepostedPost([resultRepostedPost.data])
              setMostRepostedPostId(rID)
            }

            if(response.data.dashboardData.mostForwardedPost != null && fID != ""){
              const resultForwardedPost = await axios.get(
                urlForwardedPost, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setMostForwardedPost([resultForwardedPost.data])
              setMostForwardedPostId(fID)
            }

            if(response.data.dashboardData.mostBookmarkedPost != null && bID != ""){
              const resultBookmarkedPost = await axios.get(
                urlBookmarkedPost, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setMostBookmarkedPost([resultBookmarkedPost.data])
              setMostBookmarkedPostId(bID)
            }

            if(response.data.dashboardData.mostAwardedPost != null && aID != ""){
              const resultAwardedPost = await axios.get(
                urlAwardedPost, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setMostAwardedPost([resultAwardedPost.data])
              setMostAwardedPostId(aID)
            }

            if(response.data.dashboardData.mostTrendingPost != null && tID != ""){
              const resultTrendingPost = await axios.get(
                urlTrendingPost, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setMostTrendingPost([resultTrendingPost.data])
              setMostTrendingPostId(tID)
            }

            const completeCommentedDetails = document.querySelectorAll(".tbs--most--commented--post");
            const completeRepostedDetails = document.querySelectorAll(".tbs--most--reposted--post");
            const completeForwardedDetails = document.querySelectorAll(".tbs--most--forwarded--post");
            const completeBookmarkedDetails = document.querySelectorAll(".tbs--most--bookmarked--post");
            const completeAwardedDetails = document.querySelectorAll(".tbs--most--awarded--post");
            const completeTrendingDetails = document.querySelectorAll(".tbs--most--trending--post");

            if(document.querySelector(".post--most--commented--tbs").getAttribute("href") == "/dashboard/profile/network"){
              for(let i = 0; i < completeCommentedDetails.length; i++){
                completeCommentedDetails[i].classList.remove("show")
                completeCommentedDetails[i].classList.add("hide")

                document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.remove("show")
                document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.add("hide")
                document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
              }
            } else {
              for(let i = 0; i < completeCommentedDetails.length; i++){
                completeCommentedDetails[i].classList.add("show")
                completeCommentedDetails[i].classList.remove("hide")

                document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.add("show")
                document.querySelector(".post--most--commented--tbs > div:nth-child(2)").classList.remove("hide")
                document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
              }
            }
            if(document.querySelector(".post--most--reposted--tbs").getAttribute("href") == "/dashboard/profile/network"){
              for(let i = 0; i < completeRepostedDetails.length; i++){
                completeRepostedDetails[i].classList.remove("show")
                completeRepostedDetails[i].classList.add("hide")

                document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.remove("show")
                document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.add("hide")
                document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
              }
            } else {
              for(let i = 0; i < completeRepostedDetails.length; i++){
                completeRepostedDetails[i].classList.add("show")
                completeRepostedDetails[i].classList.remove("hide")

                document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.add("show")
                document.querySelector(".post--most--reposted--tbs > div:nth-child(2)").classList.remove("hide")
                document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
              }
            }
            if(document.querySelector(".post--most--forwarded--tbs").getAttribute("href") == "/dashboard/profile/network"){
              for(let i = 0; i < completeForwardedDetails.length; i++){
                completeForwardedDetails[i].classList.remove("show")
                completeForwardedDetails[i].classList.add("hide")

                document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.remove("show")
                document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.add("hide")
                document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
              }
            } else {
              for(let i = 0; i < completeForwardedDetails.length; i++){
                completeForwardedDetails[i].classList.add("show")
                completeForwardedDetails[i].classList.remove("hide")

                document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.add("show")
                document.querySelector(".post--most--forwarded--tbs > div:nth-child(2)").classList.remove("hide")
                document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
              }
            }
            if(document.querySelector(".post--most--bookmarked--tbs").getAttribute("href") == "/dashboard/profile/network"){
              for(let i = 0; i < completeBookmarkedDetails.length; i++){
                completeBookmarkedDetails[i].classList.remove("show")
                completeBookmarkedDetails[i].classList.add("hide")

                document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.remove("show")
                document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.add("hide")
                document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
              }
            } else {
              for(let i = 0; i < completeBookmarkedDetails.length; i++){
                completeBookmarkedDetails[i].classList.add("show")
                completeBookmarkedDetails[i].classList.remove("hide")

                document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.add("show")
                document.querySelector(".post--most--bookmarked--tbs > div:nth-child(2)").classList.remove("hide")
                document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
              }
            }
            if(document.querySelector(".post--most--awarded--tbs").getAttribute("href") == "/dashboard/profile/network"){
              for(let i = 0; i < completeAwardedDetails.length; i++){
                completeAwardedDetails[i].classList.remove("show")
                completeAwardedDetails[i].classList.add("hide")

                document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.remove("show")
                document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.add("hide")
                document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
              }
            } else {
              for(let i = 0; i < completeAwardedDetails.length; i++){
                completeAwardedDetails[i].classList.add("show")
                completeAwardedDetails[i].classList.remove("hide")

                document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.add("show")
                document.querySelector(".post--most--awarded--tbs > div:nth-child(2)").classList.remove("hide")
                document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
              }
            }
            if(document.querySelector(".post--most--trending--tbs").getAttribute("href") == "/dashboard/profile/network"){
              for(let i = 0; i < completeTrendingDetails.length; i++){
                completeTrendingDetails[i].classList.remove("show")
                completeTrendingDetails[i].classList.add("hide")

                document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.remove("show")
                document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.add("hide")
                document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
              }
            } else {
              for(let i = 0; i < completeTrendingDetails.length; i++){
                completeTrendingDetails[i].classList.add("show")
                completeTrendingDetails[i].classList.remove("hide")

                document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.add("show")
                document.querySelector(".post--most--trending--tbs > div:nth-child(2)").classList.remove("hide")
                document.querySelectorAll(".no--image--container--network")[i].classList.remove("hide")
              }
            }

            /* Toggle contact informations in blocks - Awards and Features */
            if(awardUserFirstID != ""){
              document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("show")
              document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("hide")
              document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
            } else {
              document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("show")
              document.querySelector("#most-awarded > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("hide")
              document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
            }
            if(awardUserSecondID != ""){
              document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("show")
              document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("hide")
              document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
            } else {
              document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("show")
              document.querySelector("#most-awarded > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("hide")
              document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
            }
            if(awardUserThirdID != ""){
              document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("show")
              document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("hide")
              document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
            } else {
              document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("show")
              document.querySelector("#most-awarded > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("hide")
              document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
            }
            if(featuredUserFirstID != ""){
              document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("show")
              document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("hide")
              document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
            } else {
              document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.remove("show")
              document.querySelector("#most-featured > div:nth-child(2) > a > div:nth-child(3) > div").classList.add("hide")
              document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
            }
            if(featuredUserSecondID != ""){
              document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("show")
              document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("hide")
              document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
            } else {
              document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.remove("show")
              document.querySelector("#most-featured > div:nth-child(3) > a > div:nth-child(3) > div").classList.add("hide")
              document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
            }
            if(featuredUserThirdID != ""){
              document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("show")
              document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("hide")
              document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
            } else {
              document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.remove("show")
              document.querySelector("#most-featured > div:nth-child(4) > a > div:nth-child(3) > div").classList.add("hide")
              document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
            }

            /* Toggle contact informations in blocks */
            if(cID != ""){
              document.querySelector(".tbs--most--commented--post").classList.add("show")
              document.querySelector(".tbs--most--commented--post").classList.remove("hide")
              document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
            } else {
              document.querySelector(".tbs--most--commented--post").classList.remove("show")
              document.querySelector(".tbs--most--commented--post").classList.add("hide")
              document.querySelectorAll(".no--image--container--network")[0].classList.remove("hide")
            }
            if(rID != ""){
              document.querySelector(".tbs--most--reposted--post").classList.add("show")
              document.querySelector(".tbs--most--reposted--post").classList.remove("hide")
              document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
            } else {
              document.querySelector(".tbs--most--reposted--post").classList.remove("show")
              document.querySelector(".tbs--most--reposted--post").classList.add("hide")
              document.querySelectorAll(".no--image--container--network")[1].classList.remove("hide")
            }
            if(fID != ""){
              document.querySelector(".tbs--most--forwarded--post").classList.add("show")
              document.querySelector(".tbs--most--forwarded--post").classList.remove("hide")
              document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
            } else {
              document.querySelector(".tbs--most--forwarded--post").classList.remove("show")
              document.querySelector(".tbs--most--forwarded--post").classList.add("hide")
              document.querySelectorAll(".no--image--container--network")[2].classList.remove("hide")
            }
            if(bID != ""){
              document.querySelector(".tbs--most--bookmarked--post").classList.add("show")
              document.querySelector(".tbs--most--bookmarked--post").classList.remove("hide")
              document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
            } else {
              document.querySelector(".tbs--most--bookmarked--post").classList.remove("show")
              document.querySelector(".tbs--most--bookmarked--post").classList.add("hide")
              document.querySelectorAll(".no--image--container--network")[3].classList.remove("hide")
            }
            if(aID != ""){
              document.querySelector(".tbs--most--awarded--post").classList.add("show")
              document.querySelector(".tbs--most--awarded--post").classList.remove("hide")
              document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
            } else {
              document.querySelector(".tbs--most--awarded--post").classList.remove("show")
              document.querySelector(".tbs--most--awarded--post").classList.add("hide")
              document.querySelectorAll(".no--image--container--network")[4].classList.remove("hide")
            }
            if(tID != "" || tID != NaN){
              document.querySelector(".tbs--most--trending--post").classList.add("show")
              document.querySelector(".tbs--most--trending--post").classList.remove("hide")
              document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
            } else {
              document.querySelector(".tbs--most--trending--post").classList.remove("show")
              document.querySelector(".tbs--most--trending--post").classList.add("hide")
              document.querySelectorAll(".no--image--container--network")[5].classList.remove("hide")
            }
          }, 2500)
        }
      })
  }

  const toggleLinkStatus = (e) => {
    e = e || window.event;
    e = e.target || e.srcElement;

    let elems = document.querySelectorAll(".tbs--times--highlight > div");
    [].forEach.call(elems, function(el) {
      el.classList.remove("tbs--highlight--active--link");
    });
    if (e.nodeName === 'DIV') {
      e.classList.add("tbs--highlight--active--link");
    }
  }

  const truncateMessage = (str, num) => {

    if(str == null || !str) {
      return;
    }

    if(str.length <= num) {
      return str;
    }

    return str.slice(0, num) + '...';
  }

  return (
    <ReactBootstrap.Row id="network">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container--network">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--network--main--header">
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <div className="tbs--headline my--complete--highlights">
                    {
                      showNetwork
                        ?
                        "My network highlights"
                        :
                        "My highlights"
                    }
                  </div>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <div className="tbs--container--network--switch">
                    <div className="tbs--network--slider">
                      <label className="switch">
                        <input type="checkbox" id="network-slider" disabled checked="true"/>
                        <span className="tbs--slider--image--left"><img src={IconAvatarHighlightAlone} /></span>
                        <span className="slider round" id="network-switch" disabled onClick={() => toggleHighlights()}></span>
                        <span className="tbs--slider--image--right"><img src={IconAvatarHighlight} /></span>
                      </label>
                    </div>
                  </div>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {
                    showNetwork == true
                      ?
                      <div className="tbs--times--highlight">
                        <div onClick={() => {
                          getHighlights("network", "day"); toggleLinkStatus();
                        }}>
                          Today
                        </div>
                        <div onClick={() => {
                          getHighlights("network", "week"); toggleLinkStatus();
                        }}>
                          7d
                        </div>
                        <div onClick={() => {
                          getHighlights("network", "month"); toggleLinkStatus();
                        }}>
                          1m
                        </div>
                        <div onClick={() => {
                          getHighlights("network", "year"); toggleLinkStatus();
                        }}>
                          1y
                        </div>
                        <div onClick={() => {
                          getHighlights("network", "alltime"); toggleLinkStatus();
                        }}>
                          All time
                        </div>
                      </div>
                      :
                      <div className="tbs--times--highlight">
                        <div onClick={() => {
                          getHighlights("user", "day"); toggleLinkStatus();
                        }}>
                          Today
                        </div>
                        <div onClick={() => {
                          getHighlights("user", "week"); toggleLinkStatus();
                        }}>
                          7d
                        </div>
                        <div onClick={() => {
                          getHighlights("user", "month"); toggleLinkStatus();
                        }}>
                          1m
                        </div>
                        <div onClick={() => {
                          getHighlights("user", "year"); toggleLinkStatus();
                        }}>
                          1y
                        </div>
                        <div onClick={() => {
                          getHighlights("network", "alltime"); toggleLinkStatus();
                        }}>
                          All time
                        </div>
                      </div>
                  }
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
        <ReactBootstrap.Container fluid={true} className="tbs--container--network--blocks">
          {
            networkData.map((highlights) => {
              return (
                <ReactBootstrap.Row id="most-awarded">
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-left">
                    <div className="tbs--headline--network">
                      Most awarded contacts
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4} className="text-center">
                    <Link to={highlights.firstMostAwardedUser != null ? '/dashboard/profile/' + highlights.firstMostAwardedUser.id : "/dashboard/profile/network"}>
                      <div className="tbs-counter-block">
                        <div>
                          <img src={highlights.firstMostAwardedUser != null ? highlights.firstMostAwardedUser.file != null ? highlights.firstMostAwardedUser.file.url : IconAvatar : IconAvatar} />
                        </div>
                        <div className="tbs-counter">
                          1
                        </div>
                      </div>
                      <div className="tbs-friend-name-network">
                        {highlights.firstMostAwardedUser != null ? highlights.firstMostAwardedUser.name : "None yet"}
                      </div>
                      <div className="tbs-friend-position-company-network">
                        {
                          firstMostAwardedUser.length > 0
                          ?
                            firstMostAwardedUser.map((awardUser) => {
                              if(awardUser.position != "" && awardUser.company == ""){
                                return(
                                  <div>
                                    {awardUser.position}
                                  </div>
                                )
                              }
                              if(awardUser.position == "" && awardUser.company != ""){
                                return(
                                  <div>
                                    {awardUser.company}
                                  </div>
                                )
                              }
                              if(awardUser.position != "" && awardUser.company != ""){
                                return(
                                  <div>
                                    {awardUser.position} at {awardUser.company}
                                  </div>
                                )
                              }
                            })
                          :
                            ""
                        }
                      </div>
                    </Link>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4} className="text-center">
                    <Link to={highlights.secondMostAwardedUser != null ? '/dashboard/profile/' + highlights.secondMostAwardedUser.id : "/dashboard/profile/network"}>
                      <div className="tbs-counter-block">
                        <img src={highlights.secondMostAwardedUser != null ? highlights.secondMostAwardedUser.file != null ? highlights.secondMostAwardedUser.file.url : IconAvatar : IconAvatar} />
                        <div className="tbs-counter">
                          2
                        </div>
                      </div>
                      <div className="tbs-friend-name-network">
                        {highlights.secondMostAwardedUser != null ? highlights.secondMostAwardedUser.name : "None yet"}
                      </div>
                      <div className="tbs-friend-position-company-network">
                        {
                          secondMostAwardedUser.length > 0
                            ?
                            secondMostAwardedUser.map((awardUser) => {
                              if(awardUser.position != "" && awardUser.company == ""){
                                return(
                                  <div>
                                    {awardUser.position}
                                  </div>
                                )
                              }
                              if(awardUser.position == "" && awardUser.company != ""){
                                return(
                                  <div>
                                    {awardUser.company}
                                  </div>
                                )
                              }
                              if(awardUser.position != "" && awardUser.company != ""){
                                return(
                                  <div>
                                    {awardUser.position} at {awardUser.company}
                                  </div>
                                )
                              }
                            })
                            :
                            ""
                        }
                      </div>
                    </Link>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4} className="text-center">
                    <Link to={highlights.thirdMostAwardedUser != null ? '/dashboard/profile/' + highlights.thirdMostAwardedUser.id : "/dashboard/profile/network"}>
                      <div className="tbs-counter-block">
                        <img src={highlights.thirdMostAwardedUser != null ? highlights.thirdMostAwardedUser.file != null ? highlights.thirdMostAwardedUser.file.url : IconAvatar : IconAvatar} />
                        <div className="tbs-counter">
                          3
                        </div>
                      </div>
                      <div className="tbs-friend-name-network">
                        {highlights.thirdMostAwardedUser != null ? highlights.thirdMostAwardedUser.name : "None yet"}
                      </div>
                      <div className="tbs-friend-position-company-network">
                        {
                          thirdMostAwardedUser.length > 0
                            ?
                            thirdMostAwardedUser.map((awardUser) => {
                              if(awardUser.position != "" && awardUser.company == ""){
                                return(
                                  <div>
                                    {awardUser.position}
                                  </div>
                                )
                              }
                              if(awardUser.position == "" && awardUser.company != ""){
                                return(
                                  <div>
                                    {awardUser.company}
                                  </div>
                                )
                              }
                              if(awardUser.position != "" && awardUser.company != ""){
                                return(
                                  <div>
                                    {awardUser.position} at {awardUser.company}
                                  </div>
                                )
                              }
                            })
                            :
                            ""
                        }
                      </div>
                    </Link>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              )
            })
          }
        </ReactBootstrap.Container>
        <ReactBootstrap.Container fluid={true} className="tbs--container--network--blocks">
          {
            networkData.map((highlights) => {
              return (
                <ReactBootstrap.Row id="most-featured">
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-left">
                    <div className="tbs--headline--network">
                      Most featured contacts
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4} className="text-center">
                    <Link to={highlights.firstMostFeaturedUser != null ? '/dashboard/profile/' + highlights.firstMostFeaturedUser.id : "/dashboard/profile/network"}>
                      <div className="tbs-counter-block">
                        <div>
                          <img src={highlights.firstMostFeaturedUser != null ? highlights.firstMostFeaturedUser.file != null ? highlights.firstMostFeaturedUser.file.url : IconAvatar : IconAvatar} />
                        </div>
                        <div className="tbs-counter">
                          1
                        </div>
                      </div>
                      <div className="tbs-friend-name-network">
                        {highlights.firstMostFeaturedUser != null ? highlights.firstMostFeaturedUser.name : "None yet"}
                      </div>
                      <div className="tbs-friend-position-company-network">
                        {
                          firstMostFeaturedUser.length > 0
                            ?
                            firstMostFeaturedUser.map((awardUser) => {
                              if(awardUser.position != "" && awardUser.company == ""){
                                return(
                                  <div>
                                    {awardUser.position}
                                  </div>
                                )
                              }
                              if(awardUser.position == "" && awardUser.company != ""){
                                return(
                                  <div>
                                    {awardUser.company}
                                  </div>
                                )
                              }
                              if(awardUser.position != "" && awardUser.company != ""){
                                return(
                                  <div>
                                    {awardUser.position} at {awardUser.company}
                                  </div>
                                )
                              }
                            })
                            :
                            ""
                        }
                      </div>
                    </Link>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4} className="text-center">
                    <Link to={highlights.secondMostFeaturedUser != null ? '/dashboard/profile/' + highlights.secondMostFeaturedUser.id : "/dashboard/profile/network"}>
                      <div className="tbs-counter-block">
                        <div>
                          <img src={highlights.secondMostFeaturedUser != null ? highlights.secondMostFeaturedUser.file != null ? highlights.secondMostFeaturedUser.file.url : IconAvatar : IconAvatar} />
                        </div>
                        <div className="tbs-counter">
                          2
                        </div>
                      </div>
                      <div className="tbs-friend-name-network">
                        {highlights.secondMostFeaturedUser != null ? highlights.secondMostFeaturedUser.name : "None yet"}
                      </div>
                      <div className="tbs-friend-position-company-network">
                        {
                          secondMostFeaturedUser.length > 0
                            ?
                            secondMostFeaturedUser.map((awardUser) => {
                              if(awardUser.position != "" && awardUser.company == ""){
                                return(
                                  <div>
                                    {awardUser.position}
                                  </div>
                                )
                              }
                              if(awardUser.position == "" && awardUser.company != ""){
                                return(
                                  <div>
                                    {awardUser.company}
                                  </div>
                                )
                              }
                              if(awardUser.position != "" && awardUser.company != ""){
                                return(
                                  <div>
                                    {awardUser.position} at {awardUser.company}
                                  </div>
                                )
                              }
                            })
                            :
                            ""
                        }
                      </div>
                    </Link>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4} className="text-center">
                    <Link to={highlights.thirdMostFeaturedUser != null ? '/dashboard/profile/' + highlights.thirdMostFeaturedUser.id : "/dashboard/profile/network"}>
                      <div className="tbs-counter-block">
                        <div>
                          <img src={highlights.thirdMostFeaturedUser != null ? highlights.thirdMostFeaturedUser.file != null ? highlights.thirdMostFeaturedUser.file.url : IconAvatar : IconAvatar} />
                        </div>
                        <div className="tbs-counter">
                          3
                        </div>
                      </div>
                      <div className="tbs-friend-name-network">
                        {highlights.thirdMostFeaturedUser != null ? highlights.thirdMostFeaturedUser.name : "None yet"}
                      </div>
                      <div className="tbs-friend-position-company-network">
                        {
                          thirdMostFeaturedUser.length > 0
                            ?
                            thirdMostFeaturedUser.map((awardUser) => {
                              if(awardUser.position != "" && awardUser.company == ""){
                                return(
                                  <div>
                                    {awardUser.position}
                                  </div>
                                )
                              }
                              if(awardUser.position == "" && awardUser.company != ""){
                                return(
                                  <div>
                                    {awardUser.company}
                                  </div>
                                )
                              }
                              if(awardUser.position != "" && awardUser.company != ""){
                                return(
                                  <div>
                                    {awardUser.position} at {awardUser.company}
                                  </div>
                                )
                              }
                            })
                            :
                            ""
                        }
                      </div>
                    </Link>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              )
            })
          }
        </ReactBootstrap.Container>
        <ReactBootstrap.Container fluid={true} className="tbs--container--network--blocks tbs--container--network--blocks--custom">
          {
            networkData.map((highlights, index) => {
              return (
                <ReactBootstrap.Row className="tbs-highlight-posts" key={index}>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-left">
                    <div className="tbs--headline--network">
                      Most commented post
                    </div>
                  </ReactBootstrap.Col>
                  {
                    mostCommentedPost != null
                    ?
                      mostCommentedPost.map(cPost => {
                        return(
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--contact--details tbs--most--commented--post tbs--post--detail--block">
                              <div className="tbs--contact--image">
                                <img src={
                                  cPost.parentPost != null
                                    ?
                                    cPost.parentPost.user != null
                                      ?
                                      cPost.parentPost.user.profileImage != null
                                        ?
                                        cPost.parentPost.user.profileImage.url
                                        :
                                        cPost.parentPost.user.image
                                      :
                                      cPost.user != null
                                        ?
                                        cPost.user.profileImage != null
                                          ?
                                          cPost.user.profileImage.url
                                          :
                                          cPost.user.image
                                        :
                                        IconAvatar
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.profileImage != null
                                        ?
                                        cPost.user.profileImage.url
                                        :
                                        cPost.user.image
                                      :
                                      IconAvatar
                                }
                                     className="tbs--container--post--image"
                                     alt="Profile"/>
                              </div>
                              <div className="tbs--contact--data--post">
                                <div>
                                  {
                                    cPost.parentPost != null && cPost.parentPost.user != null
                                      ?
                                      cPost.parentPost.user.name
                                      :
                                      cPost.user != null
                                        ?
                                        cPost.user.name
                                        :
                                        ""
                                  }
                                </div>
                                <div>
                                  {
                                    cPost.parentPost != null && cPost.parentPost.user != null
                                      ?
                                      cPost.parentPost.user.position
                                      :
                                      cPost.user != null
                                        ?
                                        cPost.user.position
                                        :
                                        ""
                                  } at {
                                  cPost.parentPost != null && cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.company
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.company
                                      :
                                      ""
                                }
                                </div>
                                <div>
                                  {moment(cPost.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                </div>
                              </div>
                            </ReactBootstrap.Col>
                        )
                      })
                    :
                      ""
                  }
                  {/*
                    mostCommentedPostId != ""
                      ?
                      mostCommentedPost.map(cPost => {
                        return(
                          <ReactBootstrap.Col xs={1} sm={1} md={1} lg={1} xl={1} className="tbs--contact--details tbs--most--commented--post tbs--network--post--bookmark">
                            <div className="tbs--bookmark">
                              <div className="tbs--contact--data--post">
                                <div className="tbs--contact--data--post--favourite">
                                  {
                                    cPost.isFavourite == true
                                      ?
                                      <img className="tbs--favourite--image" src={IconBookmarksActive} alt="Bookmarks"/>
                                      :
                                      <img className="tbs--favourite--image" src={IconBookmarks} alt="Bookmarks"/>
                                  }
                                </div>
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        )
                      })
                      :
                      ""
                  */}
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className={highlights.mostCommentedPost != null ? "text-left tbs--post--detail--block" : "text-left tbs--post--detail--block tbs--no--data--network"}>
                    <Link to={highlights.mostCommentedPost != null ? '/dashboard/feeds/feedlist/post/' + highlights.mostCommentedPost.id : "/dashboard/profile/network"} className="post--most--commented--tbs">
                      {/*
                        <div>
                          {
                            highlights.mostCommentedPost != null
                              ?
                              highlights.mostCommentedPost.file != null
                                ?
                                <img src={highlights.mostCommentedPost.file.url}/>
                                :
                                <img src={IconImage} className="tbs--container--network--blocks--no--image"/>
                              :
                              <img src={IconImage} className="tbs--container--network--blocks--no--image"/>
                          }
                        </div>
                      */}
                      <ReactBootstrap.Row>
                        {
                          highlights.mostCommentedPost == null
                          ?
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--no--post--network">
                              <div className={"tbs--post--title--network text-center"}>
                                {"None yet"}
                              </div>
                              {
                                <div className="no--image--container--network">
                                  <img src={IconHuman} alt="No data available" className="no-data-image"/>
                                </div>
                              }
                            </ReactBootstrap.Col>
                          :
                            ""
                        }
                        {
                          highlights.mostCommentedPost != null
                          ?
                            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              <div className={"tbs--post--title--network"}>
                                {highlights.mostCommentedPost.title}
                              </div>
                              {
                                mostCommentedPost.map(cPost => {
                                  if(highlights.mostCommentedPost != null){
                                    return(
                                      <div>
                                        <div>
                                          {
                                            cPost.description != null
                                              ? truncateMessage(cPost.description, 20)
                                              : cPost.parentPost != null && cPost.parentPost.description != null
                                              ? truncateMessage(cPost.parentPost.description, 20)
                                              : ""
                                          }
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </ReactBootstrap.Col>
                          :
                            ""
                        }
                        {
                          highlights.mostCommentedPost != null
                          ?
                            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              {
                                mostCommentedPost.map(cPost => {
                                  if(highlights.mostCommentedPost != null){
                                    return(
                                      <div className="network--text--image">
                                        <div>
                                          {
                                            cPost.file != null
                                              ? <img src={`${cPost.file.url}`} />
                                              : cPost.parentPost != null && cPost.parentPost.file != null
                                              ? <img src={`${cPost.parentPost.file.url}`} />
                                              : ""
                                          }
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </ReactBootstrap.Col>
                          :
                            ""
                        }
                      </ReactBootstrap.Row>
                    </Link>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              )
            })
          }
        </ReactBootstrap.Container>
        <ReactBootstrap.Container fluid={true} className="tbs--container--network--blocks tbs--container--network--blocks--custom">
          {
            networkData.map((highlights) => {
              return (
                <ReactBootstrap.Row className="tbs-highlight-posts">
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-left">
                    <div className="tbs--headline--network">
                      Most reposted post
                    </div>
                  </ReactBootstrap.Col>
                  {
                    mostRepostedPost != null
                      ?
                      mostRepostedPost.map(cPost => {
                        return(
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--contact--details tbs--most--reposted--post tbs--post--detail--block">
                            <div className="tbs--contact--image">
                              <img src={
                                cPost.parentPost != null
                                  ?
                                  cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.profileImage != null
                                      ?
                                      cPost.parentPost.user.profileImage.url
                                      :
                                      cPost.parentPost.user.image
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.profileImage != null
                                        ?
                                        cPost.user.profileImage.url
                                        :
                                        cPost.user.image
                                      :
                                      IconAvatar
                                  :
                                  cPost.user != null
                                    ?
                                    cPost.user.profileImage != null
                                      ?
                                      cPost.user.profileImage.url
                                      :
                                      cPost.user.image
                                    :
                                    IconAvatar
                              }
                                   className="tbs--container--post--image"
                                   alt="Profile"/>
                            </div>
                            <div className="tbs--contact--data--post">
                              <div>
                                {
                                  cPost.parentPost != null && cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.name
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.name
                                      :
                                      ""
                                }
                              </div>
                              <div>
                                {
                                  cPost.parentPost != null && cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.position
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.position
                                      :
                                      ""
                                } at {
                                cPost.parentPost != null && cPost.parentPost.user != null
                                  ?
                                  cPost.parentPost.user.company
                                  :
                                  cPost.user != null
                                    ?
                                    cPost.user.company
                                    :
                                    ""
                              }
                              </div>
                              <div>
                                {moment(cPost.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        )
                      })
                      :
                      ""
                  }
                  {/*
                    mostRepostedPostId != ""
                      ?
                      mostRepostedPost.map(cPost => {
                        return(
                          <ReactBootstrap.Col xs={1} sm={1} md={1} lg={1} xl={1} className="tbs--contact--details tbs--most--reposted--post tbs--network--post--bookmark">
                            <div className="tbs--bookmark">
                              <div className="tbs--contact--data--post">
                                <div className="tbs--contact--data--post--favourite">
                                  {
                                    cPost.isFavourite == true
                                      ?
                                      <img className="tbs--favourite--image" src={IconBookmarksActive} alt="Bookmarks"/>
                                      :
                                      <img className="tbs--favourite--image" src={IconBookmarks} alt="Bookmarks"/>
                                  }
                                </div>
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        )
                      })
                      :
                      ""
                  */}
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className={highlights.mostRepostedPost != null ? "text-left tbs--post--detail--block" : "text-left tbs--post--detail--block tbs--no--data--network"}>
                    <Link to={highlights.mostRepostedPost != null ? '/dashboard/feeds/feedlist/post/' + highlights.mostRepostedPost.id : "/dashboard/profile/network"} className="post--most--reposted--tbs">
                      {/*
                        <div>
                          {
                            highlights.mostRepostedPost != null
                              ?
                              highlights.mostRepostedPost.file != null
                                ?
                                <img src={highlights.mostRepostedPost.file.url}/>
                                :
                                <img src={IconImage} className="tbs--container--network--blocks--no--image"/>
                              :
                              <img src={IconImage} className="tbs--container--network--blocks--no--image"/>
                          }
                        </div>
                      */}
                      <ReactBootstrap.Row>
                        {
                          highlights.mostRepostedPost == null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--no--post--network">
                              <div className={"tbs--post--title--network text-center"}>
                                {"None yet"}
                              </div>
                              {
                                <div className="no--image--container--network">
                                  <img src={IconHuman} alt="No data available" className="no-data-image"/>
                                </div>
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                        {
                          highlights.mostRepostedPost != null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              <div className={"tbs--post--title--network"}>
                                {highlights.mostRepostedPost.title}
                              </div>
                              {
                                mostRepostedPost.map(cPost => {
                                  if(highlights.mostRepostedPost != null){
                                    return(
                                      <div>
                                        <div>
                                          {
                                            cPost.description != null
                                              ? truncateMessage(cPost.description, 20)
                                              : cPost.parentPost != null && cPost.parentPost.description != null
                                              ? truncateMessage(cPost.parentPost.description, 20)
                                              : ""
                                          }
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                        {
                          highlights.mostRepostedPost != null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              {
                                mostRepostedPost.map(cPost => {
                                  if(highlights.mostRepostedPost != null){
                                    return(
                                      <div className="network--text--image">
                                        <div>
                                          {
                                            cPost.file != null
                                              ? <img src={`${cPost.file.url}`} />
                                              : cPost.parentPost != null && cPost.parentPost.file != null
                                              ? <img src={`${cPost.parentPost.file.url}`} />
                                              : ""
                                          }
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                      </ReactBootstrap.Row>
                    </Link>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              )
            })
          }
        </ReactBootstrap.Container>
        <ReactBootstrap.Container fluid={true} className="tbs--container--network--blocks tbs--container--network--blocks--custom">
          {
            networkData.map((highlights) => {
              return (
                <ReactBootstrap.Row className="tbs-highlight-posts">
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-left">
                    <div className="tbs--headline--network">
                      Most forwarded post
                    </div>
                  </ReactBootstrap.Col>
                  {
                    mostForwardedPost != null
                      ?
                      mostForwardedPost.map(cPost => {
                        return(
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--contact--details tbs--most--forwarded--post tbs--post--detail--block">
                            <div className="tbs--contact--image">
                              <img src={
                                cPost.parentPost != null
                                  ?
                                  cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.profileImage != null
                                      ?
                                      cPost.parentPost.user.profileImage.url
                                      :
                                      cPost.parentPost.user.image
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.profileImage != null
                                        ?
                                        cPost.user.profileImage.url
                                        :
                                        cPost.user.image
                                      :
                                      IconAvatar
                                  :
                                  cPost.user != null
                                    ?
                                    cPost.user.profileImage != null
                                      ?
                                      cPost.user.profileImage.url
                                      :
                                      cPost.user.image
                                    :
                                    IconAvatar
                              }
                                   className="tbs--container--post--image"
                                   alt="Profile"/>
                            </div>
                            <div className="tbs--contact--data--post">
                              <div>
                                {
                                  cPost.parentPost != null && cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.name
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.name
                                      :
                                      ""
                                }
                              </div>
                              <div>
                                {
                                  cPost.parentPost != null && cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.position
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.position
                                      :
                                      ""
                                } at {
                                cPost.parentPost != null && cPost.parentPost.user != null
                                  ?
                                  cPost.parentPost.user.company
                                  :
                                  cPost.user != null
                                    ?
                                    cPost.user.company
                                    :
                                    ""
                              }
                              </div>
                              <div>
                                {moment(cPost.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        )
                      })
                      :
                      ""
                  }
                  {/*
                    mostForwardedPostId != ""
                      ?
                      mostForwardedPost.map(cPost => {
                        return(
                          <ReactBootstrap.Col xs={1} sm={1} md={1} lg={1} xl={1} className="tbs--contact--details tbs--most--forwarded--post tbs--network--post--bookmark">
                            <div className="tbs--bookmark">
                              <div className="tbs--contact--data--post">
                                <div className="tbs--contact--data--post--favourite">
                                  {
                                    cPost.isFavourite == true
                                      ?
                                      <img className="tbs--favourite--image" src={IconBookmarksActive} alt="Bookmarks"/>
                                      :
                                      <img className="tbs--favourite--image" src={IconBookmarks} alt="Bookmarks"/>
                                  }
                                </div>
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        )
                      })
                      :
                      ""
                  */}
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className={highlights.mostForwardedPost != null ? "text-left tbs--post--detail--block" : "text-left tbs--post--detail--block tbs--no--data--network"}>
                    <Link to={highlights.mostForwardedPost != null ? '/dashboard/feeds/feedlist/post/' + highlights.mostForwardedPost.id : "/dashboard/profile/network"} className="post--most--forwarded--tbs">
                      {/*
                        <div>
                          {
                            highlights.mostForwardedPost != null
                              ?
                              highlights.mostForwardedPost.file != null
                                ?
                                <img src={highlights.mostForwardedPost.file.url}/>
                                :
                                <img src={IconImage} className="tbs--container--network--blocks--no--image"/>
                              :
                              <img src={IconImage} className="tbs--container--network--blocks--no--image"/>
                          }
                        </div>
                      */}
                      <ReactBootstrap.Row>
                        {
                          highlights.mostForwardedPost == null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--no--post--network">
                              <div className={"tbs--post--title--network text-center"}>
                                {"None yet"}
                              </div>
                              {
                                <div className="no--image--container--network">
                                  <img src={IconHuman} alt="No data available" className="no-data-image"/>
                                </div>
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                        {
                          highlights.mostForwardedPost != null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              <div className={"tbs--post--title--network"}>
                                {highlights.mostForwardedPost.title}
                              </div>
                              {
                                mostForwardedPost.map(cPost => {
                                  if(highlights.mostForwardedPost != null){
                                    return(
                                      <div>
                                        <div>
                                          {
                                            cPost.description != null
                                              ? truncateMessage(cPost.description, 20)
                                              : cPost.parentPost != null && cPost.parentPost.description != null
                                              ? truncateMessage(cPost.parentPost.description, 20)
                                              : ""
                                          }
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                        {
                          highlights.mostForwardedPost != null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              {
                                mostForwardedPost.map(cPost => {
                                  if(highlights.mostForwardedPost != null){
                                    return(
                                      <div className="network--text--image">
                                        <div>
                                          {
                                            cPost.file != null
                                              ? <img src={`${cPost.file.url}`} />
                                              : cPost.parentPost != null && cPost.parentPost.file != null
                                              ? <img src={`${cPost.parentPost.file.url}`} />
                                              : ""
                                          }
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                      </ReactBootstrap.Row>
                    </Link>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              )
            })
          }
        </ReactBootstrap.Container>
        <ReactBootstrap.Container fluid={true} className="tbs--container--network--blocks tbs--container--network--blocks--custom">
          {
            networkData.map((highlights) => {
              return (
                <ReactBootstrap.Row className="tbs-highlight-posts">
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-left">
                    <div className="tbs--headline--network">
                      Most bookmarked post
                    </div>
                  </ReactBootstrap.Col>
                  {
                    mostBookmarkedPost != null
                      ?
                      mostBookmarkedPost.map(cPost => {
                        return(
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--contact--details tbs--most--bookmarked--post tbs--post--detail--block">
                            <div className="tbs--contact--image">
                              <img src={
                                cPost.parentPost != null
                                  ?
                                  cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.profileImage != null
                                      ?
                                      cPost.parentPost.user.profileImage.url
                                      :
                                      cPost.parentPost.user.image
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.profileImage != null
                                        ?
                                        cPost.user.profileImage.url
                                        :
                                        cPost.user.image
                                      :
                                      IconAvatar
                                  :
                                  cPost.user != null
                                    ?
                                    cPost.user.profileImage != null
                                      ?
                                      cPost.user.profileImage.url
                                      :
                                      cPost.user.image
                                    :
                                    IconAvatar
                              }
                                   className="tbs--container--post--image"
                                   alt="Profile"/>
                            </div>
                            <div className="tbs--contact--data--post">
                              <div>
                                {
                                  cPost.parentPost != null && cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.name
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.name
                                      :
                                      ""
                                }
                              </div>
                              <div>
                                {
                                  cPost.parentPost != null && cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.position
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.position
                                      :
                                      ""
                                } at {
                                cPost.parentPost != null && cPost.parentPost.user != null
                                  ?
                                  cPost.parentPost.user.company
                                  :
                                  cPost.user != null
                                    ?
                                    cPost.user.company
                                    :
                                    ""
                              }
                              </div>
                              <div>
                                {moment(cPost.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        )
                      })
                      :
                      ""
                  }
                  {/*
                    mostBookmarkedPostId != ""
                      ?
                      mostBookmarkedPost.map(cPost => {
                        return(
                          <ReactBootstrap.Col xs={1} sm={1} md={1} lg={1} xl={1} className="tbs--contact--details tbs--most--bookmarked--post tbs--network--post--bookmark">
                            <div className="tbs--bookmark">
                              <div className="tbs--contact--data--post">
                                <div className="tbs--contact--data--post--favourite">
                                  {
                                    cPost.isFavourite == true
                                      ?
                                      <img className="tbs--favourite--image" src={IconBookmarksActive} alt="Bookmarks"/>
                                      :
                                      <img className="tbs--favourite--image" src={IconBookmarks} alt="Bookmarks"/>
                                  }
                                </div>
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        )
                      })
                      :
                      ""
                  */}
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className={highlights.mostBookmarkedPost != null ? "text-left tbs--post--detail--block" : "text-left tbs--post--detail--block tbs--no--data--network"}>
                    <Link to={highlights.mostBookmarkedPost != null ? '/dashboard/feeds/feedlist/post/' + highlights.mostBookmarkedPost.id : "/dashboard/profile/network"} className="post--most--bookmarked--tbs">
                      {/*
                        <div>
                          {
                            highlights.mostBookmarkedPost != null
                              ?
                              highlights.mostBookmarkedPost.file != null
                                ?
                                <img src={highlights.mostBookmarkedPost.file.url}/>
                                :
                                <img src={IconImage} className="tbs--container--network--blocks--no--image"/>
                              :
                              <img src={IconImage} className="tbs--container--network--blocks--no--image"/>
                          }
                        </div>
                      */}
                      <ReactBootstrap.Row>
                        {
                          highlights.mostBookmarkedPost == null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--no--post--network">
                              <div className={"tbs--post--title--network text-center"}>
                                {"None yet"}
                              </div>
                              {
                                <div className="no--image--container--network">
                                  <img src={IconHuman} alt="No data available" className="no-data-image"/>
                                </div>
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                        {
                          highlights.mostBookmarkedPost != null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              <div className={"tbs--post--title--network"}>
                                {highlights.mostBookmarkedPost.title}
                              </div>
                              {
                                mostBookmarkedPost.map(cPost => {
                                  if(highlights.mostBookmarkedPost != null){
                                    return(
                                      <div>
                                        <div>
                                          {
                                            cPost.description != null
                                              ? truncateMessage(cPost.description, 20)
                                              : cPost.parentPost != null && cPost.parentPost.description != null
                                              ? truncateMessage(cPost.parentPost.description, 20)
                                              : ""
                                          }
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                        {
                          highlights.mostBookmarkedPost != null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              {
                                mostBookmarkedPost.map(cPost => {
                                  if(highlights.mostBookmarkedPost != null){
                                    return(
                                      <div className="network--text--image">
                                        <div>
                                          {
                                            cPost.file != null
                                              ? <img src={`${cPost.file.url}`} />
                                              : cPost.parentPost != null && cPost.parentPost.file != null
                                              ? <img src={`${cPost.parentPost.file.url}`} />
                                              : ""
                                          }
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                      </ReactBootstrap.Row>
                    </Link>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              )
            })
          }
        </ReactBootstrap.Container>
        <ReactBootstrap.Container fluid={true} className="tbs--container--network--blocks tbs--container--network--blocks--custom">
          {
            networkData.map((highlights) => {
              return (
                <ReactBootstrap.Row className="tbs-highlight-posts">
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-left">
                    <div className="tbs--headline--network">
                      Most awarded post
                    </div>
                  </ReactBootstrap.Col>
                  {
                    mostAwardedPost != null
                      ?
                      mostAwardedPost.map(cPost => {
                        return(
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--contact--details tbs--most--awarded--post tbs--post--detail--block">
                            <div className="tbs--contact--image">
                              <img src={
                                cPost.parentPost != null
                                  ?
                                  cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.profileImage != null
                                      ?
                                      cPost.parentPost.user.profileImage.url
                                      :
                                      cPost.parentPost.user.image
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.profileImage != null
                                        ?
                                        cPost.user.profileImage.url
                                        :
                                        cPost.user.image
                                      :
                                      IconAvatar
                                  :
                                  cPost.user != null
                                    ?
                                    cPost.user.profileImage != null
                                      ?
                                      cPost.user.profileImage.url
                                      :
                                      cPost.user.image
                                    :
                                    IconAvatar
                              }
                                   className="tbs--container--post--image"
                                   alt="Profile"/>
                            </div>
                            <div className="tbs--contact--data--post">
                              <div>
                                {
                                  cPost.parentPost != null && cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.name
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.name
                                      :
                                      ""
                                }
                              </div>
                              <div>
                                {
                                  cPost.parentPost != null && cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.position
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.position
                                      :
                                      ""
                                } at {
                                cPost.parentPost != null && cPost.parentPost.user != null
                                  ?
                                  cPost.parentPost.user.company
                                  :
                                  cPost.user != null
                                    ?
                                    cPost.user.company
                                    :
                                    ""
                              }
                              </div>
                              <div>
                                {moment(cPost.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        )
                      })
                      :
                      ""
                  }
                  {/*
                    mostAwardedPostId != ""
                      ?
                      mostAwardedPost.map(cPost => {
                        return(
                          <ReactBootstrap.Col xs={1} sm={1} md={1} lg={1} xl={1} className="tbs--contact--details tbs--most--awarded--post--post tbs--network--post--bookmark">
                            <div className="tbs--bookmark">
                              <div className="tbs--contact--data--post">
                                <div className="tbs--contact--data--post--favourite">
                                  {
                                    cPost.isFavourite == true
                                      ?
                                      <img className="tbs--favourite--image" src={IconBookmarksActive} alt="Bookmarks"/>
                                      :
                                      <img className="tbs--favourite--image" src={IconBookmarks} alt="Bookmarks"/>
                                  }
                                </div>
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        )
                      })
                      :
                      ""
                  */}
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className={highlights.mostAwardedPost != null ? "text-left tbs--post--detail--block" : "text-left tbs--post--detail--block tbs--no--data--network"}>
                    <Link to={highlights.mostAwardedPost != null ? '/dashboard/feeds/feedlist/post/' + highlights.mostAwardedPost.id : "/dashboard/profile/network"} className="post--most--awarded--tbs">
                      {/*
                        <div>
                          {
                            highlights.mostAwardedPost != null
                              ?
                              highlights.mostAwardedPost.file != null
                                ?
                                <img src={highlights.mostAwardedPost.file.url}/>
                                :
                                <img src={IconImage} className="tbs--container--network--blocks--no--image"/>
                              :
                              <img src={IconImage} className="tbs--container--network--blocks--no--image"/>
                          }
                        </div>
                      */}
                      <ReactBootstrap.Row>
                        {
                          highlights.mostAwardedPost == null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--no--post--network">
                              <div className={"tbs--post--title--network text-center"}>
                                {"None yet"}
                              </div>
                              {
                                <div className="no--image--container--network">
                                  <img src={IconHuman} alt="No data available" className="no-data-image"/>
                                </div>
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                        {
                          highlights.mostAwardedPost != null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              <div className={"tbs--post--title--network"}>
                                {highlights.mostAwardedPost.title}
                              </div>
                              {
                                mostAwardedPost.map(cPost => {
                                  if(highlights.mostAwardedPost != null){
                                    return(
                                      <div>
                                        <div>
                                          {
                                            cPost.description != null
                                              ? truncateMessage(cPost.description, 20)
                                              : cPost.parentPost != null && cPost.parentPost.description != null
                                              ? truncateMessage(cPost.parentPost.description, 20)
                                              : ""
                                          }
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                        {
                          highlights.mostAwardedPost != null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              {
                                mostAwardedPost.map(cPost => {
                                  if(highlights.mostAwardedPost != null){
                                    return(
                                      <div className="network--text--image">
                                        <div>
                                          {
                                            cPost.file != null
                                              ? <img src={`${cPost.file.url}`} />
                                              : cPost.parentPost != null && cPost.parentPost.file != null
                                              ? <img src={`${cPost.parentPost.file.url}`} />
                                              : ""
                                          }
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                      </ReactBootstrap.Row>
                    </Link>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              )
            })
          }
        </ReactBootstrap.Container>
        <ReactBootstrap.Container fluid={true} className="tbs--container--network--blocks tbs--container--network--blocks--custom">
          {
            networkData.map((highlights) => {
              return (
                <ReactBootstrap.Row className="tbs-highlight-posts">
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-left">
                    <div className="tbs--headline--network">
                      Most trending post
                    </div>
                  </ReactBootstrap.Col>

                  {
                    mostTrendingPost != null
                      ?
                      mostTrendingPost.map(cPost => {
                        return(
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--contact--details tbs--most--trending--post tbs--post--detail--block">
                            <div className="tbs--contact--image">
                              <img src={
                                cPost.parentPost != null
                                  ?
                                  cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.profileImage != null
                                      ?
                                      cPost.parentPost.user.profileImage.url
                                      :
                                      cPost.parentPost.user.image
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.profileImage != null
                                        ?
                                        cPost.user.profileImage.url
                                        :
                                        cPost.user.image
                                      :
                                      IconAvatar
                                  :
                                  cPost.user != null
                                    ?
                                    cPost.user.profileImage != null
                                      ?
                                      cPost.user.profileImage.url
                                      :
                                      cPost.user.image
                                    :
                                    IconAvatar
                              }
                                   className="tbs--container--post--image"
                                   alt="Profile"/>
                            </div>
                            <div className="tbs--contact--data--post">
                              <div>
                                {
                                  cPost.parentPost != null && cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.name
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.name
                                      :
                                      ""
                                }
                              </div>
                              <div>
                                {
                                  cPost.parentPost != null && cPost.parentPost.user != null
                                    ?
                                    cPost.parentPost.user.position
                                    :
                                    cPost.user != null
                                      ?
                                      cPost.user.position
                                      :
                                      ""
                                } at {
                                cPost.parentPost != null && cPost.parentPost.user != null
                                  ?
                                  cPost.parentPost.user.company
                                  :
                                  cPost.user != null
                                    ?
                                    cPost.user.company
                                    :
                                    ""
                              }
                              </div>
                              <div>
                                {moment(cPost.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        )
                      })
                      :
                      ""
                  }
                  {/*
                    mostTrendingPostId != ""
                      ?
                      mostTrendingPost.map(cPost => {
                        return(
                          <ReactBootstrap.Col xs={1} sm={1} md={1} lg={1} xl={1} className="tbs--contact--details tbs--most--trending--post tbs--network--post--bookmark">
                            <div className="tbs--bookmark">
                              <div className="tbs--contact--data--post">
                                <div className="tbs--contact--data--post--favourite">
                                  {
                                    cPost.isFavourite == true
                                      ?
                                      <img className="tbs--favourite--image" src={IconBookmarksActive} alt="Bookmarks"/>
                                      :
                                      <img className="tbs--favourite--image" src={IconBookmarks} alt="Bookmarks"/>
                                  }
                                </div>
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        )
                      })
                      :
                      ""
                  */}
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className={highlights.mostTrendingPost != null ? "text-left tbs--post--detail--block" : "text-left tbs--post--detail--block tbs--no--data--network"}>
                    <Link to={highlights.mostTrendingPost != null ? '/dashboard/feeds/feedlist/post/' + highlights.mostTrendingPost.id : "/dashboard/profile/network"} className="post--most--trending--tbs">
                      {/*
                        <div>
                          {
                            highlights.mostTrendingPost != null
                              ?
                              highlights.mostTrendingPost.file != null
                                ?
                                <img src={highlights.mostTrendingPost.file.url}/>
                                :
                                <img src={IconImage} className="tbs--container--network--blocks--no--image"/>
                              :
                              <img src={IconImage} className="tbs--container--network--blocks--no--image"/>
                          }
                        </div>
                      */}
                      <ReactBootstrap.Row>
                        {
                          highlights.mostTrendingPost == null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--no--post--network">
                              <div className={"tbs--post--title--network text-center"}>
                                {"None yet"}
                              </div>
                              {
                                <div className="no--image--container--network">
                                  <img src={IconHuman} alt="No data available" className="no-data-image"/>
                                </div>
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                        {
                          highlights.mostTrendingPost != null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              <div className={"tbs--post--title--network"}>
                                {highlights.mostTrendingPost.title}
                              </div>
                              {
                                mostTrendingPost.map(cPost => {
                                  if(highlights.mostTrendingPost != null){
                                    return(
                                      <div>
                                        <div>
                                          {
                                            cPost.description != null
                                              ? truncateMessage(cPost.description, 20)
                                              : cPost.parentPost != null && cPost.parentPost.description != null
                                              ? truncateMessage(cPost.parentPost.description, 20)
                                              : ""
                                          }
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                        {
                          highlights.mostTrendingPost != null
                            ?
                            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              {
                                mostTrendingPost.map(cPost => {
                                  if(highlights.mostTrendingPost != null){
                                    return(
                                      <div className="network--text--image">
                                        <div>
                                          {
                                            cPost.file != null
                                              ? <img src={`${cPost.file.url}`} />
                                              : cPost.parentPost != null && cPost.parentPost.file != null
                                              ? <img src={`${cPost.parentPost.file.url}`} />
                                              : ""
                                          }
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </ReactBootstrap.Col>
                            :
                            ""
                        }
                      </ReactBootstrap.Row>
                    </Link>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              )
            })
          }
        </ReactBootstrap.Container>
        <ReactBootstrap.Container fluid={true} id="all-awards" className="tbs--container--network--blocks--two">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-left show new--awards--style">
              <div className="tbs--headline--network">
                My awards
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row className="text-left" onClick={() => setShowMyAwards(true)}>
            <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-center show new--awards--style">
              <div>
                <img src={IconAwardsDark} />
              </div>
              {/*<div>
                Go to my awards
              </div>*/}
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9} className="text-left show new--awards--style">
              {
                networkData.map((highlights) => {
                  return (
                    <div className="all--awards--data">
                      <div className="all--awards--data--specific">
                        {highlights.awardsGiven} Given
                      </div>
                      <div className="all--awards--data--specific">
                        {highlights.awardsReceived} Received
                      </div>
                    </div>
                  )
                })
              }
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={4} lg={4} xl={4} className="text-center">
              <ReactBootstrap.Modal
                show={showMyAwards}
                onHide={() => setShowMyAwards(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter-awards"
                centered
              >
                <ReactBootstrap.Modal.Header closeButton>
                  <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-awards">
                    My awards
                  </ReactBootstrap.Modal.Title>
                </ReactBootstrap.Modal.Header>
                <ReactBootstrap.Modal.Body id="my-awards-info">
                  <AllAwards />
                </ReactBootstrap.Modal.Body>
              </ReactBootstrap.Modal>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          {/*
            networkData.map((highlights) => {
              return (
                <ReactBootstrap.Row className="text-center">
                  <ReactBootstrap.Col xs={12} sm={12} md={4} lg={4} xl={4} className="text-center">
                    <div className="tbs--awards--highlights--one">
                      {highlights.awardsGiven}
                    </div>
                    <div className="tbs--awards--highlights--two">
                      <img src={IconAwardsDark} /> Given
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={4} lg={4} xl={4} className="text-center">
                    <div className="tbs--awards--highlights--one">
                      {highlights.awardsReceived}
                    </div>
                    <div className="tbs--awards--highlights--two">
                      <img src={IconAwardsDark} /> Received
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={4} lg={4} xl={4} className="text-center" onClick={() => setShowMyAwards(true)}>
                    <div className="tbs--highlight--image">
                      <img src={IconAwardsDark} />
                    </div>
                    <div>
                      Go to my awards
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={4} lg={4} xl={4} className="text-center">
                    <ReactBootstrap.Modal
                      show={showMyAwards}
                      onHide={() => setShowMyAwards(false)}
                      size="md"
                      aria-labelledby="contained-modal-title-vcenter-awards"
                      centered
                    >
                      <ReactBootstrap.Modal.Header closeButton>
                        <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-awards">
                          My awards
                        </ReactBootstrap.Modal.Title>
                      </ReactBootstrap.Modal.Header>
                      <ReactBootstrap.Modal.Body id="my-awards-info">
                        <AllAwards />
                      </ReactBootstrap.Modal.Body>
                    </ReactBootstrap.Modal>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              )
            })
          */}
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default SocialNetwork;
