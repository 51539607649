import React from 'react';

const NewsfeedSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="53.333" viewBox="0 0 80 53.333">
      <path id="Icon_awesome-newspaper" data-name="Icon awesome-newspaper" d="M76.667,4.5H12.222A3.333,3.333,0,0,0,8.889,7.833V8.944H3.333A3.333,3.333,0,0,0,0,12.278V50.056a7.778,7.778,0,0,0,7.778,7.778H73.333A6.667,6.667,0,0,0,80,51.167V7.833A3.333,3.333,0,0,0,76.667,4.5ZM7.778,51.167a1.111,1.111,0,0,1-1.111-1.111V15.611H8.889V50.056A1.111,1.111,0,0,1,7.778,51.167Zm32.778-2.222H19.444a1.667,1.667,0,0,1-1.667-1.667V46.167A1.667,1.667,0,0,1,19.444,44.5H40.556a1.667,1.667,0,0,1,1.667,1.667v1.111A1.667,1.667,0,0,1,40.556,48.944Zm28.889,0H48.333a1.667,1.667,0,0,1-1.667-1.667V46.167A1.667,1.667,0,0,1,48.333,44.5H69.444a1.667,1.667,0,0,1,1.667,1.667v1.111A1.667,1.667,0,0,1,69.444,48.944ZM40.556,35.611H19.444a1.667,1.667,0,0,1-1.667-1.667V32.833a1.667,1.667,0,0,1,1.667-1.667H40.556a1.667,1.667,0,0,1,1.667,1.667v1.111A1.667,1.667,0,0,1,40.556,35.611Zm28.889,0H48.333a1.667,1.667,0,0,1-1.667-1.667V32.833a1.667,1.667,0,0,1,1.667-1.667H69.444a1.667,1.667,0,0,1,1.667,1.667v1.111A1.667,1.667,0,0,1,69.444,35.611Zm0-13.333h-50a1.667,1.667,0,0,1-1.667-1.667V15.056a1.667,1.667,0,0,1,1.667-1.667h50a1.667,1.667,0,0,1,1.667,1.667v5.556A1.667,1.667,0,0,1,69.444,22.278Z" transform="translate(0 -4.5)" fill="#000"/>
    </svg>
  )
}

export default NewsfeedSVG;
