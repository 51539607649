import React, {useEffect, useState} from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import axios from "axios";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";

import MobileAppBanner from "../components/mobileAppBanner";

import API from "./../config/config.json";

/* SVG */
import Logo from "./../assets/svg/logo.svg";
import LogoDark from "./../assets/svg/logoDark.svg";

function Register() {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Store and set the state value
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [activationCode, setActivationCode] = useState('');
  const [show, setShow] = useState(false);
  const [showRegisterAlert, setShowRegisterAlert] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  // Main container for history
  let history = useHistory();

  // Make a redirect if value is true
  useEffect(() => {
    (() => {
      if(isRedirect) {
        history.push("/dashboard/newsfeed");
      }
    })()
  }, [isRedirect]);

  const handleChangeEmail = e => setEmail(e.target.value);
  const handleChangeName = e => setName(e.target.value);
  const handleChangePassword = e => setPassword(e.target.value);
  const handleChangePasswordConfirm = e => setPasswordConfirm(e.target.value);
  const handleChangeActivationCode = e => setActivationCode(e.target.value);

  // Login validation method
  const validationRegister = (e) => {
    e.preventDefault();

    // URL for the user authentication in the login area
    const url = API.URL + 'users';

    // Post value for validation
    const data = {
      email: email,
      name: name,
      referralCode: activationCode,
      password: password,
      passwordConfirm: passwordConfirm
    };

    // fetch data and validate
    axios
      .post(url, data)
      .then((response) => {
        if(password === passwordConfirm && activationCode != null && email != null && name != null){
          if(response.status === 200){
            document.getElementById("registerError").classList.remove("showError");
            document.getElementsByClassName("error-text")[0].style.display = "none";
            document.getElementsByClassName("register-email")[0].classList.remove("error-field");
            document.getElementsByClassName("error-text")[1].style.display = "none";
            document.getElementsByClassName("register-name")[0].classList.remove("error-field");
            document.getElementsByClassName("error-text")[2].style.display = "none";
            document.getElementsByClassName("register-password")[0].classList.remove("error-field");
            document.getElementsByClassName("error-text")[3].style.display = "none";
            document.getElementsByClassName("register-passwordConfirm")[0].classList.remove("error-field");
            document.getElementsByClassName("error-text")[4].style.display = "none";
            document.getElementsByClassName("register-activation-code")[0].classList.remove("error-field");

            setShow(true);
            setShowRegisterAlert(true);

            setTimeout(() => {
              setShow(false);
              setShowRegisterAlert(false);
              setIsRedirect(true);
            }, 1000);

            setTimeout(() => {
              window.location.replace("/login");
            }, 1500);
          } else {
            console.log("Error: Access Denied");
          }
        } else {
          console.log("Passwords are not equal.");
        }
      })
      .catch(e => {
        document.getElementById("registerError").classList.add("showError");

        const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!email || !regexEmail.test(String(email).toLowerCase())){
          document.getElementsByClassName("error-text")[0].style.display = "block";
          document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a valid e-mail.";
          document.getElementsByClassName("register-email")[0].classList.add("error-field");
        } else {
          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("register-email")[0].classList.remove("error-field");
        }

        if(!name){
          document.getElementsByClassName("error-text")[1].style.display = "block";
          document.getElementsByClassName("error-text")[1].innerHTML = "Please enter a username.";
          document.getElementsByClassName("register-name")[0].classList.add("error-field");
        } else {
          document.getElementsByClassName("error-text")[1].style.display = "none";
          document.getElementsByClassName("register-name")[0].classList.remove("error-field");
        }

        if(password === passwordConfirm){
          if(password != null && passwordConfirm != null && password != "" && passwordConfirm != "null"){
            document.getElementsByClassName("error-text")[2].style.display = "none";
            document.getElementsByClassName("error-text")[2].innerHTML = "";
            document.getElementsByClassName("register-password")[0].classList.remove("error-field");
            document.getElementsByClassName("error-text")[3].style.display = "none";
            document.getElementsByClassName("error-text")[3].innerHTML = "";
            document.getElementsByClassName("register-passwordConfirm")[0].classList.remove("error-field");
          } else {
            document.getElementsByClassName("error-text")[2].style.display = "block";
            document.getElementsByClassName("error-text")[2].innerHTML = "Password can't be empty.";
            document.getElementsByClassName("register-password")[0].classList.add("error-field");
            document.getElementsByClassName("error-text")[3].style.display = "block";
            document.getElementsByClassName("error-text")[3].innerHTML = "Password can't be empty.";
            document.getElementsByClassName("register-passwordConfirm")[0].classList.add("error-field");
          }
        } else if(password !== passwordConfirm){
          document.getElementsByClassName("error-text")[2].style.display = "block";
          document.getElementsByClassName("error-text")[2].innerHTML = "Password should be match.";
          document.getElementsByClassName("register-password")[0].classList.add("error-field");
          document.getElementsByClassName("error-text")[3].style.display = "block";
          document.getElementsByClassName("error-text")[3].innerHTML = "Password confirm should be match.";
          document.getElementsByClassName("register-passwordConfirm")[0].classList.add("error-field");
        } /*else if(password === passwordConfirm && password == "" && passwordConfirm == "" && password != null && passwordConfirm != null){
          document.getElementsByClassName("error-text")[2].style.display = "block";
          document.getElementsByClassName("error-text")[2].innerHTML = "Password should be match.";
          document.getElementsByClassName("register-password")[0].classList.add("error-field");
          document.getElementsByClassName("error-text")[3].style.display = "block";
          document.getElementsByClassName("error-text")[3].innerHTML = "Password confirm should be match.";
          document.getElementsByClassName("register-passwordConfirm")[0].classList.add("error-field");
        }*/ else if(!password){
          document.getElementsByClassName("error-text")[2].style.display = "block";
          document.getElementsByClassName("error-text")[2].innerHTML = "Password can't be empty.";
          document.getElementsByClassName("register-password")[0].classList.add("error-field");
        } else if(!passwordConfirm){
          document.getElementsByClassName("error-text")[3].style.display = "block";
          document.getElementsByClassName("error-text")[3].innerHTML = "Password can't be empty.";
          document.getElementsByClassName("register-passwordConfirm")[0].classList.add("error-field");
        } else {
          document.getElementsByClassName("error-text")[2].style.display = "block";
          document.getElementsByClassName("error-text")[2].innerHTML = "Password must be match";
          document.getElementsByClassName("register-password")[0].classList.add("error-field");
          document.getElementsByClassName("error-text")[3].style.display = "block";
          document.getElementsByClassName("error-text")[3].innerHTML = "Password confirm must be match";
          document.getElementsByClassName("register-passwordConfirm")[0].classList.add("error-field");
        }

        if(activationCode.length === 6){
          document.getElementsByClassName("error-text")[4].style.display = "none";
          document.getElementsByClassName("error-text")[4].innerHTML = "";
          document.getElementsByClassName("register-activation-code")[0].classList.remove("error-field");
        } else if(activationCode.length > 6){
          document.getElementsByClassName("error-text")[4].style.display = "block";
          document.getElementsByClassName("error-text")[4].innerHTML = "Digits length must be 6";
          document.getElementsByClassName("register-activation-code")[0].classList.add("error-field");
        } else {
          document.getElementsByClassName("error-text")[4].style.display = "block";
          document.getElementsByClassName("error-text")[4].innerHTML = "Digits length is not correct";
          document.getElementsByClassName("register-activation-code")[0].classList.add("error-field");
        }

        //document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Invalid credentials.";
        //document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";

        console.log(e);
      })

  };

  const registerSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid e-mail')
      .required('E-Mail is required'),
    name: Yup.string()
    .required('Username is required'),
    referralCode: Yup.string()
      .min(6, 'Must be have 6 digits')
      .required('Invitation Code is required'),
    password: Yup.string()
      .required('Password is required'),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must be match')
  });

  return (
    <ReactBootstrap.Container fluid={true}>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col md={5} lg={7} xl={7} id="register" className="d-none d-sm-none d-md-block">
          <div className="tbs--main--block">
            <div className="tbs--main--title">
              {/*<img src={Logo}/>*/}
              meoh TBS
            </div>
            <div className="tbs--main--subtitle text-center">
              Leverage your trust network
            </div>
          </div>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={7} lg={5} xl={5} id="registerForm">
          <div className="tbs--main--block--two d-block d-sm-block d-md-none d-lg-none d-xl-none">
            <div className="tbs--main--title">
              {/*<img src={LogoDark}/>*/}
              meoh TBS
            </div>
            {/*<div className="tbs--main--subtitle text-center">
              Trust Building System
            </div>*/}
          </div>
          <div className="tbs--form--block">
            <div className="tbs--form--title tbs--form--title--register">
              WELCOME <span>(Beta)</span>
            </div>
            <div className="tbs--form--complete">
              {
                /*
                  <div className="tbs--form--main--error text-center"></div>
                */
              }
              <div className="tbs--form--complete--profile">
                <Formik
                  initialValues={{
                    email: '',
                    name: '',
                    referralCode: '',
                    password: '',
                    passwordConfirm: ''
                  }}
                  validationSchema={registerSchema}
                  onSubmit={values => {
                    console.log(values.text);
                  }}
                >
                  {({errors, touched}) => (
                    <Form onSubmit={validationRegister}>
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="registerError" className="text-center">
                          Invalid credentials. <br/>
                          Please fill out all fields.
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div>
                            <label htmlFor="email">
                              EMAIL
                            </label>
                          </div>
                          <div>
                            <Field type="email"
                                   className={`register-email`}
                                   name="email" id="email" placeholder="Enter E-Mail" value={email}
                                   onChange={handleChangeEmail}/>
                          </div>
                          <div className="error-text">
                            {/*
                              errors.email && touched.email
                                ?
                                errors.email
                                :
                                null
                            */}
                            <ErrorMessage name="email">
                              {errors.email}
                            </ErrorMessage>
                          </div>
                          <div>
                            <label htmlFor="name">
                              NAME
                            </label>
                          </div>
                          <div>
                            <Field type="text"
                                   className={`register-name`}
                                   name="name" id="name" placeholder="Enter Username" value={name}
                                   onChange={handleChangeName}/>
                          </div>
                          <div className="error-text">
                            {/*
                              errors.name && touched.name
                                ?
                                errors.name
                                :
                                null
                            */}
                            <ErrorMessage name="name">
                              {errors.name}
                            </ErrorMessage>
                          </div>
                          <div>
                            <label htmlFor="password">
                              CHOOSE A PASSWORD
                            </label>
                          </div>
                          <div>
                            <Field type="password"
                                   className={`register-password`}
                                   name="password" id="password" placeholder="Enter password" value={password}
                                   onChange={handleChangePassword}/>
                          </div>
                          <div className="error-text">
                            {/*
                              errors.password && touched.password
                                ?
                                errors.password
                                :
                                null
                            */}
                            <ErrorMessage name="name">
                              {errors.name}
                            </ErrorMessage>
                          </div>
                          <div>
                            <label htmlFor="passwordConfirm">
                              CONFIRM PASSWORD
                            </label>
                          </div>
                          <div>
                            <Field type="password"
                                   className={`register-passwordConfirm`}
                                   name="passwordConfirm" id="passwordConfirm" placeholder="Confirm password" value={passwordConfirm}
                                   onChange={handleChangePasswordConfirm}/>
                          </div>
                          <div className="error-text">
                            {/*
                              errors.passwordConfirm && touched.passwordConfirm
                                ?
                                errors.passwordConfirm
                                :
                                null
                            */}
                            <ErrorMessage name="passwordConfirm">
                              {errors.passwordConfirm}
                            </ErrorMessage>
                          </div>
                          <div>
                            <label htmlFor="activationCode">
                              YOUR INVITATION CODE
                            </label>
                          </div>
                          <div>
                            <Field type="text"
                                   className={`register-activation-code`}
                                   name="activationCode" id="activationCode" placeholder="Enter Invitation Code" value={activationCode}
                                   onChange={handleChangeActivationCode}/>
                          </div>
                          <div className="error-text">
                            {/*
                              errors.activationCode && touched.activationCode
                                ?
                                errors.activationCode
                                :
                                null
                            */}
                            <ErrorMessage name="activationCode">
                              {errors.activationCode}
                            </ErrorMessage>
                          </div>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                      <div className="text-center button--sign">
                        <ReactBootstrap.Button type="submit">Register</ReactBootstrap.Button>
                      </div>
                      <div>
                        <p>Already have an account? <Link to="/login" className="link--custom--style">Log in here!</Link></p>
                      </div>
                      <div className="link--custom--public">
                        <p>
                          <Link to="/public-groups" className="link--custom--style">
                            <div className="text-center button--sign">
                              <ReactBootstrap.Button type="submit">Explore public groups</ReactBootstrap.Button>
                            </div>
                          </Link>
                        </p>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {
            show
              ?
              <ReactBootstrap.Alert variant="success" transition={true} id="registration-alert" className="text-center">
                <ReactBootstrap.Alert.Heading>
                  User account successful created.
                </ReactBootstrap.Alert.Heading>
              </ReactBootstrap.Alert>
              :
              ""
          }
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col>
          {
            showRegisterAlert
            ?
              <ReactBootstrap.Alert variant="success" transition={true} id="success-register-alert" className="text-center">
                <ReactBootstrap.Alert.Heading>
                  Registration was successful.
                </ReactBootstrap.Alert.Heading>
              </ReactBootstrap.Alert>
            :
              ""
          }
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <MobileAppBanner/>
    </ReactBootstrap.Container>
  );
}

export default Register;
