import React, {useState, useEffect, useReducer, useCallback, useRef} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import axios from 'axios';
import {Link, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import moment from "moment";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Linkify from "react-linkify";
import {useMediaQuery} from "react-responsive";
import {isSafari} from "react-device-detect";

import API from "./../../config/config.json";

import useOutsideClick from "../../helper/useOutsideClick";
import AllAwards from "./socialNetwork/allAwards";
import AllAwardsContact from "./socialNetwork/allAwardsContact";

import IconAvatar from './../../assets/svg/avatar.svg';
import IconAvatarAdd from './../../assets/svg/introduceAddIcon.svg';
import IconAwardsActive from './../../assets/icons/IconAwardsBlue.png';
import IconComment from "../../assets/icons/IconComment.png";
import IconRepost from "../../assets/icons/IconRepost.png";
import IconForward from "../../assets/icons/IconForward.png";
import IconBookmarks from "../../assets/icons/IconBookmarks.png";
import IconThreeDots from "../../assets/icons/IconThreeDots.png";
import IconBookmarksActive from "../../assets/icons/IconBookmarksBlue.png";
import IconPulse from "../../assets/icons/IconPulse.png";
import IconFeatured from "../../assets/icons/IconFeatured.png";
import IconFeaturedWhite from "../../assets/icons/IconFeaturedWhite.png";
import IconIntroduce from "../../assets/icons/IconIntroduce.png";
import MenuInvitation from "../../assets/icons/IconInvatations.png";
import IconChat from "../../assets/icons/IconChatsWhite.png";
import IconClose from "../../assets/icons/IconDelete.png";
import IconTip from "../../assets/icons/IconTip.png";
import IconPlayWhite from "../../assets/svg/playWhite.svg";
import IconPlayBlack from "../../assets/svg/playBlack.svg";
import chatFriend from "../../assets/svg/chatFriend.svg";
import AcceptSVG from "../../assets/svg/accept";
import UnFeaturedSVG from "../../assets/svg/unfeatured";
import FeaturedSVG from "../../assets/svg/featured";
import IntroduceSVG from "../../assets/svg/introduce";
import ChatFriendSVG from "../../assets/svg/chatFriend";
import ChatSVG from "../../assets/svg/chat";
import ChatWhiteSVG from "../../assets/svg/chatWhite";
import PostBookmarkActiveSVG from "../../assets/svg/postBookmarkActive";
import PostBookmarkSVG from "../../assets/svg/postBookmark";
import PostCommentSVG from "../../assets/svg/postComment";
import PostRepostSVG from "../../assets/svg/postRepost";
import PostNoRepostSVG from "../../assets/svg/postNoRepost";
import PostForwardSVG from "../../assets/svg/postForward";
import PostActivitySVG from "../../assets/svg/postActivity";

function OtherProfile(props) {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get the params
  const { match: { params } } = props;

  // Get url from all friends
  const urlMain = API.URL + 'friends';

  // Get url from other user profile
  const urlProfile = API.URL + "users/profile/" + params.id;

  // Get url from other user profile awards
  const urlTwo = API.URL + "users/" + params.id + "/all-awards";

  // Get url from feeds from other user
  const urlFeeds = API.URL + 'users/' + params.id + '/posts';

  // Get url from mutual friends from other profile
  const urlMutual = API.URL + 'users/' + params.id + '/mutual-friends';

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Get 'Tip - Feature contact' and 'Tip - Remove contact' - Storage
  let featureContactTip = localStorage.getItem("tbs-tip-feature-contact-" + userID);
  let removeContactTip = localStorage.getItem("tbs-tip-remove-contact-" + userID);

  // Store and set the state value
  const [introductionMessage, setIntroductionMessage] = useState('');
  const [friends, setFriends] = useState([]);
  const [friendsNewest, setFriendsNewest] = useState([]);
  const [rData, setRData] = useState([]);
  const [r2Data, setR2Data] = useState([]);
  const [r3Data, setR3Data] = useState([]);
  const [mutualData, setMutualData] = useState([]);
  const [contactSelect, setContactSelect] = useState([]);
  const [contact, setContact] = useState([]);
  const [favorite, setFavorite] = useState(false);
  const [tValue, setTValue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenFeatured, setIsModalOpenFeatured] = useState(false);
  const [isModalIntroduceOpen, setIsModalIntroduceOpen] = useState(false);
  const [isModalContactOpen, setIsModalContactOpen] = useState(false);
  const [isModalAwardsOpen, setIsModalAwardsOpen] = useState(false);
  const [isModalRemoveOpen, setIsModalRemoveOpen] = useState(false);
  const [isModalBlockOpen, setIsModalBlockOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showBlockMessage, setShowBlockMessage] = useState(false);
  const [showFavorite, setShowFavorite] = useState(false);
  const [showBlockUser, setShowBlockUser] = useState(false);
  const [isModalOpenPicture, setIsModalOpenPicture] = useState(false);
  const [alertIntroduce, setAlertIntroduce] = useState(false);
  const [errorIntroduce, setErrorIntroduce] = useState("");
  const [isBiggerThanTablet, setIsBiggerThanTablet] = useState(window.innerWidth > 991);

  const isBiggerTablet = useMediaQuery({ query: '(min-width: 991px)' })

  // Main container for history and location
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    const handleWindowResize = () => setIsBiggerThanTablet(window.innerWidth > 991);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  },[]);

  useEffect(async () => {
    const resultMain = await axios.get(
      urlMain, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setFriends(resultMain.data.items);

    const result = await axios.get(
      urlProfile, {
        headers: {
          authorization: token
        }
      }
    );

    setRData(result.data);

    if(result.data.isBlocked == true){
      setShowBlockUser(true);
    } else {
      setShowBlockUser(false);
    }

    const resultAward = await axios.get(
      urlTwo, {
        headers: {
          authorization: token
        }
      }
    );

    setR2Data(resultAward.data.items);

    const resultFeeds = await axios.get(
      urlFeeds, {
        headers: {
          authorization: token
        }
      }
    );

    setR3Data(resultFeeds.data.items);

    const resultMutual = await axios.get(
      urlMutual, {
        headers: {
          authorization: token
        }
      }
    );

    setMutualData(resultMutual.data.items);

    let allLinks = document.querySelectorAll("#moreOtherProfile a");

    for(let i = 0; i < allLinks.length; i++){
      allLinks[i].setAttribute("target", "_blank");
    }

    if(featureContactTip){
      document.getElementById("tip-feature-contact").classList.add("tips-disabled");
    } else {
      document.getElementById("tip-feature-contact").classList.remove("tips-disabled");
    }

    if(removeContactTip){
      document.getElementById("tip-remove-contact").classList.add("tips-disabled");
    } else {
      document.getElementById("tip-remove-contact").classList.remove("tips-disabled");
    }
  }, []);

  // fetch data and validate
  const deleteFriend = (id) => {
    // URL for deleting friend
    const url = API.URL + 'friends/' + id;

    axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);
          setShowRemove(true);

          setTimeout(() => {
            setShow(false);
          }, 1000);

          setTimeout(() => {
            window.location.replace("/dashboard/newsfeed");
          }, 1200);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Feature validation method
  const validationFeatureThisContact = (id, status) => {
    // URL for the feature status
    const url = API.URL + 'friends/' + id;

    // Data validation
    const data = {
      isFavorite: status
    }

    // fetch data and validate
    axios
      .patch(url, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);
          if(status === true){
            setShowFavorite(false);
          } else {
            setShowFavorite(true);
          }

          setTimeout(() => {
            setShow(false);
          }, 1000);

          setTimeout(async () => {
            const resultMain = await axios.get(
              urlMain, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setFriends(resultMain.data.items);
          }, 1200);
        } else {
          console.log("Error: Cannot handle this method.");
        }
      })
      .catch(e => {
        console.log(e);
      })

  };

  // Get the "new" chat
  const createChat = (chatId) => {
    // URL for creating a chat
    const urlNewChat = API.URL + 'conversations';

    // Post value for validation
    const data = {
      name: "Hi",
      type: "SINGLE",
      receiverId: [
        chatId
      ]
    };

    // fetch data and validate
    axios
      .post(urlNewChat, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status == 200){
          if(isBiggerThanTablet){
            window.location.href = "/dashboard/chats/" + response.data.id;
          } else {
            window.location.href = "/dashboard/chats/" + response.data.id + "/channel";
          }
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const createIntroduce = (e) => {
    e.preventDefault();

    // URL for create an introduce between contacts
    // const urlIntroduce = API.URL + 'conversations';
    const urlIntroduce = API.URL + 'introduce-conversations';

    // Post value for validation
    const data = {
      name: introductionMessage,
      type: "GROUP",
      rule: "",
      //groupType: "introduce",
      description: "introduce",
      receiverIds: contact
    };

    if(introductionMessage != "" && contact.length > 0){

      document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "";
      document.getElementsByClassName("tbs--form--main--error")[0].style.display = "none";
      /*document.getElementsByClassName("error-text")[0].style.display = "none";
      document.getElementsByClassName("error-text")[0].innerHTML = "";*/

      axios
        .post(urlIntroduce, data, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        })
        .then((response) => {
          if(response.status == 200){
            setAlertIntroduce(true);

            setTimeout(() => {
              setAlertIntroduce(false);
            }, 1000);

            setTimeout(() => {
              {
                isBiggerThanTablet
                ?
                  window.location.replace(`/dashboard/chats/${response.data.id}`)
                :
                  window.location.replace(`/dashboard/chats/${response.data.id}/channel`)
              }
            }, 1500);
          } else {
            setErrorIntroduce(response.data.error)
          }
        })
        .catch(e => {
          console.log(e)
          setErrorIntroduce(e.response.data.error)
        })
    } else {
      /*document.getElementsByClassName("error-text")[0].style.display = "block";
      document.getElementsByClassName("error-text")[0].innerHTML = "Please select a member from your trusted contacts.";*/

      document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Please select a contact first.";
      document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
    }
  }

  // Block an user
  const blockUser = (userId) => {
    // URL for block an user
    const urlBlockUser = API.URL + 'users/' + userId + '/block';

    // fetch data and validate
    axios
      .put(urlBlockUser, '', {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status == 200){
          setShowTwo(true);
          setShowBlockUser(true);
          setShowBlockMessage(true);

          setTimeout(() => {
            setShowTwo(false);
            /*setShowBlockUser(false);
            setShowBlockMessage(false);*/
          }, 1000);

          setTimeout(async () => {
            const result = await axios.get(
              urlProfile, {
                headers: {
                  authorization: token
                }
              }
            );

            setRData(result.data);

            const resultFeeds = await axios.get(
              urlFeeds, {
                headers: {
                  authorization: token
                }
              }
            );

            setR3Data(resultFeeds.data.items);
          }, 1200);
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Unblock an user
  const unblockUser = (userId) => {
    // URL for unblock an user
    const urlUnblockUser = API.URL + 'users/' + userId + '/un-block';

    // fetch data and validate
    axios
      .put(urlUnblockUser, '', {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status == 200){
          setShowTwo(true);
          setShowBlockUser(false);
          setShowBlockMessage(true);

          setTimeout(() => {
            setShowTwo(false);
            /*setShowBlockUser(false);
            setShowBlockMessage(false);*/
          }, 1000);

          setTimeout(async () => {
            const result = await axios.get(
              urlProfile, {
                headers: {
                  authorization: token
                }
              }
            );

            setRData(result.data);

            const resultFeeds = await axios.get(
              urlFeeds, {
                headers: {
                  authorization: token
                }
              }
            );

            setR3Data(resultFeeds.data.items);
          }, 1000);
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const Schema = Yup.object().shape({
    receivers: Yup.array()
      .required('Please add one trusted contacts to create an introduction.')
  });

  const saveContact = (nameFirst) => {
    // Get the value of the radio button which is checked.
    const data = [...document.querySelectorAll('.select-contact:checked')].map(e => Number(e.value));
    const newArr = [...document.querySelectorAll('.select-contact:checked')].map(e => Number(e.value));
    const nameContact = [...document.querySelectorAll('.select-contact:checked')].map(e => e.getAttribute("id"));

    newArr.push(Number(params.id));

    setContactSelect(data);
    setContact(newArr);
    setIsModalContactOpen(false);
    setIntroductionMessage(nameFirst + " meet " + nameContact);

    /*console.log(data);
    console.log(newArr);
    console.log(nameFirst + " meets " + nameContact);*/
  }

  const tipClose = () => {
    localStorage.setItem("tbs-tip-feature-contact-" + userID, "Feature contact tips accepted.");
    document.getElementById("tip-feature-contact").classList.add("tips-disabled");
  }

  const tipCloseSecond = () => {
    localStorage.setItem("tbs-tip-remove-contact-" + userID, "Remove contact tips accepted.");
    document.getElementById("tip-remove-contact").classList.add("tips-disabled");
  }

  // Method for toggle the small menu
  const menu = document.getElementById("tbs--menu--other--profile");

  const toggleMenu = () => {
    if(!isOpen){
      setIsOpen(true);
      menu.classList.add("open-menu");
    } else {
      setIsOpen(false);
      menu.classList.remove("open-menu");
    }
  }

  // Change the text and icon in a toggle method
  const toggleText = () => {
    const elem = document.getElementById("moreOtherProfile");
    if(elem.classList.contains("show")){
      setTValue(false);
    } else {
      setTValue(true);
    }
  }

  const removeUser = () => {
    deleteFriend(params.id);
    setIsModalRemoveOpen(false);
  }

  const userUnBlock = () => {
    if(showBlockUser){
      unblockUser(params.id)
    } else {
      blockUser(params.id)
    }
    setIsModalBlockOpen(false);
  }

  /*
  const ref = useRef();

  useOutsideClick(ref, () => {
    if(isOpen){
      setIsOpen(false);
      menu.classList.remove("open-menu");
    }
  });

  const refreshPage = (id) => {
    history.push();
    window.location.replace(`/dashboard/profile/${id}`);
  }
  */

  const truncateMessage = (str, num) => {

    if(str == null || !str) {
      return "Read more ...";
    }

    if(str.length <= num) {
      return str;
    }

    return str.slice(0, num) + ' ... Read more ...';
  }

  return (
    <ReactBootstrap.Row className="tbs--friends" id={"profile-" + params.id}>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-feature-contact">
        <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9}>
              <div className="tbs--tips--subheadline">
                TIP
              </div>
              <div>
                Featuring trusted contacts is a great way to create new opportunities.
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
              <div onClick={tipClose}>
                <AcceptSVG/>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-remove-contact">
        <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9} className="tbs--tips--context">
              <div className="tbs--tips--subheadline">
                TIP
              </div>
              <div>
                You can always re-invite this person at a later time.
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
              <div onClick={tipCloseSecond}>
                <AcceptSVG/>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>*/}
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          show
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="other-profile-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>
                {
                  showRemove
                  ?
                    "Trusted contact has been removed."
                  :
                    showFavorite
                    ?
                      "Your trusted contact has been successfully featured."
                    :
                      "Your contact has been successfully un-featured."
                }
              </ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          showTwo
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="other-profile-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>
                {
                  showBlockMessage
                    ?
                    showBlockUser
                      ?
                      "Your contact has been successfully blocked."
                      :
                      "Your contact has been successfully un-blocked."
                    :
                    ""
                }
              </ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="profile">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--header">
              <div>
                <img src={rData.image != null ? rData.image == null ? rData.profileImage.url : rData.image : rData.profileImage == null ? IconAvatar : rData.profileImage.url} className="tbs--image--main" alt="Profile" onClick={() => setIsModalOpenPicture(true)}/>
              </div>
              <ReactBootstrap.Modal
                show={isModalOpenPicture}
                onHide={() => setIsModalOpenPicture(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <ReactBootstrap.Modal.Header closeButton>
                  <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                    Profile Image
                  </ReactBootstrap.Modal.Title>
                </ReactBootstrap.Modal.Header>
                <ReactBootstrap.Modal.Body id="image-profile-big">
                  <ReactBootstrap.Row>
                    <img src={rData.image != null ? rData.image == null ? rData.profileImage.url : rData.image : rData.profileImage == null ? IconAvatar : rData.profileImage.url} alt="Profile"/>
                  </ReactBootstrap.Row>
                </ReactBootstrap.Modal.Body>
              </ReactBootstrap.Modal>
              {
                friends.map((item, index) => {
                  if(item.user.id == params.id){
                    return (
                      <div id="menu-other-profile">
                        <ReactBootstrap.DropdownButton
                          menuAlign="right"
                          title={
                            <div>
                              <img src={IconThreeDots} className="tbs--image--menu--profile" alt="Menu - Other Profile"/>
                            </div>
                          }
                          id="dropdown-menu-other-profile"
                        >
                          <div className="dropdown-item" onClick={() => setIsModalRemoveOpen(true)}>Remove User</div>
                          {
                            showBlockUser
                            ?
                              <div className="dropdown-item" onClick={() => setIsModalBlockOpen(true)}>Unblock User</div>
                            :
                              <div className="dropdown-item" onClick={() => setIsModalBlockOpen(true)}>Block User</div>
                          }
                          <div className="dropdown-item">
                            <Link to={`/dashboard/profile/${params.id}/report`}>Report
                              User
                            </Link>
                          </div>
                        </ReactBootstrap.DropdownButton>
                      </div>
                    )
                  }
                })
              }
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <ReactBootstrap.Modal
                show={isModalRemoveOpen}
                onHide={() => setIsModalRemoveOpen(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter-remove"
                centered
              >
                <ReactBootstrap.Modal.Header closeButton>
                  <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-remove">
                    Remove user
                  </ReactBootstrap.Modal.Title>
                </ReactBootstrap.Modal.Header>
                <ReactBootstrap.Modal.Body>
                  <div className="text-center button--sign" id="remove-user">
                    <ReactBootstrap.Button onClick={removeUser}>Remove</ReactBootstrap.Button>
                  </div>
                </ReactBootstrap.Modal.Body>
              </ReactBootstrap.Modal>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <ReactBootstrap.Modal
                show={isModalBlockOpen}
                onHide={() => setIsModalBlockOpen(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter-remove"
                centered
              >
                <ReactBootstrap.Modal.Header closeButton>
                  <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-remove">
                    {
                      showBlockUser
                        ?
                        "Unblock"
                        :
                        "Block"
                    } user
                  </ReactBootstrap.Modal.Title>
                </ReactBootstrap.Modal.Header>
                <ReactBootstrap.Modal.Body>
                  <div className="text-center button--sign" id="remove-user">
                    <ReactBootstrap.Button onClick={userUnBlock}>
                      {
                        showBlockUser
                        ?
                          "Unblock"
                        :
                          "Block"
                      }
                    </ReactBootstrap.Button>
                  </div>
                </ReactBootstrap.Modal.Body>
              </ReactBootstrap.Modal>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--main--data text-center">
              <div className="tbs--contact--data">
                <div className="tbs--contact--data--name">
                  {rData.name}
                </div>
                {
                  (rData.position != "" || rData.position != null) && (rData.company == "" || rData.company == null)
                    ?
                    <div className="tbs--contact--data--work">
                      {rData.position}
                    </div>
                    :
                    (rData.company != "" || rData.company != null) && (rData.position == "" || rData.position == null)
                      ?
                      <div className="tbs--contact--data--work">
                        {rData.company}
                      </div>
                      :
                      (rData.company != "" || rData.company != null) && (rData.position != "" || rData.position != null)
                        ?
                        <div className="tbs--contact--data--work">
                          {rData.position} at {rData.company}
                        </div>
                        :
                          ""
                }
                {
                  (rData.city != "" || rData.city != null) && (rData.country == "" || rData.country == null)
                    ?
                    <div className="tbs--contact--data--region">
                      {rData.city}
                    </div>
                    :
                    (rData.country != "" || rData.country != null) && (rData.city == "" || rData.city == null)
                      ?
                      <div className="tbs--contact--data--region">
                        {rData.country}
                      </div>
                      :
                      (rData.city != "" || rData.city != null) && (rData.country != "" || rData.country != null)
                      ?
                      <div className="tbs--contact--data--region">
                        {rData.city}, {rData.country}
                      </div>
                        :
                        ""
                }
              </div>
              {
                rData.about != ""
                  ?
                  <div className="tbs--about--data">
                    {rData.about}
                  </div>
                  :
                  ""
              }
              {
                friends.map((item, index) => {
                  if(item.user.id == params.id){
                    return (
                      <div>
                        <div className="tbs--main--data--contact text-center">
                          {
                            item.isFavorite == true
                            ?
                              <div className="tbs--main--data--contact--feature" onClick={() => validationFeatureThisContact(params.id, true)}>
                                <div>
                                  {/*<img src={IconFeaturedWhite} alt="Feature"/>*/}
                                  <FeaturedSVG/>
                                </div>
                                <div>Un-Feature</div>
                              </div>
                            :
                              <div className="tbs--main--data--contact--feature" onClick={() => validationFeatureThisContact(params.id, false)}>
                                <div>
                                  {/*<img src={IconFeatured} alt="Feature"/>*/}
                                  <UnFeaturedSVG/>
                                </div>
                                <div>Feature</div>
                              </div>
                          }
                          <div className="tbs--main--data--contact--introduce" onClick={() => setIsModalIntroduceOpen(true)}>
                            <div>
                              {/*<img src={IconIntroduce} alt="Introduce"/>*/}
                              <IntroduceSVG/>
                            </div>
                            <div>Introduce</div>
                          </div>
                          <ReactBootstrap.Modal
                            show={isModalIntroduceOpen}
                            onHide={() => setIsModalIntroduceOpen(false)}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <ReactBootstrap.Modal.Header closeButton>
                              <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                                Introduce {item.user.name} to ...
                              </ReactBootstrap.Modal.Title>
                            </ReactBootstrap.Modal.Header>
                            <ReactBootstrap.Modal.Body>
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center tbs--avatar--select--user">
                                  <img src={IconAvatarAdd} alt="Add a trusted contact" onClick={() => setIsModalContactOpen(true)}/>
                                  {
                                    friends.map(trustedContact => {
                                      if(contactSelect.includes(trustedContact.friendId)){
                                        return(
                                          <div>
                                            <div>
                                              <img src={trustedContact.user.image != null ? trustedContact.user.image : trustedContact.user.profileImage != null ? trustedContact.user.profileImage.url : IconAvatar} alt={trustedContact.user.name} />
                                            </div>
                                            <div>
                                              {trustedContact.user.name}
                                            </div>
                                          </div>
                                        )
                                      }
                                    })
                                  }
                                  <ReactBootstrap.Modal
                                    show={isModalContactOpen}
                                    onHide={() => setIsModalContactOpen(false)}
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter-two"
                                    centered
                                  >
                                    <ReactBootstrap.Modal.Header closeButton>
                                      <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-two">
                                        List of trusted contacts
                                      </ReactBootstrap.Modal.Title>
                                    </ReactBootstrap.Modal.Header>
                                    <ReactBootstrap.Modal.Body>
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                          {
                                            friends.map((contact, index) => {
                                              return (
                                                <ReactBootstrap.Row>
                                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-left">
                                                    {
                                                      contact.user.id == params.id
                                                      ?
                                                        ""
                                                      :
                                                        <ReactBootstrap.Row className="tbs--contact--introduce">
                                                          <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="text-left">
                                                            <img src={contact.user.image != null ? contact.user.image : contact.user.profileImage != null ? contact.user.profileImage.url : IconAvatar} alt="Profile image" />
                                                          </ReactBootstrap.Col>
                                                          <ReactBootstrap.Col xs={7} sm={7} md={7} lg={7} xl={7} className="text-left">
                                                            {contact.user.name}
                                                          </ReactBootstrap.Col>
                                                          <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="text-right">
                                                            <input type="radio" id={contact.user.name} className="select-contact" name="contact" value={contact.user.id}/>
                                                          </ReactBootstrap.Col>
                                                        </ReactBootstrap.Row>
                                                    }
                                                  </ReactBootstrap.Col>
                                                </ReactBootstrap.Row>
                                              )
                                            })
                                          }
                                          <ReactBootstrap.Row className="tbs--submit--contact">
                                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                              <div className="text-center button--sign">
                                                <ReactBootstrap.Button onClick={() => saveContact(item.user.name)}>Select Contact</ReactBootstrap.Button>
                                              </div>
                                            </ReactBootstrap.Col>
                                          </ReactBootstrap.Row>
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                    </ReactBootstrap.Modal.Body>
                                  </ReactBootstrap.Modal>
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <div className="form-create-group">
                                    <div className="tbs--form--main--error text-center"></div>
                                    <div className="tbs--form--complete--post">
                                      <Formik
                                        initialValues={{
                                          receiverIds: []
                                        }}
                                        validationSchema={Schema}
                                        onSubmit={values => {
                                          console.log(values.text);
                                        }}
                                      >
                                        {({ errors, touched }) => (
                                          <Form onSubmit={createIntroduce}>
                                            <ReactBootstrap.Row>
                                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center error-text">
                                                <div>{errorIntroduce}</div>
                                              </ReactBootstrap.Col>
                                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center tbs--submit--introduce--button">
                                                <div className="text-center button--sign">
                                                  <ReactBootstrap.Button type="submit">Introduce</ReactBootstrap.Button>
                                                </div>
                                              </ReactBootstrap.Col>
                                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                {
                                                  alertIntroduce
                                                  ?
                                                    <ReactBootstrap.Alert variant="success" transition={true} id="introduce-friend-alert" className="text-center">
                                                      <ReactBootstrap.Alert.Heading>
                                                        Introducing was successful
                                                      </ReactBootstrap.Alert.Heading>
                                                    </ReactBootstrap.Alert>
                                                  :
                                                    ""
                                                }
                                              </ReactBootstrap.Col>
                                            </ReactBootstrap.Row>
                                          </Form>
                                        )}
                                      </Formik>
                                    </div>
                                  </div>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                            </ReactBootstrap.Modal.Body>
                          </ReactBootstrap.Modal>
                          <div className="tbs--main--data--contact--chat" onClick={() => createChat(params.id)}>
                            <div className="tbs--chat--friends--image">
                              {/*<img src={IconChat} alt="Chat"/>
                              <ChatFriendSVG/>*/}
                              <img src={chatFriend} alt="Chat"/>
                            </div>
                            <div>Chat</div>
                          </div>
                        </div>
                        <div className="tbs--main--data--contact text-center">
                          <div className="tbs--main--data--contact--mutual--featured--user">
                            <Link to="#" onClick={() => setIsModalOpen(true)}>
                              <span>{mutualData.length} Mutual contacts</span>
                            </Link>
                            <ReactBootstrap.Modal
                              show={isModalOpen}
                              onHide={() => setIsModalOpen(false)}
                              size="md"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <ReactBootstrap.Modal.Header closeButton>
                                <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                                  Mutual contacts
                                </ReactBootstrap.Modal.Title>
                              </ReactBootstrap.Modal.Header>
                              <ReactBootstrap.Modal.Body>
                                <ReactBootstrap.Row>
                                  {
                                    !mutualData || mutualData.length === 0
                                    ?
                                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                        <div>
                                          You don't have any mutual contacts yet
                                        </div>
                                      </ReactBootstrap.Col>
                                    :
                                      mutualData.map((mutualFriend) => {
                                        return(
                                          <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4} className="text-center" key={mutualFriend.id}>
                                            <Link to={`/dashboard/profile/` + params.id + `/friends/${mutualFriend.id}`}>
                                              <div className="tbs--mutual--friends--image">
                                                <img src={
                                                  mutualFriend.profileImage != null
                                                  ?
                                                    mutualFriend.profileImage.url
                                                  :
                                                    mutualFriend.image != null
                                                    ?
                                                      mutualFriend.image
                                                    :
                                                      IconAvatar
                                                } alt="Profile - Name"/>
                                              </div>
                                              <div className="tbs--mutual--friends--name">
                                                {mutualFriend.name}
                                              </div>
                                            </Link>
                                          </ReactBootstrap.Col>
                                        )
                                      })
                                  }
                                </ReactBootstrap.Row>
                              </ReactBootstrap.Modal.Body>
                            </ReactBootstrap.Modal>
                            <Link to="#" onClick={() => setIsModalOpenFeatured(true)}>
                              <span>Featured Contacts</span>
                            </Link>
                            <ReactBootstrap.Modal
                              show={isModalOpenFeatured}
                              onHide={() => setIsModalOpenFeatured(false)}
                              size="md"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <ReactBootstrap.Modal.Header closeButton>
                                <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                                  Featured Contacts
                                </ReactBootstrap.Modal.Title>
                              </ReactBootstrap.Modal.Header>
                              <ReactBootstrap.Modal.Body>
                                {
                                  friends.map((featureFriend) => {
                                    if(featureFriend.friendId == params.id && featureFriend.user.friends.length === 0){
                                      return(
                                        <ReactBootstrap.Row>
                                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                            <div>
                                              This user does not have any featured contacts yet.
                                            </div>
                                          </ReactBootstrap.Col>
                                        </ReactBootstrap.Row>
                                      )
                                    }
                                    else if(featureFriend.friendId == params.id && featureFriend.user.friends.length !== 0) {
                                      return(
                                        <ReactBootstrap.Row key={featureFriend.id}>
                                          {
                                            featureFriend.user.friends.sort(function(a,b){
                                              return b.id - a.id;
                                            }).map((fFriend) => {
                                              if(fFriend.friendId == userID){
                                                return (
                                                  <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4} className="text-center" key={fFriend.id}>
                                                    <Link to={`/dashboard/profile`}>
                                                      <div className="tbs--featured--users--image">
                                                        <img src={fFriend.user.image != null ? fFriend.user.image : fFriend.user.profileImage != null ? fFriend.user.profileImage.url : IconAvatar } alt="Profile - Name"/>
                                                      </div>
                                                      <div className="tbs--featured--users--name">
                                                        {fFriend.user.name}
                                                      </div>
                                                    </Link>
                                                  </ReactBootstrap.Col>
                                                )
                                              } else {
                                                return (
                                                  <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4} className="text-center" key={featureFriend.id}>
                                                    <Link to={`/dashboard/profile/` + params.id + `/friends/${fFriend.friendId}`}>
                                                      <div className="tbs--featured--users--image">
                                                        <img src={fFriend.user.image != null ? fFriend.user.image : fFriend.user.profileImage != null ? fFriend.user.profileImage.url : IconAvatar } alt="Profile - Name"/>
                                                      </div>
                                                      <div className="tbs--featured--users--name">
                                                        {fFriend.user.name}
                                                      </div>
                                                    </Link>
                                                  </ReactBootstrap.Col>
                                                )
                                              }
                                            })
                                          }
                                        </ReactBootstrap.Row>
                                      )
                                    }
                                  })
                                }
                              </ReactBootstrap.Modal.Body>
                            </ReactBootstrap.Modal>
                            <Link to="#" onClick={() => setIsModalAwardsOpen(true)}>
                              <span>Awards</span>
                            </Link>
                            <ReactBootstrap.Modal
                              show={isModalAwardsOpen}
                              onHide={() => setIsModalAwardsOpen(false)}
                              size="md"
                              aria-labelledby="contained-modal-title-vcenter-awards"
                              centered
                            >
                              <ReactBootstrap.Modal.Header closeButton>
                                <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-awards">
                                  Awards
                                </ReactBootstrap.Modal.Title>
                              </ReactBootstrap.Modal.Header>
                              <ReactBootstrap.Modal.Body id="my-awards-info">
                                <AllAwardsContact
                                  id={params.id}
                                />
                              </ReactBootstrap.Modal.Body>
                            </ReactBootstrap.Modal>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
              }
              <div>
                <hr className="divider--line" />
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--more--data">
              <ReactBootstrap.Accordion>
                <ReactBootstrap.Card>
                  <ReactBootstrap.Card.Header className="text-center">
                    <ReactBootstrap.Accordion.Toggle eventKey={2} id="tbs--more--other--data--headline" onClick={() => toggleText()}>
                      {!tValue ? "Show more informations" : "Show less informations"} {!tValue ? <span className={`arrow ${isSafari ? "arrow-browser-safari" : ""}`}></span> : <span className={`arrow--active ${isSafari ? "arrow-browser-safari" : ""}`}></span>}
                    </ReactBootstrap.Accordion.Toggle>
                  </ReactBootstrap.Card.Header>
                  <ReactBootstrap.Accordion.Collapse eventKey={2} id="moreOtherProfile">
                    <ReactBootstrap.Card.Body>
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6} id="business--other">
                          <div className="tbs--category--data--headline">
                            Work
                          </div>
                          {
                            (rData.position == "" && rData.company == "" && rData.expertise == null) == true
                              ?
                              <div className="tbs--accordion--info--block">
                                The user has not provided any information.
                              </div>
                              :
                              ""
                          }
                          {
                            rData.position != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Role
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  {rData.position != null ? rData.position : "-"}
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            rData.company != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Company or organization
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  {rData.company != null ? rData.company : "-"}
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            rData.expertise != null
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Expertise
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  {rData.expertise != null ? rData.expertise : "-"}
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          <div className="tbs--category--data--headline">
                            Education
                          </div>
                          {
                            (rData.fieldOfStudy == "" && rData.education == "" && rData.school == "") == true
                              ?
                              <div className="tbs--accordion--info--block">
                                The user has not provided any information.
                              </div>
                              :
                              ""
                          }
                          {
                            rData.fieldOfStudy != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Field of study
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  {rData.fieldOfStudy != null ? rData.fieldOfStudy : "-"}
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            rData.education != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Degree
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  {rData.education != null ? rData.education : "-"}
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            rData.school != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  School
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  {rData.school != null ? rData.school : "-"}
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6} id="business--other-two">
                          <div className="tbs--category--data--headline">
                            Social
                          </div>
                          {
                            (rData.website == "" && rData.socialLinks == "") == true && ((typeof rData.linkedinUrl == "string" && rData.linkedinUrl == "" && typeof rData.twitterUrl == "string" && rData.twitterUrl == "") || (typeof rData.linkedinUrl == "object" && rData.linkedinUrl == null && typeof rData.twitterUrl == "object" && rData.twitterUrl == null)) == true
                              ?
                              <div className="tbs--accordion--info--block">
                                The user has not provided any information.
                              </div>
                              :
                              ""
                          }
                          {
                            rData.website != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Website
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {rData.website != null ? rData.website : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            typeof rData.linkedinUrl == "string" && rData.linkedinUrl != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  LinkedIn
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {rData.linkedinUrl != "" ? rData.linkedinUrl : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              typeof rData.linkedinUrl == "object" && rData.linkedinUrl != null
                                ?
                                <ReactBootstrap.Row>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                    LinkedIn
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                    <Linkify>
                                      {rData.linkedinUrl != null ? rData.linkedinUrl : "-"}
                                    </Linkify>
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                                :
                                ""
                          }
                          {
                            typeof rData.twitterUrl == "string" && rData.twitterUrl != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Twitter
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {rData.twitterUrl != "" ? rData.twitterUrl : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              typeof rData.twitterUrl == "object" && rData.twitterUrl != null
                                ?
                                <ReactBootstrap.Row>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                    Twitter
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                    <Linkify>
                                      {rData.twitterUrl != null ? rData.twitterUrl : "-"}
                                    </Linkify>
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                                :
                                ""
                          }
                          {
                            rData.socialLinks != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Other social profile
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {rData.socialLinks != null ? rData.socialLinks : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          <div className="tbs--category--data--headline">
                            Conversation Starters
                          </div>
                          {
                            ((typeof rData.interests == "string" && rData.interests == "") || (typeof rData.interests == "object" && rData.interests == null)) == true && (rData.sideProjects == null && rData.askMeAbout == null && rData.lookingFor == null && rData.quoteILike == null && rData.artOrMusicILike == null) == true
                              ?
                              <div className="tbs--accordion--info--block">
                                The user has not provided any information.
                              </div>
                              :
                              ""
                          }
                          {
                            typeof rData.interests == "string" && rData.interests != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Stuff I'm interested
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {rData.interests != "" ? rData.interests : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              typeof rData.interests == "object" && rData.interests != null
                                ?
                                <ReactBootstrap.Row>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                    Stuff I'm interested
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                    <Linkify>
                                      {rData.interests != null ? rData.interests : "-"}
                                    </Linkify>
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                                :
                                ""
                          }
                          {
                            rData.sideProjects != null && rData.sideProjects != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Side projects I'm working on
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {rData.sideProjects != null && rData.sideProjects != "" ? rData.sideProjects : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            rData.askMeAbout != null && rData.askMeAbout != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Ask me about
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {rData.askMeAbout != null && rData.askMeAbout != "" ? rData.askMeAbout : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            rData.lookingFor != null && rData.lookingFor != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  What I'm looking for
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {rData.lookingFor != null && rData.lookingFor != "" ? rData.lookingFor : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            rData.quoteILike != null && rData.quoteILike != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Quote that I like
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {rData.quoteILike != null && rData.quoteILike != "" ? rData.quoteILike : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                          {
                            rData.artOrMusicILike != null && rData.artOrMusicILike != ""
                              ?
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subheadline">
                                  Art or music that I like
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--category--data--subcontent">
                                  <Linkify>
                                    {rData.artOrMusicILike != null && rData.artOrMusicILike != "" ? rData.artOrMusicILike : "-"}
                                  </Linkify>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              :
                              ""
                          }
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="awards--other">
                          {/*<hr className="divider--line" />
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center tbs--awards--headline">
                              <img src={IconAwardsActive} alt="User Awards"/><span>{rData.name} have received {rData.totalAwards} Awards</span>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>*/}
                          <ReactBootstrap.Row>
                            {
                              /*r2Data.map((item, index) => {
                                if(item.users.length !== 0){
                                  return (
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} key={item.id}>
                                      <div className="text-center tbs--category--awards">
                                        {item.users.length} people find {rData.name} {item.name}
                                      </div>
                                      <div className="tbs--contact--friends--awards tbs--contact--awards">
                                        {
                                          item.users.map((user, index) => {
                                            return (
                                              <div className="text-center tbs--contact--awards--profiles" key={user.id}>
                                                <div className="tbs--contact--image">
                                                  <Link to={`/dashboard/profile/` + params.id + `/friends/${user.id}`}>
                                                    <img src={user.profileImage == null ? IconAvatar : user.profileImage.url}
                                                         className="tbs--image--trusted"
                                                         alt="Profile"/>
                                                  </Link>
                                                </div>
                                                <div className="tbs--contact--feature">
                                                  {user.name}
                                                </div>
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                    </ReactBootstrap.Col>
                                  )
                                }
                              })*/
                            }
                          </ReactBootstrap.Row>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    </ReactBootstrap.Card.Body>
                  </ReactBootstrap.Accordion.Collapse>
                </ReactBootstrap.Card>
              </ReactBootstrap.Accordion>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          {
            showBlockUser
            ?
              <ReactBootstrap.Row id="other--profile--feeds">
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--block--user text-center">
                  You have blocked this user. Please unblock first.
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
            :
              <ReactBootstrap.Row id="other--profile--feeds">
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {
                    r3Data.map((item, index) => {
                      return (
                        <Link to={`/dashboard/feeds/profile/${item.userId}/feedlist/post/${item.id}`}>
                          <ReactBootstrap.Row key={item.id} >
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}className="tbs--container--feedlist--item">
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--reposted">
                                  { item.parentId != null ? "Reposted by " + item.user.name : "" }
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              <ReactBootstrap.Row className="tbs-friend-posts tbs-friend-posts-profile">
                                <ReactBootstrap.Col xs={11} sm={11} md={11} lg={11} xl={11} className="tbs--contact--details">
                                  <div className="tbs--contact--image">
                                    <Link to={`/dashboard/profile/${params.id}/friends/${item.parentPost != null ? item.parentPost.userId : item.userId}`}>
                                      {
                                        item.user != null && item.parentId == null
                                          ?
                                          <img src={item.user.profileImage != null ? item.user.profileImage.url : item.user.image != null ? item.user.image : IconAvatar} className="tbs--container--feedlist--image" alt="Profile"/>
                                          :
                                            item.parentPost != null && item.parentPost.user != null
                                            ?
                                              <img src={item.parentPost.user.profileImage != null ? item.parentPost.user.profileImage != null ? item.parentPost.user.profileImage.url : item.parentPost.user.image : item.parentPost.user.image} className="tbs--container--feedlist--image" alt="Profile"/>
                                            :
                                              item.user.image != null
                                              ?
                                                <img src={item.user.image} className="tbs--container--feedlist--image" alt="Profile"/>
                                              :
                                                <img src={IconAvatar} className="tbs--container--feedlist--image" alt="Profile"/>
                                          /*<img src={
                                            item.parentPost != null
                                              ?
                                              item.parentPost.user != null
                                                ?
                                                item.parentPost.user.profileImage != null
                                                  ?
                                                    item.parentPost.user.profileImage.url
                                                  :
                                                    item.parentPost.user.image
                                                :
                                                item.user.image != null
                                                  ?
                                                  item.user.image
                                                  :
                                                  item.user.profileImage != null
                                                    ?
                                                    item.user.profileImage.url
                                                    :
                                                    IconAvatar
                                              :
                                              item.user.image != null
                                                ?
                                                item.user.image
                                                :
                                                item.user.profileImage != null
                                                  ?
                                                  item.user.profileImage.url
                                                  :
                                                  IconAvatar
                                          } className="tbs--container--feedlist--image" alt="Profile"/>*/
                                      }
                                    </Link>
                                  </div>
                                  <div className="tbs--contact--data--feedlist">
                                    <div>
                                      <Link to={`/dashboard/profile/${params.id}/friends/${item.parentPost != null ? item.parentPost.userId : item.userId}`}>
                                        {
                                          item.parentPost != null
                                            ?
                                            item.parentPost.user != null
                                              ?
                                              item.parentPost.user.name
                                              :
                                              item.user.name
                                            :
                                            item.user.name
                                        }
                                      </Link>
                                    </div>
                                    <div>
                                      <Link to={`/dashboard/profile/${params.id}/friends/${item.parentPost != null ? item.parentPost.userId : item.userId}`}>
                                      {
                                        item.parentPost != null
                                          ?
                                          item.parentPost.user != null
                                            ?
                                            item.parentPost.user.position != null
                                              ?
                                              item.parentPost.user.position
                                              :
                                              "null"
                                            :
                                            item.user.position != null
                                              ?
                                              item.user.position
                                              :
                                              "null"
                                          :
                                          item.user.position != null
                                            ?
                                            item.user.position
                                            :
                                            "null"
                                      }
                                      <span> at </span>
                                      {
                                        item.parentPost != null
                                          ?
                                          item.parentPost.user != null
                                            ?
                                            item.parentPost.user.company != null
                                              ?
                                              item.parentPost.user.company
                                              :
                                              "null"
                                            :
                                            item.user.company != null
                                              ?
                                              item.user.company
                                              :
                                              "null"
                                          :
                                          item.user.company != null
                                            ?
                                            item.user.company
                                            :
                                            "null"
                                      }
                                      </Link>
                                    </div>
                                    <div>
                                      <Link to={`/dashboard/profile/${params.id}/friends/${item.parentPost != null ? item.parentPost.userId : item.userId}`}>
                                        {moment(item.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                      </Link>
                                    </div>
                                  </div>
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={1} sm={1} md={1} lg={1} xl={1}>
                                  <div className="tbs--contact--data--feedlist">
                                    <div className="tbs--contact--data--post--favourite">
                                      {
                                        item.isFavourite == true
                                          ?
                                          /* Remove */
                                          /*<img className="tbs--favourite--image" src={IconBookmarksActive} alt="Bookmarks"/>*/
                                          <span>
                                            <PostBookmarkActiveSVG/>
                                          </span>
                                          :
                                          /* Add */
                                          /*<img className="tbs--favourite--image" src={IconBookmarks} alt="Bookmarks"/>*/
                                          <span>
                                            <PostBookmarkSVG/>
                                          </span>
                                      }
                                    </div>
                                  </div>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={7} lg={7} xl={7}>
                                  <div className="tbs--contact--data--feedlist">
                                    <div className="tbs--contact--data--feedlist--title">
                                      {item.title == null ? item.parentPost == null ? "" : item.parentPost.title : item.title}
                                    </div>
                                  </div>
                                  {
                                    /*(((item.parentPost == null) || (item.parentPost != null && item.parentPost.file == null)) && item.file == null)
                                      ?*/
                                      <div className="read-more">
                                        <Linkify>
                                          {
                                            item.parentPost == null
                                              ?
                                              truncateMessage(item.description, 200)
                                              :
                                              truncateMessage(item.parentPost.description, 200)
                                          }
                                        </Linkify>
                                      </div>
                                      /*:
                                        ""*/
                                      /*isBiggerTablet
                                        ?
                                        <div className="no-description-and-image">Nothing</div>
                                        :
                                        ""*/
                                  }
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={12} sm={12} md={5} lg={5} xl={5}>
                                  <div className="tbs--contact--data--feedlist">
                                    <div className={
                                      item.parentPost !== null
                                        ?
                                        item.parentPost.file === null && item.parentPost.audioId != null
                                          ?
                                          `tbs--contact--data--feedlist--image tbs--feed--image--${item.id} tbs--contact--data--feedlist--without--image`
                                          :
                                          `tbs--contact--data--feedlist--image tbs--feed--image--${item.id}`
                                        :
                                        item.file === null && item.audioId != null
                                          ?
                                          `tbs--contact--data--feedlist--image tbs--feed--image--${item.id} tbs--contact--data--feedlist--without--image`
                                          :
                                          `tbs--contact--data--feedlist--image tbs--feed--image--${item.id}`
                                    }>
                                      <img src={
                                        item.parentPost !== null
                                          ?
                                          item.parentPost.file !== null
                                            ?
                                            item.parentPost.file.url
                                            :
                                            ""
                                          :
                                          item.file !== null
                                            ?
                                            item.file.url
                                            :
                                            ""
                                      }
                                           alt="Feed - Image"/>
                                      {
                                        item.parentPost != null
                                          ?
                                          <div className="tbs--audio--feed" id={`audio-${item.parentPost.audioId}-player`}>
                                            <img src={
                                              item.parentPost.file != null && item.parentPost.audioId != null
                                                ?
                                                IconPlayWhite
                                                :
                                                item.parentPost.file == null && item.parentPost.audioId != null
                                                  ?
                                                  IconPlayBlack
                                                  :
                                                  ""
                                            } alt="Play"/>
                                          </div>
                                          :
                                          item.audioId != null
                                            ?
                                            <div className="tbs--audio--feed" id={`audio-${item.audioId}-player`}>
                                              <img src={
                                                item.file != null && item.audioId != null
                                                  ?
                                                  IconPlayWhite
                                                  :
                                                  item.file == null && item.audioId != null
                                                    ?
                                                    IconPlayBlack
                                                    :
                                                    ""
                                              } alt="Play"/>
                                            </div>
                                            :
                                            ""
                                      }
                                      {
                                        item.parentPost != null
                                          ?
                                          <div className="tbs--contact--audio--overview">
                                            <audio controls controlsList="nodownload" id={`audio-${item.parentPost.audioId}`}>
                                              <source src={item.parentPost.audioFile != null ? item.parentPost.audioFile.url : ""} type="audio/mp3"/>
                                              Your browser does not support the audio element.
                                            </audio>
                                          </div>
                                          :
                                          item.audioFile != null
                                            ?
                                            <div className="tbs--contact--audio--overview">
                                              <audio controls controlsList="nodownload" id={`audio-${item.audioId}`}>
                                                <source src={item.audioFile != null ? item.audioFile.url : ""} type="audio/mp3"/>
                                                Your browser does not support the audio element.
                                              </audio>
                                            </div>
                                            :
                                            ""
                                      }
                                    </div>
                                  </div>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <div className="tbs--contact--data--feeds">
                                    <div className="tbs--contact--data--feeds--infos tbs--data--feeds--subline">
                                      <span className="w20-flex">
                                        {/*<img src={IconComment} alt="Comments"/>*/}
                                        <PostCommentSVG/>
                                        <span>{item.totalComments}</span>
                                      </span>
                                      <span className="w20-flex">
                                        {/*<img src={IconRepost} alt="Repost"/>*/}
                                        {
                                          item.parentId == null
                                          ?
                                            <PostRepostSVG/>
                                          :
                                            <PostNoRepostSVG/>
                                        }
                                        <span>{item.repostedCount}</span>
                                      </span>
                                      <span className="w20-flex">
                                        {/*<img src={IconForward} alt="Forward"/>*/}
                                        <PostForwardSVG/>
                                        <span>{item.forwardedCount}</span>
                                      </span>
                                      <span className="w20-flex">
                                        {/*<img src={IconBookmarks} alt="Bookmarks"/>*/}
                                        {
                                          item.isFavourite == true
                                            ?
                                            <PostBookmarkActiveSVG/>
                                            :
                                            <PostBookmarkSVG/>
                                        }
                                        <span>{item.bookmarkedCount}</span>
                                      </span>
                                      <span className="w20-flex">
                                        {/*<img src={IconPulse} alt="Activity"/>*/}
                                        <PostActivitySVG/>
                                        <span>{item.activitiesCount}</span>
                                      </span>
                                    </div>
                                  </div>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                        </Link>
                      )
                    })
                  }
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
          }
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default OtherProfile;
