import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import API from "../../../config/config.json";
import axios from "axios";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";

import IconDelete from "./../../../assets/icons/IconDelete.png";

function MyAccount() {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Get url from your account data
  const urlMyData = API.URL + 'users';
  const urlOtherEmails = API.URL + 'my-emails';

  // Store and set the state value
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [otherEmails, setOtherEmails] = useState([]);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordAuthenticate, setPasswordAuthenticate] = useState('');
  const [passwordAuthenticateDelete, setPasswordAuthenticateDelete] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [show, setShow] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [showOtherEmail, setShowOtherEmail] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenTwo, setIsModalOpenTwo] = useState(false);
  const [isModalOpenEmails, setIsModalOpenEmails] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteEmailOpen, setDeleteEmailOpen] = useState(false);
  const [myData, setMyData] = useState([]);
  const [myOtherEmails, setMyOtherEmails] = useState([]);

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      urlMyData, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setMyData(result.data);

    setName(result.data.name);
    setEmail(result.data.email);
    setUserStatus(result.data.status);

    const resultEmails = await axios.get(
      urlOtherEmails, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setMyOtherEmails(resultEmails.data.items);

    let elem = document.querySelector("#de-activate-slider");
    let elemTwo = document.querySelector("#delete-slider");

    if(result.data.status == "ACTIVE"){
      elem.removeAttribute("checked");
    } else {
      elem.setAttribute("checked","true");
    }

    if(result.data.deletedAt == null){
      elemTwo.removeAttribute("checked");
    } else {
      elemTwo.setAttribute("checked","true");
    }

  }, []);

  const handleChangePassword = e => setPassword(e.target.value);
  const handleChangePasswordConfirm = e => setPasswordConfirm(e.target.value);
  const handleChangeOtherEmail = e => setOtherEmails(e.target.value);
  const handleChangePasswordAuthenticate = e => setPasswordAuthenticate(e.target.value);
  const handleChangePasswordAuthenticateDelete = e => setPasswordAuthenticateDelete(e.target.value);

  // Update account validation method
  const validationUpdateAccount = (e) => {
    e.preventDefault();

    // URL for user data
    const url = API.URL + 'users';

    // User value for validation
    const data = {
      name: name,
      email: email,
      password: password,
      passwordConfirm: passwordConfirm
    };

    // fetch data and validate
    axios
      .patch(url, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(password === passwordConfirm && password != "" && passwordConfirm != ""){
          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("error-text")[0].innerHTML = "";
          document.getElementsByClassName("update-password")[0].classList.remove("error-field");
          document.getElementsByClassName("update-passwordConfirm")[0].classList.remove("error-field");

          if(response.status === 200){
            setShow(true);
            setShowAccount(true);

            document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "";
            document.getElementsByClassName("tbs--form--main--error")[0].style.display = "none";

            setTimeout(() => {
              setShow(false);
            }, 1000);

            setTimeout(() => {
              setPassword("");
              setPasswordConfirm("");
            }, 1200);

          } else {
            document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Password can't be empty or is invalid.";
            document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";

            console.log("Error: Access Denied");
          }
        } else {
          document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Password can't be empty or is invalid.";
          document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
          document.getElementsByClassName("update-password")[0].classList.add("error-field");
          document.getElementsByClassName("error-text")[0].style.display = "block";
          document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a valid password.";
          document.getElementsByClassName("update-passwordConfirm")[0].classList.add("error-field");
          document.getElementsByClassName("error-text")[1].style.display = "block";
          document.getElementsByClassName("error-text")[1].innerHTML = "Please enter a valid password.";
        }
      })
      .catch(e => {
        document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Password can't be empty or is invalid.";
        document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
        document.getElementsByClassName("update-password")[0].classList.add("error-field");
        document.getElementsByClassName("error-text")[0].style.display = "block";
        document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a valid password.";
        document.getElementsByClassName("update-passwordConfirm")[0].classList.add("error-field");
        document.getElementsByClassName("error-text")[1].style.display = "block";
        document.getElementsByClassName("error-text")[1].innerHTML = "Please enter a valid password.";
        console.log(e)
      })
  }

  // Update Other E-Mails validation method
  const validationUpdateOtherEmail = (e) => {
    e.preventDefault();

    // URL for Other E-mails data
    const url = API.URL + 'users';

    // User value for validation
    const data = {
      otherEmails: [otherEmails]
    };

    // fetch data and validate
    axios
      .patch(url, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(otherEmails !== ""){
          document.getElementsByClassName("error-text")[2].style.display = "none";
          document.getElementsByClassName("error-text")[2].innerHTML = "";
          document.getElementsByClassName("update-other-email")[0].classList.remove("error-field");

          if(response.status === 200){
            setShow(true);
            setShowOtherEmail(true);

            document.getElementsByClassName("tbs--form--main--error")[1].innerHTML = "";
            document.getElementsByClassName("tbs--form--main--error")[1].style.display = "none";

            setTimeout(() => {
              setShow(false);
            }, 1000);

            setTimeout(async () => {
              setOtherEmails([]);

              const resultEmails = await axios.get(
                urlOtherEmails, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setMyOtherEmails(resultEmails.data.items);
            }, 1200);

          } else {
            document.getElementsByClassName("tbs--form--main--error")[1].innerHTML = "Other E-Mail can't be empty or is invalid.";
            document.getElementsByClassName("tbs--form--main--error")[1].style.display = "block";

            console.log("Error: Access Denied");
          }
        } else {
          document.getElementsByClassName("tbs--form--main--error")[1].innerHTML = "Other E-Mail can't be empty or is invalid.";
          document.getElementsByClassName("tbs--form--main--error")[1].style.display = "block";
          document.getElementsByClassName("update-other-email")[0].classList.add("error-field");
          document.getElementsByClassName("error-text")[2].style.display = "block";
          document.getElementsByClassName("error-text")[2].innerHTML = "Please enter a valid e-mail.";
        }
      })
      .catch(e => {
        document.getElementsByClassName("tbs--form--main--error")[1].innerHTML = "Other E-Mail can't be empty or is invalid.";
        document.getElementsByClassName("tbs--form--main--error")[1].style.display = "block";
        document.getElementsByClassName("update-other-email")[0].classList.add("error-field");
        document.getElementsByClassName("error-text")[2].style.display = "block";
        document.getElementsByClassName("error-text")[2].innerHTML = "Please enter a valid password.";
        console.log(e)
      })
  }

  // Update for (de-) activate validation method
  const validationDeActivateAccount = (e) => {
    e.preventDefault();

    console.log(myData.status);

    // URL for Other E-mails data
    const url = API.URL + 'authenticate';

    // User value for validation
    const data = {
      email: email,
      password: passwordAuthenticate
    };

    // fetch data and validate
    axios
      .post(url, data)
      .then((response) => {
        if(response.status === 200){
          if(userStatus == "ACTIVE"){
            setStatusOpen(true)
            deactivateStatus()
          } else {
            setStatusOpen(true)
            activeStatus()
          }
          console.log("Successful authenticate");
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Update for (de-) activate validation method
  const validationDeleteAccount = (e) => {
    e.preventDefault();

    console.log(myData.status);

    // URL for Other E-mails data
    const url = API.URL + 'authenticate';

    // User value for validation
    const data = {
      email: email,
      password: passwordAuthenticateDelete
    };

    // fetch data and validate
    axios
      .post(url, data)
      .then((response) => {
        if(response.status === 200){
          if(userStatus == "ACTIVE"){
            setDeleteOpen(true)
            //deactivateStatus()
          } else {
            setDeleteOpen(true)
            deleteAccount()
          }
          console.log("Successful authenticate");
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const activeStatus = () => {

    // URL for status data
    const urlUpdate = API.URL + 'users';

    // User status for validation

    const dataStatus = {
      status: "ACTIVE"
    };

    // fetch data and validate
    axios
      .patch(urlUpdate, dataStatus, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          console.log("Successful: " + userStatus);

          setTimeout(() => {
            setStatusOpen(false)
          }, 1000);

          setTimeout(async () => {
            setPasswordAuthenticate("");
            setIsModalOpen(false);

            let elem = document.querySelector("#de-activate-slider");

            elem.removeAttribute("checked");

            const result = await axios.get(
              urlMyData, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setMyData(result.data);
          }, 1200);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const deactivateStatus = () => {

    // URL for status data
    const urlUpdate = API.URL + 'users';

    // User status for validation

    const dataStatus = {
      status: "DE_ACTIVATE"
    };

    // fetch data and validate
    axios
      .patch(urlUpdate, dataStatus, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          console.log("Successful: " + userStatus);

          setTimeout(() => {
            setStatusOpen(false)
          }, 1000);

          setTimeout(async () => {
            setPasswordAuthenticate("");
            setIsModalOpen(false);

            let elem = document.querySelector("#de-activate-slider");

            elem.setAttribute("checked","true");

            const result = await axios.get(
              urlMyData, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setMyData(result.data);
          }, 1200);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const deleteAccount = () => {

    // URL for delete user
    const urlDelete = API.URL + 'users';

    // fetch data and validate
    axios
      .delete(urlDelete, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setDeleteOpen(true)

          setTimeout(() => {
            setDeleteOpen(false)

            localStorage.removeItem("tbs-token-" + userID);
            localStorage.removeItem("tbs-data-" + userID);
          }, 1000);

          setTimeout(() => {
            window.location.reload();
          }, 1200);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const deleteOtherEmail = (id) => {

    // URL for delete user
    const urlDelete = API.URL + 'emails/' + id;

    // fetch data and validate
    axios
      .delete(urlDelete, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          /*setDeleteEmailOpen(true)

          setTimeout(() => {
            setDeleteEmailOpen(false)
          }, 1000);*/

          setTimeout(async () => {
            const resultEmails = await axios.get(
              urlOtherEmails, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setMyOtherEmails(resultEmails.data.items);
          }, 500);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const Schema = Yup.object().shape({
    name: Yup.string()
      .required('Please enter a name.'),
    email: Yup.string()
      .required('Please enter an e-mail.'),
    password: Yup.string()
      .required('Password is required'),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must be match')
  });

  const SchemaTwo = Yup.object().shape({
    otherEmails: Yup.string()
      .required('Please enter an e-mail.')
  });

  const SchemaThree = Yup.object().shape({
    email: Yup.string(),
    password: Yup.string()
      .required('Please enter a password.'),
    status: Yup.string()
  });

  const SchemaFour = Yup.object().shape({
    email: Yup.string(),
    password: Yup.string()
      .required('Please enter a password.'),
    status: Yup.string()
  });

  /*const toggleSliderModalAuthenticate = (elementName) => {
    let elem = document.querySelector(elementName);

    if(elem.previousElementSibling.checked == true){
      elem.previousElementSibling.removeAttribute("checked");
    } else {
      elem.previousElementSibling.setAttribute("checked","true");
    }
  }*/

  return (
    <ReactBootstrap.Row id="my-account">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container">
          <ReactBootstrap.Row>
            <div className="tbs--headline tbs--headline--update-profile">
              My account
            </div>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--update--post">
              <div className="form-update-account">
                <div className="tbs--form--main--error text-center"></div>
                <div className="tbs--form--complete--profile">
                  <Formik
                    initialValues={{
                      name: '',
                      email: '',
                      password: '',
                      passwordConfirm: ''
                    }}
                    validationSchema={Schema}
                    onSubmit={values => {
                      console.log(values.text);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form onSubmit={validationUpdateAccount}>
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div>
                              <label htmlFor="name">
                                MY USERNAME
                              </label>
                            </div>
                            <div>
                              <Field type="text" className={`update-name`} name="name" id="name" placeholder="Username" value={name} disabled/>
                            </div>
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div>
                              <label htmlFor="email">
                                MY EMAIL ADDRESS
                              </label>
                            </div>
                            <div>
                              <Field type="text" className={`update-email`} name="email" id="email" placeholder="E-Mail" value={email} disabled/>
                            </div>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div>
                              <label htmlFor="password">
                                CHANGE MY PASSWORD
                              </label>
                            </div>
                            <div>
                              <Field type="password" className={`update-password`} name="password" id="password" placeholder="Change password" value={password} onChange={handleChangePassword} />
                            </div>
                            <div className="error-text">
                              {/*
                                errors.password && touched.password
                                  ?
                                  errors.password
                                  :
                                  null
                              */}
                              <ErrorMessage name="password">
                                {errors.password}
                              </ErrorMessage>
                            </div>
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div>
                              <label htmlFor="passwordConfirm">
                                CONFIRM PASSWORD
                              </label>
                            </div>
                            <div>
                              <Field type="password" className={`update-passwordConfirm`} name="passwordConfirm" id="passwordConfirm" placeholder="Confirm password" value={passwordConfirm} onChange={handleChangePasswordConfirm} />
                            </div>
                            <div className="error-text">
                              {/*
                                errors.passwordConfirm && touched.passwordConfirm
                                  ?
                                  errors.passwordConfirm
                                  :
                                  null
                              */}
                              <ErrorMessage name="passwordConfirm">
                                {errors.passwordConfirm}
                              </ErrorMessage>
                            </div>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <div className="text-center button--sign">
                          <ReactBootstrap.Button type="submit">Update</ReactBootstrap.Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--update--post">
              <div className="form-update-account">
                <div className="tbs--form--main--error text-center"></div>
                <div className="tbs--form--complete--profile">
                  <Formik
                    initialValues={{
                      otherEmails: []
                    }}
                    validationSchema={SchemaTwo}
                    onSubmit={values => {
                      console.log(values.text);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form onSubmit={validationUpdateOtherEmail} id="other-email-form">
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div class="tbs--other--email">
                              <label htmlFor="otherEmail">
                                LINK OTHER EMAIL ADDRESS
                              </label>
                              <Link to="#" onClick={() => setIsModalOpenEmails(true)}>
                                <span>
                                  See List
                                </span>
                              </Link>
                              <ReactBootstrap.Modal
                                show={isModalOpenEmails}
                                onHide={() => setIsModalOpenEmails(false)}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter-other-emails"
                                centered
                              >
                                <ReactBootstrap.Modal.Header closeButton>
                                  <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-other-emails">
                                    Alternate emails
                                  </ReactBootstrap.Modal.Title>
                                </ReactBootstrap.Modal.Header>
                                <ReactBootstrap.Modal.Body className="tbs--other--emails--main">
                                  <ReactBootstrap.Row>
                                    {
                                      !myOtherEmails || myOtherEmails.length === 0
                                        ?
                                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                          <div>
                                            You don't have any other emails.
                                          </div>
                                        </ReactBootstrap.Col>
                                        :
                                        myOtherEmails.map((otherEmails) => {
                                          return(
                                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--other--emails" key={otherEmails.id}>
                                              <ReactBootstrap.Row>
                                                <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                  {otherEmails.email}
                                                </ReactBootstrap.Col>
                                                <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="text-right">
                                                  <img src={IconDelete} alt="Delete Other E-Mail" onClick={() => deleteOtherEmail(otherEmails.id)}/>
                                                </ReactBootstrap.Col>
                                              </ReactBootstrap.Row>
                                            </ReactBootstrap.Col>
                                          )
                                        })
                                    }
                                  </ReactBootstrap.Row>
                                </ReactBootstrap.Modal.Body>
                              </ReactBootstrap.Modal>
                            </div>
                            <div>
                              <Field type="email" className={`update-other-email`} name="otherEmail" id="otherEmail" placeholder="Email address" value={otherEmails} onChange={handleChangeOtherEmail} />
                            </div>
                            <div className="error-text">
                              {/*
                                errors.otherEmail && touched.otherEmail
                                  ?
                                  errors.otherEmail
                                  :
                                  null
                              */}
                              <ErrorMessage name="otherEmail">
                                {errors.otherEmail}
                              </ErrorMessage>
                            </div>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <div className="text-center button--sign">
                          <ReactBootstrap.Button type="submit">Link Email</ReactBootstrap.Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5>
                Delete my account
              </h5>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <h6>
                Step 1: Deactivate my account
              </h6>
              <div class="tbs--step--de-activate">
                People won't be able to see or go to your profile.
                Your posts and requests won't be deleted and will be recovered when you reactivate your account.
              </div>
              <div className="tbs--de-activate--slider text-center">
                <label className="switch">
                  <input type="checkbox" id="de-activate-slider" disabled/>
                  <span className="slider round" id="de-activate" onClick={() => setIsModalOpen(true)}></span>
                  <span className="tbs--account--status">
                    {
                      myData.status == "ACTIVE"
                      ?
                        "DEACTIVATE"
                      :
                        "ACTIVATE"
                    }
                  </span>
                </label>
                <ReactBootstrap.Modal
                  show={isModalOpen}
                  onHide={() => setIsModalOpen(false)}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <ReactBootstrap.Modal.Header closeButton>
                    <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                      {
                        myData.status == "ACTIVE"
                        ?
                          "Deactivate"
                        :
                          "Activate"
                      } your account
                    </ReactBootstrap.Modal.Title>
                  </ReactBootstrap.Modal.Header>
                  <ReactBootstrap.Modal.Body>
                    <ReactBootstrap.Row>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div>
                          {
                            myData.status == "ACTIVE"
                            ?
                              "Please enter your Meoh account password to deactivate your account."
                            :
                              "Please enter your Meoh account password to activate your account."
                          }
                        </div>
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="form-update-account">
                          <div className="tbs--form--main--error text-center"></div>
                          <div className="tbs--form--complete--profile">
                            <Formik
                              initialValues={{
                                email: '',
                                password: '',
                                status: ''
                              }}
                              validationSchema={SchemaThree}
                              onSubmit={values => {
                                console.log(values.text);
                              }}
                            >
                              {({ errors, touched }) => (
                                <Form onSubmit={validationDeActivateAccount}>
                                  <ReactBootstrap.Row>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <div>
                                        <label htmlFor="passwordAuthenticate">
                                          MY PASSWORD
                                        </label>
                                      </div>
                                      <div>
                                        <Field type="password" className={`update-status`} name="password" id="passwordAuthenticate" placeholder="Enter password" value={passwordAuthenticate} onChange={handleChangePasswordAuthenticate} />
                                      </div>
                                      <div className="error-text">
                                        {/*
                                          errors.password && touched.password
                                            ?
                                            errors.password
                                            :
                                            null
                                        */}
                                        <ErrorMessage name="password">
                                          {errors.password}
                                        </ErrorMessage>
                                      </div>
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                      <div className="text-center button--sign">
                                        <ReactBootstrap.Button onClick={() => setIsModalOpen(false)}>Cancel</ReactBootstrap.Button>
                                      </div>
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                      <div className="text-center button--sign">
                                        <ReactBootstrap.Button type="submit">
                                          {
                                            userStatus == "ACTIVE"
                                            ?
                                              "Deactivate"
                                            :
                                              "Activate"
                                          }
                                        </ReactBootstrap.Button>
                                      </div>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {
                          statusOpen
                            ?
                            <ReactBootstrap.Alert variant="success" transition={true} id="account-de-activate-alert" className="text-center">
                              <ReactBootstrap.Alert.Heading>
                                {
                                  userStatus == "ACTIVE"
                                  ?
                                    "Account deactivated successfully."
                                  :
                                    "Account activated successfully."
                                }
                              </ReactBootstrap.Alert.Heading>
                            </ReactBootstrap.Alert>
                            :
                            ""
                        }
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </ReactBootstrap.Modal.Body>
                </ReactBootstrap.Modal>
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <h6>
                Step 2: Erase all of my data
              </h6>
              <div class="tbs--step--delete">
                This action will permanently erase all of your data on meoh.
                This action cannot be undone! You first need to deactivate your account.
              </div>
              <div className="tbs--delete--slider text-center">
                <label className="switch">
                  <input type="checkbox" id="delete-slider" disabled/>
                  <span className="slider-two round" id="delete" onClick={() => setIsModalOpenTwo(true)}></span>
                  <span className="tbs--account--status--delete">DELETE</span>
                </label>
                <ReactBootstrap.Modal
                  show={isModalOpenTwo}
                  onHide={() => setIsModalOpenTwo(false)}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter-delete"
                  centered
                >
                  <ReactBootstrap.Modal.Header closeButton>
                    <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-delete">
                      Delete your account
                    </ReactBootstrap.Modal.Title>
                  </ReactBootstrap.Modal.Header>
                  <ReactBootstrap.Modal.Body>
                    <ReactBootstrap.Row>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div>
                          {
                            myData.status == "ACTIVE"
                            ?
                              "Please deactivate your account first in order to delete it."
                            :
                              "Please enter your Meoh account password to delete your account."
                          }
                        </div>
                      </ReactBootstrap.Col>
                      {
                        myData.status == "ACTIVE"
                        ?
                          ""
                        :

                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="form-update-account">
                              <div className="tbs--form--main--error text-center"></div>
                              <div className="tbs--form--complete--profile">
                                <Formik
                                  initialValues={{
                                    email: '',
                                    password: '',
                                    status: ''
                                  }}
                                  validationSchema={SchemaFour}
                                  onSubmit={values => {
                                    console.log(values.text);
                                  }}
                                >
                                  {({ errors, touched }) => (
                                    <Form onSubmit={validationDeleteAccount}>
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <div>
                                            <label htmlFor="passwordAuthenticateDelete">
                                              MY PASSWORD
                                            </label>
                                          </div>
                                          <div>
                                            <Field type="password" className={`delete`} name="password" id="passwordAuthenticateDelete" placeholder="Enter password" value={passwordAuthenticateDelete} onChange={handleChangePasswordAuthenticateDelete} />
                                          </div>
                                          <div className="error-text">
                                            {/*
                                              errors.password && touched.password
                                                ?
                                                errors.password
                                                :
                                                null
                                            */}
                                            <ErrorMessage name="password">
                                              {errors.password}
                                            </ErrorMessage>
                                          </div>
                                        </ReactBootstrap.Col>
                                        <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                          <div className="text-center button--sign">
                                            <ReactBootstrap.Button onClick={() => setIsModalOpenTwo(false)}>Cancel</ReactBootstrap.Button>
                                          </div>
                                        </ReactBootstrap.Col>
                                        <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                          <div className="text-center button--sign">
                                            <ReactBootstrap.Button type="submit">
                                              Delete
                                            </ReactBootstrap.Button>
                                          </div>
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                      }
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {
                          deleteOpen
                          ?
                            <ReactBootstrap.Alert variant="success" transition={true} id="account-delete-alert" className="text-center">
                              <ReactBootstrap.Alert.Heading>
                                Account deleted successfully.
                              </ReactBootstrap.Alert.Heading>
                            </ReactBootstrap.Alert>
                          :
                            ""
                        }
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </ReactBootstrap.Modal.Body>
                </ReactBootstrap.Modal>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          show
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="user-account-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>
                {
                  showAccount
                  ?
                    "Password updated successfully."
                  :
                    showOtherEmail
                    ?
                      "Email linked successfully."
                    :
                      ""
                }
              </ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default MyAccount;
