import React from 'react';

const ProfileSVG = () => {
  return(
    <svg id="Komponente_29_6" data-name="Komponente 29 – 6" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="240" height="119" viewBox="0 0 240 119">
      <defs>
        <filter id="Icon_awesome-user-circle" x="28" y="28" width="68" height="68" filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur"/>
          <feFlood flood-opacity="0.161"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <rect id="Rechteck_732" data-name="Rechteck 732" width="240" height="119" rx="26" fill="#141c27"/>
      <path id="Pfad_148" data-name="Pfad 148" d="M26,0H93a26,26,0,0,1,26,26V93a26,26,0,0,1-26,26H26A26,26,0,0,1,0,93V26A26,26,0,0,1,26,0Z" fill="#141c27"/>
      <text id="Go_" data-name="Go!" transform="translate(175.406 77.563)" fill="#fefefe" font-size="50" font-family="Ubuntu-Light, Ubuntu" font-weight="300"><tspan x="-37.85" y="0">Go!</tspan></text>
      <g id="Gruppe_1096" data-name="Gruppe 1096" transform="translate(-1852 -2946)">
        <rect id="Rechteck_729" data-name="Rechteck 729" width="102" height="102" rx="26" transform="translate(1860 2954)" fill="#fefefe"/>
        <g transform="matrix(1, 0, 0, 1, 1852, 2946)" filter="url(#Icon_awesome-user-circle)">
          <path id="Icon_awesome-user-circle-2" data-name="Icon awesome-user-circle" d="M25,.562a25,25,0,1,0,25,25A25,25,0,0,0,25,.562Zm0,9.677a8.871,8.871,0,1,1-8.871,8.871A8.871,8.871,0,0,1,25,10.24Zm0,34.677a19.317,19.317,0,0,1-14.768-6.875,11.239,11.239,0,0,1,9.929-6.028,2.467,2.467,0,0,1,.716.111,13.347,13.347,0,0,0,4.123.7,13.3,13.3,0,0,0,4.123-.7,2.467,2.467,0,0,1,.716-.111,11.239,11.239,0,0,1,9.929,6.028A19.317,19.317,0,0,1,25,44.917Z" transform="translate(34 33.44)" fill="#141c27"/>
        </g>
      </g>
    </svg>
  )
}

export default ProfileSVG;
