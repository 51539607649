import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {Link, useHistory} from "react-router-dom";
import axios from "axios";

import API from "../../../config/config.json";

import IconAvatar from "./../../../assets/svg/avatar.svg";

function AllAwardsContact(props) {

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  //let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Get url from feeds from an user
  const urlAwards = API.URL + "users/" + props.id + "/all-likes";

  // Get url from user of this page
  const urlContactData = API.URL + "users/profile/" + props.id;

  // Store and set the state value
  const [showAllAwards, setShowAllAwards] = useState([]);
  const [showContactData, setShowContactData] = useState([]);

  // Execute the API and get all informations
  useEffect(async () => {
    await axios.get(
      urlAwards, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const uniqueArr = [...new Map(response.data.items.map(item => [item["id"], item])).values()];

        setShowAllAwards(uniqueArr);
      }
    })
    .catch(e => {
      console.log(e);
    });

    await axios.get(
      urlContactData, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    )
    .then((response) => {
      setShowContactData(response.data);
    })
    .catch(e => {
      console.log(e);
    });
  }, [])

  return (
    <ReactBootstrap.Row id="contact-awards-overview">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
        {
          showAllAwards.length == 0
          ?
            `${showContactData.name} hasn't been awarded yet for their outstanding contributions`
          :
            `${showAllAwards.length} people awarded this person for their outstanding contributions`
        }
      </ReactBootstrap.Col>
      {
        showAllAwards.length > 0 && showAllAwards.map(users => {
          return (
            <ReactBootstrap.Col xs={6} sm={6} md={4} lg={3} xl={3} className="text-center">
              <Link to={`/dashboard/profile/${users.id}`}>
                <img src={users.profileImage != null ? users.profileImage.url : users.image != null ? users.image : IconAvatar} />
                <div>
                  {users.name}
                </div>
              </Link>
            </ReactBootstrap.Col>
          )
        })
      }
    </ReactBootstrap.Row>
  )
}

export default AllAwardsContact;
