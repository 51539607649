import React from 'react';

const DefaultImageSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="121.65" height="99.375" viewBox="0 0 121.65 99.375">
      <defs>
        <filter id="Icon_awesome-image" x="0" y="0" width="121.65" height="99.375" filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur"/>
          <feFlood flood-opacity="0.161"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Icon_awesome-image)">
        <path id="Icon_awesome-image-2" data-name="Icon awesome-image" d="M93.933,85.874H9.717A9.952,9.952,0,0,1,0,75.7V14.672A9.952,9.952,0,0,1,9.717,4.5H93.933a9.952,9.952,0,0,1,9.717,10.172V75.7A9.952,9.952,0,0,1,93.933,85.874ZM22.673,16.367A11.611,11.611,0,0,0,11.337,28.234,11.611,11.611,0,0,0,22.673,40.1,11.611,11.611,0,0,0,34.01,28.234,11.611,11.611,0,0,0,22.673,16.367ZM12.956,72.312H90.694V48.578L72.977,30.032a2.353,2.353,0,0,0-3.436,0L42.108,58.75,30.869,46.985a2.353,2.353,0,0,0-3.436,0L12.956,62.14Z" transform="translate(6 1.5)" fill="#141c27"/>
      </g>
    </svg>

  )
}

export default DefaultImageSVG;
