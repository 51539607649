import React from 'react';

const UnFeaturedSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1024" height="1024" viewBox="0 0 1024 1024">
      <defs>
        <filter id="Icon_ionic-ios-star" x="236" y="257.333" width="557.323" height="519.657" filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur"/>
          <feFlood flood-opacity="0.161"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g id="Not_featured_button" data-name="Not featured button" transform="translate(-18869 2077)">
        <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(18869 -2077)" fill="#141c27" stroke="#fff" stroke-width="3">
          <circle cx="512" cy="512" r="512" stroke="none"/>
          <circle cx="512" cy="512" r="510.5" fill="none"/>
        </g>
        <g transform="matrix(1, 0, 0, 1, 18869, -2077)" filter="url(#Icon_ionic-ios-star)">
          <path id="Icon_ionic-ios-star-2" data-name="Icon ionic-ios-star" d="M509.563,172.872H336.418L283.8,15.852a19.066,19.066,0,0,0-35.783,0l-52.615,157.02H21.083A18.888,18.888,0,0,0,2.25,191.7a13.841,13.841,0,0,0,.353,3.178c.235,4.12,2.119,8.71,7.886,13.3L152.8,308.469,98.18,467.255a18.887,18.887,0,0,0,6.474,21.187c3.413,2.472,6.592,4.591,10.594,4.591,3.884,0,8.475-2,11.771-4.237L265.912,389.8,404.8,488.795c3.3,2.354,7.886,4.237,11.771,4.237,4,0,7.18-2,10.476-4.591a18.657,18.657,0,0,0,6.474-21.187L378.909,308.469l141.13-101.227,3.414-2.943c3.06-3.3,6.121-7.769,6.121-12.595C529.573,181.347,519.921,172.872,509.563,172.872Z" transform="translate(245.75 265.96)" fill="none" stroke="#fff" stroke-width="12"/>
        </g>
      </g>
    </svg>

  )
}

export default UnFeaturedSVG;
