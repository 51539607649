import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import API from "./../../config/config.json";
import {Link, useRouteMatch} from "react-router-dom";
import axios from "axios";
import moment from "moment";

import IconResend from "../../assets/icons/IconResend.png";
//import IconClear from "../../assets/icons/IconDelete.png";
import IconAvatar from "../../assets/svg/avatar.svg";
//import IconCheckmark from "../../assets/icons/IconTickGrey.png";
import IconInvitation from "../../assets/icons/IconInvatations.png";

/* SVG */
import InvitationGraySVG from "../../assets/svg/invitationGray";
import IconClear from "../../assets/svg/deleteBlack.svg";
import IconCheckmark from "../../assets/svg/accept.svg";

function Invitation() {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get url from your invitations
  const urlInvitationSent = API.URL + 'invitations?type=SENT';
  const urlInvitationReceived = API.URL + 'invitations?type=RECEIVED';

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");

  // Store and set the state value
  const [invitationSentData, setInvitationSentData] = useState([]);
  const [invitationReceivedData, setInvitationReceivedData] = useState([]);
  const [show, setShow] = useState(false);
  const [showDeleteSent, setShowDeleteSent] = useState(false);
  const [showRejectSent, setShowRejectSent] = useState(false);
  const [showAcceptSent, setShowAcceptSent] = useState(false);
  const [showResendSent, setShowResendSent] = useState(false);

  // Execute the API and get all informations
  useEffect(async () => {
    /*const resultInvitationSent = await axios.get(
      urlInvitationSent, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    let invitationResultSent = resultInvitationSent.data.items;

    setInvitationSentData(invitationResultSent);*/

    const resultInvitationReceived = await axios.get(
      urlInvitationReceived, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    let invitationResultReceived = resultInvitationReceived.data.items;

    setInvitationReceivedData(invitationResultReceived);
  }, []);

  // fetch data and validate
  /*const validationDeleteInvitationSent = (id) => {
    // URL for clear sent invitation
    const url = API.URL + 'invitations/' + id + '/clear';

    axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);
          setShowDeleteSent(true);

          setTimeout(() => {
            setShow(false);
          }, 1000);

          setTimeout(async () => {
            const resultInvitationSent = await axios.get(
              urlInvitationSent, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            let invitationResultSent = resultInvitationSent.data.items;

            setInvitationSentData(invitationResultSent);
          }, 1200);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }*/

  const validationDeclineInvitation = (id) => {
    // URL for decline received invitation
    const url = API.URL + 'invitations/' + id + '/reject';

    axios
      .put(url, '', {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);
          setShowRejectSent(true);

          setTimeout(() => {
            setShow(false);
          }, 1000);

          setTimeout(async () => {
            const resultInvitationReceived = await axios.get(
              urlInvitationReceived, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            let invitationResultReceived = resultInvitationReceived.data.items;

            setInvitationReceivedData(invitationResultReceived);
          }, 1200);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const validationAcceptInvitation = (id, userId) => {
    // URL for accept received invitation
    const url = API.URL + 'invitations/' + id + '/accept';

    // Post value for validation
    const data = {
      userId: userId
    };

    axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);
          setShowAcceptSent(true);

          setTimeout(() => {
            setShow(false);
          }, 1000);

          setTimeout(async () => {
            const resultInvitationReceived = await axios.get(
              urlInvitationReceived, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            let invitationResultReceived = resultInvitationReceived.data.items;

            setInvitationReceivedData(invitationResultReceived);
          }, 1200);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  /*const validationResendInvitationSent = (id, text, email) => {
    // URL for clear and sent (new) invitation
    const url = API.URL + 'invitations/' + id + '/clear';
    const urlResend = API.URL + 'invitations';

    axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          console.log("Invitation successful deleted.")
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })

    // Post value for validation
    const data = {
      email: email,
      publicText: text
    };

    axios
      .post(urlResend, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);
          setShowResendSent(true);

          setTimeout(() => {
            setShow(false);
          }, 1000);

          setTimeout(async () => {
            const resultInvitationSent = await axios.get(
              urlInvitationSent, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            let invitationResultSent = resultInvitationSent.data.items;

            setInvitationSentData(invitationResultSent);
          }, 1200)
          console.log("Invitation successful resend.");
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }*/

  useEffect(() => {
    const contentInfo = document.getElementById("no-data-invitation-received");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading content";
      setTimeout( () => {
        contentInfo.innerHTML = "All caught up! You have no pending invitations at this time.";
      }, 5000)
    }
  })

  return (
    <ReactBootstrap.Row id="invitation">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          show
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="invitation-dashboard-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>
                {
                  showDeleteSent
                    ?
                      "Invitation cleared from list."
                    :
                      showRejectSent
                      ?
                        "Invitation declined."
                      :
                        showAcceptSent
                        ?
                          "Invitation accepted."
                        :
                          showResendSent
                          ?
                            "Invitation resent successfully."
                          :
                            ""
                }
              </ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="tbs--headline tbs--headline--invitation">
                My Invitations
              </div>
              <div className="tbs--divider--title tbs--divider--title--invitation">
                <hr></hr>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <p>You can send a new invitation here:
                {/*<Link to="/dashboard/new-invitation"><img src={IconInvitation} alt="Invite"/></Link>*/}
                <Link to="/dashboard/new-invitation">
                  <InvitationGraySVG/>
                </Link>
              </p>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Tabs defaultActiveKey="receivedMain" id="invitation--tabs--main" fill transition={false}>
            <ReactBootstrap.Tab eventKey="receivedMain" title="Received">
              {
                !invitationReceivedData || invitationReceivedData.length === 0
                  ?
                  <div className="text-center" id="no-data-invitation-received">
                    All caught up! You have no pending invitations at this time.
                  </div>
                  :
                  invitationReceivedData.map((invitationItemReceived) => {
                    return (
                      <ReactBootstrap.Row className="invitation--element">
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                          {moment(invitationItemReceived.updatedAt).format('MMMM DD YYYY, hh:mm:A')}
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--invitation--headline--info">
                              <div>
                                <img src={invitationItemReceived.user != null && invitationItemReceived.user.profileImage != null ? invitationItemReceived.user.profileImage.url : IconAvatar} alt="Other Profile - Image" className="invitation--image"/>
                              </div>
                              <div className="invitation--contact">
                                <div>
                                  {invitationItemReceived.user != null ? invitationItemReceived.user.name : ""}
                                </div>
                                <div>
                                  {invitationItemReceived.user != null ? invitationItemReceived.user.email : ""}
                                </div>
                              </div>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="invitation--details text-center">
                          <div onClick={() => validationAcceptInvitation(invitationItemReceived.id, invitationItemReceived.userId)}>
                            <img src={IconCheckmark} alt="Accept" className="invitation--image--custom"/>
                            <span>
                              Accept
                            </span>
                          </div>
                          <div onClick={() => validationDeclineInvitation(invitationItemReceived.id)}>
                            <img src={IconClear} alt="Decline" className="invitation--image--custom"/>
                            <span>
                              Decline
                            </span>
                          </div>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    )
                  })
              }
            </ReactBootstrap.Tab>
            {/*<ReactBootstrap.Tab eventKey="sentMain" title="Sent">
              {
                !invitationSentData || invitationSentData.length === 0
                  ?
                  <div className="text-center">
                    You don't have any invitations (Sent)
                  </div>
                  :
                  invitationSentData.map((invitationItemSent) => {
                    return (
                      <ReactBootstrap.Row className="invitation--element">
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                          {moment(invitationItemSent.updatedAt).format('MMMM DD YYYY, hh:mm:A')}
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--invitation--headline--info">
                              <div>
                                <img src={invitationItemSent.friend != null ? invitationItemSent.friend.profileImage != null ? invitationItemSent.friend.profileImage.url : IconAvatar : IconAvatar} alt="Other Profile - Image" className="invitation--image"/>
                              </div>
                              <div className="invitation--contact">
                                <div>
                                  {invitationItemSent.friend == null ? invitationItemSent.name : invitationItemSent.friend.name}
                                </div>
                                <div>
                                  {invitationItemSent.friend == null ? invitationItemSent.email : invitationItemSent.friend.email}
                                </div>
                              </div>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="invitation--details text-left">
                          <div onClick={() => validationResendInvitationSent(invitationItemSent.id, invitationItemSent.publicText, invitationItemSent.friend == null ? invitationItemSent.email : invitationItemSent.friend.email)}>
                            <img src={IconResend} alt="Resend"/>
                            <span>
                              Resend
                            </span>
                          </div>
                          <div onClick={() => validationDeleteInvitationSent(invitationItemSent.id)}>
                            <img src={IconClear} alt="Clear"/>
                            <span>
                              Clear
                            </span>
                          </div>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    )
                  })
              }
            </ReactBootstrap.Tab>*/}
          </ReactBootstrap.Tabs>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default Invitation;
