import React, {useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {Link, useRouteMatch} from "react-router-dom";
import API from "./../../config/config.json";

import IconReload from "./../../assets/icons/IconResend.png";
import IconKey from "./../../assets/icons/IconKeyGrey.png";
import IconAvatar from "./../../assets/svg/avatar.svg";

/* SVG - Partner */
import TipSVG from "../../assets/svg/tips";
import ProfileSVG from "../../assets/svg/profile";
import AccountSVG from "../../assets/svg/account";
import NotificationSVG from "../../assets/svg/notificationGo.svg";

function Settings() {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Store and set the state value
  const [show, setShow] = useState(false);

  const alertHandling = () => {
    setShow(true);

    // Remove all tips storage items
    localStorage.removeItem("tbs-tip-feature-" + userID);
    localStorage.removeItem("tbs-tip-trusted-" + userID);
    localStorage.removeItem("tbs-tip-feature-contact-" + userID);
    localStorage.removeItem("tbs-tip-remove-contact-" + userID);
    localStorage.removeItem("tbs-tip-edit-profile-" + userID);
    localStorage.removeItem("tbs-tip-create-post-" + userID);
    localStorage.removeItem("tbs-tip-groups-" + userID);
    localStorage.removeItem("tbs-tip-chats-" + userID);
    localStorage.removeItem("tbs-tip-feeds-" + userID);
    localStorage.removeItem("tbs-tip-feedlist-" + userID);

    setTimeout(() => {
      setShow(false);
    }, 2000);
  }

  return (
    <ReactBootstrap.Row id="settings">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist tbs--new--title">
          <ReactBootstrap.Row>
            <div className="tbs--headline tbs--headline--settings">
              My Settings
            </div>
            <div className="tbs--divider--title tbs--setting--submenu--divider">
              <hr></hr>
            </div>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h3>
                Notifications
              </h3>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                Push notifications
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--settings--block">
              <Link to="/dashboard/notifications">
                {/*View all notifications*/}
                <img src={NotificationSVG} alt="Notification"/>
              </Link>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h3 className="headline-custom">
                Tips
              </h3>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                Reload pop up tips
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--settings--block">
              {/*<img src={IconReload} alt="Reload all tips" onClick={alertHandling}/>*/}
              <div onClick={alertHandling}>
                <TipSVG/>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h3>
                Edit my profile
              </h3>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                Go to my profile
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--settings--block">
              <Link to="/dashboard/settings/my-profile">
                {/*<img src={IconAvatar} alt="Profile"/><span>My Profile</span>*/}
                <ProfileSVG/>
              </Link>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h3>
                Edit and Delete My Account
              </h3>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                Go to My Account
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--settings--block">
              <Link to="/dashboard/settings/my-account">
                {/*<img src={IconKey} alt="Avatar"/><span>My Account</span>*/}
                <AccountSVG/>
              </Link>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
        {
          show
          ?
            <ReactBootstrap.Alert variant="success" transition={true} id="tips-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>Tips reloaded successfully.</ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
          :
            ""
        }
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default Settings;
