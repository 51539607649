import React from 'react';

const SettingsSVG = () => {
  return(
    <svg id="Icon_ionic-ios-settings" data-name="Icon ionic-ios-settings" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
      <path id="Icon_ionic-ios-settings-2" data-name="Icon ionic-ios-settings" d="M77.9,44.49a10.292,10.292,0,0,1,6.6-9.6A40.789,40.789,0,0,0,79.563,23a10.435,10.435,0,0,1-4.188.9,10.269,10.269,0,0,1-9.4-14.455A40.687,40.687,0,0,0,54.1,4.5,10.287,10.287,0,0,1,34.9,4.5,40.811,40.811,0,0,0,23,9.436a10.269,10.269,0,0,1-9.4,14.455A10.1,10.1,0,0,1,9.417,23,41.69,41.69,0,0,0,4.5,34.909a10.28,10.28,0,0,1,6.6,9.6,10.3,10.3,0,0,1-6.583,9.6A40.789,40.789,0,0,0,9.458,66,10.275,10.275,0,0,1,23.021,79.564a41.049,41.049,0,0,0,11.9,4.936,10.268,10.268,0,0,1,19.167,0,40.811,40.811,0,0,0,11.9-4.936A10.285,10.285,0,0,1,79.542,66a41.027,41.027,0,0,0,4.938-11.893A10.341,10.341,0,0,1,77.9,44.49ZM44.688,61.131A16.662,16.662,0,1,1,61.354,44.469,16.66,16.66,0,0,1,44.688,61.131Z" transform="translate(-4.5 -4.5)" fill="#141c27"/>
    </svg>
  )
}

export default SettingsSVG;
