import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';

import MobileAppBanner from "../components/mobileAppBanner";

import API from "./../config/config.json";

import IconUser from "./../assets/icons/IconUser.png";
import IconLock from "./../assets/icons/IconPassword.png";

/* SVG */
import Logo from "./../assets/svg/logo.svg";
import LogoDark from "./../assets/svg/logoDark.svg";

/* Views */
import CommunityPublic from "./dashboard/groups/communityPublic";

function PublicCommunity() {
  return (
    <ReactBootstrap.Container fluid={true}>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col md={5} lg={7} xl={7} id="login" className="d-none d-sm-none d-md-block">
          <div className="tbs--main--block tbs--main--block--community">
            <div className="tbs--main--title">
              {/*<img src={Logo}/>*/}
              meoh TBS
            </div>
            <div className="tbs--main--subtitle text-center">
              Leverage your trust network
            </div>
          </div>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={7} lg={5} xl={5} id="loginForm" className="tbs--public--community--outside">
          <Link to="/public-groups" className="link--custom--back--main">
            <div className="link--custom--back--overview">
              Back to the public groups list
            </div>
          </Link>
          <CommunityPublic/>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <MobileAppBanner/>
    </ReactBootstrap.Container>
  );
}

export default PublicCommunity;
