import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import Linkify from 'react-linkify';
import autosize from "autosize";
import API from "./../../../../config/config.json";

import IconAvatar from "../../../../assets/svg/avatar.svg";
//import IconAwards from "../../../../assets/icons/IconAwards.png";
//import IconAwardsActive from "../../../../assets/icons/IconAwardsBlue.png";
import IconComment from "../../../../assets/icons/IconComment.png";
import IconRepost from "../../../../assets/icons/IconRepost.png";
import IconForward from "../../../../assets/icons/IconForward.png";
import IconBookmarks from "../../../../assets/icons/IconBookmarks.png";
import IconBookmarksActive from "../../../../assets/icons/IconBookmarksBlue.png";
import IconDelete from "../../../../assets/icons/IconDelete.png";
import IconPulse from "../../../../assets/icons/IconPulse.png";
//import IconImage from "../../../../assets/icons/IconImage.png";
//import IconIntroduce from "../../../../assets/icons/IconIntroduce.png";
import PostCommentSVG from "../../../../assets/svg/postComment";
import PostRepostSVG from "../../../../assets/svg/postRepost";
import PostNoRepostSVG from "../../../../assets/svg/postNoRepost";
import PostForwardSVG from "../../../../assets/svg/postForward";
import PostBookmarkSVG from "../../../../assets/svg/postBookmark";
import PostActivitySVG from "../../../../assets/svg/postActivity";
import PostBookmarkActiveSVG from "../../../../assets/svg/postBookmarkActive";
import IconIntroduce from "../../../../assets/svg/introduce.svg";
import PostButton from "../../../../assets/svg/postButton.svg";

/* SVG */
import IconImage from "../../../../assets/svg/defaultImage.svg";
import IconAwards from "../../../../assets/svg/awardGray.svg";
import IconAwardsActive from "../../../../assets/svg/awardDark.svg";
import CommentButton from "../../../../assets/svg/commentButton.svg";
import ArrowBack from "../../../../assets/svg/arrowback.svg";
import NewPostBookmarkSVG from "../../../../assets/svg/newBookmarkInactive.svg";
import NewPostBookmarkActiveSVG from "../../../../assets/svg/newBookmarkActive.svg";
import ReportPostSVG from "../../../../assets/svg/reportPost.svg";
import BackButton from "../../../../assets/svg/backButton.svg";
import PostSubmenu from "../../../../assets/svg/dotMenu.svg";
import PostDeleteSVG from "../../../../assets/svg/postDelete";
import PostEditSVG from "../../../../assets/svg/postEdit";
import HumanIcon from "../../../../assets/svg/humanIcon.svg";

function PostFeed(props) {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get the params
  const { match: { params } } = props;

  // Get url from feeds from an user
  const urlFeeds = API.URL + 'posts/' + params.id;

  // Get url from discussion from publications
  const urlDiscussion = API.URL + 'posts/' + params.id + '/comments';

  // Get url from contributors from publications
  const urlContributors = API.URL + 'posts/' + params.id + '/contributors';

  // Get url from awards from publications
  const urlAwards = API.URL + 'posts/' + params.id + '/all-awards';

  // Get url from all chats and groups
  const urlChatGroupList = API.URL + 'conversations?isNew=true';
  const urlChatList = API.URL + 'conversations?isNew=true&type=SINGLE';
  const urlGroupList = API.URL + 'conversations?isNew=true&type=GROUP';

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Store and set the state value
  const [fPost, setFPost] = useState([]);
  const [fDiscussionPost, setFDiscussionPost] = useState([]);
  const [fContributorsPost, setFContributorsPost] = useState([]);
  const [fAwardsPost, setFAwardsPost] = useState([]);
  const [chatGroupList, setChatGroupList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [show, setShow] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [showReplyMessage, setShowReplyMessage] = useState(false);
  const [showReplyOfReplyMessage, setShowReplyOfReplyMessage] = useState(false);
  const [showReplyOfReply, setShowReplyOfReply] = useState(false);
  const [showReplyId, setShowReplyId] = useState('');
  const [showReplyOfReplyId, setShowReplyOfReplyId] = useState('');
  const [showComment, setShowComment] = useState(false);
  const [showCommentDelete, setShowCommentDelete] = useState(false);
  const [showCommentReplyDelete, setShowCommentReplyDelete] = useState(false);
  const [showCommentCreate, setShowCommentCreate] = useState(false);
  const [showReposted, setShowReposted] = useState(false);
  const [showForward, setShowForward] = useState(false);
  const [chatGroupAlert, setChatGroupAlert] = useState(false);
  const [showAwardsAlert, setShowAwardsAlert] = useState(false);
  const [showAwardsDefaultAlert, setShowAwardsDefaultAlert] = useState(false);
  const [showAwardsMessage, setShowAwardsMessage] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [commentField, setCommentField] = useState(true);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [postId, setPostId] = useState(0);
  const [text, setText] = useState('');
  const [textReply, setTextReply] = useState('');
  const [textReplyOfReply, setTextReplyOfReply] = useState('');
  const [messageForward, setMessageForward] = useState('');

  // Main container for history
  let history = useHistory();

  // Make a redirect if value is true
  useEffect(() => {
    (() => {
      if(isRedirect) {
        history.push("/dashboard/network");
      }
    })()
  }, [isRedirect]);

  // Execute the API and get all informations
  useEffect(async () => {
    await axios.get(
      urlFeeds, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    )
    .then((response) => {
      if(response.status === 200){
        setFPost(response.data);
      } else {
        window.location.replace("/dashboard/newsfeed");
      }
    })
    .catch(e => {
      window.location.replace("/dashboard/newsfeed");
      console.log(e);
    });

    //setFPost(result.data);

    const resultDiscussion = await axios.get(
      urlDiscussion, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setFDiscussionPost(resultDiscussion.data.items);

    const resultContributors = await axios.get(
      urlContributors, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setFContributorsPost(resultContributors.data.items);

    const resultAwards = await axios.get(
      urlAwards, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setFAwardsPost(resultAwards.data.items);

    const resultChatGroup = await axios.get(
      urlChatGroupList, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setChatGroupList(resultChatGroup.data.items);

    const resultChat = await axios.get(
      urlChatList, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setChatList(resultChat.data.items);

    const resultGroup = await axios.get(
      urlGroupList, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setGroupList(resultGroup.data.items);

    let allLinks = document.querySelectorAll(".tbs--contact--data--post--description > a");

    for(let i = 0; i < allLinks.length; i++){
      allLinks[i].setAttribute("target", "_blank");
    }
  }, []);


  // fetch data and validate
  const deleteComment = (id, reply) => {
    // URL for deleting my own comment or replies on posts
    const url = API.URL + 'posts/' + params.id + '/comments/' + id;

    axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShowComment(true);
          if(reply == true){
            setShowCommentReplyDelete(true);
          } else {
            setShowCommentDelete(true);
          }

          setTimeout(() => {
            setShowComment(false);
          }, 1000);

          setTimeout(async () => {
            //window.location.reload();
            setShowComment(false);
            const resultDiscussion = await axios.get(
              urlDiscussion, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setFDiscussionPost(resultDiscussion.data.items);
          }, 1200);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const handleChangeText = e => setText(e.target.value);
  const handleChangeTextReply = e => setTextReply(e.target.value);
  const handleChangeTextReplyOfReply = e => setTextReplyOfReply(e.target.value);
  const handleChangeMessageForward = e => setMessageForward(e.target.value);

  const createComment = (e) => {
    e.preventDefault();

    // URL for creating a comment on posts
    const url = API.URL + 'posts/' + params.id + '/comments';

    // Post value for validation
    const data = {
      text: text
    };

    axios
      .post(url, data,{
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(text != ""){
          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("error-text")[0].innerHTML = "";
          document.getElementsByClassName("post-comment")[0].classList.remove("error-field");

          if(response.status === 200){
            setShowComment(true);
            setShowCommentCreate(true);

            setTimeout(() => {
              setShow(false);
            }, 1000);

            setTimeout(async () => {
              //window.location.reload();
              setShowComment(false);
              setShowCommentCreate(false);
              const resultDiscussion = await axios.get(
                urlDiscussion, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setFDiscussionPost(resultDiscussion.data.items);

              const resultContributors = await axios.get(
                urlContributors, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setFContributorsPost(resultContributors.data.items);

              setText("")
            }, 1200);
          } else {
            document.getElementsByClassName("error-text")[0].style.display = "block";
            document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a message.";
            document.getElementsByClassName("post-comment")[0].classList.add("error-field");

            console.log("Error: Access Denied");
          }
        } else {
          document.getElementsByClassName("error-text")[0].style.display = "block";
          document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a message.";
          document.getElementsByClassName("post-comment")[0].classList.add("error-field");

          console.log("Text is required");
        }
      })
      .catch(e => {
        document.getElementsByClassName("error-text")[0].style.display = "block";
        document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a message.";
        document.getElementsByClassName("post-comment")[0].classList.add("error-field");

        console.log(e)
      })
  }

  const createCommentReply = (e) => {
    e.preventDefault();

    // URL for creating a reply comment on posts
    const url = API.URL + 'posts/' + params.id + '/comments/' + showReplyId + '/replies';

    // Post value for validation
    const data = {
      text: textReply
    };

    axios
      .post(url, data,{
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(textReply != ""){
          document.getElementsByClassName("error-text-reply")[0].style.display = "none";
          document.getElementsByClassName("error-text-reply")[0].innerHTML = "";
          document.getElementsByClassName("post-comment-reply")[0].classList.remove("error-field");

          if(response.status === 200){
            setShowReplyMessage(true);

            setTimeout(() => {
              setShowReplyMessage(false);
            }, 1000);

            setTimeout(async () => {
              //window.location.reload();
              setShowReplyMessage(false);
              setShowReply(false);

              const resultDiscussion = await axios.get(
                urlDiscussion, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setFDiscussionPost(resultDiscussion.data.items);

              setTextReply("")
            }, 1200);
          } else {
            document.getElementsByClassName("error-text-reply")[0].style.display = "block";
            document.getElementsByClassName("error-text-reply")[0].innerHTML = "Please enter a message.";
            document.getElementsByClassName("post-comment-reply")[0].classList.add("error-field");

            console.log("Error: Access Denied");
          }
        } else {
          document.getElementsByClassName("error-text-reply")[0].style.display = "block";
          document.getElementsByClassName("error-text-reply")[0].innerHTML = "Please enter a message.";
          document.getElementsByClassName("post-comment-reply")[0].classList.add("error-field");

          console.log("Text is required");
        }
      })
      .catch(e => {
        document.getElementsByClassName("error-text-reply")[0].style.display = "block";
        document.getElementsByClassName("error-text-reply")[0].innerHTML = "Please enter a message.";
        document.getElementsByClassName("post-comment-reply")[0].classList.add("error-field");

        console.log(e)
      })
  }

  const createCommentReplyOfReply = (e) => {
    e.preventDefault();

    // URL for creating a reply comment on posts
    const url = API.URL + 'posts/' + params.id + '/comments';

    // Post value for validation
    const data = {
      text: textReplyOfReply
    };

    axios
      .post(url, data,{
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(textReplyOfReply != ""){
          document.getElementsByClassName("error-text-reply-of-reply")[0].style.display = "none";
          document.getElementsByClassName("error-text-reply-of-reply")[0].innerHTML = "";
          document.getElementsByClassName("post-comment-reply-of-reply")[0].classList.remove("error-field");

          if(response.status === 200){
            setShowReplyOfReplyMessage(true);

            setTimeout(() => {
              setShowReplyOfReplyMessage(false);
            }, 1000);

            setTimeout(async () => {
              //window.location.reload();
              setShowReplyOfReplyMessage(false);
              setShowReplyOfReply(false);

              const resultDiscussion = await axios.get(
                urlDiscussion, {
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: token
                  }
                }
              );

              setFDiscussionPost(resultDiscussion.data.items);

              setTextReplyOfReply("")
            }, 1200);
          } else {
            document.getElementsByClassName("error-text-reply-of-reply")[0].style.display = "block";
            document.getElementsByClassName("error-text-reply-of-reply")[0].innerHTML = "Please enter a message.";
            document.getElementsByClassName("post-comment-reply-of-reply")[0].classList.add("error-field");

            console.log("Error: Access Denied");
          }
        } else {
          document.getElementsByClassName("error-text-reply-of-reply")[0].style.display = "block";
          document.getElementsByClassName("error-text-reply-of-reply")[0].innerHTML = "Please enter a message.";
          document.getElementsByClassName("post-comment-reply-of-reply")[0].classList.add("error-field");

          console.log("Text is required");
        }
      })
      .catch(e => {
        document.getElementsByClassName("error-text-reply-of-reply")[0].style.display = "block";
        document.getElementsByClassName("error-text-reply-of-reply")[0].innerHTML = "Please enter a message.";
        document.getElementsByClassName("post-comment-reply-of-reply")[0].classList.add("error-field");

        console.log(e)
      })
  }

  // Favourite validation method
  const validationFavourite = (id) => {
    // URL for the post favourites
    const url = API.URL + 'posts/' + id + '/favourites';

    // fetch data and validate
    axios
      .put(url, "", {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);

          setTimeout(() => {
            setShow(false);
          }, 1000);

          setTimeout(async () => {
            //window.location.reload();
            setShow(false);

            const urlFeedData = await axios.get(
              urlFeeds, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            )

            setFPost(urlFeedData.data);
          }, 1200);
        } else {
          console.log("Error: Cannot handle this method.");
        }
      })
      .catch(e => {
        console.log(e);
      })

  };

  // Set awards counter plus one (+1)
  const awardsCounter = (id) => {
    // URL for setting the counter of awards for comments or replies on posts plus one (+1)
    const urlAwardsCounter = API.URL + 'posts/' + params.id + '/comments/' + id + '/likes';

    axios.post(
      urlAwardsCounter, "",{
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    )
    .then((response) => {
      if(response.status === 200){
        setShowAwardsAlert(true);
        setShowAwardsMessage(true);
        setShowAwardsDefaultAlert(false);

        setTimeout(() => {
          setShowAwardsAlert(false);
          setShowAwardsMessage(false);
          setShowAwardsDefaultAlert(false);
        }, 1000);

        setTimeout(async () => {
          const resultDiscussion = await axios.get(
            urlDiscussion, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setFDiscussionPost(resultDiscussion.data.items);
        }, 1200);
      } else {
        setShowAwardsAlert(true);
        setShowAwardsMessage(false);
        setShowAwardsDefaultAlert(false);

        setTimeout(() => {
          setShowAwardsAlert(false);
          setShowAwardsMessage(false);
        }, 1000);

        setTimeout(() => {
          setShowAwardsDefaultAlert(false);
        }, 1500);
      }
    })
    .catch(e => {
      if(e.response.status === 400){
        setShowAwardsAlert(true);
        setShowAwardsMessage(false);
        setShowAwardsDefaultAlert(false);

        setTimeout(() => {
          setShowAwardsAlert(false);
          setShowAwardsMessage(false);
        }, 1000);

        setTimeout(() => {
          setShowAwardsDefaultAlert(false);
        }, 1500);
      }

      console.log(e);
    });
  }

  // Forward a post validation method - Not complete and valid
  const validationForwardPost = () => {
    // URL for the post to forwarded
    const urlForwardPost = API.URL + 'posts/' + params.id + '/message';

    // Get all values of the checkboxes which is checked.
    const dataChat = [...document.querySelectorAll('.chat-list:checked')].map(e => Number(e.value));
    const dataGroup = [...document.querySelectorAll('.group-list:checked')].map(e => Number(e.value));

    // Data validation
    const data = {
      userIds: dataChat,
      groupIds: dataGroup,
      message: messageForward
    }

    console.log(dataChat.length)
    console.log(dataGroup.length)

    // fetch data and validate
    if((dataChat.length != 0 && dataGroup.length == 0) || dataChat.length == 0 && dataGroup.length != 0){
      document.querySelector("#chat-group-select-contact > .row:first-child").classList.remove("is-show");

      axios
        .post(urlForwardPost, data, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        })
        .then((response) => {
          if (response.status === 200) {
            setChatGroupAlert(true)

            setTimeout(() => {
              setShowForward(false);
              setChatGroupAlert(false);
            }, 2000);

            setTimeout(() => {
              setIsRedirect(true);
            }, 3000);
          }
        })
    } else {
      document.querySelector("#chat-group-select-contact > .row:first-child").classList.add("is-show");
    }
  }

  // Reposted a post validation method
  const validationRepostedPost = () => {

    // URL for the post to reposted
    const urlForward = API.URL + 'posts/' + params.id + '/forward';

    let title = fPost.parentPost != null ? fPost.parentPost.title : fPost.title;
    let description = fPost.parentPost != null ? fPost.parentPost.description : fPost.description;
    let fileId = fPost.parentPost != null ? fPost.parentPost.fileId : fPost.fileId;
    let audioId = fPost.parentPost != null ? fPost.parentPost.audioId : fPost.audioId;

    const data = {
      parentPost: {
        title: title,
        description: description,
        fileId: fileId,
        audioId: audioId,
        forwardToConvoType: "SINGLE"
      }
    }

    // fetch data and validate
    axios
      .post(urlForward, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShowReposted(true);

          setTimeout(() => {
            setShowReposted(false);
          }, 1000);

          /*setTimeout(() => {
            window.location.reload();
          }, 1200);*/
        } else {
          console.log("Error: Cannot handle this method.");
        }
      })
      .catch(e => {
        console.log(e);
      })

  };

  const Schema = Yup.object().shape({
    text: Yup.string()
      .required('Please enter a message.')
  });

  // Toggle method for show and hide "reply" form
  const toggleReply = (id) => {
    if(showReplyId == id) {
      setShowReply(!showReply);
      setShowReplyId(id);
    } else {
      setShowReply(true);
      setShowReplyId(id);
      setTextReply("");
    }
  }

  const toggleReplyOfReply = (parentId, id) => {
    if(showReplyOfReplyId == id) {
      setShowReplyOfReply(!showReplyOfReply);
      setShowReplyOfReplyId(id);
    } else {
      setShowReplyOfReply(true);
      setShowReplyOfReplyId(id);
      setTextReplyOfReply("");
    }
  }

  const urlify = (text) => {
    let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    return text.replace(urlRegex, function(url) {

      return '<a href="' + url + '" target="_blank">' + url + '</a>';

    })
  }

  useEffect(() => {
    const textElement = document.querySelectorAll("textarea");
    autosize(textElement);

    const buttonElement = document.querySelectorAll("#postButtonComment img");
    autosize(buttonElement);
  })

  const changeVisibleCommentField = () => {
    setCommentField(!commentField)
    //console.log(!commentField)
  }

  const savePostId = (id) => {
    setPostId(id);
    setIsModalDeleteOpen(true)
  }

  // fetch data and validate
  const deletePost = (id) => {
    // URL for deleting my own posts
    const url = API.URL + 'posts/' + id;

    axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShowDelete(true);

          setTimeout(() => {
            setShowDelete(false);
            setIsModalDeleteOpen(false);
          }, 1500);

          setTimeout(() => {
            window.location.replace("/dashboard/profile");
          }, 2000);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const focusField = () => {
    document.getElementById("textPost").focus();
  }

  return (
    <ReactBootstrap.Row id="post-feed">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          chatGroupAlert
          ?
            <ReactBootstrap.Alert variant="success" transition={true} id="chat-group-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>
                Post has been forwarded.
              </ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
          :
            ""
        }
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          show
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="favourite-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>
                {fPost.isFavourite == true ? "Remove from favourites" : "Added to favourites"}
              </ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container--post">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--container--post--item">
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--reposted">
                  {
                    fPost.parentId != null
                    ?
                      <span className="reposted-post-icon">
                        <PostRepostSVG/>
                      </span>
                    :
                      ""
                  }
                  { fPost.parentPost != null ? "Reposted by " + fPost.user.name : "" }
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row className="tbs-friend-posts">
                <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10} className="tbs--contact--details">
                  <Link to={`/dashboard/profile/${fPost.parentPost != null ? fPost.parentPost.userId : fPost.userId}`}>
                    <div className="tbs--contact--image">
                      {/*
                        fPost.user != null && ((fPost.user.profileImage != null) || (fPost.parentPost != null && fPost.parentPost.user != null)) && fPost.parentId == null
                          ?
                          <img src={fPost.user.profileImage != null ? fPost.user.profileImage.url : fPost.user.image} className="tbs--container--post--image" alt="Profile"/>
                          :
                          fPost.parentPost != null
                            ?
                            <img src={fPost.parentPost.user != null && fPost.parentPost.user.profileImage != null ? fPost.parentPost.user.profileImage.url : ""} className="tbs--container--post--image" alt="Profile"/>
                            :
                            fPost.user.image != null
                              ?
                              <img src={fPost.user.image} className="tbs--container--feedlist--image" alt="Profile"/>
                              :
                              <img src={IconAvatar} className="tbs--container--feedlist--image" alt="Profile"/>
                      */}
                      {
                      <img src={
                        fPost.parentPost != null
                        ?
                          fPost.parentPost.user != null
                          ?
                            fPost.parentPost.user.profileImage != null
                            ?
                              fPost.parentPost.user.profileImage.url
                            :
                              fPost.parentPost.user.image
                          :
                            fPost.user != null
                            ?
                              fPost.user.profileImage != null
                              ?
                                fPost.user.profileImage.url
                              :
                                fPost.user.image != null
                                ?
                                  fPost.user.image
                                :
                                  IconAvatar
                            :
                              IconAvatar
                        :
                          fPost.user != null
                          ?
                            fPost.user.profileImage != null
                            ?
                              fPost.user.profileImage.url
                            :
                              fPost.user.image != null
                              ?
                                fPost.user.image
                              :
                                IconAvatar
                          :
                            IconAvatar
                      }
                       className="tbs--container--post--image"
                       alt="Profile"/>
                      }
                    </div>
                    <div className="tbs--contact--data--post">
                      <div>
                        {
                          fPost.parentPost != null && fPost.parentPost.user != null
                            ?
                            fPost.parentPost.user.name
                            :
                            fPost.user != null
                            ?
                              fPost.user.name
                            :
                              ""
                        }
                      </div>
                      <div>
                        {
                          fPost.parentPost != null
                            ?
                            fPost.parentPost.user != null
                              ?
                              fPost.parentPost.user.position != null
                                ?
                                fPost.parentPost.user.position
                                :
                                "null"
                              :
                              fPost.user != null && fPost.user.position != null
                                ?
                                fPost.user.position
                                :
                                "null"
                            :
                            fPost.user != null && fPost.user.position != null
                              ?
                              fPost.user.position
                              :
                              "null"
                        }
                        <span> at </span>
                        {
                          fPost.parentPost != null
                            ?
                            fPost.parentPost.user != null
                              ?
                              fPost.parentPost.user.company != null
                                ?
                                fPost.parentPost.user.company
                                :
                                "null"
                              :
                              fPost.user != null && fPost.user.company != null
                                ?
                                fPost.user.company
                                :
                                "null"
                            :
                            fPost.user != null && fPost.user.company != null
                              ?
                              fPost.user.company
                              :
                              "null"
                        }
                      </div>
                      <div>
                        {moment(fPost.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                      </div>
                    </div>
                  </Link>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--bookmark text-right" id="tbs--post--submenu">
                  <div className="tbs--contact--data--post">
                    <ReactBootstrap.DropdownButton
                      menuAlign="right"
                      title={
                        <img src={PostSubmenu} alt="Submenu - Post" />
                      }
                      id="dropdown-menu-post"
                      //onToggle={() => toggleNotification()}
                    >
                      <div className="back-button-newsfeed">
                        <a href="javascript:history.back()">
                          {/*<img src={BackButton} alt="Back" />*/}
                          <div>Back</div>
                        </a>
                      </div>
                      <div className="report-post-container">
                        <div className="button--sign">
                          <Link to={`${url}/report`}>
                            {/*<img src={ReportPostSVG} alt="Report post" className="report-post-icon"/>*/}
                            <div>Report Post</div>
                          </Link>
                        </div>
                      </div>
                      <div className="tbs--contact--data--post--favourite">
                        {
                          fPost.isFavourite == true
                            ?
                            /* Remove */
                            /*<img className="tbs--favourite--image" src={IconBookmarksActive} alt="Bookmarks" onClick={() => validationFavourite(params.id)}/>*/
                            <span onClick={() => validationFavourite(params.id)}>
                            {/*
                              <PostBookmarkActiveSVG/>
                            */}
                              {/*<img src={NewPostBookmarkActiveSVG}/>*/}
                              <div>Bookmark</div>
                            </span>
                            :
                            /* Add */
                            /*<img className="tbs--favourite--image" src={IconBookmarks} alt="Bookmarks" onClick={() => validationFavourite(params.id)}/>*/
                            <span onClick={() => validationFavourite(params.id)}>
                            {/*
                              <PostBookmarkSVG/>
                            */}
                              {/*<img src={NewPostBookmarkSVG}/>*/}
                              <div>Bookmark</div>
                            </span>
                        }
                      </div>
                      {
                        fPost.userId == userID
                          ?
                          <div className="delete-post-container">
                            <div className="button--sign" onClick={() => savePostId(fPost.id)}>
                              <div>Delete</div>
                            </div>
                          </div>
                          :
                          ""
                      }
                      {
                        fPost.userId == userID && fPost.parentPost == null
                        ?
                          <div className="edit-post-container">
                            <div className="button--sign">
                              <Link to={`/dashboard/profile/post/${params.id}/edit`}>
                                <div>Edit</div>
                              </Link>
                            </div>
                          </div>
                        :
                          ""
                      }
                    </ReactBootstrap.DropdownButton>
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="tbs--contact--data--post">
                    <div className="tbs--contact--data--post--image">
                      <img src={fPost.parentPost === null ? fPost.file != null ? fPost.file.url : "" : fPost.parentPost != null ? fPost.parentPost.file !== null ? fPost.parentPost.file.url : "" : ""}
                           alt="Feed - Image"/>
                      <div className={
                        (((fPost.parentPost != null && fPost.parentPost.file == null) && fPost.file === null) || (fPost.parentPost == null && fPost.file === null))
                        ?
                          "tbs--contact--data--post no-image-available"
                        :
                          "tbs--contact--data--post"
                      }>
                        <div className="tbs--contact--data--post--title">
                          {fPost.parentPost === null ? fPost.title : fPost.parentPost != null ? fPost.parentPost.title : ""}
                        </div>
                      </div>
                    </div>
                    {
                      fPost.parentPost != null && fPost.parentPost.audioFile != null
                      ?
                        <div className="tbs--contact--audio">
                          <audio controls controlsList="nodownload">
                            <source src={fPost.parentPost.audioFile.url} type="audio/mp3"/>
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      :
                        fPost.audioFile != null
                        ?
                          <div className="tbs--contact--audio">
                            <audio controls controlsList="nodownload">
                              <source src={
                                fPost.audioFile != null
                                ?
                                  fPost.audioFile.url
                                :
                                  ""
                              } type="audio/mp3"/>
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                        :
                          ""
                    }
                  </div>
                  <div className="tbs--contact--data--post--description tbs--contact--data--post--description-detail">
                    {
                      fPost.parentPost === null
                        ?
                        <Linkify>
                          {fPost.description}
                        </Linkify>
                        :
                        fPost.parentPost != null
                          ?
                          <Linkify>
                            {fPost.parentPost.description}
                          </Linkify>
                          :
                          ""
                    }
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="tbs--contact--data--feeds">
                    <div className="tbs--contact--data--feeds--infos">
                      <span className="w20-flex" onClick={focusField}>
                        {/*<img src={IconComment} alt="Comments"/>*/}
                        <PostCommentSVG/>
                        <span>{fPost.totalComments}</span>
                      </span>
                      {
                        fPost.parentId == null
                        ?
                          <span className="w20-flex" onClick={validationRepostedPost}>
                            {/*<img src={IconRepost} alt="Repost"/>*/}
                            <PostRepostSVG/>
                            <span>{fPost.repostedCount}</span>
                          </span>
                        :
                          <span className="w20-flex">
                            {/*<img src={IconRepost} alt="Repost"/>*/}
                            <PostNoRepostSVG/>
                            <span>{fPost.repostedCount}</span>
                          </span>
                      }
                      <span className="w20-flex" onClick={() => setShowForward(true)}>
                        {/*<img src={IconForward} alt="Forward"/>*/}
                        <PostForwardSVG/>
                        <span>{fPost.forwardedCount}</span>
                      </span>
                      {/*<img src={IconBookmarks} alt="Bookmarks"/>*/}
                      {
                        fPost.isFavourite == true
                          ?
                          /* Remove */
                          /*<img className="tbs--favourite--image" src={IconBookmarksActive} alt="Bookmarks" onClick={() => validationFavourite(params.id)}/>*/
                          <span className="w20-flex" onClick={() => validationFavourite(params.id)}>
                            {/*
                              <img src={PostBookmarkActiveSVG}/>
                            */}
                            <PostBookmarkActiveSVG/>
                            <span>{fPost.bookmarkedCount}</span>
                          </span>
                          :
                          /* Add */
                          /*<img className="tbs--favourite--image" src={IconBookmarks} alt="Bookmarks" onClick={() => validationFavourite(params.id)}/>*/
                          <span className="w20-flex" onClick={() => validationFavourite(params.id)}>
                            {/*
                              <img src={PostBookmarkSVG}/>
                            */}
                            <PostBookmarkSVG/>
                            <span>{fPost.bookmarkedCount}</span>
                          </span>
                      }
                      <span className="w20-flex">
                        {/*<img src={IconPulse} alt="Activity"/>*/}
                        <PostActivitySVG/>
                        <span>{fPost.activitiesCount}</span>
                      </span>
                    </div>
                    <ReactBootstrap.Modal
                      show={showForward}
                      onHide={() => setShowForward(false)}
                      size="md"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <ReactBootstrap.Modal.Header closeButton>
                        <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                          Forward this post to
                        </ReactBootstrap.Modal.Title>
                      </ReactBootstrap.Modal.Header>
                      <ReactBootstrap.Modal.Body id="chat-group-select-contact">
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                            <div className="error-text">Please select one or more contacts first.</div>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        {
                          chatList.map(list => {
                            if(list.type == "SINGLE"){
                              return (
                                <ReactBootstrap.Row>
                                  <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--image--select">
                                    <img src={
                                      list.conversationReceiver.user.image != null
                                      ?
                                        list.conversationReceiver.user.image
                                      :
                                        list.conversationReceiver.user.profileImage != null
                                        ?
                                          list.conversationReceiver.user.profileImage.url
                                        :
                                          IconAvatar
                                    }
                                         alt="Contact image"
                                    />
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="tbs--contact--name--select">
                                    {list.conversationReceiver.user.name}
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--checkbox--select">
                                    <input type="checkbox" className="chat-list" name="contact" value={list.conversationReceiver.userId} />
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                              )
                            }
                          })
                        }
                        {
                          chatList.map(list => {
                            if(list.type == "GROUP" && list.groupType == "introduce"){
                              return (
                                <ReactBootstrap.Row>
                                  <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--image--select tbs--introduce--image">
                                    <img src={IconIntroduce} alt="Contact image"  />
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="tbs--contact--name--select">
                                    {list.name}
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--checkbox--select">
                                    <input type="checkbox" className="group-list" name="contact" value={list.id} />
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                              )
                            }
                          })
                        }
                        {
                          groupList.map((list) => {
                            if(list.type == "GROUP"){
                              return (
                                <ReactBootstrap.Row>
                                  <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--image--select">
                                    {
                                      list.groupType == "introduce"
                                        ?
                                        <img src={
                                          list.image != null
                                            ?
                                            list.image.url
                                            :
                                            list.conversationReceiver.user.image != null
                                              ?
                                              list.conversationReceiver.user.image
                                              :
                                              IconIntroduce
                                        }
                                             alt="Group image - Introduce"
                                             className="tbs--image--chats--introduce"
                                        />
                                        :
                                        <img src={
                                          list.image != null
                                            ?
                                            list.image.url
                                            :
                                            IconImage
                                        }
                                             alt="Group image"
                                        />
                                    }
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="tbs--contact--name--select">
                                    {list.name}
                                  </ReactBootstrap.Col>
                                  <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--checkbox--select">
                                    <input type="checkbox" className="group-list" name="contact" value={list.id} />
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                              )
                            }
                          })
                        }
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div>
                              <input type="search" autocomplete="off" className={`forward-message`} name="forwardMessage" id="forwardMessage" placeholder="Enter your message here." value={messageForward} onChange={handleChangeMessageForward} />
                            </div>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <div className="text-center button--sign">
                          <ReactBootstrap.Button onClick={() => validationForwardPost()}>Forward</ReactBootstrap.Button>
                        </div>
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            {
                              chatGroupAlert
                                ?
                                <ReactBootstrap.Alert variant="success" transition={true} id="chat-group-alert" className="text-center">
                                  <ReactBootstrap.Alert.Heading>
                                    Post has been forwarded.
                                  </ReactBootstrap.Alert.Heading>
                                </ReactBootstrap.Alert>
                                :
                                ""
                            }
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      </ReactBootstrap.Modal.Body>
                    </ReactBootstrap.Modal>
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {
                    show
                      ?
                      <ReactBootstrap.Alert variant="success" transition={true} id="favourite-alert" className="text-center">
                        <ReactBootstrap.Alert.Heading>
                          {fPost.isFavourite == true ? "Remove from favourites" : "Added to favourites"}
                        </ReactBootstrap.Alert.Heading>
                      </ReactBootstrap.Alert>
                      :
                      ""
                  }
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="tbs--contact--data--feeds--dca">
                    <div className="tbs--contact--data--feeds--groups">
                      <ReactBootstrap.Tabs defaultActiveKey="conversation" fill transition={false} onSelect={changeVisibleCommentField}>
                        <ReactBootstrap.Tab eventKey="conversation" title="Conversation">
                          <ReactBootstrap.Row className="tbs--contact--discussion--user">
                            {
                              fDiscussionPost.length === 0
                              ?
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--contact--discussion--default text-center">
                                  <div>
                                    No conversation to display at this time...
                                  </div>
                                  <div className="tbs--no--conversation">
                                    <img src={HumanIcon} alt="Human"/>
                                  </div>
                                </ReactBootstrap.Col>
                              :
                                ""
                            }
                            {
                              fDiscussionPost.map((item, index) => {
                                return (
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} key={item.id}>
                                    <div className="tbs--contact--discussion--profiles">
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--comment--default--discussion">
                                          <div className="tbs--contact--image">
                                            <img src={item.user.image != null ? item.user.image : item.user.profileImage != null ? item.user.profileImage.url : IconAvatar}
                                                 className="tbs--image--feeds"
                                                 alt="Profile"/>
                                          </div>
                                          <div className="tbs--discussion--comment--detail--block">
                                            <div className="tbs--discussion--user">
                                              <ReactBootstrap.Row className="post-awards">
                                                <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                  <div>
                                                    {item.user.name}
                                                  </div>
                                                </ReactBootstrap.Col>
                                                <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="post-awards-image-counter text-right">
                                                  <img src={IconAwardsActive} />
                                                  <span>
                                                    {item.likesCount > 0 ? item.likesCount : ""}
                                                  </span>
                                                </ReactBootstrap.Col>
                                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                  <div className="tbs--discussion--comment--date">
                                                    {moment(item.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                                  </div>
                                                </ReactBootstrap.Col>
                                              </ReactBootstrap.Row>
                                            </div>
                                            <div className="tbs--discussion--text">
                                              <Linkify>
                                                {item.text}
                                              </Linkify>
                                            </div>
                                          </div>
                                          <div className={`reply--comment comment--${item.id}`}>
                                            {
                                              item.userId === userID
                                                ?
                                                <div className="tbs--discussion--comment--reply--awards">
                                                  <div id={`comment--reply--${item.id}`} onClick={() => toggleReply(item.id)}>
                                                    {/*<img src={IconComment} alt="Comment"/>*/}
                                                    <i className="fas fa-comment-alt fa-1x"></i>
                                                    <span className="tbs--discussion--comment--reply--awards--icon">Reply</span>
                                                  </div>
                                                  <div onClick={() => deleteComment(item.id, false)}>
                                                    {/*<img src={IconDelete} alt="Delete"/>*/}
                                                    <i className="fas fa-times fa-1x"></i>
                                                    <span className="tbs--discussion--comment--reply--awards--icon">Delete</span>
                                                  </div>
                                                </div>
                                                :
                                                <div className="tbs--discussion--comment--reply--awards">
                                                  <div id={`comment--reply--${item.id}`} onClick={() => toggleReply(item.id)}>
                                                    {/*<img src={IconComment} alt="Comment"/>*/}
                                                    <i className="fas fa-comment-alt fa-1x"></i>
                                                    <span className="tbs--discussion--comment--reply--awards--icon">Reply</span>
                                                  </div>
                                                  <div className="tbs--awards--sizer" onClick={() => setShowAwardsDefaultAlert(true)}>
                                                    <img src={IconAwardsActive} alt="Awards"/>
                                                    <span>Awards</span>
                                                  </div>
                                                  <ReactBootstrap.Modal
                                                    show={showAwardsDefaultAlert}
                                                    onHide={() => setShowAwardsDefaultAlert(false)}
                                                    size="md"
                                                    aria-labelledby="contained-modal-title-vcenter-four"
                                                    centered
                                                  >
                                                    <ReactBootstrap.Modal.Header closeButton>
                                                      <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-four">
                                                        Award
                                                      </ReactBootstrap.Modal.Title>
                                                    </ReactBootstrap.Modal.Header>
                                                    <ReactBootstrap.Modal.Body id="awards-info">
                                                      <ReactBootstrap.Row>
                                                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                          Are you sure you want to give an award?
                                                        </ReactBootstrap.Col>
                                                        <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                          <div className="text-center button--sign">
                                                            <ReactBootstrap.Button type="submit" onClick={() => setShowAwardsDefaultAlert(false)}>No</ReactBootstrap.Button>
                                                          </div>
                                                        </ReactBootstrap.Col>
                                                        <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                          <div className="text-center button--sign">
                                                            <ReactBootstrap.Button type="submit" onClick={() => awardsCounter(item.id)}>Yes</ReactBootstrap.Button>
                                                          </div>
                                                        </ReactBootstrap.Col>
                                                      </ReactBootstrap.Row>
                                                    </ReactBootstrap.Modal.Body>
                                                  </ReactBootstrap.Modal>
                                                </div>
                                            }
                                          </div>
                                          {
                                            showReply
                                              ?
                                                showReplyId == item.id
                                                ?
                                                  <ReactBootstrap.Row className="tbs--line--form">
                                                    <ReactBootstrap.Col className="reply--comment--show" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                      <div className={`form--reply--${item.id}`}>
                                                        <Formik
                                                          initialValues={{
                                                            text: '',
                                                          }}
                                                          validationSchema={Schema}
                                                          onSubmit={values => {
                                                            console.log(values.text);
                                                          }}
                                                        >
                                                          {({ errors, touched }) => (
                                                            <Form onSubmit={createCommentReply}>
                                                              <ReactBootstrap.Row>
                                                                <ReactBootstrap.Col xs={9} sm={10} md={9} lg={10} xl={10}>
                                                                  <div>
                                                                    <Field type="text" className={`post-comment-reply`} name="text" id="text" placeholder="Contribute to the conversation" value={textReply} onChange={handleChangeTextReply}/>
                                                                  </div>
                                                                  <div className="error-text-reply">
                                                                    {/*
                                                                      errors.text && touched.text
                                                                        ?
                                                                        errors.text
                                                                        :
                                                                        null
                                                                    */}
                                                                    <ErrorMessage name="text">
                                                                      {errors.text}
                                                                    </ErrorMessage>
                                                                  </div>
                                                                </ReactBootstrap.Col>
                                                                <ReactBootstrap.Col xs={3} sm={2} md={3} lg={2} xl={2}>
                                                                  <div className="text-center button--sign">
                                                                    <ReactBootstrap.Button type="submit">
                                                                      {
                                                                        /*
                                                                          <ReactBootstrap.Button type="submit">
                                                                            Post
                                                                          </ReactBootstrap.Button>
                                                                          <img src={CommentButton} />
                                                                        */
                                                                      }
                                                                      Post
                                                                    </ReactBootstrap.Button>
                                                                  </div>
                                                                </ReactBootstrap.Col>
                                                              </ReactBootstrap.Row>
                                                            </Form>
                                                          )}
                                                        </Formik>
                                                      </div>
                                                    </ReactBootstrap.Col>
                                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                      {
                                                        showReplyMessage
                                                          ?
                                                          <ReactBootstrap.Alert variant="success" transition={true} id="reply-alert" className="text-center">
                                                            <ReactBootstrap.Alert.Heading>
                                                              Commented (Reply) successfully.
                                                            </ReactBootstrap.Alert.Heading>
                                                          </ReactBootstrap.Alert>
                                                          :
                                                          ""
                                                      }
                                                    </ReactBootstrap.Col>
                                                  </ReactBootstrap.Row>
                                                :
                                                  ""
                                              :
                                                ""
                                          }
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                      {
                                        item.replies.map((replyItem, index) => {
                                          return (
                                            <ReactBootstrap.Row className="tbs--contact--discussion--reply" key={replyItem.id}>
                                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className="tbs--contact--discussion--reply--profiles">
                                                  <ReactBootstrap.Row>
                                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--comment--default--discussion tbs--comment--default--discussion--reply">
                                                      <div className="tbs--contact--image">
                                                        <img src={replyItem.user.image != null ? replyItem.user.image : replyItem.user.profileImage != null ? replyItem.user.profileImage.url : IconAvatar}
                                                             className="tbs--image--feeds"
                                                             alt="Profile"/>
                                                      </div>
                                                      <div className="tbs--discussion--comment--detail--block">
                                                        <div className="tbs--discussion--user">
                                                          <ReactBootstrap.Row className="reply-awards">
                                                            <ReactBootstrap.Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                              <div>
                                                                {replyItem.user.name}
                                                              </div>
                                                            </ReactBootstrap.Col>
                                                            <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="post-awards-image-counter-reply text-right">
                                                              <img src={IconAwardsActive} />
                                                              <span>
                                                                {
                                                                  item.replies.map(replyItemAward => {
                                                                    if(replyItemAward.likesCount > 0){
                                                                      return replyItemAward.likesCount;
                                                                    }
                                                                  })
                                                                }
                                                              </span>
                                                            </ReactBootstrap.Col>
                                                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                              <div className="tbs--discussion--comment--date">
                                                                {moment(replyItem.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                                              </div>
                                                            </ReactBootstrap.Col>
                                                          </ReactBootstrap.Row>
                                                        </div>
                                                        <div className="tbs--discussion--text">
                                                          <Linkify>
                                                            {item.text}
                                                          </Linkify>
                                                        </div>
                                                      </div>
                                                      <div className={`reply--comment comment--${replyItem.id}`}>
                                                        {
                                                          replyItem.userId === userID
                                                            ?
                                                            <div className="tbs--discussion--comment--reply--awards">
                                                              <div onClick={() => toggleReplyOfReply(item.id, replyItem.id)}>
                                                                {/*<img src={IconComment} alt="Comment"/>*/}
                                                                <i className="fas fa-comment-alt fa-1x"></i>
                                                                <span className="tbs--discussion--comment--reply--awards--icon">Reply</span>
                                                              </div>
                                                              <div onClick={() => deleteComment(replyItem.id, true)}>
                                                                {/*<img src={IconDelete} alt="Delete"/>*/}
                                                                <i className="fas fa-times fa-1x"></i>
                                                                <span className="tbs--discussion--comment--reply--awards--icon">Delete</span>
                                                              </div>
                                                            </div>
                                                            :
                                                            <div className="tbs--discussion--comment--reply--awards">
                                                              <div onClick={() => toggleReplyOfReply(item.id, replyItem.id)}>
                                                                {/*<img src={IconComment} alt="Comment"/>*/}
                                                                <i className="fas fa-comment-alt fa-1x"></i>
                                                                <span className="tbs--discussion--comment--reply--awards--icon">Reply</span>
                                                              </div>
                                                              <div className="tbs--awards--sizer" onClick={() => setShowAwardsDefaultAlert(true)}>
                                                                <img src={IconAwardsActive} alt="Awards"/>
                                                                <span>Awards</span>
                                                              </div>
                                                              <ReactBootstrap.Modal
                                                                show={showAwardsDefaultAlert}
                                                                onHide={() => setShowAwardsDefaultAlert(false)}
                                                                size="md"
                                                                aria-labelledby="contained-modal-title-vcenter-four"
                                                                centered
                                                              >
                                                                <ReactBootstrap.Modal.Header closeButton>
                                                                  <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-four">
                                                                    Award
                                                                  </ReactBootstrap.Modal.Title>
                                                                </ReactBootstrap.Modal.Header>
                                                                <ReactBootstrap.Modal.Body id="awards-info">
                                                                  <ReactBootstrap.Row>
                                                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                      Are you sure you want to give an award?
                                                                    </ReactBootstrap.Col>
                                                                    <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                      <div className="text-center button--sign">
                                                                        <ReactBootstrap.Button type="submit" onClick={() => setShowAwardsDefaultAlert(false)}>No</ReactBootstrap.Button>
                                                                      </div>
                                                                    </ReactBootstrap.Col>
                                                                    <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                      <div className="text-center button--sign">
                                                                        <ReactBootstrap.Button type="submit" onClick={() => awardsCounter(item.id)}>Yes</ReactBootstrap.Button>
                                                                      </div>
                                                                    </ReactBootstrap.Col>
                                                                  </ReactBootstrap.Row>
                                                                </ReactBootstrap.Modal.Body>
                                                              </ReactBootstrap.Modal>
                                                            </div>
                                                        }
                                                      </div>
                                                      {
                                                        showReplyOfReply
                                                          ?
                                                          showReplyOfReplyId == replyItem.id
                                                            ?
                                                            <ReactBootstrap.Row className="tbs--line--form">
                                                              <ReactBootstrap.Col className="reply--comment--show" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <div className={`form--reply--${item.id}`}>
                                                                  <Formik
                                                                    initialValues={{
                                                                      text: '',
                                                                    }}
                                                                    validationSchema={Schema}
                                                                    onSubmit={values => {
                                                                      console.log(values.text);
                                                                    }}
                                                                  >
                                                                    {({ errors, touched }) => (
                                                                      <Form onSubmit={createCommentReplyOfReply}>
                                                                        <ReactBootstrap.Row>
                                                                          <ReactBootstrap.Col xs={9} sm={10} md={9} lg={10} xl={10}>
                                                                            <div>
                                                                              <Field type="text" className={`post-comment-reply-of-reply`} name="text" id="text" placeholder="Contribute to the conversation" value={textReplyOfReply} onChange={handleChangeTextReplyOfReply}/>
                                                                            </div>
                                                                            <div className="error-text-reply-of-reply">
                                                                              {/*
                                                                                errors.text && touched.text
                                                                                  ?
                                                                                  errors.text
                                                                                  :
                                                                                  null
                                                                              */}
                                                                              <ErrorMessage name="text">
                                                                                {errors.text}
                                                                              </ErrorMessage>
                                                                            </div>
                                                                          </ReactBootstrap.Col>
                                                                          <ReactBootstrap.Col xs={3} sm={2} md={3} lg={2} xl={2}>
                                                                            <div className="text-center button--sign">
                                                                              <ReactBootstrap.Button type="submit">
                                                                                {
                                                                                  /*
                                                                                    <ReactBootstrap.Button type="submit">
                                                                                      Post
                                                                                    </ReactBootstrap.Button>
                                                                                    <img src={CommentButton} />
                                                                                  */
                                                                                }
                                                                                Post
                                                                              </ReactBootstrap.Button>
                                                                            </div>
                                                                          </ReactBootstrap.Col>
                                                                        </ReactBootstrap.Row>
                                                                      </Form>
                                                                    )}
                                                                  </Formik>
                                                                </div>
                                                              </ReactBootstrap.Col>
                                                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                {
                                                                  showReplyOfReplyMessage
                                                                  ?
                                                                    <ReactBootstrap.Alert variant="success" transition={true} id="reply-of-reply-alert" className="text-center">
                                                                      <ReactBootstrap.Alert.Heading>
                                                                        Commented successfully.
                                                                      </ReactBootstrap.Alert.Heading>
                                                                    </ReactBootstrap.Alert>
                                                                  :
                                                                    ""
                                                                }
                                                              </ReactBootstrap.Col>
                                                            </ReactBootstrap.Row>
                                                            :
                                                            ""
                                                          :
                                                          ""
                                                      }
                                                    </ReactBootstrap.Col>
                                                  </ReactBootstrap.Row>
                                                </div>
                                              </ReactBootstrap.Col>
                                            </ReactBootstrap.Row>
                                          )
                                        })
                                      }
                                    </div>
                                  </ReactBootstrap.Col>
                                )
                              })
                            }
                          </ReactBootstrap.Row>
                        </ReactBootstrap.Tab>
                        <ReactBootstrap.Tab eventKey="contributors" title="Contributors">
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center tbs--awards--headline">
                              This post has {fContributorsPost.length} contributors
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                          <ReactBootstrap.Row className="tbs--contact--contributors--user tbs--contact--awards">
                            {
                              fContributorsPost.map((item, index) => {
                                return (
                                  <ReactBootstrap.Col xs={6} sm={6} md={3} lg={2} xl={2} key={item.id}>
                                    <Link to={`/dashboard/profile/${item.id}`}>
                                      <div className="text-center tbs--contact--contributors--profiles">
                                        <div className="tbs--contact--image">
                                          <img src={item.profileImage == null ? item.image != null ? item.image : IconAvatar : item.profileImage.url}
                                               className="tbs--image--feeds"
                                               alt="Profile"/>
                                        </div>
                                        <div className="tbs--contact--feature">
                                          {item.name}
                                        </div>
                                      </div>
                                    </Link>
                                  </ReactBootstrap.Col>
                                )
                              })
                            }
                          </ReactBootstrap.Row>
                        </ReactBootstrap.Tab>
                        {/*<ReactBootstrap.Tab eventKey="awards" title="Awards">
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center tbs--awards--headline">
                              <img src={IconAwardsActive} alt="User Awards"/><span>This post received {fPost.totalAwards} Awards</span>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                          <ReactBootstrap.Row>
                            {
                            fAwardsPost.map((item, index) => {
                              if(item.users.length !== 0){
                                return (
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} key={item.id}>
                                    <div className="text-center">
                                      {item.users.length} people find you {item.name}
                                    </div>
                                    <div className="tbs--contact--awards--user tbs--contact--awards">
                                      {
                                        item.users.map((user, index) => {
                                          return (
                                            <Link to={`/dashboard/profile/${item.id}`}>
                                              <div className="text-center tbs--contact--awards--profiles" key={user.id}>
                                                <div className="tbs--contact--image">
                                                  <img src={user.profileImage == null ? IconAvatar : user.profileImage.url}
                                                       className="tbs--image--feeds"
                                                       alt="Profile"/>
                                                </div>
                                                <div className="tbs--contact--feature">
                                                  {user.name}
                                                </div>
                                              </div>
                                            </Link>
                                          )
                                        })
                                      }
                                    </div>
                                  </ReactBootstrap.Col>
                                )
                              }
                            })
                          }
                          </ReactBootstrap.Row>
                        </ReactBootstrap.Tab>*/}
                      </ReactBootstrap.Tabs>
                    </div>
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          showReposted
          ?
            <ReactBootstrap.Alert variant="success" transition={true} id="reposted-post-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>
                Post has been reposted.
              </ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
          :
            ""
        }
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          commentField
          ?
            <div className="form--comments">
              <div className="tbs--form--main--error text-center"></div>
              <div className="tbs--form--complete--post">
                <Formik
                  initialValues={{
                    text: '',
                  }}
                  validationSchema={Schema}
                  onSubmit={values => {
                    console.log(values.text);
                  }}
                >
                  {({ errors, touched }) => (
                    <Form onSubmit={createComment}>
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div id="new-comment-postfeed">
                            <Field as="textarea" rows={2} className={`post-comment`} name="text" id="textPost" placeholder="Contribute to the conversation" value={text} onChange={handleChangeText}/>
                            <div className="text-center button--sign" id="postButtonComment">
                              <ReactBootstrap.Button type="submit">
                                {
                                  /*
                                    <ReactBootstrap.Button type="submit">
                                      Post
                                    </ReactBootstrap.Button>
                                    <img src={PostButton} />
                                  */
                                }
                                Post
                              </ReactBootstrap.Button>
                            </div>
                          </div>
                          <div className="error-text new-comment">
                            {/*
                          errors.text && touched.text
                            ?
                            errors.text
                            :
                            null
                        */}
                            <ErrorMessage name="text">
                              {errors.text}
                            </ErrorMessage>
                          </div>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          :
            ""
        }
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          showComment
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="comment-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>
                {
                  showCommentDelete
                  ?
                    "Comment successful deleted."
                  :
                    showCommentReplyDelete
                    ?
                      "Comment (Reply) successful deleted."
                    :
                      showCommentCreate
                      ?
                        "Commented successfully."
                      :
                        ""
                }
              </ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Modal
          show={showAwardsAlert}
          onHide={() => setShowAwardsAlert(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter-three"
          centered
        >
          <ReactBootstrap.Modal.Header closeButton>
            <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-three">
              Award
            </ReactBootstrap.Modal.Title>
          </ReactBootstrap.Modal.Header>
          <ReactBootstrap.Modal.Body id="awards-info">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                {
                  showAwardsMessage
                  ?
                    "Awarding post successfully."
                  :
                    "Sorry, you don't have enough coins."
                }
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Modal.Body>
        </ReactBootstrap.Modal>
      </ReactBootstrap.Col>
      <ReactBootstrap.Modal
        show={isModalDeleteOpen}
        onHide={() => setIsModalDeleteOpen(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter-delete-post"
        centered
      >
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-delete-post">
            Delete post
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Modal.Body className="delete-post-confirmation">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="text-left">
                Are you sure you want to delete this post?
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="text-center button--sign">
                <ReactBootstrap.Button type="submit" onClick={() => setIsModalDeleteOpen(false)}>Cancel</ReactBootstrap.Button>
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="text-center button--sign">
                <ReactBootstrap.Button type="submit" onClick={() => deletePost(postId)}>Delete</ReactBootstrap.Button>
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                showDelete
                  ?
                  <ReactBootstrap.Alert variant="success" transition={true} id="publication-detail-delete-alert" className="text-center">
                    <ReactBootstrap.Alert.Heading>
                      Post successful deleted.
                    </ReactBootstrap.Alert.Heading>
                  </ReactBootstrap.Alert>
                  :
                  ""
              }
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Modal.Body>
      </ReactBootstrap.Modal>
    </ReactBootstrap.Row>
  );
}

export default PostFeed;
