import React from 'react';

const FaqSVG = () => {
  return(
    <svg id="Komponente_5_1" data-name="Komponente 5 – 1" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
      <g id="Ellipse_110" data-name="Ellipse 110" fill="#141c27" stroke="#141c27" stroke-width="5">
        <circle cx="40" cy="40" r="40" stroke="none"/>
        <circle cx="40" cy="40" r="37.5" fill="none"/>
      </g>
      <text id="_" data-name="?" transform="translate(49 59)" fill="#fefefe" font-size="47" font-family="Ubuntu-Light, Ubuntu" font-weight="300"><tspan x="-17.86" y="0">?</tspan></text>
    </svg>
  )
}

export default FaqSVG;
