import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {useHistory, useRouteMatch} from "react-router-dom";
import API from "../../../config/config.json";
import axios from "axios";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";

import IconClose from "../../../assets/icons/IconDelete.png";
import IconTip from "../../../assets/icons/IconTip.png";
import IconAvatar from "../../../assets/svg/avatar.svg";
import AcceptSVG from "../../../assets/svg/accept";
import UploadFile from "../../../assets/svg/uploadFile.svg";

function MyProfile() {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Get url from your invitations
  const urlMyData = API.URL + 'users';

  // Store and set the state value
  const [image, setImage] = useState('');
  const [imageId, setImageId] = useState('');
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [about, setAbout] = useState('');
  const [position, setPosition] = useState('');
  const [company, setCompany] = useState('');
  const [expertise, setExpertise] = useState('');
  const [fieldOfStudy, setFieldOfStudy] = useState('');
  const [degree, setDegree] = useState('');
  const [school, setSchool] = useState('');
  const [website, setWebsite] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [twitter, setTwitter] = useState('');
  const [socialLinks, setSocialLinks] = useState('');
  const [interests, setInterests] = useState('');
  const [sideProjects, setSideProjects] = useState('');
  const [askMeAbout, setAskMeAbout] = useState('');
  const [lookingFor, setLookingFor] = useState('');
  const [quoteILike, setQuoteILike] = useState('');
  const [artOrMusicILike, setArtOrMusicILike] = useState('');
  const [whyIamHere, setWhyIamHere] = useState('');
  const [show, setShow] = useState(false);
  const [myData, setMyData] = useState([]);
  const [isRedirect, setIsRedirect] = useState(false);

  // Main container for history
  let history = useHistory();

  // Make a redirect if value is true
  useEffect(() => {
    (() => {
      if(isRedirect) {
        history.push("/dashboard/network");
      }
    })()
  }, [isRedirect]);

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      urlMyData, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setMyData(result.data);

    const linkedinURL = result.data.linkedinUrl;
    const twitterURL = result.data.twitterUrl;

    console.log(linkedinURL + ", " + twitterURL)

    setImage(result.data.profileImage != null ? result.data.profileImage.url : IconAvatar);
    setImageId(result.data.imageId);
    setName(result.data.name);
    setCity(result.data.city);
    setCountry(result.data.country);
    setAbout(result.data.about);
    setPosition(result.data.position);
    setCompany(result.data.company);
    setExpertise(result.data.expertise);
    setFieldOfStudy(result.data.fieldOfStudy);
    setDegree(result.data.education);
    setSchool(result.data.school);
    setLinkedIn(linkedinURL);
    setTwitter(twitterURL);
    setWebsite(result.data.website);
    setSocialLinks(result.data.socialLinks);
    setInterests(result.data.interests)
    setSideProjects(result.data.sideProjects)
    setAskMeAbout(result.data.askMeAbout)
    setLookingFor(result.data.lookingFor)
    setQuoteILike(result.data.quoteILike)
    setArtOrMusicILike(result.data.artOrMusicILike)
    setWhyIamHere(result.data.whyIamHere);

  }, []);

  const handleChangeName = e => setName(e.target.value);
  const handleChangeCity = e => setCity(e.target.value);
  const handleChangeCountry = e => setCountry(e.target.value);
  const handleChangePosition = e => setPosition(e.target.value);
  const handleChangeCompany = e => setCompany(e.target.value);
  const handleChangeExpertise = e => setExpertise(e.target.value);
  const handleChangeFieldOfStudy = e => setFieldOfStudy(e.target.value);
  const handleChangeDegree = e => setDegree(e.target.value);
  const handleChangeSchool = e => setSchool(e.target.value);
  const handleChangeLinkedIn = e => setLinkedIn(e.target.value);
  const handleChangeTwitter = e => setTwitter(e.target.value);
  const handleChangeWebsite = e => setWebsite(e.target.value);
  const handleChangeSocialLinks = e => setSocialLinks(e.target.value);
  const handleChangeWhyIamHere = e => setWhyIamHere(e.target.value);
  const handleChangeInterests = e => setInterests(e.target.value);
  const handleChangeSideProjects = e => setSideProjects(e.target.value);
  const handleChangeAskMeAbout = e => setAskMeAbout(e.target.value);
  const handleChangeLookingFor = e => setLookingFor(e.target.value);
  const handleChangeQuoteILike = e => setQuoteILike(e.target.value);
  const handleChangeArtOrMusicILike = e => setArtOrMusicILike(e.target.value);
  const handleChangeAbout = e => setAbout(e.target.value);

  const imageUploadSave = (element) => {
    // URL for the files
    const urlFiles = API.URL + 'files';

    // Post value for validation - Files
    let dataFiles = new FormData();
    dataFiles.append("fileType", "IMAGE");
    dataFiles.append("file", element);
    dataFiles.append("type", "USER_IMAGE");

    // fetch data and validate
    axios
      .post(urlFiles, dataFiles, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setImageId(response.data.id);
          console.log(response.data);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Update post validation method
  const validationUpdateProfile = (e) => {
    e.preventDefault();

    // URL for user data
    const url = API.URL + 'users';

    // Post value for validation
    const data = {
      name: name,
      imageId: imageId,
      city: city,
      country: country,
      about: about,
      position: position,
      company: company,
      expertise: expertise,
      fieldOfStudy: fieldOfStudy,
      education: degree,
      school: school,
      linkedinUrl: linkedIn,
      twitterUrl: twitter,
      website: website,
      socialLinks: socialLinks,
      interests: interests,
      sideProjects: sideProjects,
      askMeAbout: askMeAbout,
      lookingFor: lookingFor,
      quoteILike: quoteILike,
      artOrMusicILike: artOrMusicILike,
      whyIamHere: whyIamHere
    };

    // fetch data and validate
    axios
      .patch(url, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(name != ""){
          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("error-text")[0].innerHTML = "";
          document.getElementsByClassName("update-name")[0].classList.remove("error-field");

          if(response.status === 200){
            setShow(true);

            document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "";
            document.getElementsByClassName("tbs--form--main--error")[0].style.display = "none";

            setTimeout(() => {
              setShow(false);
            }, 1000);

            setTimeout(() => {
              setIsRedirect(true);
            }, 1200);

            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Name can't be empty.";
            document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";

            console.log("Error: Access Denied");
          }
        } else {
          document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Name can't be empty.";
          document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
          document.getElementsByClassName("update-name")[0].classList.add("error-field");
          document.getElementsByClassName("error-text")[0].style.display = "block";
          document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a name.";
        }
      })
      .catch(e => {
        document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Name can't be empty.";
        document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
        document.getElementsByClassName("update-name")[0].classList.add("error-field");
        document.getElementsByClassName("error-text")[0].style.display = "block";
        document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a name.";
        console.log(e)
      })
  }

  const Schema = Yup.object().shape({
    name: Yup.string()
      .required('Please enter a name.'),
    imageId: Yup.number(),
    city: Yup.string(),
    country: Yup.string(),
    about: Yup.string(),
    position: Yup.string(),
    company: Yup.string(),
    expertise: Yup.string(),
    fieldOfStudy: Yup.string(),
    education: Yup.string(),
    school: Yup.string(),
    linkedinUrl: Yup.string(),
    twitterUrl: Yup.string(),
    website: Yup.string(),
    socialLinks: Yup.string(),
    interests: Yup.string(),
    sideProjects: Yup.string(),
    askMeAbout: Yup.string(),
    lookingFor: Yup.string(),
    quoteILike: Yup.string(),
    artOrMusicILike: Yup.string(),
    whyIamHere: Yup.string()
  });

  const previewFile = () => {
    const preview = document.querySelector('.tbs--user--profile--image');
    const previewDefault = document.querySelector('.tbs--user--profile--default--image');
    const file = document.querySelector('#upload-image').files[0];
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      if(imageId == null){
        previewDefault.src = reader.result;
      } else {
        preview.src = reader.result;
      }
    }, false);

    if (file) {
      reader.readAsDataURL(file);
      imageUploadSave(file);
    }
  }

  // Get 'Tip - Edit Profile' - Storage
  let editProfileTip = localStorage.getItem("tbs-tip-edit-profile-" + userID);

  // Execute the API and get all informations
  useEffect(async () => {
    if(editProfileTip){
      document.getElementById("tip-edit-profile").classList.add("tips-disabled");
    } else {
      document.getElementById("tip-edit-profile").classList.remove("tips-disabled");
    }
  }, []);

  const tipClose = () => {
    localStorage.setItem("tbs-tip-edit-profile-" + userID, "Edit profile tips accepted.");
    document.getElementById("tip-edit-profile").classList.add("tips-disabled");
  }

  return (
    <ReactBootstrap.Row id="my-profile">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-edit-profile">
        <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
          <ReactBootstrap.Row>
            {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--tips--close">
              <img src={IconClose} alt="Tips - Close" onClick={tipClose}/>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-right">
              <img src={IconTip} alt="Tips" />
            </ReactBootstrap.Col>*/}
            <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9}>
              <div className="tbs--tips--subheadline">
                TIP
              </div>
              <div>
                Make sure to add a profile picture and other details as people like to know who they are dealing with.
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
              <div onClick={tipClose}>
                <AcceptSVG/>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container">
          <ReactBootstrap.Row>
            <div className="tbs--headline tbs--headline--update-user">
              Edit my profile
            </div>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--update--post">
              <div className="form-update-user">
                <div className="tbs--form--main--error text-center"></div>
                <div className="tbs--form--complete--post">
                  <Formik
                    initialValues={{
                      name: '',
                      imageId: imageId,
                      city: '',
                      country: '',
                      about: '',
                      position: '',
                      company: '',
                      expertise: '',
                      fieldOfStudy: '',
                      education: '',
                      school: '',
                      website: '',
                      linkedinUrl: '',
                      twitterUrl: '',
                      socialLinks: '',
                      interests: '',
                      sideProjects: '',
                      askMeAbout: '',
                      lookingFor: '',
                      quoteILike: '',
                      artOrMusicILike: '',
                      whyIamHere: '',
                    }}
                    validationSchema={Schema}
                    onSubmit={values => {
                      console.log(values.text);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form onSubmit={validationUpdateProfile}>
                        <ReactBootstrap.Row id="edit-profile-image">
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <img src={image} alt="Profile - Image" className={imageId == null ? "tbs--user--profile--default--image" : "tbs--user--profile--image"}/>
                            <input type="file" id="upload-image" onChange={previewFile} hidden/>
                            <label htmlFor="upload-image" className="tbs--upload--image">
                              {/*UPLOAD AN IMAGE*/}
                              <img src={UploadFile} alt="Upload file" id="upload-file"/>
                            </label>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div>
                              <label htmlFor="name">
                                MY USERNAME
                              </label>
                            </div>
                            <div>
                              <Field type="text" className={`update-name`} name="name" id="name" placeholder="Username" value={name} onChange={handleChangeName}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.name && touched.name
                                  ?
                                  errors.name
                                  :
                                  null
                              */}
                              <ErrorMessage name="name">
                                {errors.name}
                              </ErrorMessage>
                            </div>
                            <div>
                              <label htmlFor="city">
                                LOCATION
                              </label>
                            </div>
                            <div>
                              City
                            </div>
                            <div>
                              <Field type="text" className={`update-city-area`} name="city" id="city" placeholder="City area" value={city} onChange={handleChangeCity}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.city && touched.city
                                  ?
                                  errors.city
                                  :
                                  null
                              */}
                              <ErrorMessage name="city">
                                {errors.city}
                              </ErrorMessage>
                            </div>
                            <div>
                              Country
                            </div>
                            <div>
                              <Field type="text" className={`update-country`} name="country" id="country" placeholder="Country" value={country} onChange={handleChangeCountry}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.country && touched.country
                                  ?
                                  errors.country
                                  :
                                  null
                              */}
                              <ErrorMessage name="country">
                                {errors.country}
                              </ErrorMessage>
                            </div>
                            <div>
                              <label htmlFor="about">
                                INTRODUCTION
                              </label>
                            </div>
                            <div>
                              <Field type="text" className={`update-about`} name="about" id="about" placeholder="Provide others a bit of your background" value={about} onChange={handleChangeAbout}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.about && touched.about
                                  ?
                                  errors.about
                                  :
                                  null
                              */}
                              <ErrorMessage name="about">
                                {errors.about}
                              </ErrorMessage>
                            </div>
                            <div>
                              <label htmlFor="position">
                                WORK
                              </label>
                            </div>
                            <div>
                              Role
                            </div>
                            <div>
                              <Field type="text" className={`update-position`} name="position" id="position" placeholder="Position" value={position} onChange={handleChangePosition}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.position && touched.position
                                  ?
                                  errors.position
                                  :
                                  null
                              */}
                              <ErrorMessage name="position">
                                {errors.position}
                              </ErrorMessage>
                            </div>
                            <div>
                              Company or Organization
                            </div>
                            <div>
                              <Field type="text" className={`update-company`} name="company" id="company" placeholder="Company or organization" value={company} onChange={handleChangeCompany}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.company && touched.company
                                  ?
                                  errors.company
                                  :
                                  null
                              */}
                              <ErrorMessage name="company">
                                {errors.company}
                              </ErrorMessage>
                            </div>
                            <div>
                              Expertise
                            </div>
                            <div>
                              <Field type="text" className={`update-expertise`} name="expertise" id="expertise" placeholder="Expertise" value={expertise} onChange={handleChangeExpertise}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.expertise && touched.expertise
                                  ?
                                  errors.expertise
                                  :
                                  null
                              */}
                              <ErrorMessage name="expertise">
                                {errors.expertise}
                              </ErrorMessage>
                            </div>
                            <div>
                              <label htmlFor="fieldOfStudy">
                                EDUCATION
                              </label>
                            </div>
                            <div>
                              Field of study
                            </div>
                            <div>
                              <Field type="text" className={`update-field-of-study`} name="fieldOfStudy" id="fieldOfStudy" placeholder="Field of study" value={fieldOfStudy} onChange={handleChangeFieldOfStudy}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.fieldOfStudy && touched.fieldOfStudy
                                  ?
                                  errors.fieldOfStudy
                                  :
                                  null
                              */}
                              <ErrorMessage name="fieldOfStudy">
                                {errors.fieldOfStudy}
                              </ErrorMessage>
                            </div>
                            <div>
                              Degree
                            </div>
                            <div>
                              <Field type="text" className={`update-education`} name="education" id="education" placeholder="Degree" value={degree} onChange={handleChangeDegree}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.education && touched.education
                                  ?
                                  errors.education
                                  :
                                  null
                              */}
                              <ErrorMessage name="education">
                                {errors.education}
                              </ErrorMessage>
                            </div>
                            <div>
                              School
                            </div>
                            <div>
                              <Field type="text" className={`update-school`} name="school" id="school" placeholder="School" value={school} onChange={handleChangeSchool}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.school && touched.school
                                  ?
                                  errors.school
                                  :
                                  null
                              */}
                              <ErrorMessage name="school">
                                {errors.school}
                              </ErrorMessage>
                            </div>
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div>
                              <label htmlFor="website">
                                SOCIAL
                              </label>
                            </div>
                            <div>
                              Website
                            </div>
                            <div>
                              <Field type="text" className={`update-website`} name="website" id="website" placeholder="Website" value={website} onChange={handleChangeWebsite}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.website && touched.website
                                  ?
                                  errors.website
                                  :
                                  null
                              */}
                              <ErrorMessage name="website">
                                {errors.website}
                              </ErrorMessage>
                            </div>
                            <div>
                              LinkedIn
                            </div>
                            <div>
                              <Field type="text" className={`update-linkedin`} name="linkedIn" id="linkedIn" placeholder="LinkedIn" value={linkedIn} onChange={handleChangeLinkedIn}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.linkedinUrl && touched.linkedinUrl
                                  ?
                                  errors.linkedinUrl
                                  :
                                  null
                              */}
                              <ErrorMessage name="linkedinUrl">
                                {errors.linkedinUrl}
                              </ErrorMessage>
                            </div>
                            <div>
                              Twitter
                            </div>
                            <div>
                              <Field type="text" className={`update-twitter`} name="twitter" id="twitter" placeholder="Twitter" value={twitter} onChange={handleChangeTwitter}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.twitterUrl && touched.twitterUrl
                                  ?
                                  errors.twitterUrl
                                  :
                                  null
                              */}
                              <ErrorMessage name="twitterUrl">
                                {errors.twitterUrl}
                              </ErrorMessage>
                            </div>
                            <div>
                              Other social profile
                            </div>
                            <div>
                              <Field type="text" className={`update-socialLinks`} name="socialLinks" id="socialLinks" placeholder="Social profile" value={socialLinks} onChange={handleChangeSocialLinks}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.socialLinks && touched.socialLinks
                                  ?
                                  errors.socialLinks
                                  :
                                  null
                              */}
                              <ErrorMessage name="socialLinks">
                                {errors.socialLinks}
                              </ErrorMessage>
                            </div>
                            <div>
                              <label htmlFor="interests">
                                CONVERSATION STARTERS
                              </label>
                            </div>
                            <div>
                              Stuff I'm interested in
                            </div>
                            <div>
                              <Field type="text" className={`update-interests`} name="interests" id="interests" placeholder="Stuff I'm interested in" value={interests} onChange={handleChangeInterests}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.interests && touched.interests
                                  ?
                                  errors.interests
                                  :
                                  null
                              */}
                              <ErrorMessage name="interests">
                                {errors.interests}
                              </ErrorMessage>
                            </div>
                            <div>
                              Side project I'm working on
                            </div>
                            <div>
                              <Field type="text" className={`update-sideProjects`} name="sideProjects" id="sideProjects" placeholder="Side projects I'm working on" value={sideProjects} onChange={handleChangeSideProjects}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.sideProjects && touched.sideProjects
                                  ?
                                  errors.sideProjects
                                  :
                                  null
                              */}
                              <ErrorMessage name="sideProjects">
                                {errors.sideProject}
                              </ErrorMessage>
                            </div>
                            <div>
                              Ask me about
                            </div>
                            <div>
                              <Field type="text" className={`update-askMeAbout`} name="askMeAbout" id="askMeAbout" placeholder="Ask me about" value={askMeAbout} onChange={handleChangeAskMeAbout}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.askMeAbout && touched.askMeAbout
                                  ?
                                  errors.askMeAbout
                                  :
                                  null
                              */}
                              <ErrorMessage name="askMeAbout">
                                {errors.askMeAbout}
                              </ErrorMessage>
                            </div>
                            <div>
                              What I'm looking for
                            </div>
                            <div>
                              <Field type="text" className={`update-lookingFor`} name="lookingFor" id="lookingFor" placeholder="What I'm looking for" value={lookingFor} onChange={handleChangeLookingFor}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.lookingFor && touched.lookingFor
                                  ?
                                  errors.lookingFor
                                  :
                                  null
                              */}
                              <ErrorMessage name="lookingFor">
                                {errors.lookingFor}
                              </ErrorMessage>
                            </div>
                            <div>
                              Quote that I like
                            </div>
                            <div>
                              <Field type="text" className={`update-quoteILike`} name="quoteILike" id="quoteILike" placeholder="Quote that I like" value={quoteILike} onChange={handleChangeQuoteILike}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.quoteILike && touched.quoteILike
                                  ?
                                  errors.quoteILike
                                  :
                                  null
                              */}
                              <ErrorMessage name="quoteILike">
                                {errors.quoteILike}
                              </ErrorMessage>
                            </div>
                            <div>
                              Art or music that I like (artist or URL)
                            </div>
                            <div>
                              <Field type="text" className={`update-artOrMusicILike`} name="artOrMusicILike" id="artOrMusicILike" placeholder="Art or music that i like (artist or URL)" value={artOrMusicILike} onChange={handleChangeArtOrMusicILike}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.artOrMusicILike && touched.artOrMusicILike
                                  ?
                                  errors.artOrMusicILike
                                  :
                                  null
                              */}
                              <ErrorMessage name="artOrMusicILike">
                                {errors.artOrMusicILike}
                              </ErrorMessage>
                            </div>
                            <div>
                              <label htmlFor="whyIamHere">
                                WHY I'M HERE
                              </label>
                            </div>
                            <div>
                              <Field type="text" className={`update-whyIamHere`} name="whyIamHere" id="whyIamHere" placeholder="Tell others what drives you forward!" value={whyIamHere} onChange={handleChangeWhyIamHere}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.whyIamHere && touched.whyIamHere
                                  ?
                                  errors.whyIamHere
                                  :
                                  null
                              */}
                              <ErrorMessage name="whyIamHere">
                                {errors.whyIamHere}
                              </ErrorMessage>
                            </div>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <div className="text-center button--sign">
                          <ReactBootstrap.Button type="submit">Update</ReactBootstrap.Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          show
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="user-update-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>User profile has been successful created.</ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default MyProfile;
