import React, {useEffect, useState} from 'react';
import {Link, Route, Switch, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import API from "../../config/config.json";
import axios from "axios";
import moment from "moment";
import {Form} from "formik";
import {useMediaQuery} from "react-responsive";

import ActionMenu from "./actionMenu";
import Channel from "./chats/channel";

import IconAvatar from './../../assets/svg/avatar.svg';
//import IconThreeDots from "../../assets/icons/IconThreeDots.png";
import IconIntroduce from "../../assets/icons/IconIntroduce.png";
import IconClose from "../../assets/icons/IconDelete.png";
import IconTip from "../../assets/icons/IconTip.png";

/* SVG - Partner */
import AcceptSVG from "../../assets/svg/accept";
import IntroduceSVG from "../../assets/svg/introduce";
import IconThreeDots from "../../assets/svg/dotMenu.svg";

function Chats() {
  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get url from chats
  const urlChat = API.URL + 'conversations?isNew=true&type=SINGLE';
  //const urlChat = API.URL + 'conversations?type=SINGLE';

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Store and set the state value
  const [chats, setChats] = useState([]);
  const [showGroupAlert, setShowGroupAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [showMuteAlert, setShowMuteAlert] = useState(false);
  const [showMuteMessage, setShowMuteMessage] = useState(false);
  const [showUnmuteMessage, setShowUnmuteMessage] = useState(false);
  const [showOpenMuteModal, setShowOpenMuteModal] = useState(false);
  const [adminMessage, setAdminMessage] = useState(false);
  const [lastChat, setLastChat] = useState(false);
  const [mute, setMute] = useState(0);
  const [groupDuration, setGroupDuration] = useState([]);
  const [groupDurationNew, setGroupDurationNew] = useState([]);
  const [isBiggerThanTablet, setIsBiggerThanTablet] = useState(window.innerWidth > 991);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' })

  // Main container for history
  let history = useHistory();

  // Main container for location
  const location = useLocation();

  useEffect(() => {
    const handleWindowResize = () => setIsBiggerThanTablet(window.innerWidth > 991);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  },[]);

  const handleChangeMute = e => setMute(Number(e.target.value));

  const getMuteValue = async (id) => {
    // Get url from group [Community Info]
    const urlCommunity = API.URL + 'conversations/' + id;

    const response = await axios
      .get(urlCommunity, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      });

    return response.data.data.conversationUser.muteDuration;
  }

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      urlChat, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    const allChats = result.data.items;

    setLastChat(allChats[0].id)

    setChats(result.data.items);
  }, [])

  const truncateMessage = (str, num) => {

    if(str == null || !str) {
      return;
    }

    if(str.length <= num) {
      return str;
    }

    return str.slice(0, num) + '...';
  }

  const showRoom = (id) => {
    setTimeout(() => {
      window.location.replace(`${url}/` + id);
    }, 100);
  }

  const deleteConversation = (id) => {
    // Get url from chat conversation for the deleting
    const deleteChat = API.URL + 'conversations/' + id;

    axios
      .delete(deleteChat, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then(async (response) => {
        if(response.status === 200){
          const result = await axios.get(
            urlChat, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          const allChats = result.data.items;

          setLastChat(allChats[0].id)

          setTimeout(() => {
            isTablet
            ?
              window.location.reload()
            :
              window.location.replace(`/dashboard/chats/${allChats[0].id}`)
          }, 500);

        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
        setAdminMessage(true)
      })
  }

  // Get 'Tip - Chats' - Storage
  let chatsTip = localStorage.getItem("tbs-tip-chats-" + userID);

  // Execute the API and get all informations
  useEffect( async () => {
    if(chatsTip){
      document.getElementById("tip-chats").classList.add("tips-disabled");
    } else {
      document.getElementById("tip-chats").classList.remove("tips-disabled");
    }

    // Get url from group [Community Info]
    const urlCommunities = API.URL + 'conversations?isNew=true&type=SINGLE';

    const response = await axios
      .get(urlCommunities, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      });

    let dataResponse = response.data.items;

    let newArr = [];

    dataResponse.map(data => {
      newArr.push(data.id);
    })

    let txt = [];

    newArr.map(async (number) => {
        txt.push({Id: number, Duration: await getMuteValue(number)});
      }
    );

    setGroupDuration(txt);

    /*const mainElement = document.getElementById("chats");

    if(window.location.href.indexOf("/dashboard/chats") && !isBiggerThanTablet){
      mainElement.style.paddingBottom = "250px";
    }

    if(window.location.href.indexOf("/dashboard/chats") && isBiggerThanTablet){
      mainElement.style.paddingBottom = "250px";
    }*/
  }, []);

  const getDurationValue = (id) => {
    let dur;
    const x = groupDuration;

    x.map(number => {
      if(number.Id === id){
        dur = number.Duration;
      } else {
      }
    })

    return dur;
  }

  const tipClose = () => {
    localStorage.setItem("tbs-tip-chats-" + userID, "Chats tips accepted.");
    document.getElementById("tip-chats").classList.add("tips-disabled");
  }

  const muteConversation = (id) => {
    // URL for the mute status
    const urlMute = API.URL + 'conversations/' + id + '/mute';

    // Get url from group [Community Info]
    // const urlCommunity = API.URL + 'conversations/' + id;

    console.log("Intern: " + mute)

    // Data validation
    const data = {
      duration: mute
    }

    // fetch data and validate
    axios
      .put(urlMute, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          document.querySelector("#communityMute .tbs--error--un-mute").innerHTML = "";
          document.querySelector("#communityMute .tbs--error--un-mute").style.display = "none";

          setShowMuteAlert(true);
          setShowMuteMessage(true);
          setShowOpenMuteModal(false);

          setTimeout(() => {
            setShowMuteAlert(false);
            setShowMuteMessage(false);
          }, 1000);

          setTimeout(() => {
            window.location.reload();
          }, 1200);
        }
      })
      .catch(e => {
        console.log(e);

        document.querySelector("#communityMute .tbs--error--un-mute").innerHTML = "The mute duration cannot be empty.";
        document.querySelector("#communityMute .tbs--error--un-mute").style.display = "block";
      })
  }

  const unmuteConversation = (id) => {
    // URL for the unmute status
    const urlUnmute = API.URL + 'conversations/' + id + '/unmute';

    // Get url from group [Community Info]
    // const urlCommunity = API.URL + 'conversations/' + id;

    // Data validation
    const data = {
      duration: 0
    }

    // fetch data and validate
    axios
      .put(urlUnmute, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShowMuteAlert(true);
          setShowUnmuteMessage(true);

          setTimeout(() => {
            setShowMuteAlert(false);
            setShowUnmuteMessage(false);
          }, 1000);

          setTimeout(async () => {
            // Get url from group [Community Info]
            const urlCommunity = API.URL + 'conversations?isNew=true&type=SINGLE';

            const response = await axios
              .get(urlCommunity, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              });

            let dataResponse = response.data.items;

            let newArr = [];

            dataResponse.map(data => {
              newArr.push(data.id);
            })

            let txt = [];

            newArr.map(async (number) => {
                txt.push({Id: number, Duration: await getMuteValue(number)});
              }
            );

            setGroupDuration(txt);

            /*document.getElementById("unmute--" + id).classList.add("is-not-visible");
            document.getElementById("unmute--" + id).classList.remove("is-visible");
            document.getElementById("mute--" + id).classList.add("is-visible");
            document.getElementById("mute--" + id).classList.remove("is-not-visible");*/
          }, 1000);
        }
      })
  }

  /*useEffect(() => {
    const lastElement = document.getElementById("chat-new-message");
    const mainElement = document.getElementById("chat-room-contact");

    if(typeof(mainElement) != 'undefined' && mainElement != null){
      setTimeout(() => {
        let offsetTop;
        if(isTablet){
          offsetTop = mainElement.offsetTop-300;
        } else {
          offsetTop = 0;
        }

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth"
        });
      }, 2500)
    }

    if(typeof(lastElement) != 'undefined' && lastElement != null){
      setTimeout(() => {
        lastElement.scrollIntoView();

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth"
        });
      }, 1500)
    }
  }, [])*/

  useEffect(() => {
    const contentInfo = document.getElementById("no-data-chat");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading content";
      setTimeout( () => {
        contentInfo.innerHTML = "You don't have any chat conversations";
      }, 5000)
    }
  }, [])

  useEffect(() => {
    const contentInfo = document.getElementById("chat-default-message");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading conversations";
      setTimeout( () => {
        contentInfo.innerHTML = "Please select a chat conversation.";
      }, 5000)
    }
  }, [])

  useEffect(() => {
    const tipElement = document.getElementById("tip-chats");
    const titleElement = document.querySelector(".main-headline-title");
    let offsetTop;

    if(tipElement.classList.contains("tips-disabled") == false){
      offsetTop = titleElement.offsetTop+125;

      setTimeout(() => {
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth"
        });
      }, 2000)
    } else {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }, 2500)
    }
  }, []);

  /*useEffect(() => {
    chats.map((chat) => {
      if(chat.groupType == "introduce"){
        console.log("true")
      } else {
        console.log("false")
      }
    })
  })*/

  if (!chats || chats.length == 0) {
    return (
      <ReactBootstrap.Row id="chats">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-chats">
          <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
            <ReactBootstrap.Row>
              {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--tips--close">
                <img src={IconClose} alt="Tips - Close" onClick={tipClose}/>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-left">
                <img src={IconTip} alt="Tips" />
              </ReactBootstrap.Col>*/}
              <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9}>
                <div className="tbs--tips--subheadline">
                  TIP
                </div>
                <div>
                  Access options from the 3 dots in the chat list.
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
                <div onClick={tipClose}>
                  <AcceptSVG/>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={5} xl={5} id="chat-contacts">
          <ReactBootstrap.Container fluid={true} className="tbs--container tbs--new--title">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="tbs--headline main-headline-title">
                  <span>My Chats</span>
                </div>
                <div className="tbs--divider--title tbs--additional--menu--chats">
                  <hr></hr>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center" id="no-data-chat">
                You don't have any chat conversations
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={7} xl={7} className="d-none d-sm-none d-md-none d-lg-block" id="chat-room-contact">
          <ReactBootstrap.Container fluid={true} className="tbs--container no-chats">
            {/*
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="tbs--headline tbs--headline--chats text-center">
                  Welcome to the chats
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            */}
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--welcome--chat">
                <div id="chat-default-message" className="text-center tbs--welcome--chat">
                  Please select a chat conversation.
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  } else {
    return (
      <ReactBootstrap.Row id="chats">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-chats">
          <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
            <ReactBootstrap.Row>
              {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--tips--close">
                <img src={IconClose} alt="Tips - Close" onClick={tipClose}/>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-left">
                <img src={IconTip} alt="Tips" />
              </ReactBootstrap.Col>*/}
              <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9}>
                <div className="tbs--tips--subheadline">
                  TIP
                </div>
                <div>
                  Access options from the 3 dots in the chat list.
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
                <div onClick={tipClose}>
                  <AcceptSVG/>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={5} xl={5} id="chat-contacts">
          <ReactBootstrap.Container fluid={true} className="tbs--container tbs--new--title">
            <ReactBootstrap.Row>
              <div className="tbs--headline main-headline-title">
                <span>My Chats</span>
              </div>
              <div className="tbs--divider--title tbs--additional--menu--chats">
                <hr></hr>
              </div>
            </ReactBootstrap.Row>
              {
                chats.map((chat, index) => {
                  //if (chat.lastMessage != null) {
                    return (
                      <ReactBootstrap.Row id={`chat-row-${chat.id}`}>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} key={index} id={`chat-${chat.id}`}>
                          {
                            isBiggerThanTablet
                            ?
                              <Link to={`${url}/${chat.id}`} onClick={() => showRoom(chat.id)}>
                                <ReactBootstrap.Row className={chat.groupType == "introduce" ? "tbs--image--chats--no--image" : ""}>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="tbs--image--chats">
                                      <div>
                                        {
                                          chat.groupType == "introduce"
                                          ?
                                            <IntroduceSVG/>
                                          :
                                            <img src={chat.conversationReceiver.user.profileImage != null ? chat.conversationReceiver.user.profileImage.url : chat.conversationReceiver.user != null && chat.conversationReceiver.user.image != null ? chat.conversationReceiver.user.image : IconAvatar} alt="Profile Image"/>
                                        }
                                      </div>
                                    </div>
                                    <div>
                                      <div className="tbs--chat--name">
                                        {
                                          chat.name != null
                                          ?
                                            /*truncateMessage(chat.name, 20)*/
                                            chat.name
                                          :
                                            /*truncateMessage(chat.conversationReceiver.user.name, 20)*/
                                            chat.conversationReceiver.user.name
                                        }
                                      </div>
                                      <div className="tbs--chat--update--date">
                                        {moment(chat.updatedAt).format('MMMM DD YYYY, hh:mm:A')}
                                      </div>
                                      {
                                        chat.lastMessage != null
                                        ?
                                          <div>
                                            <div className="tbs--chat--last--message">
                                              {truncateMessage(chat.lastMessage.text, 25)}
                                            </div>
                                          </div>
                                        :
                                          ""
                                      }
                                    </div>
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                              </Link>
                            :
                              <Link to={`${url}/${chat.id}/channel`}>
                                <ReactBootstrap.Row className={chat.groupType == "introduce" ? "tbs--image--chats--no--image" : ""}>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="tbs--image--chats">
                                      <div>
                                        {
                                          chat.groupType == "introduce"
                                            ?
                                            <IntroduceSVG/>
                                            :
                                            <img src={chat.conversationReceiver.user.profileImage != null ? chat.conversationReceiver.user.profileImage.url : chat.conversationReceiver.user != null && chat.conversationReceiver.user.image != null ? chat.conversationReceiver.user.image : IconAvatar} alt="Profile Image"/>
                                        }
                                      </div>
                                    </div>
                                    <div>
                                      <div className="tbs--chat--name">
                                        {chat.name != null ? chat.name : chat.conversationReceiver.user.name}
                                      </div>
                                      <div className="tbs--chat--update--date">
                                        {moment(chat.updatedAt).format('MMMM DD YYYY, hh:mm:A')}
                                      </div>
                                      {
                                        chat.lastMessage != null
                                        ?
                                          <div>
                                            <div className="tbs--chat--last--message">
                                              {truncateMessage(chat.lastMessage.text, 25)}
                                            </div>
                                          </div>
                                        :
                                          ""
                                      }
                                    </div>
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                              </Link>
                          }
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div id={`chat-room-${chat.id}`} className="tbs--chat--submenu">
                            <ReactBootstrap.DropdownButton
                              menuAlign="right"
                              title={
                                <div className="tbs--chat--details">
                                  <img src={IconThreeDots} alt="Chat Menu" />
                                </div>
                              }
                              id={`chat-room-${chat.id}-channel`}
                            >
                              {
                                getDurationValue(chat.id) > 0
                                ?
                                  <div className="dropdown-item tbs--mute--group" id={`unmute--${chat.id}`} onClick={() => unmuteConversation(chat.id)}>
                                    Unmute
                                  </div>
                                :
                                  <div className="dropdown-item tbs--mute--group" id={`mute--${chat.id}`} onClick={() => setShowOpenMuteModal(true)}>
                                    Mute
                                  </div>
                              }
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col>
                                  <ReactBootstrap.Modal
                                    show={showOpenMuteModal}
                                    onHide={() => setShowOpenMuteModal(false)}
                                    size="md"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                  >
                                    <ReactBootstrap.Modal.Header closeButton>
                                      <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                                        Mute notifications for ...
                                      </ReactBootstrap.Modal.Title>
                                    </ReactBootstrap.Modal.Header>
                                    <ReactBootstrap.Modal.Body id="mute-create">
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <div id="communityMute">
                                            <div className="tbs--error--un-mute"></div>
                                            <div>
                                              <label>
                                                <input type="radio"
                                                       className="muted"
                                                       name="muted" value={8}
                                                       onChange={handleChangeMute}/>
                                                <span>For the next 2 hours</span>
                                              </label>
                                            </div>
                                            <div>
                                              <label>
                                                <input type="radio"
                                                       className="muted"
                                                       name="muted" value={168}
                                                       onChange={handleChangeMute}/>
                                                <span>Until next day (7 am)</span>
                                              </label>
                                            </div>
                                            <div>
                                              <label>
                                                <input type="radio"
                                                       className="muted"
                                                       name="muted" value={8760}
                                                       onChange={handleChangeMute}/>
                                                <span>For 1 week</span>
                                              </label>
                                            </div>
                                            <div>
                                              <label>
                                                <input type="radio"
                                                       className="muted"
                                                       name="muted" value={99999}
                                                       onChange={handleChangeMute}/>
                                                <span>Disable notifications for this conversation</span>
                                              </label>
                                            </div>
                                            <div>
                                              <hr/>
                                            </div>
                                            <div className="text-right button--sign">
                                              <ReactBootstrap.Button onClick={() => setShowOpenMuteModal(false)}>Cancel</ReactBootstrap.Button>
                                              <ReactBootstrap.Button onClick={() => muteConversation(chat.id)}>OK</ReactBootstrap.Button>
                                            </div>
                                          </div>
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                    </ReactBootstrap.Modal.Body>
                                  </ReactBootstrap.Modal>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                              <div className="dropdown-item" onClick={() => setShowAlertDelete(true)}>
                                {
                                  chat.groupType == "introduce"
                                  ?
                                    "Leave this chat group"
                                  :
                                    "Erase conversation"
                                }
                              </div>
                              <ReactBootstrap.Modal
                                show={showAlertDelete}
                                onHide={() => setShowAlertDelete(false)}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                              >
                                <ReactBootstrap.Modal.Header closeButton>
                                  <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                                    {
                                      chat.groupType == "introduce"
                                      ?
                                        "Leave this chat group"
                                      :
                                        "Erase conversation"
                                    }
                                  </ReactBootstrap.Modal.Title>
                                </ReactBootstrap.Modal.Header>
                                <ReactBootstrap.Modal.Body id="erase-conversation-modal">
                                  <ReactBootstrap.Row>
                                    {
                                      adminMessage
                                      ?
                                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                          <div className="error-text">
                                            Only the admin can delete this conversation.
                                          </div>
                                        </ReactBootstrap.Col>
                                      :
                                        ""
                                    }
                                    <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-center">
                                      <div className="text-center button--sign">
                                        <ReactBootstrap.Button type="submit" onClick={() => setShowAlertDelete(false)}>No</ReactBootstrap.Button>
                                      </div>
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-center">
                                      <div className="text-center button--sign">
                                        <ReactBootstrap.Button type="submit" onClick={() => deleteConversation(chat.id)}>Yes</ReactBootstrap.Button>
                                      </div>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                </ReactBootstrap.Modal.Body>
                              </ReactBootstrap.Modal>
                              <div className="dropdown-item"><Link to={`${path}/${chat.id}/report`}>Report conversation</Link></div>
                              <div className="dropdown-item" onClick={() => setShowAlert(true)}>Encryption</div>
                              <ReactBootstrap.Modal
                                show={showAlert}
                                onHide={() => setShowAlert(false)}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                              >
                                <ReactBootstrap.Modal.Header closeButton>
                                  <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                                    TBS
                                  </ReactBootstrap.Modal.Title>
                                </ReactBootstrap.Modal.Header>
                                <ReactBootstrap.Modal.Body>
                                  <ReactBootstrap.Row>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                      <div>
                                        This chat is AES encrypted.
                                      </div>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                </ReactBootstrap.Modal.Body>
                              </ReactBootstrap.Modal>
                              <div className="dropdown-item"><Link to={`${path}/${chat.id}/publication`}>Chat posts</Link></div>
                            </ReactBootstrap.DropdownButton>
                          </div>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    )
                  //}
                })
              }
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={7} xl={7} className="d-none d-sm-none d-md-none d-lg-block" id="chat-room-contact">
          <Switch>
            <Route
              path={`${path}/:id`}
              component={Channel}
            />
            <Route
              exact
              path={path}
              render={() => {
                return (
                  <ReactBootstrap.Container fluid={true} className="tbs--container" id="tbs--chats--default">
                    {/*
                    <ReactBootstrap.Row>
                      <div className="tbs--headline tbs--headline--chats text-center">
                        Welcome to the chats
                      </div>
                    </ReactBootstrap.Row>
                    */}
                    <ReactBootstrap.Row>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--welcome--chat">
                        <div className="tbs--welcome--group">
                          <div id="chat-default-message" className="tbs--default--chat--message text-center">
                            Please select a chat conversation.
                          </div>
                        </div>
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </ReactBootstrap.Container>
                )
              }}
            />
          </Switch>
        </ReactBootstrap.Col>
        {/*
          isMobile
            ?
            <ReactBootstrap.Col>
              <ActionMenu/>
            </ReactBootstrap.Col>
            :
            ""
        */}
      </ReactBootstrap.Row>
    );
  }
}

export default Chats;
