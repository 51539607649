import React from 'react';

const NetworkSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="56" viewBox="0 0 80 56">
      <path id="Icon_awesome-users" data-name="Icon awesome-users" d="M12,26.25a8,8,0,1,0-8-8A8.007,8.007,0,0,0,12,26.25Zm56,0a8,8,0,1,0-8-8A8.007,8.007,0,0,0,68,26.25Zm4,4H64a7.976,7.976,0,0,0-5.637,2.325A18.284,18.284,0,0,1,67.75,46.25H76a4,4,0,0,0,4-4v-4A8.007,8.007,0,0,0,72,30.25Zm-32,0a14,14,0,1,0-14-14A13.993,13.993,0,0,0,40,30.25Zm9.6,4H48.563a19.329,19.329,0,0,1-17.125,0H30.4A14.4,14.4,0,0,0,16,48.65v3.6a6,6,0,0,0,6,6H58a6,6,0,0,0,6-6v-3.6A14.4,14.4,0,0,0,49.6,34.25ZM21.637,32.575A7.976,7.976,0,0,0,16,30.25H8a8.007,8.007,0,0,0-8,8v4a4,4,0,0,0,4,4h8.238A18.33,18.33,0,0,1,21.637,32.575Z" transform="translate(0 -2.25)" fill="#000"/>
    </svg>
  )
}

export default NetworkSVG;
