import React from 'react';

const LogoutSVG = () => {
  return(
    <svg id="Icon_metro-exit" data-name="Icon metro-exit" xmlns="http://www.w3.org/2000/svg" width="75" height="80" viewBox="0 0 75 80">
      <path id="Icon_metro-exit-2" data-name="Icon metro-exit" d="M62.571,51.928v-10h-25v-10h25v-10l15,15Zm-5-5v20h-25v15l-30-15v-65h55v25h-5v-20h-40l20,10v45h20v-15Z" transform="translate(-2.571 -1.928)" fill="#000"/>
    </svg>
  )
}

export default LogoutSVG;
