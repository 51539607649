import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import axios from 'axios';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
/* import { yupResolver } from "@hookform/resolvers/yup"; */

import MobileAppBanner from "../components/mobileAppBanner";

import API from "./../config/config.json";

/* SVG */
import Logo from "./../assets/svg/logo.svg";
import LogoDark from "./../assets/svg/logoDark.svg";

function ResetPassword() {

  // Get the user email for handling the reset password form
  let itemReset = localStorage.getItem("tbs-reset-password");

  // Store and set the state value
  const [email] = useState(itemReset);
  const [invitationCode, setInvitationCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [showResetPasswordAlert, setShowResetPasswordAlert] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  // Main container for history
  let history = useHistory();

  // Make a redirect if value is true
  useEffect(() => {
    (() => {
      if(isRedirect) {
        history.push("/login");
      }
    })()
  }, [isRedirect]);

  const handleChangeInvitationCode = e => setInvitationCode(e.target.value);
  const handleChangePassword = e => setPassword(e.target.value);
  const handleChangePasswordConfirm = e => setPasswordConfirm(e.target.value);

  // Password Reset validation method
  const validationPasswordReset = (e) => {
    e.preventDefault();

    // URL for the user authentication in the login area
    const url = API.URL + 'reset-password';

    // Post value for validation
    const data = {
      email: email,
      otp: invitationCode,
      password: password,
      passwordConfirm: passwordConfirm
    };

    // fetch data and validate
    axios
      .post(url, data)
      .then((response) => {
        if(password === passwordConfirm && invitationCode != null){
          if(response.status === 200){
            document.getElementsByClassName("error-text")[0].style.display = "none";
            document.getElementsByClassName("reset")[0].classList.remove("error-field");
            document.getElementsByClassName("error-text")[1].style.display = "none";
            document.getElementsByClassName("reset")[1].classList.remove("error-field");
            document.getElementsByClassName("error-text")[2].style.display = "none";
            document.getElementsByClassName("reset")[2].classList.remove("error-field");

            setShowResetPasswordAlert(true);

            setTimeout(() => {
              setShowResetPasswordAlert(false);
              setIsRedirect(true);
            }, 1000);

            localStorage.removeItem("tbs-reset-password");

            window.location.replace("/login");
          } else {
            console.log("Error: Access Denied");
          }
        } else {
          console.log("Passwords are not equal.");
        }
      })
      .catch(e => {

        if(password === passwordConfirm){
          document.getElementsByClassName("error-text")[1].style.display = "none";
          document.getElementsByClassName("error-text")[1].innerHTML = "";
          document.getElementsByClassName("reset")[1].classList.remove("error-field");
          document.getElementsByClassName("error-text")[2].style.display = "none";
          document.getElementsByClassName("error-text")[2].innerHTML = "";
          document.getElementsByClassName("reset")[2].classList.remove("error-field");
        } else {
          document.getElementsByClassName("error-text")[1].style.display = "block";
          document.getElementsByClassName("error-text")[1].innerHTML = "Passwords must be match";
          document.getElementsByClassName("reset")[1].classList.add("error-field");
          document.getElementsByClassName("error-text")[2].style.display = "block";
          document.getElementsByClassName("error-text")[2].innerHTML = "Passwords must be match";
          document.getElementsByClassName("reset")[2].classList.add("error-field");
        }

        if(invitationCode.length === 6){
          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("error-text")[0].innerHTML = "";
          document.getElementsByClassName("reset")[0].classList.remove("error-field");
        } else if(invitationCode.length > 6){
          document.getElementsByClassName("error-text")[0].style.display = "block";
          document.getElementsByClassName("error-text")[0].innerHTML = "Digits length must be 6";
          document.getElementsByClassName("reset")[0].classList.add("error-field");
        } else {
          document.getElementsByClassName("error-text")[0].style.display = "block";
          document.getElementsByClassName("error-text")[0].innerHTML = "Digits length is not correct";
          document.getElementsByClassName("reset")[0].classList.add("error-field");
        }

        document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Invalid credentials.\nThis account does not exist.";
        document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";

        console.log(e);
      })

  };

  const resetSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid e-mail')
      .required('E-Mail is required'),
    otp: Yup.string()
      .min(6, 'Must be have 6 digits')
      .required('Invitation Code is required'),
    password: Yup.string()
      .required('Password is required'),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must be match')
  });

  return (
    <ReactBootstrap.Container fluid={true}>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col md={5} lg={7} xl={7} id="resetPassword" className="d-none d-sm-none d-md-block">
          <div className="tbs--main--block">
            <div className="tbs--main--title">
              {/*<img src={Logo}/>*/}
              meoh TBS
            </div>
            <div className="tbs--main--subtitle text-center">
              Leverage your trust network
            </div>
          </div>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={7} lg={5} xl={5} id="forgotPasswordForm">
          <div className="tbs--main--block--two d-block d-sm-block d-md-none d-lg-none d-xl-none">
            <div className="tbs--main--title">
              {/*<img src={LogoDark}/>*/}
              meoh TBS
            </div>
            {/*<div className="tbs--main--subtitle text-center">
              Trust Building System
            </div>*/}
          </div>
          <div className="tbs--form--block">
            <div className="tbs--form--title tbs--form--title--forgot--password">
              RESET PASSWORD
            </div>
            <div className="tbs--form--main--error text-center"></div>
            <div className="tbs--form--complete">
              <Formik
                initialValues={{
                  otp: '',
                  email: email,
                  password: '',
                  passwordConfirm: ''
                }}
                validationSchema={resetSchema}
                onSubmit={values => {
                  console.log(values.text);
                }}
              >
                {({ errors, touched }) => (
                  <Form onSubmit={validationPasswordReset}>
                    <div>
                      <label htmlFor="password">
                        INVITATION CODE
                      </label>
                    </div>
                    <div>
                      <Field type="text" className={`reset`} name="invitationCode" value={invitationCode} id="invitationCode" placeholder="Enter your invitation code" onChange={handleChangeInvitationCode}/>
                    </div>
                    <div className="error-text">
                      {/*
                        errors.otp && touched.otp
                          ?
                          errors.otp
                          :
                          null
                      */}
                      <ErrorMessage name="otp">
                        {errors.otp}
                      </ErrorMessage>
                    </div>
                    <div>
                      <label htmlFor="password">
                        PASSWORD
                      </label>
                    </div>
                    <div>
                      <Field type="password" className={`reset`} name="password" value={password} id="password" placeholder="Enter your new password" onChange={handleChangePassword}/>
                    </div>
                    <div className="error-text">
                      {/*
                        errors.password && touched.password
                          ?
                          errors.password
                          :
                          null
                      */}
                      <ErrorMessage name="password">
                        {errors.password}
                      </ErrorMessage>
                    </div>
                    <div>
                      <label htmlFor="passwordConfirm">
                        PASSWORD CONFIRM
                      </label>
                    </div>
                    <div>
                      <Field type="password" className={`reset`} name="passwordConfirm" value={passwordConfirm} id="passwordConfirm" placeholder="Confirm your new password" onChange={handleChangePasswordConfirm}/>
                    </div>
                    <div className="error-text">
                      {/*
                        errors.passwordConfirm && touched.passwordConfirm
                          ?
                          errors.passwordConfirm
                          :
                          null
                      */}
                      <ErrorMessage name="passwordConfirm">
                        {errors.passwordConfirm}
                      </ErrorMessage>
                    </div>
                    <div className="text-center button--sign">
                      <ReactBootstrap.Button type="submit">Reset</ReactBootstrap.Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col>
          {
            showResetPasswordAlert
              ?
              <ReactBootstrap.Alert variant="success" transition={true} id="success-reset-password-alert" className="text-center">
                <ReactBootstrap.Alert.Heading>
                  Reset password was successful.
                </ReactBootstrap.Alert.Heading>
              </ReactBootstrap.Alert>
              :
              ""
          }
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <MobileAppBanner/>
    </ReactBootstrap.Container>
  );
}

export default ResetPassword;
