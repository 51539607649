import React from 'react';

const IntroduceSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1024" height="1024" viewBox="0 0 1024 1024">
      <defs>
        <filter id="Icon_awesome-hands-helping" x="173.09" y="194.551" width="690.432" height="651.535" filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur"/>
          <feFlood flood-opacity="0.161"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g id="Gruppe_1536" data-name="Gruppe 1536" transform="translate(-8170 -2625)">
        <g id="Ellipse_107" data-name="Ellipse 107" transform="translate(8170 2625)" fill="#141c27" stroke="#fff" stroke-width="3">
          <circle cx="512" cy="512" r="512" stroke="none"/>
          <circle cx="512" cy="512" r="510.5" fill="none"/>
        </g>
        <g transform="matrix(1, 0, 0, 1, 8170, 2625)" filter="url(#Icon_awesome-hands-helping)">
          <path id="Icon_awesome-hands-helping-2" data-name="Icon awesome-hands-helping" d="M405.02,159.327H278.872V205.8a59.754,59.754,0,1,1-119.509,0V104.884L105.5,137.251a53,53,0,0,0-25.811,45.48v39.255L13.3,260.328A26.494,26.494,0,0,0,3.588,296.6L69.981,411.623a26.566,26.566,0,0,0,36.268,9.71l85.814-49.546H305.43a53.164,53.164,0,0,0,53.115-53.115h13.279a26.529,26.529,0,0,0,26.557-26.557V239h6.639a19.87,19.87,0,0,0,19.918-19.918V179.245A19.87,19.87,0,0,0,405.02,159.327ZM527.6,128.288,461.206,13.261a26.566,26.566,0,0,0-36.268-9.71L339.125,53.1H254.307a53.514,53.514,0,0,0-28.134,8.05l-27.8,17.345a26.379,26.379,0,0,0-12.449,22.491V205.8a33.2,33.2,0,0,0,66.394,0V132.769H405.02A46.505,46.505,0,0,1,451.5,179.245V202.9l66.394-38.342A26.586,26.586,0,0,0,527.6,128.288Z" transform="translate(391.53 200.55) rotate(30)" fill="#fefefe"/>
        </g>
      </g>
    </svg>
  )
}

export default IntroduceSVG;
