import React from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';

/* SVG */
import LetsGoSVG from "./../assets/svg/letsGo.svg";
import OnboardingGroupSVG from "./../assets/svg/onboardingGroup.svg";
import OnboardingPostSVG from "./../assets/svg/onboardingPost.svg";
import OnboardingFavouriteSVG from "./../assets/svg/onboardingFavourite.svg";

function OnboardingStepTwo() {
  return (
    <ReactBootstrap.Container fluid={true} id="onboarding-step-two">
      <ReactBootstrap.Row>
        <ReactBootstrap.Col md={5} lg={7} xl={7} id="login" className="d-none d-sm-none d-md-block">
          <div className="tbs--main--block">
            <div className="tbs--main--title">
              {/*<img src={Logo}/>*/}
              meoh TBS
            </div>
            <div className="tbs--main--subtitle text-center">
              Leverage your trust network
            </div>
          </div>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={7} lg={5} xl={5}>
          <div className="tbs--main--block--two d-block d-sm-block d-md-none d-lg-none d-xl-none">
            <div className="tbs--main--title">
              {/*<img src={LogoDark}/>*/}
              meoh TBS
            </div>
            {/*<div className="tbs--main--subtitle text-center">
              Trust Building System
            </div>*/}
          </div>
          <div className="tbs--form--block onboarding-details">
            <div className="tbs--form--title">
              <h1>Your groups in 3 easy steps</h1>
            </div>
            <div className="tbs--onboarding--steps">
              <div>
                <img src={OnboardingGroupSVG} alt="Group" />
              </div>
              <div>
                1. Create groups and discuss topics of interest
              </div>
            </div>
            <div className="tbs--onboarding--steps">
              <div>
                <img src={OnboardingPostSVG} alt="Post" />
              </div>
              <div>
                2. Post requests or inspiring stuff
              </div>
            </div>
            <div className="tbs--onboarding--steps">
              <div>
                <img src={OnboardingFavouriteSVG} alt="Favourite" />
              </div>
              <div>
                3. Award best contributions and attract great people
              </div>
            </div>
            <div className="text-center">
              <Link to="/register">
                <img src={LetsGoSVG} alt="Okay, let's go!" />
              </Link>
            </div>
          </div>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    </ReactBootstrap.Container>
  );
}

export default OnboardingStepTwo;
