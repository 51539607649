import React from 'react';

const CommunitiesSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
      <g id="Gruppe_2111" data-name="Gruppe 2111" transform="translate(-680 -2946)">
        <path id="Icon_awesome-users" data-name="Icon awesome-users" d="M9,20.25a6,6,0,1,0-6-6A6.006,6.006,0,0,0,9,20.25Zm42,0a6,6,0,1,0-6-6A6.006,6.006,0,0,0,51,20.25Zm3,3H48a5.982,5.982,0,0,0-4.228,1.744A13.713,13.713,0,0,1,50.813,35.25H57a3,3,0,0,0,3-3v-3A6.006,6.006,0,0,0,54,23.25Zm-24,0a10.5,10.5,0,1,0-10.5-10.5A10.495,10.495,0,0,0,30,23.25Zm7.2,3h-.778a14.5,14.5,0,0,1-12.844,0H22.8A10.8,10.8,0,0,0,12,37.05v2.7a4.5,4.5,0,0,0,4.5,4.5h27a4.5,4.5,0,0,0,4.5-4.5v-2.7A10.8,10.8,0,0,0,37.2,26.25ZM16.228,24.994A5.982,5.982,0,0,0,12,23.25H6a6.006,6.006,0,0,0-6,6v3a3,3,0,0,0,3,3H9.178A13.748,13.748,0,0,1,16.228,24.994Z" transform="translate(690.5 2963)" fill="#000"/>
        <g id="Ellipse_149" data-name="Ellipse 149" transform="translate(680 2946)" fill="none" stroke="#000" stroke-width="3">
          <circle cx="40" cy="40" r="40" stroke="none"/>
          <circle cx="40" cy="40" r="38.5" fill="none"/>
        </g>
      </g>
    </svg>
  )
}

export default CommunitiesSVG;
