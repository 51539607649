import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import axios from 'axios';
import {Link, useRouteMatch} from "react-router-dom";
import moment from "moment";
import API from "./../../config/config.json";

import IconAvatar from "../../assets/svg/avatar.svg";
import IconComment from "../../assets/icons/IconComment.png";
import IconRepost from "../../assets/icons/IconRepost.png";
import IconForward from "../../assets/icons/IconForward.png";
import IconBookmarks from "../../assets/icons/IconBookmarks.png";
import IconBookmarksBlue from "../../assets/icons/IconBookmarksBlue.png";
import IconPulse from "../../assets/icons/IconPulse.png";

/* SVG */
import PostBookmarkActiveSVG from "../../assets/svg/postBookmarkActive";
import PostCommentSVG from "../../assets/svg/postComment";
import PostRepostSVG from "../../assets/svg/postRepost";
import PostForwardSVG from "../../assets/svg/postForward";
import PostBookmarkSVG from "../../assets/svg/postBookmark";
import PostActivitySVG from "../../assets/svg/postActivity";
import IconPlayWhite from "../../assets/svg/playBlack.svg";
import IconPlayBlack from "../../assets/svg/playWhite.svg";

function Favourites() {

  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get url from all your favourite posts
  const urlMain = API.URL + 'favourite-posts';

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");

  // Store and set the state value
  const [favourites, setFavourites] = useState([]);

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      urlMain, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setFavourites(result.data.items);
  }, []);

  const audioPlayer = (elementId, audioId) => {
    let audioPlayer = document.getElementById("audio-" + audioId + "-player");
    let audioElement = document.getElementById("audio-" + audioId);
    let postContainer = document.getElementById("post-link-" + elementId);

    postContainer.addEventListener("click", (evt) => {
      evt.preventDefault();
      let targetElement = evt.target;

      do {
        if (targetElement == audioPlayer) {
          if (audioElement.paused) {
            audioElement.play();
          } else {
            audioElement.pause();
            audioElement.currentTime = 0;
          }
        }

        if (targetElement != audioPlayer && targetElement == postContainer) {
          window.location.href = `${url}/post/${elementId}`;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);

      window.location.href = `${url}/post/${elementId}`;

    });
  }

  const truncateMessage = (str, num) => {

    if(str == null || !str) {
      return "Read more ...";
    }

    if(str.length <= num) {
      return str;
    }

    return str.slice(0, num) + ' ... Read more ...';
  }

  useEffect(() => {
    const contentInfo = document.getElementById("no-data-favorites");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading content";
      setTimeout( () => {
        contentInfo.innerHTML = "You don't have any favorites posts";
      }, 5000)
    }
  })

  if (!favourites || favourites.length === 0) {
    return (
      <ReactBootstrap.Row id="favourites">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container--favourites tbs--container--feedlist tbs--new--title">
            <ReactBootstrap.Row>
              <div className="tbs--headline">
                My favorite posts
              </div>
              <div className="tbs--divider--title tbs--divider--title--favourites">
                <hr></hr>
              </div>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="no-data-favorites">
                You don't have any favorites posts
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  } else {
    return (
      <ReactBootstrap.Row id="favourites">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container--favourites tbs--container--feedlist tbs--new--title">
            <ReactBootstrap.Row>
              <div className="tbs--headline">
                My favorite posts
              </div>
              <div className="tbs--divider--title tbs--divider--title--favourites">
                <hr></hr>
              </div>
            </ReactBootstrap.Row>
              {
                favourites.map((item, index) => {
                  return (
                    <Link to={`/dashboard/feeds/profile/${item.userId}/feedlist/post/${item.id}`} key={item.id}>
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--container--favourites--item">
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col  xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--reposted">
                              { item.parentId != null ? "Reposted by " + item.user.name : "" }
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={11} sm={11} md={11} lg={11} xl={11} className="tbs--contact--details">
                              <div className="tbs--contact--image">
                                <img src={
                                  item.parentPost === null
                                  ?
                                    item.user != null && item.user.profileImage != null
                                    ?
                                      item.user.profileImage.url
                                    :
                                      IconAvatar
                                  :
                                    item.parentPost.user != null && item.parentPost.user.profileImage != null
                                    ?
                                      item.parentPost.user.profileImage.url
                                    :
                                      item.parentPost === null && item.user === null
                                      ?
                                        IconAvatar
                                      :
                                        IconAvatar
                                }
                                     className="tbs--container--favourites--image"
                                     alt="Profile"/>
                              </div>
                              <div className="tbs--contact--data--favourites">
                                <div>
                                  {
                                    item.parentPost === null && item.user === null
                                    ?
                                      "null"
                                    :
                                      item.parentPost === null && item.user != null
                                      ?
                                        item.user.name
                                      :
                                        item.parentPost.user.name
                                  }
                                </div>
                                <div>
                                  {
                                    item.parentPost === null && item.user === null
                                    ?
                                      "null"
                                    :
                                      item.parentPost === null && item.user != null
                                      ?
                                        item.user.position
                                      :
                                        item.parentPost.user.position
                                  } at {
                                    item.parentPost === null && item.user === null
                                    ?
                                      "null"
                                    :
                                      item.parentPost === null && item.user != null
                                      ?
                                        item.user.company
                                      :
                                        item.parentPost.user.company
                                  }
                                </div>
                                <div>
                                  {moment(item.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                </div>
                              </div>
                            </ReactBootstrap.Col>
                            <ReactBootstrap.Col xs={1} sm={1} md={1} lg={1} xl={1}>
                              <div className="tbs--contact--data--favourites">
                                {/*<img src={IconBookmarksBlue} className="tbs--favourite--image" alt="Favourite - Active"/>*/}
                                <span>
                                  <PostBookmarkActiveSVG/>
                                </span>
                              </div>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={7} lg={7} xl={7}>
                              <div className="tbs--contact--data--favourites">
                                <div className="tbs--contact--data--favourites--title">
                                  {item.parentPost != null ? item.parentPost.title : item.title}
                                </div>
                              </div>
                              {
                                /*(((item.parentPost == null) || (item.parentPost != null && item.parentPost.file == null)) && item.file == null)
                                ?*/
                                <div className="read-more">
                                  {
                                    item.parentPost == null
                                      ?
                                      truncateMessage(item.description, 200)
                                      :
                                      truncateMessage(item.parentPost.description, 200)
                                  }
                                </div>
                                /*:
                                  ""*/
                              }
                            </ReactBootstrap.Col>
                            <ReactBootstrap.Col xs={12} sm={12} md={5} lg={5} xl={5}>
                              <div className="tbs--contact--data--favourites">
                                <div className="tbs--contact--data--favourites--image">
                                  <img src={
                                    /*item.parentPost === null
                                      ?
                                        item.file != null
                                        ? item.file.url
                                        : ""
                                      : item.parentPost.file != null
                                        ? item.parentPost.file.url
                                        : ""*/
                                    item.parentPost != null && item.parentPost.file != null
                                    ?
                                      item.parentPost.file.url
                                    :
                                      item.file != null
                                      ?
                                        item.file.url
                                      :
                                        ""
                                    }
                                      alt="Favourite - Image"/>
                                  {
                                    item.parentPost != null
                                      ?
                                      <div className="tbs--audio--feed" id={`audio-${item.parentPost.audioId}-player`}>
                                        {/* onClick={() => audioPlayer(item.id, item.parentPost.audioId)} */}
                                        <img src={
                                          item.parentPost.file != null && item.parentPost.audioId != null
                                            ?
                                            IconPlayWhite
                                            :
                                            item.parentPost.file == null && item.parentPost.audioId != null
                                              ?
                                              IconPlayBlack
                                              :
                                              ""
                                        } alt="Play"/>
                                      </div>
                                      :
                                      item.audioId != null
                                        ?
                                        <div className="tbs--audio--feed" id={`audio-${item.audioId}-player`}>
                                          {/* onClick={() => audioPlayer(item.id, item.audioId)} */}
                                          <img src={
                                            item.file != null && item.audioId != null
                                              ?
                                              IconPlayWhite
                                              :
                                              item.file == null && item.audioId != null
                                                ?
                                                IconPlayBlack
                                                :
                                                ""
                                          } alt="Play"/>
                                        </div>
                                        :
                                        ""
                                  }
                                  {/*
                                    item.parentPost != null
                                      ?
                                      <div className="tbs--contact--audio--overview">
                                        <audio controls controlsList="nodownload" id={`audio-${item.parentPost.audioId}`}>
                                          <source
                                            src={item.parentPost.audioFile != null ? item.parentPost.audioFile.url : ""}
                                            type="audio/mp3"/>
                                          Your browser does not support the audio element.
                                        </audio>
                                      </div>
                                      :
                                      item.audioFile != null
                                        ?
                                        <div className="tbs--contact--audio--overview">
                                          <audio controls controlsList="nodownload" id={`audio-${item.audioId}`}>
                                            <source src={item.audioFile != null ? item.audioFile.url : ""}
                                                    type="audio/mp3"/>
                                            Your browser does not support the audio element.
                                          </audio>
                                        </div>
                                        :
                                        ""
                                  */}
                                </div>
                              </div>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <div className="tbs--contact--data--feeds">
                                <div className="tbs--contact--data--feeds--infos tbs--data--feeds--subline">
                                  <span className="w20-flex">
                                    {/*<img src={IconComment} alt="Comments"/>*/}
                                    <PostCommentSVG/>
                                    <span>{item.totalComments}</span>
                                  </span>
                                  <span className="w20-flex">
                                    {/*<img src={IconRepost} alt="Repost"/>*/}
                                    <PostRepostSVG/>
                                    <span>{item.repostedCount}</span>
                                  </span>
                                  <span className="w20-flex">
                                    {/*<img src={IconForward} alt="Forward"/>*/}
                                    <PostForwardSVG/>
                                    <span>{item.forwardedCount}</span>
                                  </span>
                                  <span className="w20-flex">
                                    {/*<img src={IconBookmarks} alt="Bookmarks"/>*/}
                                    <PostBookmarkSVG/>
                                  <span>{item.bookmarkedCount}</span>
                                  </span>
                                  <span className="w20-flex">
                                    {/*<img src={IconPulse} alt="Activity"/>*/}
                                    <PostActivitySVG/>
                                    <span>{item.activitiesCount}</span>
                                  </span>
                                </div>
                              </div>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    </Link>
                  );
                })
              }
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  }
}

export default Favourites;
