import React, { useState, useEffect } from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import API from "./../../config/config.json";

import IconTip from './../../assets/icons/IconTip.png';
import IconClose from './../../assets/icons/IconDelete.png';
import IconAvatar from './../../assets/svg/profileAvatarIcon.svg';
import AcceptSVG from "../../assets/svg/accept";

function Trusted() {

  // Get url from all friends
  const url = API.URL + 'friends';

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Store and set the state value
  const [profile, setProfile] = useState([]);

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      url, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    const fData = result.data.items;
    /*const fDataNew = fData.sort(function(a,b){
      return new Date(b.lastPostAt) - new Date(a.lastPostAt);
    });*/
    const fDataNew = fData.reverse();

    console.log(fDataNew)

    setProfile(fDataNew);
  }, []);

  useEffect(() => {
    const contentInfo = document.getElementById("no-data-trusted");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading content";
      setTimeout( () => {
        contentInfo.innerHTML = "You don't have any trusted contacts";
      }, 5000)
    }
  })

  if (!profile || profile.length === 0) {
    return (
      <ReactBootstrap.Row id="trusted">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container">
            {/*<ReactBootstrap.Row>
              <div className="tbs--headline">
                My network
              </div>
            </ReactBootstrap.Row>*/}
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center" id="no-data-trusted">
                You don't have any trusted contacts
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  } else {
    return (
      <ReactBootstrap.Row id="trusted">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container">
            {/*<ReactBootstrap.Row>
              <div className="tbs--headline">
                My network
              </div>
            </ReactBootstrap.Row>*/}
            <ReactBootstrap.Row>
            {
              profile.map((item, index) => {
                return (
                  <ReactBootstrap.Col xs={6} sm={6} md={3} lg={3} xl={3} key={item.id} className="text-center">
                    <Link to={`/dashboard/profile/${item.user.id}`}>
                      <div className="tbs--contact--image tbs--contact--trusted--friend">
                        <div>
                          <img src={
                            item.user.image != null
                              ?
                                item.user.image
                              :
                                item.user.profileImage != null
                                  ?
                                    item.user.profileImage.url
                                  :
                                    item.user == null || (item.user.image == null && item.user.profileImage == null)
                                      ?
                                        IconAvatar
                                      :
                                        IconAvatar
                          } className={item.user.image != null ? "tbs--image--trusted" : "tbs--image--trusted--custom"} alt="Profile" />
                          <div className="tbs--contact--trusted">
                            {item.user.name}
                          </div>
                        </div>
                      </div>
                      {
                        /*
                          <div className="tbs--contact--trusted tbs--contact--trusted--position">
                            {item.user.position}
                          </div>
                        */
                      }
                    </Link>
                  </ReactBootstrap.Col>
                );
              })
            }
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  }
}

export default Trusted;
