import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import {useMediaQuery} from "react-responsive";

import Feature from "./../dashboard/features";
import Trusted from "./../dashboard/trusted";

import IconFeatured from "../../assets/icons/IconFeatured.png";
//import IconFeaturedWhite from "../../assets/icons/IconFeaturedWhite.png";
import ActionMenu from "./actionMenu";

/* SVG */
import AcceptSVG from "../../assets/svg/accept";
import IconFeaturedWhite from "../../assets/svg/whiteStar.svg";

function Network() {
  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Store and set the state value
  const [isChangeNetwork, setIsChangeNetwork] = useState(false);
  const [isBiggerThanMobile, setIsBiggerThanMobile] = useState(window.innerWidth > 767);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  const toggleNetworkContacts = async () => {
    let elem = document.querySelector("#network-slider-contact");

    if(isChangeNetwork){
      elem.removeAttribute("checked");

      setIsChangeNetwork(false);

      document.querySelector(".tbs--network--slider > .switch > .slider").classList.add("tbs--network--deactivate");
      document.querySelector(".tbs--network--slider > .switch > .slider").classList.remove("tbs--network--active");
    } else {
      elem.setAttribute("checked","true");

      setIsChangeNetwork(true);

      document.querySelector(".tbs--network--slider > .switch > .slider").classList.remove("tbs--network--deactivate");
      document.querySelector(".tbs--network--slider > .switch > .slider").classList.add("tbs--network--active");
    }
  }

  useEffect(() => {
    const handleWindowResize = () => setIsBiggerThanMobile(window.innerWidth > 767);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Get 'Tip - Feature' - Storage
  let featureTip = localStorage.getItem("tbs-tip-feature-" + userID);

  // Get 'Tip - Trusted' - Storage
  let trustedTip = localStorage.getItem("tbs-tip-trusted-" + userID);

  useEffect(() => {
    const tipFeature = document.getElementById("tip-feature");
    const tipTrusted = document.getElementById("tip-trusted");
    const networkMain = document.getElementById("network-contacts");
    const networkSlider = document.querySelector("#network-contacts > div:nth-child(2)");
    const networkElement = document.querySelector("#network-contacts > div:nth-child(3)");

    if(typeof(tipFeature) != 'undefined' && tipFeature != null){
      if(featureTip){
        tipFeature.classList.add("tips-disabled");
      } else {
        tipFeature.classList.remove("tips-disabled");
      }

      /*if(tipFeature.classList.contains("tips-disabled") == true){
        if(isMobile == true){
          networkMain.classList.add("network-is-mobile");
          networkSlider.classList.add("network-is-mobile");
          networkMain.classList.remove("network-main-mobile");
          networkSlider.classList.remove("network-feature");
        } else {
          networkMain.classList.add("network-main-mobile");
          networkSlider.classList.add("network-feature");
          networkMain.classList.remove("network-is-mobile");
          networkSlider.classList.remove("network-is-mobile");
        }
      }*/

      /*if(tipFeature.classList.contains("tips-disabled") == false){
        if(!isMobile){
          networkElement.classList.add("network-main");
          networkSlider.classList.add("network-feature");
        } else {
          networkElement.classList.remove("network-main");
          networkSlider.classList.remove("network-feature");
        }
      }*/
    }

    if(typeof(tipTrusted) != 'undefined' && tipTrusted != null){
      if(trustedTip){
        tipTrusted.classList.add("tips-disabled");
      } else {
        tipTrusted.classList.remove("tips-disabled");
      }

      /*if(tipTrusted.classList.contains("tips-disabled") == true){
        if(isMobile == true){
          networkMain.classList.add("network-is-mobile");
          networkSlider.classList.add("network-is-mobile");
          networkMain.classList.remove("network-main");
          networkSlider.classList.remove("network-trusted");
        } else {
          networkMain.classList.add("network-main");
          networkSlider.classList.add("network-trusted");
          networkMain.classList.remove("network-is-mobile");
          networkSlider.classList.remove("network-is-mobile");
        }
      }*/

      /*if(tipTrusted.classList.contains("tips-disabled") == false){
        if(!isMobile){
          networkElement.classList.add("network-main");
          networkSlider.classList.add("network-trusted");
        } else {
          networkElement.classList.remove("network-main");
          networkSlider.classList.remove("network-trusted");
        }
      }*/
    }
  })

  const tipCloseFeature = () => {
    /*const networkMain = document.getElementById("network-contacts");
    const networkSlider = document.querySelector("#network-contacts > div:nth-child(2)");
    const networkElement = document.querySelector("#network-contacts > div:nth-child(3)");*/

    localStorage.setItem("tbs-tip-feature-" + userID, "Feature tips accepted.");
    document.getElementById("tip-feature").classList.add("tips-disabled");

    /*if(isMobile){
      networkMain.classList.add("network-is-mobile");
      networkSlider.classList.add("network-is-mobile");
      networkMain.classList.remove("network-main-mobile");
      networkSlider.classList.remove("network-feature");
    } else {
      networkMain.classList.add("network-main-mobile");
      networkSlider.classList.add("network-feature");
      networkMain.classList.remove("network-is-mobile");
      networkSlider.classList.remove("network-is-mobile");
    }*/
  }

  const tipCloseTrusted = () => {
    /*const networkMain = document.getElementById("network-contacts");
    const networkSlider = document.querySelector("#network-contacts > div:nth-child(2)");
    const networkElement = document.querySelector("#network-contacts > div:nth-child(3)");*/

    localStorage.setItem("tbs-tip-trusted-" + userID, "Trusted tips accepted.");
    document.getElementById("tip-trusted").classList.add("tips-disabled");

    /*if(isMobile){
      networkMain.classList.add("network-is-mobile");
      networkSlider.classList.add("network-is-mobile");
      networkMain.classList.remove("network-main");
      networkSlider.classList.remove("network-trusted");
    } else {
      networkMain.classList.add("network-main");
      networkSlider.classList.add("network-trusted");
      networkMain.classList.remove("network-is-mobile");
      networkSlider.classList.remove("network-is-mobile");
    }*/
  }

  return (
    <ReactBootstrap.Row id="network-contacts">
      {
        isChangeNetwork
        ?
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-feature">
            <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
              <ReactBootstrap.Row>
                {/*
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--tips--close">
                  <img src={IconClose} alt="Tips - Close" onClick={tipClose}/>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-right">
                  <img src={IconTip} alt="Tips" />
                </ReactBootstrap.Col>
                */}
                <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9}>
                  <div className="tbs--tips--subheadline">
                    TIP
                  </div>
                  <div>
                    You can feature up to 72 trusted contacts. You will see those they feature and their posts in your newsfeed.
                  </div>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
                  <div onClick={tipCloseFeature}>
                    <AcceptSVG/>
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
            </ReactBootstrap.Container>
          </ReactBootstrap.Col>
        :
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-trusted">
            <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
              <ReactBootstrap.Row>
                {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--tips--close">
                <img src={IconClose} alt="Tips - Close" onClick={tipClose}/>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-right">
                <img src={IconTip} alt="Tips" />
              </ReactBootstrap.Col>*/}
                <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9}>
                  <div className="tbs--tips--subheadline">
                    TIP
                  </div>
                  <div>
                    You can add up to 144 trusted contacts. Choose wisely.
                  </div>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
                  <div onClick={tipCloseTrusted}>
                    <AcceptSVG/>
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
            </ReactBootstrap.Container>
          </ReactBootstrap.Col>
      }
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Row id="network--submenu">
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist tbs--new--title">
              <ReactBootstrap.Row>
                <div className="tbs--headline">
                  <span className={isChangeNetwork == false ? "network--submenu--active" : "network--submenu--inactive"} onClick={() => setIsChangeNetwork(false)}>My Network</span>
                  <span className={isChangeNetwork == true ? "network--submenu--active" : "network--submenu--inactive"} onClick={() => setIsChangeNetwork(true)}>My Featured Contacts</span>
                </div>
                <div className="tbs--divider--title tbs--additional--menu">
                  <hr></hr>
                </div>
              </ReactBootstrap.Row>
            </ReactBootstrap.Container>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
        {/*<ReactBootstrap.Row id="network-headline">
          <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="text-left">
            <div className="tbs--headline">
              {
                isChangeNetwork
                  ?
                  "My Featured Contacts"
                  :
                  "My Network"
              }
            </div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={4} sm={4} md={4} lg={4} xl={4} className="text-right">
            <div className="tbs--network--slider">
              <label className="switch">
                <input type="checkbox" id="network-slider-contact" disabled />
                <span className="slider round" id="network-switch" disabled onClick={() => toggleNetworkContacts()}></span>
                <span className="tbs--slider--image--right--network"><img src={IconFeaturedWhite} /></span>
              </label>
            </div>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>*/}
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <ReactBootstrap.Container fluid={true}>
                {
                  isChangeNetwork
                    ?
                    <Feature/>
                    :
                    <Trusted/>
                }
              </ReactBootstrap.Container>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className={isChangeNetwork ? "slider-main-switch-container-active" : "slider-main-switch-container"}>
        <ReactBootstrap.Container fluid={true}>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
              <div className="tbs--network--slider">
                <label className="switch">
                  <input type="checkbox" id="network-slider-contact" disabled />
                  <span className="slider round" id="network-switch" disabled onClick={() => toggleNetworkContacts()}></span>
                  <span className="tbs--slider--image--right--network"><img src={isChangeNetwork ? IconFeaturedWhite : IconFeatured} /></span>
                </label>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>*/}
      {/*
        isMobile
          ?
          <ReactBootstrap.Col>
            <ActionMenu/>
          </ReactBootstrap.Col>
          :
          ""
      */}
    </ReactBootstrap.Row>
  );
}

export default Network;
