import React, {useEffect, useState, useRef} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import axios from "axios";
import {Link, useRouteMatch} from "react-router-dom";
import API from "./../../../config/config.json";
import moment from "moment";

import useOutsideClick from "../../../helper/useOutsideClick";

import IconAvatar from "./../../../assets/svg/avatar.svg";
import IconComment from "./../../../assets/icons/IconComment.png";
import IconForward from "./../../../assets/icons/IconForward.png";
import IconRepost from "./../../../assets/icons/IconRepost.png";
import IconBookmarks from "./../../../assets/icons/IconBookmarks.png";
import IconPulse from "../../../assets/icons/IconPulse.png";
import IconBookmarksActive from "../../../assets/icons/IconBookmarksBlue.png";
import IconPlayBlack from "../../../assets/svg/playBlack.svg";
import IconPlayWhite from "../../../assets/svg/playWhite.svg";
import IconPauseBlack from "../../../assets/svg/pauseBlack.svg";
import IconPauseWhite from "../../../assets/svg/pauseWhite.svg";
import PostBookmarkActiveSVG from "../../../assets/svg/postBookmarkActive";
import PostBookmarkSVG from "../../../assets/svg/postBookmark";
import PostCommentSVG from "../../../assets/svg/postComment";
import PostRepostSVG from "../../../assets/svg/postRepost";
import PostForwardSVG from "../../../assets/svg/postForward";
import PostActivitySVG from "../../../assets/svg/postActivity";

function Feedlist(props) {

  const ref = useRef();

  // Validation and get the correct path and url from pages
  let {path, url} = useRouteMatch();

  // Get the params
  const {match: {params}} = props;

  // Get url from other user profile feeds
  const urlPost = API.URL + "users/" + params.id + "/posts";

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");

  // Store and set the state value
  const [feedData, setFeedData] = useState([]);

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      urlPost, {
        headers: {
          authorization: token
        }
      }
    );

    setFeedData(result.data.items);
  }, []);

  const audioPlayer = (elementId, audioId) => {
    let audioPlayer = document.getElementById("audio-" + audioId + "-player");
    let audioElement = document.getElementById("audio-" + audioId);
    let postContainer = document.getElementById("post-link-" + elementId);

    postContainer.addEventListener("click", (evt) => {
      evt.preventDefault();
      let targetElement = evt.target;

      do {
        if (targetElement == audioPlayer) {
          if (audioElement.paused) {
            audioElement.play();
          } else {
            audioElement.pause();
            audioElement.currentTime = 0;
          }
        }

        if (targetElement != audioPlayer && targetElement == postContainer) {
          window.location.href = `${url}/post/${elementId}`;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);

      window.location.href = `${url}/post/${elementId}`;

    });
  }

  useEffect(() => {
    const contentInfo = document.getElementById("no-data-feeds-old");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading content";
      setTimeout( () => {
        contentInfo.innerHTML = "No posts to display at the time";
      }, 5000)
    }
  })

  if (!feedData || feedData.length === 0) {
    return (
      <ReactBootstrap.Row id="feed-list">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist">
            {/*<ReactBootstrap.Row>
              <div className="tbs--headline">
                Users list of feeds
              </div>
            </ReactBootstrap.Row>*/}
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center" id="no-data-feeds-old">
                No posts to display at the time
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  } else {
    return (
      <ReactBootstrap.Row id="feed-list">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist">
            {/*<ReactBootstrap.Row>
              <div className="tbs--headline">
                Users list of feeds
              </div>
            </ReactBootstrap.Row>*/}
            {
              feedData.map((item, index) => {
                return (
                  <Link to={`${url}/post/${item.id}`}>
                    <ReactBootstrap.Row id={`post-link-${item.id}`} key={item.id}>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}
                                          className="tbs--container--feedlist--item" id={`tbs--post--${item.id}`}>
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--reposted">
                            {item.parentId != null ? "Reposted by " + item.user.name : ""}
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <ReactBootstrap.Row className="tbs-friend-posts">
                          <ReactBootstrap.Col xs={11} sm={11} md={11} lg={11} xl={11} className="tbs--contact--details tbs--container--feedlist--item--custom">
                            <Link to={`/dashboard/profile/${item.parentPost != null ? item.parentPost.userId : item.userId}`}>
                              <div className="tbs--contact--image tbs--feedlist-old--list">
                                {
                                  item.user.profileImage != null && item.parentId == null
                                    ?
                                    <img src={item.user.profileImage.url} className="tbs--container--feedlist--image" alt="Profile"/>
                                    :
                                    item.parentPost != null && item.parentPost.user != null && item.parentPost.user.profileImage != null
                                      ?
                                      <img src={item.parentPost.user.profileImage.url} className="tbs--container--feedlist--image" alt="Profile"/>
                                      :
                                      item.parentPost != null && item.parentPost.user != null && item.parentPost.user.profileImage == null
                                        ?
                                        <img src={item.parentPost.user.image} className="tbs--container--feedlist--image" alt="Profile"/>
                                        :
                                        item.user.image != null
                                          ?
                                          <img src={item.user.image} className="tbs--container--feedlist--image" alt="Profile"/>
                                          :
                                          <img src={IconAvatar} className="tbs--container--feedlist--image" alt="Profile"/>
                                  /*
                                  item.user.image != null
                                    ?
                                    <img src={item.user.image} className="tbs--container--feedlist--image" alt="Profile"/>
                                    :
                                    <img src={
                                      item.parentPost != null
                                        ?
                                        item.parentPost.user != null
                                          ?
                                          item.parentPost.user.profileImage != null
                                            ?
                                            item.parentPost.user.profileImage != null
                                              ?
                                              item.parentPost.user.profileImage.url
                                              :
                                              IconAvatar
                                            :
                                            item.parentPost.user.image
                                          :
                                          item.user.image != null
                                            ?
                                            item.user.image
                                            :
                                            item.user.profileImage != null
                                              ?
                                              item.user.profileImage.url
                                              :
                                              IconAvatar
                                        :
                                        item.user.image != null
                                          ?
                                          item.user.image
                                          :
                                          item.user.profileImage != null
                                            ?
                                            item.user.profileImage.url
                                            :
                                            IconAvatar
                                    } className="tbs--container--feedlist--image" alt="Profile"/>*/
                                }
                              </div>
                              <div className="tbs--contact--data--feedlist">
                                <Link to={`/dashboard/profile/${item.parentPost != null ? item.parentPost.userId : item.userId}`}>
                                  <div>
                                    {
                                      item.parentPost != null
                                        ?
                                        item.parentPost.user != null
                                          ?
                                          item.parentPost.user.name
                                          :
                                          item.user.name
                                        :
                                        item.user.name
                                    }
                                  </div>
                                  <div>
                                    {
                                      item.parentPost != null
                                        ?
                                        item.parentPost.user != null
                                          ?
                                          item.parentPost.user.position
                                          :
                                          item.user.position
                                        :
                                        item.user.position
                                    } at {
                                    item.parentPost != null
                                      ?
                                      item.parentPost.user != null
                                        ?
                                        item.parentPost.user.company
                                        :
                                        item.user.company
                                      :
                                      item.user.company
                                  }
                                  </div>
                                </Link>
                                <div>
                                  <Link to={`/dashboard/profile/${item.parentPost != null ? item.parentPost.userId : item.userId}`}>
                                    {moment(item.createdAt).format('MMMM DD YYYY, hh:mm:A')}
                                  </Link>
                                </div>
                              </div>
                            </Link>
                          </ReactBootstrap.Col>
                          <ReactBootstrap.Col xs={1} sm={1} md={1} lg={1} xl={1}>
                            <div className={`tbs--contact--data--feedlist`}>
                              {
                                item.isFavourite == true
                                  ?
                                  /* Remove */
                                  /*<img className="tbs--favourite--image" src={IconBookmarksActive} alt="Bookmarks"/>*/
                                  <span>
                                    <PostBookmarkActiveSVG/>
                                  </span>
                                  :
                                  /* Add */
                                  /*<img className="tbs--favourite--image" src={IconBookmarks} alt="Bookmarks"/>*/
                                  <span>
                                    <PostBookmarkSVG/>
                                  </span>
                              }
                            </div>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="tbs--contact--data--feedlist">
                              <div className="tbs--contact--data--feedlist--title">
                                {item.parentPost != null ? item.parentPost.title : item.title}
                              </div>
                            </div>
                            <div className="tbs--contact--data--feedlist">
                              <div className={
                                item.parentPost !== null
                                  ?
                                  item.parentPost.file === null && item.parentPost.audioId != null
                                    ?
                                    `tbs--contact--data--feedlist--image tbs--feed--image--${item.id} tbs--contact--data--feedlist--without--image`
                                    :
                                    `tbs--contact--data--feedlist--image tbs--feed--image--${item.id}`
                                  :
                                  item.file === null && item.audioId != null
                                    ?
                                    `tbs--contact--data--feedlist--image tbs--feed--image--${item.id} tbs--contact--data--feedlist--without--image`
                                    :
                                    `tbs--contact--data--feedlist--image tbs--feed--image--${item.id}`
                              }>
                                <img src={
                                  item.parentPost !== null
                                    ?
                                    item.parentPost.file !== null
                                      ?
                                      item.parentPost.file.url
                                      :
                                      ""
                                    :
                                    item.file !== null
                                      ?
                                      item.file.url
                                      :
                                      ""
                                }
                                     alt="Feed - Image"/>
                                {
                                  item.parentPost != null
                                    ?
                                    <div className="tbs--audio--feed" id={`audio-${item.parentPost.audioId}-player`}
                                         onClick={() => audioPlayer(item.id, item.parentPost.audioId)}>
                                      <img src={
                                        item.parentPost.file != null && item.parentPost.audioId != null
                                          ?
                                          IconPlayWhite
                                          :
                                          item.parentPost.file == null && item.parentPost.audioId != null
                                            ?
                                            IconPlayBlack
                                            :
                                            ""
                                      } alt="Play"/>
                                    </div>
                                    :
                                    item.audioId != null
                                      ?
                                      <div className="tbs--audio--feed" id={`audio-${item.audioId}-player`}
                                           onClick={() => audioPlayer(item.id, item.audioId)}>
                                        <img src={
                                          item.file != null && item.audioId != null
                                            ?
                                            IconPlayWhite
                                            :
                                            item.file == null && item.audioId != null
                                              ?
                                              IconPlayBlack
                                              :
                                              ""
                                        } alt="Play"/>
                                      </div>
                                      :
                                      ""
                                }
                                {
                                  item.parentPost != null
                                    ?
                                    <div className="tbs--contact--audio--overview">
                                      <audio controls controlsList="nodownload" id={`audio-${item.parentPost.audioId}`}>
                                        <source
                                          src={item.parentPost.audioFile != null ? item.parentPost.audioFile.url : ""}
                                          type="audio/mp3"/>
                                        Your browser does not support the audio element.
                                      </audio>
                                    </div>
                                    :
                                    item.audioFile != null
                                      ?
                                      <div className="tbs--contact--audio--overview">
                                        <audio controls controlsList="nodownload" id={`audio-${item.audioId}`}>
                                          <source src={item.audioFile != null ? item.audioFile.url : ""}
                                                  type="audio/mp3"/>
                                          Your browser does not support the audio element.
                                        </audio>
                                      </div>
                                      :
                                      ""
                                }
                                {
                                  (((item.parentPost == null) || (item.parentPost != null && item.parentPost.file == null)) && item.file == null)
                                    ?
                                    <div className="read-more">
                                      Read more ...
                                    </div>
                                    :
                                    ""
                                }
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="tbs--contact--data--feeds">
                              <div className="tbs--contact--data--feeds--infos tbs--data--feeds--subline">
                                <span className="w20-flex">
                                  {/*<img src={IconComment} alt="Comments"/>*/}
                                  <PostCommentSVG/>
                                  <span>{item.totalComments}</span>
                                </span>
                                <span className="w20-flex">
                                  {/*<img src={IconRepost} alt="Repost"/>*/}
                                  <PostRepostSVG/>
                                  <span>{item.repostedCount}</span>
                                </span>
                                <span className="w20-flex">
                                  {/*<img src={IconForward} alt="Forward"/>*/}
                                  <PostForwardSVG/>
                                  <span>{item.forwardedCount}</span>
                                </span>
                                <span className="w20-flex">
                                  {/*<img src={IconBookmarks} alt="Bookmarks"/>*/}
                                  <PostBookmarkSVG/>
                                  <span>{item.bookmarkedCount}</span>
                                </span>
                                <span className="w20-flex">
                                  {/*<img src={IconPulse} alt="Activity"/>*/}
                                  <PostActivitySVG/>
                                  <span>{item.activitiesCount}</span>
                                </span>
                              </div>
                            </div>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </Link>
                );
              })
            }
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  }
}

export default Feedlist;
