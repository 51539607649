import React, {useEffect, useState} from 'react';
import {Link, Switch, Route, useLocation, useRouteMatch, Redirect, BrowserRouter as Router, useHistory} from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import moment from "moment";
import {useMediaQuery} from "react-responsive";

import API from "../../config/config.json";

import Channel from "./groups/channel";

/* Own images */
import IconAvatar from './../../assets/svg/avatar.svg';
/*import IconImage from './../../assets/icons/IconImage.png';*/
//import IconThreeDots from './../../assets/icons/IconThreeDots.png';
import IconNewChat from './../../assets/icons/IconNewChat.png';
import IconClose from "../../assets/icons/IconDelete.png";
import IconTip from "../../assets/icons/IconTip.png";

/* SVG - Partner */
import AcceptSVG from "../../assets/svg/accept";
import IntroduceSVG from "../../assets/svg/introduce";
import DefaultImageSVG from "../../assets/svg/defaultImage";
import ActionMenu from "./actionMenu";
import IconImage from "../../assets/svg/defaultImage.svg";
import UploadFile from "../../assets/svg/uploadFile.svg";
import DeleteFile from "../../assets/svg/deleteUploadFile.svg";
import IconThreeDots from "../../assets/svg/dotMenu.svg";

function Group() {
  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get url from groups
  const urlGroup = API.URL + 'conversations?isNew=true&type=GROUP';
  // const urlGroup = API.URL + 'conversations?type=GROUP';
  const urlUser = API.URL + 'users';
  const urlFriends = API.URL + 'friends';

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Store and set the state value
  const [groupId, setGroupId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [rule, setRule] = useState('');
  const [receivers, setReceivers] = useState([]);
  const [imageId, setImageId] = useState('');
  const [groupIdAlert, setGroupIdAlert] = useState('');
  const [groups, setGroups] = useState([]);
  const [user, setUser] = useState([]);
  const [friends, setFriends] = useState([]);
  const [newGroup, setNewGroup] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [members, setMembers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [showMembers, setShowMembers] = useState(false);
  const [showGroupAlert, setShowGroupAlert] = useState(false);
  const [showMuteAlert, setShowMuteAlert] = useState(false);
  const [showAddNetworkAlert, setShowAddNetworkAlert] = useState(false);
  const [showAddMembersAlert, setShowAddMembersAlert] = useState(false);
  const [showAddMembersSuccessAlert, setShowAddMembersSuccessAlert] = useState(false);
  const [showAddAdminsAlert, setShowAddAdminsAlert] = useState(false);
  const [showAddAdminsSuccessAlert, setShowAddAdminsSuccessAlert] = useState(false);
  const [showMuteMessage, setShowMuteMessage] = useState(false);
  const [showUnmuteMessage, setShowUnmuteMessage] = useState(false);
  const [showOpenMuteModal, setShowOpenMuteModal] = useState(false);
  const [showMute, setShowMute] = useState(false);
  const [showAddedNetworkConfirm, setShowAddedNetworkConfirm] = useState(false);
  const [mute, setMute] = useState(0);
  const [groupDuration, setGroupDuration] = useState([]);
  const [isBiggerThanTablet, setIsBiggerThanTablet] = useState(window.innerWidth > 991);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' })

  // Main container for history
  let history = useHistory();

  // Main container for location
  const location = useLocation();

  useEffect(async () => {
    const handleWindowResize = () => setIsBiggerThanTablet(window.innerWidth > 991);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  },[]);

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      urlGroup, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setGroups(result.data.items);

    const resultUsers = await axios.get(
      urlUser, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setUser(resultUsers.data);

    const resultFriends = await axios.get(
      urlFriends, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setFriends(resultFriends.data.items);

    // Default image ID
    setImageId(null)
    setRule('')
  }, [])

  const handleChangeName = e => setName(e.target.value);
  const handleChangeDescription = e => setDescription(e.target.value);
  const handleChangeRule = e => setRule(e.target.value);
  const handleChangeMute = e => setMute(Number(e.target.value));

  const getMuteValue = async (id) => {
    // Get url from group [Community Info]
    const urlCommunity = API.URL + 'conversations/' + id;

    const response = await axios
      .get(urlCommunity, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      });

    return response.data.data.conversationUser.muteDuration;
  }

  const createNewGroup = (e) => {
    e.preventDefault();

    // URL for create a new group
    const urlNewGroup = API.URL + 'conversations';

    // Post value for validation
    const data = {
      name: name,
      description: description,
      rule: rule,
      receiverIds: receivers,
      type: "GROUP",
      imageId: imageId
    };

    if(name != "" && receivers.length > 0){

      document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "";
      document.getElementsByClassName("tbs--form--main--error")[0].style.display = "none";
      document.getElementsByClassName("create-name")[0].classList.remove("error-field");
      document.getElementsByClassName("error-text")[0].style.display = "none";
      document.getElementsByClassName("error-text")[0].innerHTML = "";
      //document.getElementsByClassName("error-text")[3].style.display = "none";
      //document.getElementsByClassName("error-text")[3].innerHTML = "";

      axios
        .post(urlNewGroup, data, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        })
        .then((response) => {
          setNewGroup(response.data);

          // URL for create rule value (Workaround)
          const urlRuleText = API.URL + 'conversations/' + response.data.id;

          receivers.push(userID);

          // Post value for validation
          const dataTwo = {
            rule: rule,
            receiverIds: receivers,
          };

          axios
            .put(urlRuleText, dataTwo, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            })
            .then((response) => {
              if(response.status == 200){
                setShowGroupAlert(true);
                setShowGroup(false);
                setReceivers([]);

                setTimeout(() => {
                  setShowGroupAlert(false);
                  setShowGroup(false);
                  setReceivers([]);
                }, 1000);

                setTimeout(async () => {
                  const result = await axios.get(
                    urlGroup, {
                      headers: {
                        'Content-Type': 'application/json',
                        authorization: token
                      }
                    }
                  );

                  setGroups(result.data.items);
                }, 1200);

                setTimeout(async () => {
                  window.location.reload();
                }, 1400);
              }
            })
        })
    } else {
      document.getElementsByClassName("create-name")[0].classList.add("error-field");
      document.querySelectorAll(".tbs--form--complete--post > form > .error-text")[0].style.display = "block";
      document.querySelectorAll(".tbs--form--complete--post > form > .error-text")[0].innerHTML = "Please enter a name.";

      //document.getElementsByClassName("error-text")[3].style.display = "block";
      //document.getElementsByClassName("error-text")[3].innerHTML = "Please select members.";

      document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "The group name cannot be empty.";
      document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
      document.getElementsByClassName("tbs--new--group--error--members")[0].innerHTML = "The members to be added to this group must contain at least one contact.";
      document.getElementsByClassName("tbs--new--group--error--members")[0].style.display = "block";
    }
  }

  /*const saveRuleText = (e) => {
    e.preventDefault();

    console.log(newData);

    // URL for create rule value (Workaround)
    const urlRuleText = API.URL + 'conversations/' + newData.id;

    let allReceivers = receivers.push(userID);

    // Post value for validation
    const dataTwo = {
      rule: rule,
      receiverIds: allReceivers,
    };

    axios
      .put(urlRuleText, dataTwo, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {

        if(response.status == 200){
          setShowGroupAlert(true);
        }

        setTimeout(() => {
          setShowGroupAlert(false);
        }, 1000);

        setTimeout(() => {
          window.location.reload();
        }, 1200);
      })
  }*/

  const Schema = Yup.object().shape({
    name: Yup.string()
      .required('Please enter a name for the group.'),
    description: Yup.string(),
    rule: Yup.string(),
    receivers: Yup.array()
      .required('Please add more than one trusted contacts to create a group.'),
    imageId: Yup.number()
  });

  const imageUploadSave = (element) => {
    // URL for the files
    const urlFiles = API.URL + 'files';

    // Post value for validation - Files
    let dataFiles = new FormData();
    dataFiles.append("fileType", "IMAGE");
    dataFiles.append("file", element);
    dataFiles.append("type", "POST_IMAGE");

    // fetch data and validate
    axios
      .post(urlFiles, dataFiles, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setImageId(response.data.id);
          console.log(response.data);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const previewFile = () => {
    const preview = document.querySelector('.tbs--user--profile--image--default');
    const imageContainer = document.querySelector('.tbs--default--image');

    const file = document.querySelector('#upload-image').files[0];
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      preview.src = reader.result;
    }, false);

    if (file) {
      reader.readAsDataURL(file);
      imageUploadSave(file);

      imageContainer.classList.add("image--upload--success")
    }
  }

  const truncateMessage = (str, num) => {

    if(str == null || !str) {
      return;
    }

    if(str.length <= num) {
      return str;
    }

    return str.slice(0, num) + '...';
  }

  const showRoom = (id) => {
    setTimeout(() => {
      window.location.replace(`${url}/` + id);
    }, 100);
  }

  // Method for toggle the small menu
  const toggleMenu = (id) => {
    const menu = document.querySelector("#group-row-" + id + " > div:nth-child(2) > div.tbs--submenu--chat--overlay");

    if(!isOpen){
      setIsOpen(true);
      menu.classList.add("show-chat-menu");
    } else {
      setIsOpen(false);
      menu.classList.remove("show-chat-menu");
    }
  }

  const closeMainModal = () => {
    setShowGroup(false);
    setReceivers([]);
  }

  const saveContacts = () => {
    // Get all values of the checkboxes which is checked.
    const data = [...document.querySelectorAll('.contact-friend:checked')].map(e => Number(e.value));

    setReceivers(data);
    setShowMembers(false);

    console.log(data)
  }

  // Get 'Tip - Groups' - Storage
  let groupsTip = localStorage.getItem("tbs-tip-groups-" + userID);

  // Execute the API and get all informations
  useEffect( async () => {
    if(groupsTip){
      document.getElementById("tip-groups").classList.add("tips-disabled");
    } else {
      document.getElementById("tip-groups").classList.remove("tips-disabled");
    }

    // Get url from group [Community Info]
    const urlCommunity = API.URL + 'conversations?isNew=true&type=GROUP';

    const response = await axios
      .get(urlCommunity, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      });

    let dataResponse = response.data.items;

    let newArr = [];

    dataResponse.map(data => {
      newArr.push(data.id);
    })

    let txt = [];

    newArr.map(async (number) => {
        txt.push({Id: number, Duration: await getMuteValue(number)});
      }
    );

    setGroupDuration(txt);

    /*const mainElement = document.getElementById("groups");

    if(window.location.href.indexOf("/dashboard/communities") && !isBiggerThanTablet){
      mainElement.style.paddingBottom = "350px";
    }

    if(window.location.href.indexOf("/dashboard/communities") && isBiggerThanTablet){
      mainElement.style.paddingBottom = "300px";
    }*/
  }, []);

  const getDurationValue = (id) => {
    let dur;
    const x = groupDuration;

    x.map(number => {
      if(number.Id === id){
        dur = number.Duration;
      } else {
      }
    })

    return dur;
  }

  /*const getDurationValueAdditional = (id, arr) => {
    let dur;
    const x = arr;

    x.map(number => {
      if(number.Id === id){
        dur = number.Duration;
      }
    })

    return dur;
  }*/

  const tipClose = () => {
    localStorage.setItem("tbs-tip-groups-" + userID, "Groups tips accepted.");
    document.getElementById("tip-groups").classList.add("tips-disabled");
  }

  const muteConversation = (id) => {
    // URL for the mute status
    const urlMute = API.URL + 'conversations/' + id + '/mute';

    // Get url from group [Community Info]
    const urlCommunity = API.URL + 'conversations/' + id;

    // Data validation
    const data = {
      duration: mute
    }

    console.log(mute)

    // fetch data and validate
    axios
      .put(urlMute, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          document.querySelector("#communityMute .tbs--error--un-mute").innerHTML = "";
          document.querySelector("#communityMute .tbs--error--un-mute").style.display = "none";

          /*document.querySelector(`#tbs--mute--${id}`).style.display = "none";
          document.querySelector(`#tbs--unmute--${id}`).style.display = "block";*/

          setShowMuteAlert(true);
          setShowMuteMessage(true);
          setShowOpenMuteModal(false);
          setShowMute(true);

          setTimeout(() => {
            setShowMuteAlert(false);
            setShowMuteMessage(false);
          }, 1000);

          setTimeout(() => {
            window.location.reload();
          }, 1200);
        }
      })
      .catch(e => {
        console.log(e);

        document.querySelector("#communityMute .tbs--error--un-mute").innerHTML = "The mute duration cannot be empty.";
        document.querySelector("#communityMute .tbs--error--un-mute").style.display = "block";
      })
  }

  const unmuteConversation = (id) => {
    // URL for the unmute status
    const urlUnmute = API.URL + 'conversations/' + id + '/unmute';

    // Get url from group [Community Info]
    //const urlCommunity = API.URL + 'conversations/' + id;

    // Data validation
    const data = {
      duration: 0
    }

    // fetch data and validate
    axios
      .put(urlUnmute, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          /*document.querySelector(`#tbs--mute--${id}`).style.display = "block";
          document.querySelector(`#tbs--unmute--${id}`).style.display = "none";*/

          setShowMuteAlert(true);
          setShowUnmuteMessage(true);
          setShowMute(false);

          console.log("Id: " + id + ", new mute: " + mute)

          setTimeout(() => {
            setShowMuteAlert(false);
            setShowUnmuteMessage(false);
          }, 1000);

          setTimeout(async () => {
            // Get url from group [Community Info]
            const urlCommunity = API.URL + 'conversations?isNew=true&type=GROUP';

            const response = await axios
              .get(urlCommunity, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              });

            let dataResponse = response.data.items;

            let newArr = [];

            dataResponse.map(data => {
              newArr.push(data.id);
            })

            let txt = [];

            newArr.map(async (number) => {
                txt.push({Id: number, Duration: await getMuteValue(number)});
              }
            );

            setGroupDuration(txt);

            //return getDurationValue(id);
          }, 1000);
        }
      })
  }

  const addToNetwork = (groupId) => {
    // URL for add me to network (group)
    const urlAddNetwork = API.URL + 'conversations/' + groupId + '/self-introduce';

    // Data validation
    const data = {
      text: "Hey, add me to your network!"
    }

    // fetch data and validate
    axios
      .post(urlAddNetwork, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setShowAddNetworkAlert(true);

          setTimeout(() => {
            setShowAddNetworkAlert(false);
          }, 1700);

          setTimeout(async () => {
            const result = await axios.get(
              urlGroup, {
                headers: {
                  'Content-Type': 'application/json',
                  authorization: token
                }
              }
            );

            setGroups(result.data.items);
          }, 2000);

          setTimeout(async () => {
            window.location.reload();
          }, 2500);
        }
      })
  }

  const addMembersList = async (id) => {

    setGroupIdAlert(id);

    // URL for add members - list
    const urlAddMembersList = API.URL + 'conversations/' + id;

    // fetch data and validate
    axios
      .get(urlAddMembersList, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setMembersList(response.data.data.conversationReceivers);
          setShowAddMembersAlert(true)
        }
      })

    // Get url from group [Community Info]
    const urlCommunityMembers = API.URL + "conversations/" + id + "/members";

    const resultMembers = await axios.get(
      urlCommunityMembers, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    let adminMember = [...resultMembers.data.items.admins].map(e => Number(e.id));
    let userMember = [...resultMembers.data.items.users].map(e => Number(e.id));

    if(adminMember == ""){
      adminMember = [userID];
    } else {
      adminMember.push(userID);
    }

    if(userMember == ""){
      userMember = [userID];
    }

    let membersAll = adminMember.concat(userMember);

    setAdmins(adminMember);
    setMembers(userMember);
    setAllMembers(membersAll);

    const result = await axios.get(
      urlGroup, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setGroups(result.data.items);
  }

  const addAdminList = async (id) => {

    setGroupIdAlert(id);

    // URL for add members - list
    const urlAddMembersList = API.URL + 'conversations/' + id;

    // fetch data and validate
    axios
      .get(urlAddMembersList, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setAdminList(response.data.data.conversationReceivers);
          setShowAddAdminsAlert(true)
        }
      })

    // Get url from group [Community Info]
    const urlCommunityMembers = API.URL + "conversations/" + id + "/members";

    const resultMembers = await axios.get(
      urlCommunityMembers, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    let adminMember = [...resultMembers.data.items.admins].map(e => Number(e.id));
    let userMember = [...resultMembers.data.items.users].map(e => Number(e.id));

    if(adminMember == ""){
      adminMember = [userID];
    } else {
      adminMember.push(userID);
    }

    if(userMember == ""){
      userMember = [userID];
    }

    let membersAll = adminMember.concat(userMember);

    setAdmins(adminMember);
    setMembers(userMember);
    setAllMembers(membersAll);

    const result = await axios.get(
      urlGroup, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setGroups(result.data.items);
  }

  const resetMembersList = async () => {
    setShowAddMembersAlert(false);
    setShowAddAdminsAlert(false);

    setGroupIdAlert('');

    setMembersList([]);

    setAdmins([]);
    setMembers([]);
    setAllMembers([]);

    const result = await axios.get(
      urlGroup, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setGroups(result.data.items);
  }

  const saveGroupMember = (id) => {
    // Get all values of the checkboxes which is checked.
    const selectedData = [...document.querySelectorAll('.tbs--contacts--group:checked')].map(e => Number(e.value));

    selectedData.push(userID);

    let membersAll = [...new Set(selectedData)];

    // URL for updating the data in the community
    const communityDataUrl = API.URL + 'conversations/' + id;

    // Post value for validation
    const data = {
      receiverIds: membersAll
    };

    axios
      .put(communityDataUrl, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status == 200){
          document.querySelector(".tbs--members--list--hidden").classList.remove("tbs--members--list--active");

          setShowAddMembersSuccessAlert(true);

          setGroupIdAlert('');

          setTimeout(() => {
            setShowAddMembersSuccessAlert(false);
            setShowAddMembersAlert(false);
          }, 1000);

        }
      })
      .catch((e) => {
        console.log(e);
        document.querySelector(".tbs--members--list--hidden").classList.add("tbs--members--list--active");
      })
  }

  const saveGroupAdmin = (id) => {
    // Get all values of the checkboxes which is checked.
    const selectedData = [...document.querySelectorAll('.tbs--admin--group:checked')].map(e => Number(e.value));

    selectedData.push(userID);

    let membersAdmin = [...new Set(selectedData)];

    let allMembers = admins.concat(members);

    // URL for updating the data in the community
    const communityDataUrl = API.URL + 'conversations/' + id;

    // Post value for validation
    const data = {
      receiverIds: allMembers,
      groupAdminIds: membersAdmin
    };

    axios
      .put(communityDataUrl, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then(async (response) => {
        if(response.status == 200){
          setShowAddAdminsSuccessAlert(true)

          setTimeout(() => {
            setShowAddAdminsSuccessAlert(false);
            setShowAddAdminsAlert(false);
          }, 1000);

          setGroupIdAlert('');

          setMembersList([]);

          setAdmins([]);
          setMembers([]);
          setAllMembers([]);

          const result = await axios.get(
            urlGroup, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );

          setGroups(result.data.items);
        }
      })
      .catch((e) => {
        console.log(e);
      })
  }

  useEffect(() => {
    const tipElement = document.getElementById("tip-groups");
    const titleElement = document.querySelector(".main-headline-title");
    let offsetTop;

    if(tipElement.classList.contains("tips-disabled") == false){
      offsetTop = titleElement.offsetTop+125;

      setTimeout(() => {
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth"
        });
      }, 2000)
    } else {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }, 2500)
    }
  }, []);

  /*useEffect(() => {
    const lastElement = document.getElementById("group-new-message");
    const mainElement = document.getElementById("group-room-contact");

    if(typeof(mainElement) != 'undefined' && mainElement != null){
      setTimeout(() => {
        let offsetTop;
        if(isTablet){
          offsetTop = mainElement.offsetTop-300;
        } else {
          offsetTop = 0;
        }

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth"
        });
      }, 2500)
    }

    if(typeof(lastElement) != 'undefined' && lastElement != null){
      setTimeout(() => {
        lastElement.scrollIntoView();

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth"
        });
      }, 1000)
    }
  }, [])*/

  useEffect(() => {
    const contentInfo = document.getElementById("no-data-community");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading content";
      setTimeout( () => {
        contentInfo.innerHTML = "You don't have any group conversations";
      }, 5000)
    }
  }, [])

  useEffect(() => {
    const contentInfo = document.getElementById("group-default-message");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading conversations";
      setTimeout( () => {
        contentInfo.innerHTML = "Please select a group conversation.";
      }, 5000)
    }
  }, [])

  const selectImage = () => {
    document.getElementById("upload-image").click();
  }

  const deleteNewUploadFile = () => {
    setImageId(null)
  }

  const searchingGroup = () => {
    let input, filter, listContainer, listContainerNoGroups, listElement, memberNameText, index, textValue;

    input = document.getElementById('memberSearch');
    filter = input.value.toUpperCase();
    listContainer = document.getElementById("group-select-contact");
    listElement = listContainer.getElementsByClassName('row');

    for (index = 1; index < listElement.length; index++) {
      memberNameText = listElement[index].querySelector("div:nth-child(2)");
      textValue = memberNameText.textContent || memberNameText.innerText;

      if (textValue.toUpperCase().indexOf(filter) > -1) {
        listElement[index].style.display = "";
      } else {
        listElement[index].style.display = "none";
      }
    }
  }

  const searchingGroupOnly = () => {
    let input, filter, listContainer, listContainerNoGroups, listElement, memberNameText, index, textValue;

    input = document.getElementById('memberSearchOnly');
    filter = input.value.toUpperCase();
    listContainer = document.getElementById("member--overview--contact");
    listElement = listContainer.getElementsByClassName('row');

    for (index = 0; index < listElement.length; index++) {
      memberNameText = listElement[index].querySelector("div > div > .text-left");
      textValue = memberNameText.textContent || memberNameText.innerText;

      if (textValue.toUpperCase().indexOf(filter) > -1) {
        listElement[index].style.display = "";
      } else {
        listElement[index].style.display = "none";
      }
    }
  }

  const searchingGroupAdmin = () => {
    let input, filter, listContainer, listContainerNoGroups, listElement, memberNameText, index, textValue;

    input = document.getElementById('adminSearchOnly');
    filter = input.value.toUpperCase();
    listContainer = document.getElementById("admin--overview--contact");
    listElement = listContainer.getElementsByClassName('row');

    for (index = 0; index < listElement.length; index++) {
      memberNameText = listElement[index].querySelector("div > div > .text-left");
      textValue = memberNameText.textContent || memberNameText.innerText;

      if (textValue.toUpperCase().indexOf(filter) > -1) {
        listElement[index].style.display = "";
      } else {
        listElement[index].style.display = "none";
      }
    }
  }

  if (!groups || groups.length == 0) {
    return (
      <ReactBootstrap.Row id="groups">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-groups">
          <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
            <ReactBootstrap.Row>
              {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--tips--close">
                <img src={IconClose} alt="Tips - Close" onClick={tipClose}/>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-left">
                <img src={IconTip} alt="Tips" />
              </ReactBootstrap.Col>*/}
              <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9}>
                <div className="tbs--tips--subheadline">
                  TIP
                </div>
                <div>
                  Access options from the 3 dots in the group list.
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
                <div onClick={tipClose}>
                  <AcceptSVG/>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={5} xl={5} id="group-contacts">
          <ReactBootstrap.Container fluid={true} className="tbs--container tbs--new--title">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="tbs--headline main-headline-title">
                  <span>My groups</span>
                  {/*<span id="createCommunityButton" onClick={() => setShowGroup(true)}>
                    <img src={IconNewChat} alt="New group" className="hide--element"/>
                    <i className="fas fa-times-circle fa-3x"></i>
                  </span>*/}
                </div>
                <div className="tbs--divider--title tbs--divider--title--group tbs--additional--menu--chats">
                  <hr></hr>
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="text-center button--sign">
                  <ReactBootstrap.Button onClick={() => setShowGroup(true)}>Create a new group</ReactBootstrap.Button>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center" id="no-data-community">
                You don't have any group conversations
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={7} xl={7} className="d-none d-sm-none d-md-none d-lg-block" id="group-room-contact">
          <ReactBootstrap.Container fluid={true} className="tbs--container no-groups">
            <ReactBootstrap.Row>
              <div className="tbs--headline tbs--headline--chats text-center tbs--no--group--headline">
                Welcome to the groups
              </div>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--welcome--chat">
                <div id="group-default-message" className="text-center tbs--welcome--chat">
                  Please select a group conversation.
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  } else {
    return (
      <ReactBootstrap.Row id="groups">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="tip-groups">
          <ReactBootstrap.Container fluid={true} className="tbs--container--tips">
            <ReactBootstrap.Row>
              {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right tbs--tips--close">
                <img src={IconClose} alt="Tips - Close" onClick={tipClose}/>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={2} sm={2} md={1} lg={1} xl={1} className="text-left">
                <img src={IconTip} alt="Tips" />
              </ReactBootstrap.Col>*/}
              <ReactBootstrap.Col xs={9} sm={9} md={9} lg={9} xl={9}>
                <div className="tbs--tips--subheadline">
                  TIP
                </div>
                <div>
                  Access options from the 3 dots in the group list.
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={3} sm={3} md={3} lg={3} xl={3} className="tbs--tips--context">
                <div onClick={tipClose}>
                  <AcceptSVG/>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={5} xl={5} id="group-contacts">
          <ReactBootstrap.Container fluid={true} className="tbs--container tbs--new--title">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="tbs--headline main-headline-title">
                  <span>My groups</span>
                  <span id="createNewGroupButton" onClick={() => setShowGroup(true)}>
                    <img src={IconNewChat} alt="New group" className="hide--element"/>
                    <i className="fas fa-times-circle fa-3x"></i>
                  </span>
                </div>
                <div className="tbs--divider--title tbs--divider--title--group tbs--additional--menu--chats">
                  <hr></hr>
                </div>
                <ReactBootstrap.Modal
                  show={showGroup}
                  onHide={() => closeMainModal()}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <ReactBootstrap.Modal.Header closeButton>
                    <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                      Create a new group
                    </ReactBootstrap.Modal.Title>
                  </ReactBootstrap.Modal.Header>
                  <ReactBootstrap.Modal.Body id="group-create">
                    <ReactBootstrap.Row>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                        <div className="form-create-group">
                          <div className="tbs--form--main--error tbs--new--group--error text-center"></div>
                          <div className="tbs--form--complete--post">
                            <Formik
                              initialValues={{
                                name: '',
                                description: '',
                                rule: '',
                                receiverIds: [],
                                type: 'GROUP',
                                imageId: imageId
                              }}
                              validationSchema={Schema}
                              onSubmit={values => {
                                console.log(values.text);
                              }}
                            >
                              {({ errors, touched }) => (
                                <Form onSubmit={createNewGroup}>
                                  <div className="text-left">
                                    <label htmlFor="name">
                                      GROUP NAME
                                    </label>
                                  </div>
                                  <div className="text-left">
                                    <Field type="text" className={`create-name`} name="name" id="name" placeholder="Group Name" value={name} onChange={handleChangeName}/>
                                  </div>
                                  <div className="error-text text-left">
                                    {/*
                                      errors.name && touched.name
                                      ?
                                        errors.name
                                      :
                                        null
                                    */}
                                    <ErrorMessage name="name">
                                      {errors.name}
                                    </ErrorMessage>
                                  </div>
                                  <div className="text-left">
                                    <label htmlFor="name">
                                      IMAGE
                                    </label>
                                  </div>
                                  {
                                    imageId == null
                                      ?
                                      <div>
                                        <div className={`upload-new-image`} name="image" id="image" onClick={selectImage}>
                                          Add image
                                        </div>
                                        <input type="file" id="upload-image" accept="image/*" onChange={previewFile} hidden/>
                                      </div>
                                      :
                                      ""
                                  }
                                  <ReactBootstrap.Row className={imageId != null ? "new-community-top-layout" : "new-community-top-layout hide"}>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <label htmlFor="upload-image" className="new-image-upload">
                                        <div className="tbs--default--image">
                                          <img src={IconImage} alt="Profile - Image" className="tbs--user--profile--image--default"/>
                                          <input type="file" id="upload-image" accept="image/*" onChange={previewFile} hidden/>
                                          {/*<label htmlFor="upload-image" className="tbs--upload--image">UPLOAD AN IMAGE</label>*/}
                                          <div className="text-center" id="edit-file">
                                            <label htmlFor="upload-image" className="tbs--upload--image new-upload">
                                              {/*UPLOAD AN IMAGE*/}
                                              <img src={UploadFile} alt="Upload file" id="upload-file"/>
                                            </label>
                                          </div>
                                          <div className="text-center" id="delete-file" onClick={deleteNewUploadFile}>
                                            <label className="tbs--upload--image new-upload">
                                              {/*UPLOAD AN IMAGE*/}
                                              <img src={DeleteFile} alt="Upload file" id="delete-upload-file"/>
                                            </label>
                                          </div>
                                        </div>
                                      </label>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                  <div className="text-left">
                                    <label htmlFor="description">
                                      ABOUT
                                    </label>
                                  </div>
                                  <div className="text-left">
                                    <Field as="textarea" className={`create-description`} name="description" id="description" placeholder="Add some info about your group's purpose and desired output." value={description} onChange={handleChangeDescription}/>
                                  </div>
                                  <div className="error-text text-left">
                                    {/*
                                      errors.description && touched.description
                                        ?
                                        errors.description
                                        :
                                        null
                                    */}
                                    <ErrorMessage name="description">
                                      {errors.description}
                                    </ErrorMessage>
                                  </div>
                                  <div className="text-left">
                                    <label htmlFor="rule">
                                      RULES
                                    </label>
                                  </div>
                                  <div className="text-left">
                                    <Field as="textarea" className={`create-rule`} name="rule" id="rule" placeholder="Enter here the rules of your group." value={rule} onChange={handleChangeRule}/>
                                  </div>
                                  <div className="error-text text-left">
                                    {/*
                                      errors.rule && touched.rule
                                        ?
                                        errors.rule
                                        :
                                        null
                                    */}
                                    <ErrorMessage name="rule">
                                      {errors.rule}
                                    </ErrorMessage>
                                  </div>
                                  <div className="text-left">
                                    <label htmlFor="admin">
                                      ADMIN
                                    </label>
                                  </div>
                                  <div className="text-left">
                                    <img src={user.profileImage != null ? user.profileImage.url : IconAvatar} alt="Profile image" />
                                    <div>
                                      {user.name}
                                    </div>
                                  </div>
                                  {
                                    /*
                                      <div className="error-text text-center tbs--add--members"></div>
                                    */
                                  }
                                  <div className="tbs--new--group--error--members text-center"></div>
                                  <div className="text-left admin-and-new-members">
                                    <label htmlFor="members">
                                      MEMBERS LIST
                                    </label>
                                  </div>
                                  <div className="text-left" id="select-contact-group">
                                    <div className="tbs--contact--group" onClick={() => setShowMembers(true)}>
                                      <img src={IconAvatar} alt="Add new members" />
                                      <div>
                                        Add members
                                      </div>
                                    </div>
                                    {
                                      friends.map(friend => {
                                        if(receivers.includes(friend.friendId)){
                                          return(
                                            <div className="tbs--contact--group">
                                              <img src={friend.user.image != null ? friend.user.image : friend.user.profileImage != null ? friend.user.profileImage.url : IconAvatar} alt={friend.user.name} />
                                              <div>
                                                {friend.user.name}
                                              </div>
                                            </div>
                                          )
                                        }
                                      })
                                    }
                                  </div>
                                  <ReactBootstrap.Modal
                                    show={showMembers}
                                    onHide={() => setShowMembers(false)}
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter-two"
                                    centered
                                  >
                                    <ReactBootstrap.Modal.Header closeButton>
                                      <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-two">
                                        Add members
                                      </ReactBootstrap.Modal.Title>
                                    </ReactBootstrap.Modal.Header>
                                    <ReactBootstrap.Modal.Body id="group-select-contact">
                                      <ReactBootstrap.Row>
                                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="member-contacts-search">
                                          <input type="text" id="memberSearch" onKeyUp={searchingGroup} placeholder="Search for member name"/>
                                        </ReactBootstrap.Col>
                                      </ReactBootstrap.Row>
                                      {
                                        friends.map(friend => {
                                          return (
                                            <ReactBootstrap.Row>
                                              <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--image--select">
                                                <img src={
                                                  friend.user.image != null
                                                    ?
                                                    friend.user.image
                                                    :
                                                    friend.user.profileImage != null
                                                      ?
                                                      friend.user.profileImage.url
                                                      :
                                                      IconAvatar
                                                }
                                                     alt="Contact image"
                                                />
                                              </ReactBootstrap.Col>
                                              <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="tbs--contact--name--select">
                                                {friend.user.name}
                                              </ReactBootstrap.Col>
                                              <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--checkbox--select">
                                                <input type="checkbox" className="contact-friend" name="contact" value={friend.friendId} />
                                              </ReactBootstrap.Col>
                                            </ReactBootstrap.Row>
                                          )
                                        })
                                      }
                                      <div className="text-center button--sign">
                                        <ReactBootstrap.Button onClick={() => saveContacts()}>Add Members</ReactBootstrap.Button>
                                      </div>
                                    </ReactBootstrap.Modal.Body>
                                  </ReactBootstrap.Modal>
                                  <div className="text-center button--sign">
                                    <ReactBootstrap.Button type="submit">Create Group</ReactBootstrap.Button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {
                          showGroupAlert
                          ?
                            <ReactBootstrap.Alert variant="success" transition={true} id="create-group-alert" className="text-center">
                              <ReactBootstrap.Alert.Heading>Group successful created.</ReactBootstrap.Alert.Heading>
                            </ReactBootstrap.Alert>
                          :
                            ""
                        }
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </ReactBootstrap.Modal.Body>
                </ReactBootstrap.Modal>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="create-group-button">
                <div className="text-center button--sign">
                  <div onClick={() => setShowGroup(true)}>Create a new group</div>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            {
              groups.map((group, index) => {
                return (
                  <ReactBootstrap.Row id={`group-row-${group.id}`}>
                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} key={index} id={`group-${group.id}`}>
                      {
                        isBiggerThanTablet
                        ?
                          <Link to={`${url}/${group.id}`} onClick={() => showRoom(group.id)}>
                            <ReactBootstrap.Row className={group.image == null ? "tbs--image--chats--no--image" : ""}>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="tbs--image--chats">
                                  <div>
                                    {
                                      group.image != null
                                      ?
                                        <img src={group.image.url} alt="Profile Image"/>
                                      :
                                        <DefaultImageSVG/>
                                    }
                                  </div>
                                </div>
                                <div>
                                  <div className="tbs--chat--name">
                                    {/*truncateMessage(group.name, 15)*/}
                                    {group.name}
                                  </div>
                                  <div className="tbs--chat--update--date">
                                    {moment(group.updatedAt).format('MMMM DD YYYY, hh:mm:A')}
                                  </div>
                                  {
                                    group.lastMessage != null
                                      ?
                                      <div>
                                        <div className="tbs--chat--last--message">
                                          {truncateMessage(group.lastMessage.text, 25)}
                                        </div>
                                      </div>
                                      :
                                      ""
                                  }
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          </Link>
                        :
                          <Link to={`${url}/${group.id}/channel`}>
                            <ReactBootstrap.Row className={group.image == null ? "tbs--image--chats--no--image" : ""}>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="tbs--image--chats">
                                  <div>
                                    {
                                      group.image != null
                                      ?
                                        <img src={group.image.url} alt="Profile Image"/>
                                      :
                                        <DefaultImageSVG/>
                                    }
                                  </div>
                                </div>
                                <div>
                                  <div className="tbs--chat--name">
                                    {group.name}
                                  </div>
                                  <div className="tbs--chat--update--date">
                                    {moment(group.updatedAt).format('MMMM DD YYYY, hh:mm:A')}
                                  </div>
                                  {
                                    group.lastMessage != null
                                      ?
                                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className="tbs--chat--last--message">
                                          {truncateMessage(group.lastMessage.text, 25)}
                                        </div>
                                      </ReactBootstrap.Col>
                                      :
                                      ""
                                  }
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          </Link>
                      }
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div id={`chat-room-${group.id}`} className="tbs--group--submenu">
                        <ReactBootstrap.DropdownButton
                          menuAlign="right"
                          title={
                            <div className="tbs--chat--details">
                              <img src={IconThreeDots} alt="Chat Menu"/>
                            </div>
                          }
                          id={`chat-room-${group.id}-channel`}
                        >
                          {
                            getDurationValue(group.id) > 0
                            ?
                              <div className={`dropdown-item tbs--mute--group tbs--unmute--element--${group.id}`} onClick={() => unmuteConversation(group.id)} id={`tbs--unmute--${group.id}`}>
                                Unmute
                              </div>
                            :
                              <div className={`dropdown-item tbs--mute--group tbs--mute--element--${group.id}`} onClick={() => setShowOpenMuteModal(true)} id={`tbs--mute--${group.id}`}>
                                Mute
                              </div>
                          }
                          <ReactBootstrap.Row>
                            <ReactBootstrap.Col>
                              <ReactBootstrap.Modal
                                show={showOpenMuteModal}
                                onHide={() => setShowOpenMuteModal(false)}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                              >
                                <ReactBootstrap.Modal.Header closeButton>
                                  <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                                    Mute notifications for ...
                                  </ReactBootstrap.Modal.Title>
                                </ReactBootstrap.Modal.Header>
                                <ReactBootstrap.Modal.Body id="mute-create">
                                  <ReactBootstrap.Row>
                                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <div id="communityMute">
                                        <div className="tbs--error--un-mute"></div>
                                        <div>
                                          <label>
                                            <input type="radio"
                                                   className="muted"
                                                   name="muted" value={8}
                                                   onChange={handleChangeMute}/>
                                            <span>For the next 2 hours</span>
                                          </label>
                                        </div>
                                        <div>
                                          <label>
                                            <input type="radio"
                                                   className="muted"
                                                   name="muted" value={168}
                                                   onChange={handleChangeMute}/>
                                            <span>Until next day (7 am)</span>
                                          </label>
                                        </div>
                                        <div>
                                          <label>
                                            <input type="radio"
                                                   className="muted"
                                                   name="muted" value={8760}
                                                   onChange={handleChangeMute}/>
                                            <span>For 1 week</span>
                                          </label>
                                        </div>
                                        <div>
                                          <label>
                                            <input type="radio"
                                                   className="muted"
                                                   name="muted" value={99999}
                                                   onChange={handleChangeMute}/>
                                            <span>Disable notifications for this conversation</span>
                                          </label>
                                        </div>
                                        <div>
                                          <hr/>
                                        </div>
                                        <div className="text-right button--sign">
                                          <ReactBootstrap.Button onClick={() => setShowOpenMuteModal(false)}>Cancel</ReactBootstrap.Button>
                                          <ReactBootstrap.Button onClick={() => muteConversation(group.id)}>OK</ReactBootstrap.Button>
                                        </div>
                                      </div>
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                </ReactBootstrap.Modal.Body>
                              </ReactBootstrap.Modal>
                            </ReactBootstrap.Col>
                          </ReactBootstrap.Row>
                          <div className="dropdown-item"><Link to={`${path}/${group.id}/community`}>Group info</Link></div>
                          <div className="dropdown-item"><Link to={`${path}/${group.id}/report`}>Report conversation</Link></div>
                          <div className="dropdown-item" onClick={() => setShowAlert(true)}>Encryption</div>
                          <ReactBootstrap.Modal
                            show={showAlert}
                            onHide={() => setShowAlert(false)}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <ReactBootstrap.Modal.Header closeButton>
                              <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                                TBS
                              </ReactBootstrap.Modal.Title>
                            </ReactBootstrap.Modal.Header>
                            <ReactBootstrap.Modal.Body>
                              <ReactBootstrap.Row>
                                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                  <div>
                                    This chat is AES encrypted.
                                  </div>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                            </ReactBootstrap.Modal.Body>
                          </ReactBootstrap.Modal>
                          {
                            ((group.conversationUser.role == "ADMIN" && group.conversationUser.userId == userID) || (group.conversationReceiver.role == "ADMIN" && group.conversationReceiver.userId == userID))
                            ?
                              <div>
                                <div className="dropdown-item" onClick={() => addMembersList(group.id)}>
                                  Add members
                                </div>
                                <ReactBootstrap.Modal
                                  show={showAddMembersAlert}
                                  onHide={() => resetMembersList()}
                                  size="md"
                                  aria-labelledby="contained-modal-title-vcenter-members"
                                  centered
                                >
                                  <ReactBootstrap.Modal.Header closeButton>
                                    <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-members">
                                      Add members
                                    </ReactBootstrap.Modal.Title>
                                  </ReactBootstrap.Modal.Header>
                                  <ReactBootstrap.Modal.Body id="group--overview--contact" className={"tbs-group--" + groupIdAlert}>
                                    <ReactBootstrap.Row>
                                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className="tbs--members--list--hidden error-text text-center">
                                          Please select a contact.
                                        </div>
                                      </ReactBootstrap.Col>
                                    </ReactBootstrap.Row>
                                    <ReactBootstrap.Row>
                                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="member-contacts-search">
                                        <input type="text" id="memberSearchOnly" onKeyUp={searchingGroupOnly} placeholder="Search for member name"/>
                                      </ReactBootstrap.Col>
                                    </ReactBootstrap.Row>
                                    <ReactBootstrap.Row id="member--overview--contact">
                                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        {
                                          friends.map((contact) => {
                                            return(
                                              <ReactBootstrap.Row>
                                                <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                                  <img src={contact.user.image != null ? contact.user.image : contact.user.profileImage != null ? contact.user.profileImage.url : IconAvatar} />
                                                </ReactBootstrap.Col>
                                                <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="text-left">
                                                  {contact.user.name}
                                                </ReactBootstrap.Col>
                                                <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                                  {
                                                    members.includes(contact.friendId) || admins.includes(contact.friendId)
                                                      ?
                                                      <input type="checkbox" className="tbs--contacts--group" name="contacts" value={contact.friendId} defaultChecked={true} />
                                                      :
                                                      <input type="checkbox" className="tbs--contacts--group" name="contacts" value={contact.friendId} />
                                                  }
                                                </ReactBootstrap.Col>
                                              </ReactBootstrap.Row>
                                            )
                                          })
                                        }
                                        <ReactBootstrap.Row>
                                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                            <div className="text-center button--sign">
                                              <ReactBootstrap.Button onClick={() => saveGroupMember(groupIdAlert)}>Update</ReactBootstrap.Button>
                                            </div>
                                          </ReactBootstrap.Col>
                                        </ReactBootstrap.Row>
                                      </ReactBootstrap.Col>
                                    </ReactBootstrap.Row>
                                  </ReactBootstrap.Modal.Body>
                                </ReactBootstrap.Modal>
                                <ReactBootstrap.Modal
                                  show={showAddMembersSuccessAlert}
                                  onHide={() => setShowAddMembersSuccessAlert(false)}
                                  size="sm"
                                  aria-labelledby="contained-modal-title-vcenter-members-success"
                                  centered
                                >
                                  <ReactBootstrap.Modal.Header closeButton>
                                    <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-members-success">
                                      Group members - Update
                                    </ReactBootstrap.Modal.Title>
                                  </ReactBootstrap.Modal.Header>
                                  <ReactBootstrap.Modal.Body id="group--overview--members--success">
                                    <ReactBootstrap.Row>
                                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                        Successful.
                                      </ReactBootstrap.Col>
                                    </ReactBootstrap.Row>
                                  </ReactBootstrap.Modal.Body>
                                </ReactBootstrap.Modal>
                              </div>

                            :
                              ""
                          }
                          {
                            ((group.conversationUser.role == "ADMIN" && group.conversationUser.userId == userID) || (group.conversationReceiver.role == "ADMIN" && group.conversationReceiver.userId == userID))
                            ?
                              <div>
                                <div className="dropdown-item" onClick={() => addAdminList(group.id)}>
                                  Add admins
                                </div>
                                <ReactBootstrap.Modal
                                  show={showAddAdminsAlert}
                                  onHide={() => resetMembersList()}
                                  size="md"
                                  aria-labelledby="contained-modal-title-vcenter-admins"
                                  centered
                                >
                                  <ReactBootstrap.Modal.Header closeButton>
                                    <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-admins">
                                      Add admins
                                    </ReactBootstrap.Modal.Title>
                                  </ReactBootstrap.Modal.Header>
                                  <ReactBootstrap.Modal.Body id="group--overview--admin">
                                    <ReactBootstrap.Row>
                                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="admin-contacts-search">
                                        <input type="text" id="adminSearchOnly" onKeyUp={searchingGroupAdmin} placeholder="Search for member name"/>
                                      </ReactBootstrap.Col>
                                    </ReactBootstrap.Row>
                                    <ReactBootstrap.Row id="admin--overview--contact">
                                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        {
                                          friends.map((contact) => {
                                            if(members.includes(contact.friendId) || admins.includes(contact.friendId)) {
                                              return (
                                                <ReactBootstrap.Row>
                                                  <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                                    <img src={contact.user.image != null ? contact.user.image : contact.user.profileImage != null ? contact.user.profileImage.url : IconAvatar}/>
                                                  </ReactBootstrap.Col>
                                                  <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="text-left">
                                                    {contact.user.name}
                                                  </ReactBootstrap.Col>
                                                  <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                                    {
                                                      admins.includes(contact.friendId)
                                                      ?
                                                        <input type="checkbox" className="tbs--admin--group" name="contacts" value={contact.friendId} defaultChecked={true}/>
                                                      :
                                                        <input type="checkbox" className="tbs--admin--group" name="contacts" value={contact.friendId}/>
                                                    }
                                                  </ReactBootstrap.Col>
                                                </ReactBootstrap.Row>
                                              )
                                            }
                                          })
                                        }
                                        <ReactBootstrap.Row>
                                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                            <div className="text-center button--sign">
                                              <ReactBootstrap.Button onClick={() => saveGroupAdmin(groupIdAlert)}>Update</ReactBootstrap.Button>
                                            </div>
                                          </ReactBootstrap.Col>
                                        </ReactBootstrap.Row>
                                      </ReactBootstrap.Col>
                                    </ReactBootstrap.Row>
                                  </ReactBootstrap.Modal.Body>
                                </ReactBootstrap.Modal>
                                <ReactBootstrap.Modal
                                  show={showAddAdminsSuccessAlert}
                                  onHide={() => setShowAddAdminsSuccessAlert(false)}
                                  size="sm"
                                  aria-labelledby="contained-modal-title-vcenter-admins-success"
                                  centered
                                >
                                  <ReactBootstrap.Modal.Header closeButton>
                                    <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-admins-success">
                                      Group admins - Update
                                    </ReactBootstrap.Modal.Title>
                                  </ReactBootstrap.Modal.Header>
                                  <ReactBootstrap.Modal.Body id="group--overview--admin--success">
                                    <ReactBootstrap.Row>
                                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                        Successful.
                                      </ReactBootstrap.Col>
                                    </ReactBootstrap.Row>
                                  </ReactBootstrap.Modal.Body>
                                </ReactBootstrap.Modal>
                              </div>
                            :
                              ""
                          }
                          <div className="dropdown-item" onClick={() => setShowAddedNetworkConfirm(true)}>Add me to your network</div>
                          <ReactBootstrap.Modal
                            show={showAddedNetworkConfirm}
                            onHide={() => setShowAddedNetworkConfirm(false)}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter-add-network"
                            centered
                          >
                            <ReactBootstrap.Modal.Header closeButton>
                              <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-add-network">
                                Add me to your network
                              </ReactBootstrap.Modal.Title>
                            </ReactBootstrap.Modal.Header>
                            <ReactBootstrap.Modal.Body>
                              <ReactBootstrap.Row id="add-to-your-network">
                                <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-center">
                                  <div className="text-center button--sign">
                                    <ReactBootstrap.Button onClick={() => setShowAddedNetworkConfirm(false)}>No</ReactBootstrap.Button>
                                  </div>
                                </ReactBootstrap.Col>
                                <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-center">
                                  <div className="text-center button--sign">
                                    <ReactBootstrap.Button onClick={() => addToNetwork(group.id)}>Yes</ReactBootstrap.Button>
                                  </div>
                                </ReactBootstrap.Col>
                              </ReactBootstrap.Row>
                            </ReactBootstrap.Modal.Body>
                          </ReactBootstrap.Modal>
                          <div className="dropdown-item"><Link to={`${path}/${group.id}/publication`}>Group posts</Link></div>
                        </ReactBootstrap.DropdownButton>
                      </div>
                    </ReactBootstrap.Col>
                  </ReactBootstrap.Row>
                )
              })
            }
            <ReactBootstrap.Modal
              show={showAddNetworkAlert}
              onHide={() => setShowAddNetworkAlert(false)}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter-three"
              centered
            >
              <ReactBootstrap.Modal.Header closeButton>
                <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-three">
                  Request for introduce to group
                </ReactBootstrap.Modal.Title>
              </ReactBootstrap.Modal.Header>
              <ReactBootstrap.Modal.Body id="introduce-to-group">
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                    <div>
                      Successful
                    </div>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </ReactBootstrap.Modal.Body>
            </ReactBootstrap.Modal>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={7} xl={7} className="d-none d-sm-none d-md-none d-lg-block" id="group-room-contact">
          <Switch>
            <Route
              path={`${path}/:id`}
              component={Channel}
            />
            <Route
              exact
              path={path}
              render={() => {
                return (
                  <ReactBootstrap.Container fluid={true} className="tbs--container">
                    {/*
                    <ReactBootstrap.Row>
                      <div className="tbs--headline tbs--headline--chats text-center">
                        Welcome to the communities
                      </div>
                    </ReactBootstrap.Row>
                    */}
                    <ReactBootstrap.Row>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--welcome--chat">
                        <div className="tbs--welcome--group">
                          <div id="group-default-message" className="tbs--default--chat--message text-center">
                            Please select a community conversation.
                          </div>
                        </div>
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </ReactBootstrap.Container>
                )
              }}
            />
          </Switch>
        </ReactBootstrap.Col>
        {/*
          isMobile
          ?
            <ReactBootstrap.Col>
              <ActionMenu/>
            </ReactBootstrap.Col>
          :
            ""
        */}
      </ReactBootstrap.Row>
    );
  }
}

export default Group;
