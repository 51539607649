import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import axios from "axios";
import moment from "moment";
import {useMediaQuery} from "react-responsive";
import * as Yup from "yup";

import API from "../../../config/config.json";
import {ErrorMessage, Field, Form, Formik} from "formik";
import IconImage from "../../../assets/svg/defaultImage.svg";
import IconAvatar from "../../../assets/svg/avatar.svg";
import UploadFile from "../../../assets/svg/uploadFile.svg";
import DeleteFile from "../../../assets/svg/deleteUploadFile.svg";

function CreateCommunity() {
  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get url from groups
  const urlGroup = API.URL + 'conversations?isNew=true&type=GROUP';
  // const urlGroup = API.URL + 'conversations?type=GROUP';
  const urlUser = API.URL + 'users';
  const urlFriends = API.URL + 'friends';

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;

  // Store and set the state value
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [rule, setRule] = useState('');
  const [receivers, setReceivers] = useState([]);
  const [imageId, setImageId] = useState('');
  const [groups, setGroups] = useState([]);
  const [user, setUser] = useState([]);
  const [friends, setFriends] = useState([]);
  const [newGroup, setNewGroup] = useState([]);
  const [showGroup, setShowGroup] = useState(false);
  const [showMembers, setShowMembers] = useState(false);
  const [showGroupAlert, setShowGroupAlert] = useState(false);
  const [mute, setMute] = useState(0);
  const [groupDuration, setGroupDuration] = useState([]);
  const [isBiggerThanTablet, setIsBiggerThanTablet] = useState(window.innerWidth > 991);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' })

  // Main container for history
  let history = useHistory();

  // Main container for location
  const location = useLocation();

  useEffect(async () => {
    const handleWindowResize = () => setIsBiggerThanTablet(window.innerWidth > 991);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  },[]);

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      urlGroup, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setGroups(result.data.items);

    const resultUsers = await axios.get(
      urlUser, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setUser(resultUsers.data);

    const resultFriends = await axios.get(
      urlFriends, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setFriends(resultFriends.data.items);

    // Default image ID
    setImageId(null)
    setRule('')
  }, [])

  const handleChangeName = e => setName(e.target.value);
  const handleChangeDescription = e => setDescription(e.target.value);
  const handleChangeRule = e => setRule(e.target.value);
  const handleChangeMute = e => setMute(Number(e.target.value));

  const getMuteValue = async (id) => {
    // Get url from group [Community Info]
    const urlCommunity = API.URL + 'conversations/' + id;

    const response = await axios
      .get(urlCommunity, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      });

    return response.data.data.conversationUser.muteDuration;
  }

  const createNewGroup = (e) => {
    e.preventDefault();

    // URL for create a new group
    const urlNewGroup = API.URL + 'conversations';

    // Post value for validation
    const data = {
      name: name,
      description: description,
      rule: rule,
      receiverIds: receivers,
      type: "GROUP",
      imageId: imageId
    };

    if(name != "" && receivers.length > 0){

      document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "";
      document.getElementsByClassName("tbs--form--main--error")[0].style.display = "none";
      document.getElementsByClassName("create-name")[0].classList.remove("error-field");
      document.getElementsByClassName("error-text")[0].style.display = "none";
      document.getElementsByClassName("error-text")[0].innerHTML = "";
      //document.getElementsByClassName("error-text")[3].style.display = "none";
      //document.getElementsByClassName("error-text")[3].innerHTML = "";

      axios
        .post(urlNewGroup, data, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        })
        .then((response) => {
          setNewGroup(response.data);

          const newGroupID = response.data.id;

          // URL for create rule value (Workaround)
          const urlRuleText = API.URL + 'conversations/' + response.data.id;

          receivers.push(userID);

          // Post value for validation
          const dataTwo = {
            rule: rule,
            receiverIds: receivers,
          };

          axios
            .put(urlRuleText, dataTwo, {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            })
            .then((response) => {
              if(response.status == 200){
                setShowGroupAlert(true);
                setShowGroup(false);
                setReceivers([]);

                setTimeout(() => {
                  setShowGroupAlert(false);
                  setShowGroup(false);
                  setReceivers([]);
                }, 1000);

                setTimeout(async () => {
                  const result = await axios.get(
                    urlGroup, {
                      headers: {
                        'Content-Type': 'application/json',
                        authorization: token
                      }
                    }
                  );

                  setGroups(result.data.items);

                  if(isTablet){
                    window.location.href = `/dashboard/communities/${newGroupID}/channel`;
                  } else {
                    window.location.href = `/dashboard/communities/${newGroupID}`;
                  }
                }, 1200);
              }
            })
        })
    } else {
      document.getElementsByClassName("create-name")[0].classList.add("error-field");
      document.getElementsByClassName("error-text")[0].style.display = "block";
      document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a name.";

      document.getElementsByClassName("error-text")[3].style.display = "block";
      //document.getElementsByClassName("error-text")[3].innerHTML = "Please select members.";

      document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "The community name cannot be empty and the members to be added to this group must contain at least one contact.";
      document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
    }
  }

  const Schema = Yup.object().shape({
    name: Yup.string()
      .required('Please enter a name for the group.'),
    description: Yup.string(),
    rule: Yup.string(),
    receivers: Yup.array()
      .required('Please add more than one trusted contacts to create a community.'),
    imageId: Yup.number()
  });

  const imageUploadSave = (element) => {
    // URL for the files
    const urlFiles = API.URL + 'files';

    // Post value for validation - Files
    let dataFiles = new FormData();
    dataFiles.append("fileType", "IMAGE");
    dataFiles.append("file", element);
    dataFiles.append("type", "POST_IMAGE");

    // fetch data and validate
    axios
      .post(urlFiles, dataFiles, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setImageId(response.data.id);
          console.log(response.data);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const previewFile = () => {
    const preview = document.querySelector('.tbs--user--profile--image--default');
    const imageContainer = document.querySelector(".new-community-top-layout .tbs--default--image");
    const imageElement = document.querySelector(".new-community-top-layout .tbs--default--image > img");

    const file = document.querySelector('#upload-image').files[0];
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      preview.src = reader.result;
    }, false);

    if (file) {
      reader.readAsDataURL(file);
      imageUploadSave(file);

      imageContainer.classList.remove("tbs--default--image");
      imageContainer.classList.add("create-new-community-upload-image");
      imageElement.style.width = "100%";
    }
  }

  const saveContacts = () => {
    // Get all values of the checkboxes which is checked.
    const data = [...document.querySelectorAll('.contact-friend:checked')].map(e => Number(e.value));

    setReceivers(data);
    setShowMembers(false);

    console.log(data)
  }

  // Get 'Tip - Groups' - Storage
  let groupsTip = localStorage.getItem("tbs-tip-groups-" + userID);

  // Execute the API and get all informations
  useEffect( async () => {
    if(groupsTip){
      document.getElementById("tip-groups").classList.add("tips-disabled");
    } else {
      document.getElementById("tip-groups").classList.remove("tips-disabled");
    }

    // Get url from group [Community Info]
    const urlCommunity = API.URL + 'conversations?isNew=true&type=GROUP';

    const response = await axios
      .get(urlCommunity, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      });

    let dataResponse = response.data.items;

    let newArr = [];

    dataResponse.map(data => {
      newArr.push(data.id);
    })

    let txt = [];

    newArr.map(async (number) => {
        txt.push({Id: number, Duration: await getMuteValue(number)});
      }
    );

    setGroupDuration(txt);

    /*const mainElement = document.getElementById("groups");

    if(window.location.href.indexOf("/dashboard/communities") && !isBiggerThanTablet){
      mainElement.style.paddingBottom = "350px";
    }

    if(window.location.href.indexOf("/dashboard/communities") && isBiggerThanTablet){
      mainElement.style.paddingBottom = "300px";
    }*/
  }, []);

  const selectImage = () => {
    document.getElementById("upload-image").click();
  }

  const deleteNewUploadFile = () => {
    setImageId(null)
  }

  return (
    <ReactBootstrap.Row id="group-create" className="create-new-community">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="tbs--headline">
                New group
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
              <div className="form-create-group">
                <div className="tbs--form--main--error text-center"></div>
                <div className="tbs--form--complete--post">
                  <Formik
                    initialValues={{
                      name: '',
                      description: '',
                      rule: '',
                      receiverIds: [],
                      type: 'GROUP',
                      imageId: imageId
                    }}
                    validationSchema={Schema}
                    onSubmit={values => {
                      console.log(values.text);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form onSubmit={createNewGroup}>
                        <div className="text-left">
                          <label htmlFor="name">
                            GROUP NAME
                          </label>
                        </div>
                        <div className="text-left">
                          <Field type="text" className={`create-name`} name="name" id="name" placeholder="Group Name" value={name} onChange={handleChangeName}/>
                        </div>
                        <div className="error-text text-left">
                          {/*
                            errors.name && touched.name
                            ?
                              errors.name
                            :
                              null
                          */}
                          <ErrorMessage name="name">
                            {errors.name}
                          </ErrorMessage>
                        </div>
                        <div className="text-left">
                          <label htmlFor="upload-image">
                            IMAGE
                          </label>
                        </div>
                        {
                          imageId == null
                            ?
                            <div>
                              <div className={`upload-new-image`} name="image" id="image" onClick={selectImage}>
                                Add image
                              </div>
                              <input type="file" id="upload-image" accept="image/*" onChange={previewFile} hidden/>
                            </div>
                            :
                            ""
                        }
                        <ReactBootstrap.Row className={imageId == null ? "new-post-top-layout-added hide" : "new-post-top-layout-added"}>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <label htmlFor="upload-image" className="new-image-upload">
                              <div className="tbs--default--image">
                                <img src={IconImage} alt="Profile - Image" className={imageId != null ? "tbs--user--profile--image--upload" : "tbs--user--profile--image--default"}/>
                                <input type="file" id="upload-image" onChange={previewFile} hidden/>
                                {/*<label htmlFor="upload-image" className="tbs--upload--image">UPLOAD AN IMAGE</label>*/}
                                <div className="text-center" id="edit-file">
                                  <label htmlFor="upload-image" className="tbs--upload--image new-upload">
                                    {/*UPLOAD AN IMAGE*/}
                                    <img src={UploadFile} alt="Upload file" id="upload-file"/>
                                  </label>
                                </div>
                                <div className="text-center" id="delete-file" onClick={deleteNewUploadFile}>
                                  <label className="tbs--upload--image new-upload">
                                    {/*UPLOAD AN IMAGE*/}
                                    <img src={DeleteFile} alt="Upload file" id="delete-upload-file"/>
                                  </label>
                                </div>
                              </div>
                            </label>
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <div className="text-left">
                          <label htmlFor="description">
                            ABOUT
                          </label>
                        </div>
                        <div className="text-left">
                          <Field as="textarea" className={`create-description`} name="description" id="description" placeholder="Add some info about your group's purpose and desired output." value={description} onChange={handleChangeDescription}/>
                        </div>
                        <div className="error-text text-left">
                          {/*
                        errors.description && touched.description
                          ?
                          errors.description
                          :
                          null
                      */}
                          <ErrorMessage name="description">
                            {errors.description}
                          </ErrorMessage>
                        </div>
                        <div className="text-left">
                          <label htmlFor="rule">
                            RULES
                          </label>
                        </div>
                        <div className="text-left">
                          <Field as="textarea" className={`create-rule`} name="rule" id="rule" placeholder="Enter here the rules of your group." value={rule} onChange={handleChangeRule}/>
                        </div>
                        <div className="error-text text-left">
                          {/*
                        errors.rule && touched.rule
                          ?
                          errors.rule
                          :
                          null
                      */}
                          <ErrorMessage name="rule">
                            {errors.rule}
                          </ErrorMessage>
                        </div>
                        <div className="text-left">
                          <label htmlFor="admin">
                            ADMIN
                          </label>
                        </div>
                        <div className="text-left">
                          <img src={user.profileImage != null ? user.profileImage.url : IconAvatar} alt="Profile image" />
                          <div>
                            {user.name}
                          </div>
                        </div>
                        {
                          /*
                            <div className="error-text text-center tbs--add--members"></div>
                          */
                        }
                        <div className="text-left admin-and-new-members">
                          <label htmlFor="members">
                            MEMBERS LIST
                          </label>
                        </div>
                        <div className="text-left" id="select-contact-group">
                          <div className="tbs--contact--group" onClick={() => setShowMembers(true)}>
                            <img src={IconAvatar} alt="Add new members" />
                            <div>
                              Add members
                            </div>
                          </div>
                          {
                            friends.map(friend => {
                              if(receivers.includes(friend.friendId)){
                                return(
                                  <div className="tbs--contact--group">
                                    <img src={friend.user.image != null ? friend.user.image : friend.user.profileImage != null ? friend.user.profileImage.url : IconAvatar} alt={friend.user.name} />
                                    <div>
                                      {friend.user.name}
                                    </div>
                                  </div>
                                )
                              }
                            })
                          }
                        </div>
                        <ReactBootstrap.Modal
                          show={showMembers}
                          onHide={() => setShowMembers(false)}
                          size="sm"
                          aria-labelledby="contained-modal-title-vcenter-two"
                          centered
                        >
                          <ReactBootstrap.Modal.Header closeButton>
                            <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-two">
                              Add members
                            </ReactBootstrap.Modal.Title>
                          </ReactBootstrap.Modal.Header>
                          <ReactBootstrap.Modal.Body id="group-select-contact">
                            {
                              friends.map(friend => {
                                return (
                                  <ReactBootstrap.Row>
                                    <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--image--select">
                                      <img src={
                                        friend.user.image != null
                                          ?
                                          friend.user.image
                                          :
                                          friend.user.profileImage != null
                                            ?
                                            friend.user.profileImage.url
                                            :
                                            IconAvatar
                                      }
                                           alt="Contact image"
                                      />
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={8} sm={8} md={8} lg={8} xl={8} className="tbs--contact--name--select">
                                      {friend.user.name}
                                    </ReactBootstrap.Col>
                                    <ReactBootstrap.Col xs={2} sm={2} md={2} lg={2} xl={2} className="tbs--contact--checkbox--select">
                                      <input type="checkbox" className="contact-friend" name="contact" value={friend.friendId} />
                                    </ReactBootstrap.Col>
                                  </ReactBootstrap.Row>
                                )
                              })
                            }
                            <div className="text-center button--sign">
                              <ReactBootstrap.Button onClick={() => saveContacts()}>Add Members</ReactBootstrap.Button>
                            </div>
                          </ReactBootstrap.Modal.Body>
                        </ReactBootstrap.Modal>
                        <div className="text-center button--sign">
                          <ReactBootstrap.Button type="submit">Create Group</ReactBootstrap.Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          showGroupAlert
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="create-group-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>Group successful created.</ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default CreateCommunity;
