import React from 'react';

const NotificationWhiteSVG = () => {
  return(
    <svg id="Icon_ionic-ios-notifications" data-name="Icon ionic-ios-notifications" xmlns="http://www.w3.org/2000/svg" width="63.963" height="90" viewBox="0 0 63.963 90">
      <path id="Pfad_125" data-name="Pfad 125" d="M17.993,32.062c2.187,0,3.382-1.547,3.382-3.727H14.6C14.6,30.516,15.8,32.062,17.993,32.062Z" transform="translate(13.928 57.938)" fill="#fff"/>
      <path id="Pfad_126" data-name="Pfad 126" d="M69.914,71.6c-3.079-4.636-9.138-7.354-9.138-28.116,0-21.309-8.238-29.874-15.916-31.93-.72-.206-1.24-.48-1.24-1.348V9.549c0-3.061-2.2-5.641-4.879-5.619-2.679-.046-4.879,2.558-4.879,5.619v.662c0,.845-.52,1.142-1.24,1.348-7.7,2.078-15.916,10.62-15.916,31.93,0,20.761-6.058,23.456-9.138,28.116-1.979,2.992-.1,7.263,3.179,7.263H66.755C70.014,78.868,71.893,74.574,69.914,71.6Z" transform="translate(-6.761 -3.93)" fill="#fff"/>
    </svg>
  )
}

export default NotificationWhiteSVG;
