import React, {useEffect, useState} from 'react';
import {Link, Switch, Route, useLocation, useRouteMatch, Redirect, BrowserRouter as Router, useHistory} from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import moment from "moment";
import {useMediaQuery} from "react-responsive";

import API from "../../config/config.json";

import Channel from "./groups/channel";

/* Own images */
import IconAvatar from './../../assets/svg/avatar.svg';
/*import IconImage from './../../assets/icons/IconImage.png';*/
//import IconThreeDots from './../../assets/icons/IconThreeDots.png';
import IconNewChat from './../../assets/icons/IconNewChat.png';
import IconClose from "../../assets/icons/IconDelete.png";
import IconTip from "../../assets/icons/IconTip.png";

/* SVG - Partner */
import AcceptSVG from "../../assets/svg/accept";
import IntroduceSVG from "../../assets/svg/introduce";
import DefaultImageSVG from "../../assets/svg/defaultImage";
import ActionMenu from "./actionMenu";
import IconImage from "../../assets/svg/defaultImage.svg";
import UploadFile from "../../assets/svg/uploadFile.svg";
import DeleteFile from "../../assets/svg/deleteUploadFile.svg";
import IconThreeDots from "../../assets/svg/dotMenu.svg";

function PublicGroups() {
  // Validation and get the correct path and url from pages
  let { path, url } = useRouteMatch();

  // Get url from groups
  const urlGroup = API.URL + 'public-group-conversations';
  const urlAvailableGroup = API.URL + 'conversations?isNew=true&type=GROUP';
  const urlUser = API.URL + 'users';
  const urlFriends = API.URL + 'friends';

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");
  let userID;

  if(localStorage.getItem("tbs-data")){
    userID = JSON.parse(localStorage.getItem("tbs-data")).user.id;
  }

  // Store and set the state value
  const [rule, setRule] = useState('');
  const [imageId, setImageId] = useState('');
  const [groups, setGroups] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [user, setUser] = useState([]);
  const [friends, setFriends] = useState([]);
  const [groupDuration, setGroupDuration] = useState([]);
  const [isBiggerThanTablet, setIsBiggerThanTablet] = useState(window.innerWidth > 991);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' })

  // Main container for history
  let history = useHistory();

  // Main container for location
  const location = useLocation();

  useEffect(async () => {
    const handleWindowResize = () => setIsBiggerThanTablet(window.innerWidth > 991);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  },[]);

  // Execute the API and get all informations
  useEffect(async () => {
    const result = await axios.get(
      urlGroup, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    let combineAllGroups = [];

    let pagesCount = result.data.paginate.pages;

    result.data.items.map(item => {
      combineAllGroups.push(item);
    })

    for(let i = 1; i < pagesCount; i++){
      let urlGroupAll = API.URL + 'public-group-conversations?page=' + (i+1);

      let resultPublicGroups = await axios.get(
        urlGroupAll, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          }
        }
      );

      resultPublicGroups.data.items.map(item => {
        combineAllGroups.push(item);
      })
    }

    combineAllGroups.reverse();

    setGroups(combineAllGroups);

    const resultOwn = await axios.get(
      urlAvailableGroup, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    let ownGroups = [];

    resultOwn.data.items.map(item => {
      ownGroups.push(item.id)
    })

    setAvailableGroups(ownGroups);

    const resultUsers = await axios.get(
      urlUser, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setUser(resultUsers.data);

    const resultFriends = await axios.get(
      urlFriends, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setFriends(resultFriends.data.items);

    // Default image ID
    setImageId(null)
    setRule('')
  }, [])

  const getMuteValue = async (id) => {
    // Get url from group [Community Info]
    const urlCommunity = API.URL + 'conversations/' + id;

    const response = await axios
      .get(urlCommunity, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      });

    return response.data.data.conversationUser.muteDuration;
  }

  const truncateMessage = (str, num) => {

    if(str == null || !str) {
      return;
    }

    if(str.length <= num) {
      return str;
    }

    return str.slice(0, num) + '...';
  }

  // Get 'Tip - Groups' - Storage
  let groupsTip = localStorage.getItem("tbs-tip-groups-" + userID);

  // Execute the API and get all informations
  useEffect( async () => {
    if(groupsTip){
      document.getElementById("tip-groups").classList.add("tips-disabled");
    } else {
      document.getElementById("tip-groups").classList.remove("tips-disabled");
    }

    // Get url from group [Community Info]
    const urlCommunity = API.URL + 'conversations?isNew=true&type=GROUP';

    const response = await axios
      .get(urlCommunity, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      });

    let dataResponse = response.data.items;

    let newArr = [];

    dataResponse.map(data => {
      newArr.push(data.id);
    })

    let txt = [];

    newArr.map(async (number) => {
        txt.push({Id: number, Duration: await getMuteValue(number)});
      }
    );

    setGroupDuration(txt);

    /*const mainElement = document.getElementById("groups");

    if(window.location.href.indexOf("/dashboard/communities") && !isBiggerThanTablet){
      mainElement.style.paddingBottom = "350px";
    }

    if(window.location.href.indexOf("/dashboard/communities") && isBiggerThanTablet){
      mainElement.style.paddingBottom = "300px";
    }*/
  }, []);

  useEffect(() => {
    const contentInfo = document.getElementById("no-data-community");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading content";
      setTimeout( () => {
        contentInfo.innerHTML = "You don't have any group conversations";
      }, 5000)
    }
  }, [])

  useEffect(() => {
    const contentInfo = document.getElementById("group-default-message");
    if(typeof(contentInfo) != 'undefined' && contentInfo != null){
      contentInfo.innerHTML = "Loading conversations";
      setTimeout( () => {
        contentInfo.innerHTML = "Please select a group conversation.";
      }, 5000)
    }
  }, [])

  const publicClickGroup = () => {
    if(!token){
      setTimeout(() => {
        window.location.reload();
      }, 300)
    } else {
      console.log("Redirect")
    }
  }

  const searchingGroup = () => {
    let input, filter, listContainer, listContainerNoGroups, listElement, groupNameText, index, textValue;

    input = document.getElementById('groupSearch');
    filter = input.value.toUpperCase();
    listContainer = document.getElementById("all-groups");
    listContainerNoGroups = document.getElementById("no-groups");
    listElement = listContainer.getElementsByClassName('row');

    for (index = 0; index < listElement.length; index++) {
      groupNameText = listElement[index].querySelector("div > a > div > div > div:last-child > div:first-child");
      textValue = groupNameText.textContent || groupNameText.innerText;

      if (textValue.toUpperCase().indexOf(filter) > -1) {
        listElement[index].style.display = "";
      } else {
        listElement[index].style.display = "none";
      }
    }
  }

  if (!groups || groups.length == 0) {
    return (
      <ReactBootstrap.Row id="public-groups">
        <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist tbs--new--title">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                window.location.pathname.indexOf("/public-groups") && !window.location.pathname.indexOf("/dashboard/public-groups")
                ?
                  <>
                    <div className="tbs--headline">
                      Public groups
                    </div>
                    <div className="tbs--divider--title tbs--additional--menu">
                      <hr></hr>
                    </div>
                  </>
                :
                  <>
                    <div className="tbs--headline--outside">
                      Public groups
                    </div>
                  </>
              }
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="group-contacts">
          <ReactBootstrap.Container fluid={true} className="tbs--container tbs--new--title">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div id="tbs-no-public-groups">
                  {/*No public groups available*/}
                  Loading content ...
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  } else {
    return (
      <ReactBootstrap.Row id="public-groups">
        <ReactBootstrap.Container fluid={true} className="tbs--container--feedlist tbs--new--title">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                window.location.pathname.indexOf("/public-groups") && !window.location.pathname.indexOf("/dashboard/public-groups")
                  ?
                  <>
                    <div className="tbs--headline">
                      Public groups
                    </div>
                    <div className="tbs--divider--title tbs--additional--menu">
                      <hr></hr>
                    </div>
                  </>
                  :
                  <>
                    <div className="tbs--headline--outside">
                      Public groups
                    </div>
                  </>
              }
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="group-contacts-search">
          <input type="text" id="groupSearch" onKeyUp={searchingGroup} placeholder="Search for group name"/>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="group-contacts">
          <ReactBootstrap.Container fluid={true} className="tbs--container tbs--new--title" id="no-groups">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="tbs--chat--name--no--results">
                  No results
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
          <ReactBootstrap.Container fluid={true} className="tbs--container tbs--new--title" id="all-groups">
            {
              groups.map((group, index) => {
                if(group.groupType === null && group.type === "GROUP") {
                  return (
                    <ReactBootstrap.Row id={`group-row-${group.id}`}>
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} key={index} id={`group-${group.id}`}>
                        {
                          <Link to={availableGroups.includes(group.id) ? `/dashboard/communities/${group.id}/community` : `${url}/${group.id}/community`} onClick={publicClickGroup}>
                            <ReactBootstrap.Row className={group.image == null ? "tbs--image--chats--no--image" : ""}>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="tbs--image--chats">
                                  <div>
                                    {
                                      group.image != null
                                        ?
                                        <img src={group.image.url} alt="Profile Image"/>
                                        :
                                        <DefaultImageSVG/>
                                    }
                                  </div>
                                </div>
                                <div>
                                  <div className="tbs--chat--name">
                                    {group.name}
                                  </div>
                                  <div className="tbs--chat--update--date">
                                    {moment(group.updatedAt).format('MMMM DD YYYY, hh:mm:A')}
                                  </div>
                                  {
                                    group.description != "" || group.description != null
                                    ?
                                      <div>
                                        <div className="tbs--chat--last--message">
                                          {truncateMessage(group.description, 25)}
                                        </div>
                                      </div>
                                    :
                                      ""
                                  }
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                          </Link>
                        }
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  )
                }
              })
            }
          </ReactBootstrap.Container>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  }
}

export default PublicGroups;
