import React from 'react';
import * as ReactBootstrap from 'react-bootstrap';

function MobileApp() {
  return (
    <ReactBootstrap.Container fluid={true} id="mobile-app-dashboard" className="tbs--container">
      <ReactBootstrap.Row id="my-coins-overview">
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h2>Mobile App</h2>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          Meoh looks better on the app.
          <br/><br/>
          Download on <a href="https://play.google.com/store/apps/details?id=com.luezoid.meoh" target="_blank">Google Play</a> or <a href="https://apps.apple.com/de/app/meoh/id1466786850" target="_blank">Apple store</a>.
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    </ReactBootstrap.Container>
  )
}

export default MobileApp;
