import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {useHistory} from "react-router-dom";
import API from "../../config/config.json";
import axios from "axios";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";

//import IconImage from "../../assets/icons/IconImage.png";

/* SVG */
import IconImage from "../../assets/svg/defaultImage.svg";

function Report() {

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");

  // Store and set the state value
  const [text, setText] = useState('');
  const [show, setShow] = useState(false);
  //const [image, setImage] = useState('');
  const [imageId, setImageId] = useState('');
  const [isRedirect, setIsRedirect] = useState(false);

  // Main container for history
  let history = useHistory();

  // Make a redirect if value is true
  useEffect(() => {
    (() => {
      if(isRedirect) {
        history.push("/dashboard/newsfeed");
      }
    })()

    setImageId(null)
  }, [isRedirect]);

  const handleChangeText = e => setText(e.target.value);

  const imageUploadSave = (element) => {
    // URL for the files
    const urlFiles = API.URL + 'files';

    // Post value for validation - Files
    let dataFiles = new FormData();
    dataFiles.append("fileType", "IMAGE");
    dataFiles.append("file", element);
    dataFiles.append("type", "BUG_IMAGE");

    // fetch data and validate
    axios
      .post(urlFiles, dataFiles, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setImageId(response.data.id);
          console.log(response.data);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Feedback validation method
  const validationBugReport = (e) => {
    e.preventDefault();

    // URL for the feedback
    const url = API.URL + 'feedback';

    // Post value for validation
    const data = {
      type: "BUG",
      text: text,
      fileId: imageId
    };

    // fetch data and validate
    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setShow(true);

          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("bug")[0].classList.remove("error-field");

          setTimeout(() => {
            setShow(false);
          }, 1500);

          setTimeout(() => {
            setIsRedirect(true);
          }, 2000);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        document.getElementsByClassName("error-text")[0].style.display = "block";
        document.getElementsByClassName("error-text")[0].innerHTML = "Please enter your feedback.";
        document.getElementsByClassName("bug")[0].classList.add("error-field");

        console.log(e)
      })
  }

  const Schema = Yup.object().shape({
    text: Yup.string()
      .required('Please enter your feedback.'),
    imageId: Yup.number()
  });

  const previewFile = () => {
    const preview = document.querySelector('.tbs--user--profile--image--default');
    const imageContainer = document.querySelector('#bug-report .tbs--default--image');
    const imageElement = document.querySelector('#bug-report .tbs--default--image > img');

    const file = document.querySelector('#upload-image').files[0];
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      preview.src = reader.result;
    }, false);

    if (file) {
      reader.readAsDataURL(file);
      imageUploadSave(file);
      imageContainer.classList.remove("tbs--default--image")
      imageContainer.classList.add("tbs--upload--image--new--post")
      imageElement.style.width = "98.5%"
    }
  }

  return (
    <ReactBootstrap.Row id="bug-report">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true}>
          <ReactBootstrap.Row>
            <div className="tbs--headline tbs--headline--bug">
              Bug report
            </div>
            <div className="tbs--divider--title tbs--bug-report--submenu--divider">
              <hr></hr>
            </div>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container">
          <ReactBootstrap.Row>
            {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--bug">
              Something went wrong?
            </ReactBootstrap.Col>*/}
            {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--bug">
              Please help us fix the problem by sending a bug report.
            </ReactBootstrap.Col>*/}
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--bug">
              <Formik
                initialValues={{
                  text: '',
                  imageId: imageId,
                }}
                validationSchema={Schema}
                onSubmit={values => {
                  console.log(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form onSubmit={validationBugReport}>
                    <Field as="textarea" className={`bug`} rows="10" name="text" placeholder="Please help us fix the problem by sending a bug report." value={text} onChange={handleChangeText} />
                    {/*Provide your feedback here*/}
                    <div className="error-text">
                      {/*
                        errors.text && touched.text
                          ?
                          errors.text
                          :
                          null
                      */}
                      <ErrorMessage name="text">
                        {errors.text}
                      </ErrorMessage>
                    </div>
                    <ReactBootstrap.Row>
                      {/*
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div className="tbs--default--image">
                            <img src={IconImage} alt="Profile - Image" className="tbs--user--profile--image--default" placeholder="if possible, uploaded a screenshot."/>
                          </div>
                        </ReactBootstrap.Col>
                      */}
                      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                        <input type="file" id="upload-image" onChange={previewFile} hidden/>
                        <label htmlFor="upload-image" className="tbs--upload--image">Upload a screenshot</label>
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                    <div className="text-center">
                      <ReactBootstrap.Button className="bug-report-btn" type="submit">Send</ReactBootstrap.Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--bug text-center">
              Thank you.
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
        {
          show
            ?
            <ReactBootstrap.Alert variant="success" transition={true} id="bug-alert" className="text-center">
              <ReactBootstrap.Alert.Heading>Bug report successfully to Meoh</ReactBootstrap.Alert.Heading>
            </ReactBootstrap.Alert>
            :
            ""
        }
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default Report;
