import React, { Component } from 'react';
import PropTypes from 'prop-types';

import 'mmenu-js/dist/mmenu'
import 'mmenu-js/dist/mmenu.css';

/* Website: https://mmenujs.com/ */

// Core
import Mmenu from "mmenu-js/dist/core/oncanvas/mmenu.oncanvas";

// Core Add-Ons
import offcanvas from "mmenu-js/dist/core/offcanvas/mmenu.offcanvas";
import screenReader from "mmenu-js/dist/core/screenreader/mmenu.screenreader";
import scrollBugFix from "mmenu-js/dist/core/scrollbugfix/mmenu.scrollbugfix";

// Wrappers
import bootstrap from "mmenu-js/dist/wrappers/bootstrap/mmenu.bootstrap";

// Add-Ons
import autoheight from "mmenu-js/dist/addons/autoheight/mmenu.autoheight";
import backbutton from "mmenu-js/dist/addons/backbutton/mmenu.backbutton";
import columns from "mmenu-js/dist/addons/columns/mmenu.columns";
import counters from "mmenu-js/dist/addons/counters/mmenu.counters";
import dividers from "mmenu-js/dist/addons/dividers/mmenu.dividers";
import drag from "mmenu-js/dist/addons/drag/mmenu.drag";
import dropdown from "mmenu-js/dist/addons/dropdown/mmenu.dropdown";
import fixedelements from "mmenu-js/dist/addons/fixedelements/mmenu.fixedelements";
import iconbar from "mmenu-js/dist/addons/iconbar/mmenu.iconbar";
import iconpanels from "mmenu-js/dist/addons/iconpanels/mmenu.iconpanels";
import keyboardnavigation from "mmenu-js/dist/addons/keyboardnavigation/mmenu.keyboardnavigation";
import lazysubmenus from "mmenu-js/dist/addons/lazysubmenus/mmenu.lazysubmenus";
import navbars from "mmenu-js/dist/addons/navbars/mmenu.navbars";
import pagescroll from "mmenu-js/dist/addons/pagescroll/mmenu.pagescroll";
import searchfield from "mmenu-js/dist/addons/searchfield/mmenu.searchfield";
import sectionindexer from "mmenu-js/dist/addons/sectionindexer/mmenu.sectionindexer";
import setselected from "mmenu-js/dist/addons/setselected/mmenu.setselected";
import sidebar from "mmenu-js/dist/addons/sidebar/mmenu.sidebar";
import toggles from "mmenu-js/dist/addons/toggles/mmenu.toggles";

class Navi extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount(){

    Mmenu.addons = {
      // Core
      offcanvas,
      screenReader,
      scrollBugFix,
      // Wrappers
      bootstrap,
      // Add-Ons
      autoheight,
      backbutton,
      columns,
      counters,
      dividers,
      drag,
      dropdown,
      fixedelements,
      iconbar,
      iconpanels,
      keyboardnavigation,
      lazysubmenus,
      navbars,
      pagescroll,
      searchfield,
      sectionindexer,
      setselected,
      sidebar,
      toggles
    };

    window.Mmenu = Mmenu;

    document.addEventListener(
      "DOMContentLoaded", () => {
        new Mmenu( "#" + this.props.id, this.props.options );
      }
    );

  }

  render(){
    return(
      <nav id={this.props.id}>
        {this.props.children}
      </nav>
    );
  }

}

Navi.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  children: PropTypes.element
}

// #####################################

class NaviList extends Component {

  render(){
    return(
      <ul className={this.props.class}>
        {this.props.children}
      </ul>
    );
  }

}

NaviList.propTypes = {
  class: PropTypes.string.isRequired,
  children: PropTypes.element
}

// #####################################

class NaviListItem extends Component {

  render(){
    return(
      <li className={this.props.class}>
        {this.props.children}
      </li>
    );
  }

}

NaviListItem.propTypes = {
  class: PropTypes.string.isRequired,
  children: PropTypes.element
}

// #####################################

class NaviListText extends Component {

  render(){
    return(
      <span className={this.props.class}>
        {this.props.children}
      </span>
    );
  }

}

NaviListText.propTypes = {
  class: PropTypes.string.isRequired,
  children: PropTypes.element
}

// #####################################

class NaviListLink extends Component {

  render(){
    return(
      <a href={this.props.url}>
        {this.props.children}
      </a>
    );
  }

}

NaviListLink.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.element
}

// #####################################

export {
  Navi,
  NaviList,
  NaviListItem,
  NaviListText,
  NaviListLink
}




/**
 * Mmenu - Only in Dashboard
 *
 * <div className="mmenu--offcanvas">
 <a href="#menu"><img src={MenuIcon} alt="Menu" /></a>
 </div>
 <Navi
 id="menu"
 options={{
                      "extensions": [
                        "pagedim-black",
                        "position-right"
                      ]
                    }}
 >
 <NaviList>
 <NaviListItem onClick={() => settingsLoad()}>
 <Link className="dropdown-item link--navi--style" to={`${url}/settings`}><img src={MenuSetting} alt="Settings" />Settings</Link>
 </NaviListItem>
 <NaviListItem>
 <Link className="dropdown-item link--navi--style" to={{ pathname: "https://www.meoh.io/app" }} target="_blank"><img src={MenuInfo} alt="About" />About</Link>
 </NaviListItem>
 <NaviListItem>
 <Link className="dropdown-item link--navi--style" to={{ pathname: "https://www.meoh.io/faq" }} target="_blank"><img src={MenuFAQ} alt="FAQs" />FAQs</Link>
 </NaviListItem>
 <NaviListItem>
 <Link className="dropdown-item link--navi--style" to={{ pathname: "https://www.meoh.io/donate" }} target="_blank"><img src={MenuDonation} alt="Donate" />Donate</Link>
 </NaviListItem>
 <NaviListItem>
 <Link className="dropdown-item link--navi--style" to={{ pathname: "https://www.meoh.io/guidelines" }} target="_blank"><img src={MenuCommunityGuideline} alt="Community Guidelines" />Community Guidelines</Link>
 </NaviListItem>
 <NaviListItem>
 <Link className="dropdown-item link--navi--style" to={{ pathname: "https://www.meoh.io/privacy" }} target="_blank"><img src={MenuPrivacy} className="custom--image--one" alt="Privacy Policy" />Privacy Policy</Link>
 </NaviListItem>
 <NaviListItem>
 <Link className="dropdown-item link--navi--style" to={{ pathname: "https://www.meoh.io/terms" }} target="_blank"><img src={MenuUserTerms} alt="User Terms" />User Terms</Link>
 </NaviListItem>
 <NaviListItem>
 <Link className="dropdown-item link--navi--style" to={`${url}/feedback`} ><img src={MenuFeedback} className="custom--image--two" alt="Feedback" />Feedback</Link>
 </NaviListItem>
 <NaviListItem>
 <Link className="dropdown-item link--navi--style" to={`${url}/report`} ><img src={MenuBug} alt="Bug Report" />Bug Report</Link>
 </NaviListItem>
 <NaviListItem>
 <Link className="dropdown-item link--navi--style d-block d-sm-block d-md-none d-lg-none d-xl-none" to={`${url}/logout`} onClick={() => validationLogout()}><img src={MenuLogout} alt="Logout" />Logout</Link>
 </NaviListItem>
 </NaviList>
 </Navi>
 */
