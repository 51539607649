import React from 'react';

const PostCommentSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="59.166" height="52" viewBox="0 0 59.166 52">
      <path id="Icon_awesome-comment" data-name="Icon awesome-comment" d="M28.571,2.25C12.79,2.25,0,12.641,0,25.464A20.6,20.6,0,0,0,6.362,40.051C4.967,45.676.3,50.688.246,50.743a.887.887,0,0,0-.167.971.874.874,0,0,0,.815.536,25.524,25.524,0,0,0,15.692-5.737,34.038,34.038,0,0,0,11.987,2.165c15.781,0,28.571-10.391,28.571-23.214S44.353,2.25,28.571,2.25Z" transform="translate(1.024 -1.25)" fill="none" stroke="#000" stroke-width="2"/>
    </svg>
  )
}

export default PostCommentSVG;
