import React from 'react';

const PostBookmarkSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="40.889" height="52" viewBox="0 0 40.889 52">
      <path id="Icon_feather-bookmark" data-name="Icon feather-bookmark" d="M46.389,54.5,26.944,40.611,7.5,54.5V10.056A5.556,5.556,0,0,1,13.056,4.5H40.833a5.556,5.556,0,0,1,5.556,5.556Z" transform="translate(-6.5 -3.5)" fill="none" stroke="#0a0a0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </svg>
  )
}

export default PostBookmarkSVG;
