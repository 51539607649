import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import axios from "axios";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import autosize from "autosize";
/* import { yupResolver } from "@hookform/resolvers/yup";*/

import {Recorder} from 'react-voice-recorder';
import 'react-voice-recorder/dist/index.css';

import API from "./../../../../config/config.json";

import IconAvatar from "../../../../assets/svg/avatar.svg";
//import IconImage from "../../../../assets/icons/IconImage.png";

/* SVG */
import IconImage from "../../../../assets/svg/defaultImage.svg";
import UploadFile from "../../../../assets/svg/uploadFile.svg";
import DeleteFile from "../../../../assets/svg/deleteUploadFile.svg";

function UpdateFeed(props) {

  // Get the params
  const { match: { params } } = props;

  // Get 'Access token' and save it in a variable
  let token = localStorage.getItem("tbs-token");

  // URL for post data
  const urlFeed = API.URL + 'posts/' + params.id;

  // Store and set the state value
  const [resultData, setResultData] = useState('');
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [imageId, setImageId] = useState('');
  const [audio, setAudio] = useState('');
  const [audioId, setAudioId] = useState('');
  const [recordAudio, setRecordAudio] = useState('');
  const [show, setShow] = useState(false);
  const [audioShow, setAudioShow] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [showOpenAudioModal, setShowOpenAudioModal] = useState(false);
  const [audioRecordFile, setAudioRecordFile] = useState(false);

  const [getFile, setGetFile] = useState("");
  const [getData, setGetData] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {
      h: null,
      m: null,
      s: null,
    }
  })

  // Main container for history
  let history = useHistory();

  // Make a redirect if value is true
  useEffect(() => {
    (() => {
      if(isRedirect) {
        history.push("/dashboard/profile");
      }
    })()
  }, [isRedirect]);

  // Execute the API and get all informations
  useEffect(async () => {
    const results = await axios.get(
      urlFeed, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      }
    );

    setResultData(results.data);

    let x = results.data.parentPost == null ? results.data.file == null ? IconImage : results.data.file.url : results.data.parentPost != null ? results.data.parentPost.file != null ? results.data.parentPost.file.url : IconImage : IconImage
    let y = results.data.parentPost == null ? results.data.audioFile == null ? "" : results.data.audioFile.url : results.data.parentPost != null ? results.data.parentPost.audioFile != null ? results.data.parentPost.audioFile.url : "" : ""

    setTitle(results.data.title);
    setDescription(results.data.description);
    setImage(x);
    setImageId(results.data.fileId);
    setAudio(y);
    setAudioId(results.data.audioId);

    const audios = document.querySelectorAll("#update-post audio");

    for(let i = 0; i < audios.length; i++){
      audios[i].setAttribute("controlslist","nodownload")
    }

    //document.querySelector("#audio-recording-update-post audio").setAttribute("controlslist","nodownload")

  }, []);

  useEffect(() => {
    const textElement = document.querySelectorAll("textarea");
    autosize(textElement);
  })

  const handleChangeDescription = e => setDescription(e.target.value);
  const handleChangeTitle = e => setTitle(e.target.value);

  const imageUploadSave = (element) => {
    // URL for the files
    const urlFiles = API.URL + 'files';

    // Post value for validation - Files
    let dataFiles = new FormData();
    dataFiles.append("fileType", "IMAGE");
    dataFiles.append("file", element);
    dataFiles.append("type", "POST_IMAGE");

    const imageUploadElement = document.querySelector("#update-post .tbs--default--image img");

    // fetch data and validate
    axios
      .post(urlFiles, dataFiles, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setImageId(response.data.id);
          console.log(response.data);
          imageUploadElement.classList.add("tbs--upload--image--newest");
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const audioUploadSave = async (element) => {
    // URL for the files
    const urlFiles = API.URL + 'files';

    const blobToBase64 = async blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };

    const convertAudioFile = await blobToBase64(element)
      .then((res) => {
        return res;
      })

    function urlToFile(url, filename, mimeType){
      return (fetch(url)
          .then(function(res){return res.arrayBuffer();})
          .then(function(buf){return new File([buf], filename,{type:mimeType});})
      );
    }
    const getAudioFile = await urlToFile(convertAudioFile, 'new-audio-record.mp3','audio/mp3')
      .then(function(file){
        return file;
      });

    console.log(getAudioFile)

    // Post value for validation - Files
    let dataFiles = new FormData();
    dataFiles.append("fileType", "AUDIO");
    dataFiles.append("file", getAudioFile);
    dataFiles.append("type", "POST_AUDIO");

    // fetch data and validate
    axios
      .post(urlFiles, dataFiles, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: token
        }
      })
      .then((response) => {
        if(response.status === 200){
          setAudioId(response.data.id);
          console.log(response.data);
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Update post validation method
  const validationUpdatePost = (e) => {
    e.preventDefault();

    // URL for post data
    const url = API.URL + 'posts/' + params.id;

    // Post value for validation
    const data = {
      type: "POST",
      description: description,
      title: title,
      fileId: imageId,
      audioId: audioId
    };

    // fetch data and validate
    axios
      .patch(url, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        }
      })
      .then((response) => {
        if(title != ""){
          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("error-text")[0].innerHTML = "";
          document.getElementsByClassName("update-title")[0].classList.remove("error-field");

          if(response.status === 200){
            setShow(true);

            document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "";
            document.getElementsByClassName("tbs--form--main--error")[0].style.display = "none";

            setTimeout(() => {
              setShow(false);
            }, 1000);

            setTimeout(() => {
              setIsRedirect(true);
            }, 1200);
          } else {
            document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Title can't be empty.";
            document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";

            console.log("Error: Access Denied");
          }
        } else {
          document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Title can't be empty.";
          document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
          document.getElementsByClassName("update-title")[0].classList.add("error-field");
          document.getElementsByClassName("error-text")[0].style.display = "block";
          document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a title.";
        }
      })
      .catch(e => {
        document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Title can't be empty.";
        document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
        document.getElementsByClassName("update-title")[0].classList.add("error-field");
        document.getElementsByClassName("error-text")[0].style.display = "block";
        document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a title.";
        console.log(e)
      })
  }

  const Schema = Yup.object().shape({
    title: Yup.string()
      .required('Please enter a title.'),
    imageId: Yup.number(),
    audioId: Yup.number(),
    description: Yup.string()
  });

  const previewFile = (defaultElement) => {
    if(defaultElement == true){
      const preview = document.querySelector('.tbs--user--profile--image--default');

      const file = document.querySelector('#upload-image').files[0];
      const reader = new FileReader();

      reader.addEventListener("load", function () {
        preview.src = reader.result;
      }, false);

      if (file) {
        reader.readAsDataURL(file);
        imageUploadSave(file);
      }
    } else {
      const preview = document.querySelector('.tbs--user--profile--image--default');

      const file = document.querySelector('#upload-image').files[0];
      const reader = new FileReader();

      reader.addEventListener("load", function () {
        preview.src = reader.result;
      }, false);

      if (file) {
        reader.readAsDataURL(file);
        imageUploadSave(file);
      }
    }
  }

  // Audio Recording - All
  const handleAudioStop = async (data) => {
    console.log(data)
    setGetData(data);

    console.log(data.url)
    setGetFile(data.url);

    setAudioRecordFile(true);

    setRecordAudio(data.url);

    await audioUploadSave(data.blob);

    document.querySelector("#audio-recording-update-post > div > div > div > div:nth-child(2) > a").classList.add("hide-record");
    document.querySelector("#audio-recording-update-post > div > div > div > div:nth-child(2) > a").classList.remove("show-record");
    document.querySelector("#audio-recording-update-post > div > div > div").classList.add("add-bottom");

  }

  const handleAudioUpload = (file) => {
    console.log(file);
  }

  const handleReset = () => {
    setGetData({
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: null,
        m: null,
        s: null,
      }
    });
  }

  const completeReset = () => {
    setAudioRecordFile(false);

    document.querySelector("#audio-recording-update-post > div > div > div > div > div > button:nth-child(2)").click();

    document.querySelector("#audio-recording-update-post > div > div > div > div:nth-child(2) > a").classList.remove("hide-record");
    document.querySelector("#audio-recording-update-post > div > div > div > div:nth-child(2) > a").classList.add("show-record");
    document.querySelector("#audio-recording-update-post > div > div > div").classList.remove("add-bottom");
  }

  const selectImage = () => {
    document.getElementById("upload-image").click();
  }

  const deleteNewUploadFile = () => {
    setImageId(null)
  }

  return (
    <ReactBootstrap.Row id="update-post">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ReactBootstrap.Container fluid={true} className="tbs--container">
          <ReactBootstrap.Row>
            <div className="tbs--headline tbs--headline--update-post">
              Edit Post
            </div>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="tbs--form--block--update--post">
              <div className="form-update-post">
                <div className="tbs--form--main--error text-center"></div>
                <div className="tbs--form--complete--post">
                  <Formik
                    initialValues={{
                      type: "POST",
                      imageId: imageId,
                      audioId: audioId,
                      title: title,
                      description: description,
                    }}
                    validationSchema={Schema}
                    onSubmit={values => {
                      console.log(values.text);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form onSubmit={validationUpdatePost}>
                        <ReactBootstrap.Row>
                          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div>
                              <label htmlFor="title">
                                TITLE
                              </label>
                            </div>
                            <div>
                              <Field type="text" maxLength={70} className={`update-title`} name="title" id="title" placeholder="Enter a title" value={title} onChange={handleChangeTitle}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.title && touched.title
                                  ?
                                  errors.title
                                  :
                                  null
                              */}
                              <ErrorMessage name="title">
                                {errors.title}
                              </ErrorMessage>
                            </div>
                            <div>
                              <label htmlFor="description">
                                TEXT
                              </label>
                            </div>
                            <div>
                              <Field as="textarea" maxLength={1500} rows={4} className={`update-description`} name="description" id="description" placeholder="Enter your description" value={description} onChange={handleChangeDescription}/>
                            </div>
                            <div className="error-text">
                              {/*
                                errors.description && touched.description
                                  ?
                                  errors.description
                                  :
                                  null
                              */}
                              <ErrorMessage name="description">
                                {errors.description}
                              </ErrorMessage>
                            </div>
                            <div>
                              <label htmlFor="upload-image">
                                IMAGE
                              </label>
                            </div>
                            {
                              imageId == null
                                ?
                                <div>
                                  <div className={`upload-new-image`} name="image" id="image" onClick={selectImage}>
                                    Add image
                                  </div>
                                  <input type="file" id="upload-image" accept="image/*" onChange={previewFile} hidden/>
                                </div>
                                :
                                ""
                            }
                            <ReactBootstrap.Row className={imageId != null ? "new-post-top-layout-added" : "new-post-top-layout-added hide"}>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <label htmlFor="upload-image" className="new-image-upload">
                                  <div className="tbs--default--image">
                                    <img src={image} alt="Profile - Image" className="tbs--user--profile--image--default"/>
                                    <input type="file" id="upload-image" accept="image/*" onChange={() => previewFile(true)} hidden/>
                                    <div id="upload-image-new-post" className="text-right">
                                      {/*<label htmlFor="upload-image" className="tbs--upload--image">
                                        UPLOAD AN IMAGE
                                        <img src={UploadFile} alt="Upload file" id="upload-file"/>
                                      </label>*/}
                                    </div>
                                    <div className="text-center" id="edit-file">
                                      <label htmlFor="upload-image" className="tbs--upload--image new-upload">
                                        {/*UPLOAD AN IMAGE*/}
                                        <img src={UploadFile} alt="Upload file" id="upload-file"/>
                                      </label>
                                    </div>
                                    <div className="text-center" id="delete-file" onClick={deleteNewUploadFile}>
                                      <label className="tbs--upload--image new-upload">
                                        {/*UPLOAD AN IMAGE*/}
                                        <img src={DeleteFile} alt="Upload file" id="delete-upload-file"/>
                                      </label>
                                    </div>
                                  </div>
                                </label>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                            {/*
                              image == IconImage
                              ?
                                <div className="tbs--default--image">
                                  <img src={image} alt="Profile - Image" className="tbs--user--profile--image--default"/>
                                  {
                                    image == IconImage
                                      ?
                                      <div id="upload-image-new-post" className="edit-post text-right">
                                        <input type="file" id="upload-image" onChange={() => previewFile(true)} hidden/>
                                        <label htmlFor="upload-image" className="tbs--upload--image">
                                          <img src={UploadFile} alt="Upload file" id="upload-file"/>
                                        </label>
                                      </div>
                                      :
                                      <div id="upload-image-new-post" className="edit-post text-right">
                                        <input type="file" id="upload-image" onChange={() => previewFile(false)} hidden/>
                                        <label htmlFor="upload-image" className="tbs--upload--image">
                                          <img src={UploadFile} alt="Upload file" id="upload-file"/>
                                        </label>
                                      </div>
                                  }
                                </div>
                              :
                                <div>
                                  <img src={image} alt="Profile - Image" className="tbs--user--profile--image"/>
                                  {
                                    image == IconImage
                                      ?
                                      <div id="upload-image-new-post" className="edit-post text-right">
                                        <input type="file" id="upload-image" onChange={() => previewFile(true)} hidden/>
                                        <label htmlFor="upload-image" className="tbs--upload--image">
                                          <img src={UploadFile} alt="Upload file" id="upload-file"/>
                                        </label>
                                      </div>
                                      :
                                      <div id="upload-image-new-post" className="edit-post text-right">
                                        <input type="file" id="upload-image" onChange={() => previewFile(false)} hidden/>
                                        <label htmlFor="upload-image" className="tbs--upload--image">
                                          <img src={UploadFile} alt="Upload file" id="upload-file"/>
                                        </label>
                                      </div>
                                  }
                                </div>
                            */}
                          </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                        <div>
                          <label>
                            AUDIO
                          </label>
                        </div>
                        {
                          recordAudio != ""
                          ?
                            <div className="text-center" id="new-audio-player">
                              <audio controls>
                                <source src={recordAudio} type="audio/mp3"/>
                                Your browser does not support the audio element.
                              </audio>
                            </div>
                          :
                            audioId != null && audio != ""
                            ?
                              <div className="text-center" id="default-audio-player">
                                <audio controls>
                                  <source src={audio} type="audio/webm"/>
                                  Your browser does not support the audio element.
                                </audio>
                              </div>
                            :
                              ""
                        }
                        <div className="text-center button--sign" id="audio-recorder">
                          {/*<ReactBootstrap.Button onClick={() => setShowOpenAudioModal(true)}>Record</ReactBootstrap.Button>*/}
                          <Field type="text" className={`create-audio-message`} name="audio" id="audio" placeholder="Audio message added." onClick={() => setShowOpenAudioModal(true)}/>
                        </div>
                        <ReactBootstrap.Modal
                          show={showOpenAudioModal}
                          onHide={() => setShowOpenAudioModal(false)}
                          size="md"
                          aria-labelledby="contained-modal-title-vcenter-record"
                          centered
                        >
                          <ReactBootstrap.Modal.Header closeButton>
                            <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-record">
                              Add audio message
                            </ReactBootstrap.Modal.Title>
                          </ReactBootstrap.Modal.Header>
                          <ReactBootstrap.Modal.Body id="audio-record-update-post">
                            <ReactBootstrap.Row>
                              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div id="audio-recording-update-post">
                                  <Recorder
                                    record={false}
                                    title={"Audio Recording"}
                                    audioURL={getData.url}
                                    showUIAudio
                                    hideHeader={false}
                                    handleAudioStop={data => handleAudioStop(data)}
                                    handleAudioUpload={data => handleAudioUpload(data)}
                                    handleReset={handleReset}
                                    //mimeTypeToUseWhenRecording={`audio/webm`}
                                  />
                                </div>
                              </ReactBootstrap.Col>
                            </ReactBootstrap.Row>
                            {
                              audioRecordFile
                              ?
                                <ReactBootstrap.Row>
                                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                    <div id="audio-recording-update-post-reset" onClick={() => completeReset()}>
                                      Reset
                                    </div>
                                  </ReactBootstrap.Col>
                                </ReactBootstrap.Row>
                              :
                                ""
                            }
                          </ReactBootstrap.Modal.Body>
                        </ReactBootstrap.Modal>
                        <div className="text-center button--sign" id="updating-post">
                          <ReactBootstrap.Button type="submit">Update</ReactBootstrap.Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </ReactBootstrap.Col>
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          show
            ?
              <ReactBootstrap.Alert variant="success" transition={true} id="update-post-alert" className="text-center">
                <ReactBootstrap.Alert.Heading>Publication successful updated.</ReactBootstrap.Alert.Heading>
              </ReactBootstrap.Alert>
            :
              ""
        }
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
}

export default UpdateFeed;
