import React from 'react';

const FavoritesSVG = () => {
  return(
    <svg id="Icon_awesome-bookmark" data-name="Icon awesome-bookmark" xmlns="http://www.w3.org/2000/svg" width="60" height="80" viewBox="0 0 60 80">
      <path id="Icon_awesome-bookmark-2" data-name="Icon awesome-bookmark" d="M0,80V7.5A7.5,7.5,0,0,1,7.5,0h45A7.5,7.5,0,0,1,60,7.5V80L30,62.5Z" fill="#141c27"/>
    </svg>
  )
}

export default FavoritesSVG;
